import { NumberProperty, NumberRangeProperty, ProjectMetricsType, PropertyBase, PropertyGroupArgsType, UnitsMapper } from "bim-ts";
import { IterUtils, RGBA, RGBAHex, ScopedLogger, StringUtils, ValueAndUnit } from "engine-utils-ts";
import jsPDF, { ShadingPattern } from "jspdf";
import { Aabb2, Vector2 } from "math-ts";
import { markChecked } from "./images/markChecked";
import { PalettePerType } from "./PdfCommon";


export type PdfTextStyle = 'normal' | 'bold' | 'transparent' | 'underline';

export function setTextStyle(page: jsPDF, style: PdfTextStyle | undefined, fontSize: number){
    if(style === 'bold') {
        page.setFont("Lato-Bold", "normal");
        page.setTextColor(0,0,0);
        page.setFontSize(fontSize);
    } else if(style === 'transparent') {
        page.setFont("Lato-Regular", "normal");
        page.setTextColor(0,0,0, 0.4);
        page.setFontSize(fontSize);
    } else if(style === 'underline'){
        page.setFont("Lato-Regular", "normal");
        page.setTextColor(0,0,0, 0.7);
        page.setFontSize(fontSize);
    } else {
        page.setFont("Lato-Regular", "normal");
        page.setTextColor(0,0,0);
        page.setFontSize(fontSize);
    }
}
interface PdfTextRowValue {
    value: string;
    style?: PdfTextStyle;
    unit?: string;
    icon?: "mark_selected"
}
interface PdfRowMetricValue {
    key: keyof PropertyGroupArgsType<ProjectMetricsType>, 
    style?: PdfTextStyle;
}
type PdfRowValueType = PdfTextRowValue | PdfRowMetricValue;
interface PdfTextRow {
    color?: RGBAHex;
    gradient?: [RGBAHex, RGBAHex];
    text: string;
    fontSize?: number;
    headerLevel?: number;
    style?: PdfTextStyle,
    values: PdfRowValueType[];
}

type RowParams = {
    name?: string;
    style?: PdfTextStyle;
    fontSize?: number;
    digits?: number;
    headerLevel?: number;
};

export class TextBuilder {
    private _offsetYPx = 0;
    private readonly nameOffset = 340;
    private readonly valuesOffsetPx = 100;
    private readonly offsetH1Px = 15;
    private readonly offsetH2Px = 12;
    private readonly baseOffsetPx = 7;

    private _lastRow: PdfTextRow | undefined;

    constructor(
        readonly unitsMapper: UnitsMapper,
        readonly page: jsPDF,
        offsetYPx: number,
        readonly leftOffsetPx: number,
        readonly totalMetrics: Partial<ProjectMetricsType> | null,
        readonly logger: ScopedLogger,
        readonly convertUnit: (value: number) => number
    ){
        this._offsetYPx = offsetYPx;
    }

    get offsetY() {
        return this.convertUnit(this._offsetYPx);
    }

    get offsetYPx() {
        return this._offsetYPx;
    }

    addRows(rows: PdfTextRow[]){
        for (const row of rows) {
            this.addRow(row);
        }
    }

    addRow(row: PdfTextRow){ 

        let fontSize = row.fontSize;
        let valueFontSize = row.fontSize;
        if(fontSize == null && row.headerLevel == 1 && row.style === 'bold'){
            fontSize = 15;
            valueFontSize = 13;
        } else if(fontSize == null && row.style === "underline"){
            fontSize = 10;
            valueFontSize = 10;
        } else if(fontSize == null) {
            fontSize = 13;
            valueFontSize = 13;
        }
        this._offsetYPx += fontSize + this._getHeaderLevelOffset(row.headerLevel);

        let colorOffset = 0;
        if(row.color != undefined) {
            let offsetX = this.convertUnit(this.leftOffsetPx);
            let offsetY = this.convertUnit(this._offsetYPx);
            const rectSize = this.convertUnit(10);
            const round = this.convertUnit(2);
            const [r, g, b] = RGBA.toRgbArray(row.color);
            this.page.setFillColor(r, g, b);
            this.page.roundedRect(
                offsetX,
                offsetY - rectSize,
                rectSize,
                rectSize,
                round,
                round,
                'F'
            );
            colorOffset += this.convertUnit(4) + rectSize;
        }

        if(row.gradient != undefined) {
            let offsetX = this.convertUnit(this.leftOffsetPx);
            let offsetY = this.convertUnit(this._offsetYPx);
            const height = this.convertUnit(12);
            const width = this.convertUnit(32);
            const round = this.convertUnit(1);
            const [r1, g1, b1] = RGBA.toRgbArray(row.gradient[0]);
            const [r2, g2, b2] = RGBA.toRgbArray(row.gradient[1]);
            const key = 'linearGradient' + row.gradient[0] + row.gradient[1];
            this.page.advancedAPI(pdf => {
                pdf.addShadingPattern(key, 
                new ShadingPattern(
                    'axial', 
                    [0, 0, width, height],
                    [ {
                        offset: 0,
                        color: [r1, g1, b1]
                      },
                      {
                        offset: 1,
                        color: [r2, g2, b2]
                      }
                    ])
                );

                pdf.roundedRect(
                    offsetX,
                    offsetY - height,
                    width,
                    height,
                    round, 
                    round,
                    null
                ).fill({
                    key: key,
                    matrix: pdf.Matrix(
                        1, 0,
                        0, 1, 
                        offsetX, offsetY - height
                    )
                });
            });




            colorOffset += this.convertUnit(10) + width;
        }

        setTextStyle(this.page, row.style, fontSize);
        const lines = this.page.splitTextToSize(row.text, this.convertUnit(this.nameOffset - 80));
        this.page.text(lines.length > 1 ? `${lines[0]}...` : lines[0], colorOffset + this.convertUnit(this.leftOffsetPx), this.convertUnit(this._offsetYPx));
        let offsetPx = this.leftOffsetPx + this.nameOffset;
        for (const v of row.values) {
            setTextStyle(this.page, v.style, valueFontSize!);
            let value: string = "";
            let unit: string | undefined = undefined;
            if('key' in v){
                const metric = this.totalMetrics?.[v.key];
                if(metric instanceof NumberProperty){
                    const valueUnit = getFormattedValueUnit(metric, this.unitsMapper);
                    value = valueUnit.value;
                    unit = valueUnit.unit;
                } else {
                    value = "n/a";
                }
            } else {
                value = v.value;
                unit = v.unit;
            }
            const width = this.page.getTextWidth(value);
            this.page.text(value, this.convertUnit(offsetPx) - width, this.convertUnit(this._offsetYPx));
            if(unit){
                setTextStyle(this.page, 'transparent', valueFontSize!);
                this.page.text(unit, this.convertUnit(offsetPx + 2), this.convertUnit(this._offsetYPx));
            }

            if('icon' in v && v.icon === "mark_selected"){
                const sizePx = 16;
                const size = this.convertUnit(sizePx);
                const textHeight = this.page.getTextDimensions(v.value).h;
                this.page.addImage(
                    markChecked, 
                    'PNG', 
                    this.convertUnit(offsetPx), 
                    this.convertUnit(this._offsetYPx) - textHeight, 
                    size, 
                    size
                );
                offsetPx += sizePx;
            }
            offsetPx += this.valuesOffsetPx;
        }
        this._lastRow = row;
    }

    addFromNumberProp(
        key: string, 
        prop: NumberProperty, 
        params?: RowParams
    ) {
        const {value, unit} = getFormattedValueUnit(prop, this.unitsMapper, params?.digits);
        this.addRow({
            text: params?.name ?? StringUtils.capitalizeFirstLatterInWord(key as string), 
            fontSize: params?.fontSize, 
            style: params?.style,
            values: [
                { 
                    value, 
                    unit,
                    style: params?.style,
                }
            ], 

        });
    }

    addFromNumberRangeProp(
        key: string, 
        prop: NumberRangeProperty, 
        params?: RowParams
    ) {
        const valueUnit = prop.toConfiguredUnits(this.unitsMapper);
        const decimals = params?.digits ? params.digits : valueUnit.decimals === 0 ? 0 : 2;
        const value = valueUnit.value[0] === valueUnit.value[1] 
            ? valueUnit.value[0].toFixed(decimals)
            : `${valueUnit.value[0].toFixed(decimals)}-${valueUnit.value[1].toFixed(decimals)}`
        this.addRow({
            text: params?.name ?? StringUtils.capitalizeFirstLatterInWord(key as string), 
            fontSize: params?.fontSize, 
            style: params?.style,
            values: [
                { 
                    value: value, 
                    unit: valueUnit.unit,
                    style: params?.style,
                }
            ], 

        });
    }

    addFromMetric(            
        key: keyof PropertyGroupArgsType<ProjectMetricsType>, 
        params?: RowParams
    ) {
        const metric = this.totalMetrics?.[key];
        if(metric == undefined){
            this.addRow({
                text: params?.name ?? StringUtils.capitalizeFirstLatterInWord(key as string), 
                values: [{ value: "n/a", style: 'transparent' }],
            });
        } else if(metric instanceof NumberProperty){
            this.addFromNumberProp(key as string, metric, params);
        } else if(metric instanceof NumberRangeProperty){
            this.addFromNumberRangeProp(key as string, metric, params);
        } else {
            this.logger.error('unexpected type', metric);
        }

    }

    addOffset(){
        this._offsetYPx += 2;
    }

    addCustomOffset(offsetPx: number){
        this._offsetYPx += offsetPx;
    }

    addGroupedFromMetric(
        groupName: string, 
        group1: keyof ProjectMetricsType, 
        group2: keyof ProjectMetricsType,
        palette?: PalettePerType, 
        params?: RowParams
    ){
        const m1 = this.totalMetrics?.[group1];
        const m2 = this.totalMetrics?.[group2];
        if(Array.isArray(m1) || Array.isArray(m2) || (!m1 && !m2)) {
            return;
        }
        const values: [string, NumberProperty | undefined, NumberProperty | undefined, RGBAHex | undefined][] = [];
        for (const key in m2) {
            if(m1 instanceof PropertyBase || m2 instanceof PropertyBase){
                this.logger.error("Unexpected type", m1, m2)
                continue;
            }
            const value1 = m1?.[key];
            const value2 = m2?.[key];
            let row: [string, NumberProperty| undefined, NumberProperty| undefined, RGBAHex | undefined] = [key, undefined, undefined, undefined];
            if(value1 instanceof NumberProperty){
                row[1] = value1;
            } 
            if(value2 instanceof NumberProperty){
                row[2] = value2;
            }
            if(palette != undefined){
                row[3] = palette.getNext(key);
            }
            values.push(row);
        }

        if(values.length === 0){
            return;
        }

        const v1 = IterUtils.find(values, v => v[1] instanceof NumberProperty)?.[1]?.toConfiguredUnits(this.unitsMapper);
        const v2 = IterUtils.find(values, v => v[2] instanceof NumberProperty)?.[2]?.toConfiguredUnits(this.unitsMapper);
        const sum1 = IterUtils.sum(values, (x) => x[1]?.value ?? 0);
        const sum2 = IterUtils.sum(values, (x) => x[2]?.value ?? 0);
        const dec1 = v1?.decimals == 0 ? 0 : 2;
        const dec2 = v2?.decimals == 0 ? 0 : 2;
        this.addRow({
            text: groupName,
            style: "bold",
            headerLevel: params?.headerLevel,
            values: [
                {value: sum1 ? sum1.toFixed(dec1) : "", style: 'bold', unit: v1?.unit},
                {value: sum2 ? sum2.toFixed(dec2) : "", style: 'bold', unit: v2?.unit}
            ],
        });
        for (const [key, value1, value2, color] of values) {
            const valueUnit1 = value1?.toConfiguredUnits(this.unitsMapper);
            const valueUnit2 = value2?.toConfiguredUnits(this.unitsMapper);
            const dec1 = valueUnit1?.decimals == 0 ? 0 : 2;
            const dec2 = valueUnit2?.decimals == 0 ? 0 : 2;
            this.addRow({
                color,
                text: key,
                values: [
                    valueUnit1 ? {value: valueUnit1.value.toFixed(dec1), unit: valueUnit1.unit} : { value: "" },
                    valueUnit2 ? {value: valueUnit2.value.toFixed(dec2), unit: valueUnit2.unit} : { value: "" }
                ]
            });
        }
        this.addOffset();
    }

    private _getHeaderLevelOffset(level: number | undefined){
        return level === 1 
            ? this.offsetH1Px 
            : level === 2 
                ? this.offsetH2Px 
                : this.baseOffsetPx;
    }

    calculatePageSize(pageSize: Vector2): Aabb2 {
        return Aabb2.empty().setFromPoints([
            new Vector2(0, 0),
            new Vector2(pageSize.x, pageSize.y)
        ]);
    
    }

    drawValueUnit(
        value: ValueAndUnit, 
        x: number, 
        y: number, 
        fontSize: number, 
        align: "left" | "right" | "center" = "left",
        digits?: number, 
        style?: PdfTextStyle
    ){
        const unitOffset = this.convertUnit(2);
        setTextStyle(this.page, style, fontSize);
        const configured = this.unitsMapper.mapToConfigured(value);
        const valueStr = configured.value.toFixed(digits ?? 2);
        let xPos = x;
        const valueWidth = this.page.getTextWidth(valueStr) + (configured.unit ? this.page.getTextWidth(configured.unit) + unitOffset : 0);
        if(align === "right"){
            xPos -= valueWidth;
        } else if(align === "center"){
            xPos -= valueWidth / 2;
        }
        this.page.text(configured.value.toFixed(digits ?? 2), xPos, y);
        if(configured.unit){
            setTextStyle(this.page, 'transparent', fontSize);
            this.page.text(configured.unit, xPos + this.page.getTextWidth(valueStr) + unitOffset, y);
        }
    }
}

function getFormattedValueUnit(prop: NumberProperty, unitsMapper: UnitsMapper, digits?: number): {value: string, unit?: string} {
    const valueUnit = prop.toConfiguredUnits(unitsMapper);
    const decimals = digits ? digits : valueUnit.decimals === 0 ? 0 : 2;
    return {
        value: valueUnit.value.toFixed(decimals),
        unit: valueUnit.unit
    };
}
