import type { Bim, Catalog, IdBimScene, IdInEntityLocal, LocalIdsEdge, SegmentInterp} from 'bim-ts';
import { BasicAnalyticalRepresentation, BimPatch, BimProperty, GraphGeometry, LocalIdsCounter, SegmentInterpLinear, WgsProjectionOrigin } from 'bim-ts';
import { Yield } from 'engine-utils-ts';
import type { FileImporter, FileImporterContext, FileToImport } from 'ui-bindings';
import { DxfModelAdapter } from './DxfFileExtractor';
import { Vector3, Matrix4} from 'math-ts';
import type { ImportForm} from '../LayoutFormUi';
import { buildLayoutFormUi } from '../LayoutFormUi';
import { allocateBorder, allocateEquipment, allocateRoad, getLayoutOrigin, parseLayoutWgsOrigin, LayoutJson } from '../civil/LayoutParser';
import { DxfSerializer } from './DxfSerializer';
import { DXFNode } from './DxfNode';
import { setTrackerWindPositions } from 'layout-service';
import { getBimPatchOrigin, ImportMode } from 'src/bim-assets/BimAssetsOriginHandling';

export interface AssetImportSettings {
}

export class DxfFileImporter implements FileImporter {

    fileExtensions = ['.dxf'];

    constructor(

        readonly bim: Bim,
        readonly catalog: Catalog
    ) {
    }

    *startImport(context: FileImporterContext, file: FileToImport): Generator<Yield, void> {

        if (!context.network) {
            context.logger.error('network not available');
            return;
        }

        let dxfNode:DXFNode | null = null;;
        try {
            
            const fileText = new TextDecoder().decode(file.fileArrayBuffer)
            dxfNode = DxfSerializer.deserialize(fileText)
            
        } catch (err) {
            throw new Error(err);
        }
        if(dxfNode == null){
            throw new Error("Not valid dxf file");
        }

        const adapter = new DxfModelAdapter(dxfNode)
        const layout = yield* adapter.getLayoutModel();

        buildLayoutFormUi(
            context,
            layout,
            this.catalog,
            (formData) => context.importInstances(this.importInstances(context, formData, layout))
        );
    }

    *importInstances(context: FileImporterContext, formData: ImportForm, layout: LayoutJson) {
        const getSceneInstance = (type: 'trackers' | 'skid', name: string) => {
            const data = formData[type][name];
            return data.selected?.sceneInstance;
        }

        const layoutOrigin = getLayoutOrigin(layout);
        const layoutWgsOrigin: WgsProjectionOrigin | null =
            parseLayoutWgsOrigin(layout, context.sendNotification);
        const layoutSceneOriginAndCorrection = getBimPatchOrigin(
            this.bim.instances.getSceneOrigin(),
            formData.mode,
            layoutOrigin,
            layoutWgsOrigin,
            context.sendNotification
        );

        let bimPatch = new BimPatch();
        bimPatch.sceneOrigin = layoutSceneOriginAndCorrection.origin;
        const correction = layoutSceneOriginAndCorrection.correction;

        if (formData.mode === ImportMode.Replace) {
            this.bim.clear();
        }

        const positionPatches: Map<IdBimScene, Matrix4> = new Map();

        const roadBimPatch = allocateRoad(this.bim, layout.Roads, correction, context);
        const borderBimPatch = allocateBorder(this.bim, layout.Contour3D, correction.xy(), context);
        const equipmentBimPatch = allocateEquipment(this.bim, layout, getSceneInstance, correction.xy(), context, this.catalog);

        bimPatch.mergeWith(equipmentBimPatch.bimPatch);
        equipmentBimPatch.matixes.forEach((x, i) => positionPatches.set(i, x));
        
        for(const [id, matrix] of equipmentBimPatch.matixes){
            positionPatches.set(id, matrix);
        }

        if (roadBimPatch !== null) {
            bimPatch.mergeWith(roadBimPatch.bimPatch);
            for(const [id, matrix] of roadBimPatch.matixes){
                positionPatches.set(id, matrix);
            }
        }
        if (borderBimPatch !== null) {
            bimPatch.mergeWith(borderBimPatch);
        }

        const allocatedSceneIstances = bimPatch.applyTo(this.bim);

        this.bim.instances.applyPatches(equipmentBimPatch.patches);
        this.bim.instances.patchWorldMatrices(positionPatches);
        this.bim.instances.setSelected(allocatedSceneIstances);

        yield* context.onFinish(allocatedSceneIstances);
        yield* setTrackerWindPositions({bim: this.bim, logger: context.logger.newScope("tracker-position")});

    }
}




