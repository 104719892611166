<script lang="ts">
    import { getContext } from "svelte";
    import type { Readable } from "svelte/store";
    import type { UnitsMapper } from "bim-ts";
    import type { ImportGroupContext } from "./ImportObject";
    import ImportTrackerSelector from "./TrackerSelector.svelte";
    import ImportSkidSelector from "./SkidSelector.svelte";
    import PropertyView from "../../PropertyView.svelte";
    import { PUI_PropertyNodeNumber } from "ui-bindings";
    import { IconComponent } from "../../../libui/icon";

    export let context: ImportGroupContext;

    let expanded = true;
    const unitsMapper = getContext<Readable<UnitsMapper>>("unitsMapper");
    let toleranceValue = 0.3048;
    $: tolerance = context.activeMatching
        ? new PUI_PropertyNodeNumber({
              name: "Length tolerance",
              value: toleranceValue,
              unit: "m",
              step:
                  $unitsMapper.currentSystemOfUnits === "Imperial" ? 0.1 : 0.01,
              minMax: [0, 10],
              onChange: (newValue) => {
                  toleranceValue = newValue;
              },
          })
        : null;
</script>

<div class="group">
    <div
        class="group-title-container"
        on:click={() => {
            expanded = !expanded;
        }}
    >
        <IconComponent name={expanded ? "ChevronDown" : "ChevronRight"} />
        <span class="mdc-typography--headline5">
            {@html context.name}
        </span>
    </div>
    {#if expanded}
        <div class="group-content">
            {#if tolerance}
                <PropertyView bind:property={tolerance} offset={24} />
            {/if}
            <div class="ui-config-property property-row header-row">
                <div class="column-name">
                    <div
                        class="property-name-label mdc-typography--table-header"
                    >
                        Imported item data
                    </div>
                </div>
                <div class="column-name">
                    <div class="mdc-typography--table-header">
                        Import as equipment from the catalog
                    </div>
                </div>
            </div>
            {#each Object.entries(context.items) as [name, info] (name)}
                <div class="ui-config-property property-row multiselect-prop">
                    <div class="property-name text-main-medium">
                        <div class="property-name-label mdc-typography--body1">
                            <div class="property-name-before">
                                &times; {info.count}
                            </div>
                            <div>{@html info.name}</div>
                        </div>
                    </div>

                    {#if context.activeMatching && info.trackerData}
                        <ImportTrackerSelector
                            value={info.selected}
                            options={context.options}
                            trackerData={info.trackerData}
                            tolerance={toleranceValue}
                            on:change={(e) => {
                                context.onChange(name, {
                                    ...info,
                                    selected: e.detail,
                                });
                            }}
                        />
                    {:else}
                        <ImportSkidSelector
                            value={info.selected}
                            options={context.options}
                            on:change={(e) => {
                                context.onChange(name, {
                                    ...info,
                                    selected: e.detail,
                                });
                            }}
                        />
                    {/if}
                </div>
            {/each}
        </div>
    {/if}
</div>

<style lang="scss">
    .property-name-label {
        display: flex;
        gap: 4px;
    }
    .property-name-before {
        width: 56px;
        flex-shrink: 0;
    }
    .ui-config-property {
        align-items: flex-start;
        :global(.custom-svelecte-control) {
            --sv-placeholder-color: var(--color-main-dark);
        }
    }

    .property-row {
        margin-bottom: 4px;
    }
    .group-content {
        padding-top: 4px;
        & > :global(.property-view:first-child) {
            margin-bottom: 12px;
        }
    }
    .group {
        margin-bottom: 8px;
        :global(.property-name-label) {
            margin-left: 24px;
        }
    }
    .header-row {
        margin-bottom: 7px;
        .column-name {
            flex: 1;
            &:last-child div {
                padding: 0 8px;
            }
        }
    }

    .group-title-container {
        cursor: default;
        display: flex;
        align-items: center;
        padding: 4px 0 4px 0;
    }
</style>
