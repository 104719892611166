<script lang="ts">
    import flash from "../pui/flash";
    import { Vector2Like } from "math-ts";
    import Icon from "../libui/icon/Icon.svelte";
    import { TrackerWindPosition } from "bim-ts";

    export let name: string;
    export let value: TrackerWindPosition;
    export let variants: TrackerWindPosition[];
    export let onChange: (value: TrackerWindPosition) => void;
    export let configure: (p: Vector2Like) => void;

    type WindLoadVariant = "EXT" | "INT" | "EDGE" | "TOP" | "BOT";
    const WindLoadVariants: TrackerWindPosition[] = [
        TrackerWindPosition.Exterior,
        TrackerWindPosition.Interior,
        TrackerWindPosition.Edge,
        TrackerWindPosition.EdgeTop,
        TrackerWindPosition.EdgeBot,
    ] as const;

    const WindPositionPerLabel: Record<TrackerWindPosition, WindLoadVariant> = {
        [TrackerWindPosition.Exterior]: "EXT",
        [TrackerWindPosition.Interior]: "INT",
        [TrackerWindPosition.Edge]: "EDGE",
        [TrackerWindPosition.EdgeTop]: "TOP",
        [TrackerWindPosition.EdgeBot]: "BOT",
    };

    let div: HTMLElement;

    let prevValue = value;
    $: if (prevValue != value && div) {
        prevValue = value;
        flash(div);
    }
    $: visibleVariants = WindLoadVariants.filter((variant) =>
        variants.includes(variant),
    );
</script>

<div class="ui-config-property property-row">
    <div bind:this={div} class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1">
            {name}
        </div>
    </div>

    <div class="property-value">
        <div class="edit-field-container number-with-options-property">
            <div class="input-value text-main-dark edit-field-container">
                <span class="buttons-group">
                    {#each visibleVariants as variant}
                        {@const mappedValue = WindPositionPerLabel[value]}
                        {@const position = WindPositionPerLabel[variant]}
                        <button
                            class="mdc-typography--body1 unstyled-button"
                            class:active={position === mappedValue}
                            on:click={() => {
                                if (variants.includes(variant)) {
                                    onChange(variant);
                                }
                            }}
                        >
                            {position}
                        </button>
                    {/each}
                </span>
                <button
                    class="unstyled-button"
                    on:click={(e) => configure({ x: e.clientX, y: e.clientY })}
                >
                    <Icon name={"Settings"} />
                </button>
            </div>
        </div>
    </div>
</div>

<style lang="scss">
    .input-value {
        width: 100%;
        height: 100%;
    }

    .buttons-group {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        gap: 2px;
        width: 100%;
    }

    .unstyled-button {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        min-width: fit-content;
        min-height: fit-content;
        max-width: 120px;
        padding: 2px;
        border-radius: 1px;
        transition: opacity 0.5s;
        &.active {
            background-color: white;
        }
    }
</style>
