import { Vector3 } from 'math-ts';
import type { WGSCoord } from './WGSCoord';
import type { ProjectionInfo } from './ProjectionInfo';


export class BimSceneOrigin {
	cartesianCoordsOrigin: Vector3 | null = null;
	wgsProjectionOrigin: WgsProjectionOrigin | null = null;

	public constructor (civilCoord?: Vector3 | null, wgsCoord?:WgsProjectionOrigin | null ){
		if(civilCoord){
			this.cartesianCoordsOrigin = civilCoord;
		}else{
			this.cartesianCoordsOrigin = null;
		}
		if(wgsCoord){
			this.wgsProjectionOrigin = wgsCoord;
		}else{
			this.wgsProjectionOrigin = null;
		}
	}
	
	equals(origin: Partial<BimSceneOrigin> | null){
		if(origin === null){
			return false;
		}
		
		return this.civilOriginEquals(origin) && this.wgsOriginEquals(origin);
	}

	civilOriginEquals(origin: Partial<BimSceneOrigin> | null): boolean {
		if (origin == null) {
			return false;
		}

		let civilEquality = false;

		if (this.cartesianCoordsOrigin != null && origin.cartesianCoordsOrigin != null) {
			civilEquality = this.cartesianCoordsOrigin.equals(origin.cartesianCoordsOrigin);
		}
		return civilEquality;
	}

	wgsOriginEquals(origin: Partial<BimSceneOrigin> | null) {
		if (origin == null) {
			return false;
		}

		let wgsEquality = false;

		if (this.wgsProjectionOrigin != null && origin.wgsProjectionOrigin != null) {
			wgsEquality = this.wgsProjectionOrigin.equals(origin.wgsProjectionOrigin);
		}
		return wgsEquality;
	}
}


export class WgsProjectionOrigin {
	public readonly projectionInfo: ProjectionInfo;
	public readonly wgsOriginLatLong: WGSCoord;
	public readonly wgsOriginCartesianCoords: Vector3;

	constructor (wgsCoordinates: WGSCoord, projectionInfo: ProjectionInfo, origin?: Vector3){
		this.wgsOriginLatLong = wgsCoordinates;
		this.projectionInfo = projectionInfo;
		if(origin!=null){
			this.wgsOriginCartesianCoords = origin;
		}else{
			this.wgsOriginCartesianCoords = Vector3.zero();
		}
		Object.freeze(this);
	}

	
	clone() {
		return new WgsProjectionOrigin(
			this.wgsOriginLatLong.clone(), 
			this.projectionInfo, 
			this.wgsOriginCartesianCoords.clone()
		);
	}
	equals(coord: WgsProjectionOrigin){
		return this.projectionInfo.equals(coord.projectionInfo)
		&& this.wgsOriginCartesianCoords.equals(coord.wgsOriginCartesianCoords) 
		&& this.wgsOriginLatLong.equals(coord.wgsOriginLatLong);
	}

}
