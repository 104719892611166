import type { KreoEngine } from "engine-ts";
import { type ScopedLogger, type ProjectNetworkClient, type LongTask, Yield, Success } from "engine-utils-ts";
import { NotificationDescription, NotificationType, type UiBindings } from "ui-bindings";
import type { ExportedFileDescription, FileExporterContext } from "ui-bindings";
import { requestSettingsViaDialog } from "./RequestSettingsViaDialog";
import { downloadFile } from "../exports/verdata/DownloadFile";
import { notificationSource } from '../Notifications';

const TimeoutIntervalMs = 1000 * 60 * 10;

export class FilesExportsHandler {
    constructor(
        readonly logger: ScopedLogger,
        readonly networkClient: ProjectNetworkClient,
        readonly engine: KreoEngine,
        readonly uiBindings: UiBindings
    ) {}

    export(exporterId: string) {
        this.engine.tasksRunner.newLongTask({
            defaultGenerator: this.startExport(exporterId),
        });
    }

    private *startExport(exporterId: string) {
        const exporter = this.uiBindings.filesExporters.get(exporterId)?.[1];
        if (!exporter) {
            this.logger.error('exporter not found', exporterId);
            return;
        }

        const initialSettings = exporter.initialSettings();
        let cancelled = false;
        const settings = yield* requestSettingsViaDialog({
            header: 'Export ' + exporterId,
            ...initialSettings,
            ident: `${exporterId}-initial-settings`,
            uiBindings: this.uiBindings,
            submitActionLabel: 'Export',
            cancel: () => {
                cancelled = true;
            },
        });

        if (cancelled) {
            return;
        }

        let exportTask: LongTask<ExportedFileDescription[]> | null = null;
        const exporterContext: FileExporterContext = {
            logger: this.logger.newScope(exporterId),
            network: this.networkClient,
            requestSettings: (args) => {
                return requestSettingsViaDialog({
                    header: 'Export ' + exporterId,
                    ...args,
                    uiBindings: this.uiBindings,
                    cancel: () => {
                        if (exportTask) {
                            exportTask.reject();
                        } else {
                            this.logger.error('no export task to cancel');
                        }
                    }
                });
            },
            additionalContext: {
                engine: this.engine,
            },
            sendNotification: (notification: NotificationDescription): void => {
                this.engine.uiBindings.addNotification(notification);
            },
            addDialog:discription => {this.engine.uiBindings.addDialog(discription)}

        }
        const exporterGenerator = exporter.startExport(settings, exporterContext);
        exportTask = this.engine.tasksRunner.newLongTask({
            defaultGenerator: exporterGenerator,
            taskTimeoutMs: TimeoutIntervalMs,
        });
        this.engine.uiBindings.addNotification(NotificationDescription.newWithTask({
            source: notificationSource,
            key: 'exportFile',
            descriptionArg: exporterId,
            type: NotificationType.Info,
            taskDescription: { task: exportTask },
            removeAfterMs: 3000,
            addToNotificationsLog: true
        }));

        while (!exportTask.isFinalized()) {
            yield Yield.Asap;
        }
        const filesResult = exportTask.asResult();

        if (filesResult instanceof Success) {
            for (const file of filesResult.value) {
                const fileName = (file.name ?? '') + '.' + file.extension;
                downloadFile(fileName, file.file);
            }
        } else {
            this.logger.error('export failed', filesResult.errorMsg());
        }

    }
}
