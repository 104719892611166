<script lang="ts">
    import type {
        MultiSelectorValue,
        PUI_PropertyNodeSelector,
    } from "ui-bindings";
    import SelectorPropertyBase from "./SelectorPropertyBase.svelte";
    import { ObjectUtils } from "engine-utils-ts";
    import flash from "./flash";
    import { VersionedStore } from "../VersionedStore";

    export let property: PUI_PropertyNodeSelector;

    let div: HTMLElement;
    let preValue = property.value;

    $: if (!ObjectUtils.areObjectsEqual(preValue, property.value)) {
        preValue = property.value;
        flash(div);
    }

    $: options = property.options as MultiSelectorValue[];
    $: value = property.value !== null ? [property.value] : [];
    const tag = property.tag ? new VersionedStore(property.tag) : undefined;
</script>

<div
    class="ui-config-property property-row multiselect-prop"
    class:not-active={property.notActive}
>
    <div bind:this={div} class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1">
            {property.name}
        </div>
    </div>
    <SelectorPropertyBase
        {value}
        {options}
        placeholder={`Select ${property.name?.toLowerCase()}`}
        readonly={property.readonly}
        calculated={property.calculated}
        description={property.description}
        tag={tag ? $tag : ""}
        on:change={(e) => {
            property.value = e.detail && e.detail.value;
        }}
    />
</div>

<style>
</style>
