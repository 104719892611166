import type {
    Bim, EntitiesCollectionUpdates, Hierarchy, IdBimScene, InstanceHierarchyData, SceneInstance} from 'bim-ts';
import { handleEntitiesUpdates, SceneInstances, SceneObjDiff
} from 'bim-ts';
import type { LazyVersioned, PollWithVersionCache, PollWithVersionResult, VersionedValue} from 'engine-utils-ts';
import { LazyBasic, StringUtils, VersionedInvalidator} from 'engine-utils-ts';
import { StreamAccumulator } from 'engine-utils-ts';

export type HoveredSegment = 'top' | 'center' | 'bottom'

export interface InputCombo {
  ctrlKey?: boolean;
  shiftKey?: boolean;
  altKey?: boolean;
}

export class SceneExplorerObj {

  constructor(
    // bim state
    public readonly id: IdBimScene,
    public readonly bimStateRef: Readonly<SceneInstance>,
    
    // additional ui state
    public isNodeOpen: boolean = false, // for hierachical views
    // smth else
    public depth: number = 0,
    public hasChildren: boolean = false,
    public searchHL: boolean = false,
    public searchHLextra: boolean = false,
  ){
  }

  uiName(): string {
    return SceneInstances.uiNameFor(this.id, this.bimStateRef);
  }

  isSelected() { return this.bimStateRef.isSelected }
  isHidden() { return this.bimStateRef.isHidden }
  toggleIsOpened() { this.isNodeOpen = !this.isNodeOpen }
}

export class SceneExplorerObjPrimaryProperty implements VersionedValue {
  private _show:boolean;
  private _invalidator = new VersionedInvalidator();

  constructor(
    public readonly name: string, 
    public readonly getDisplayValue: (s: Readonly<SceneInstance>) => string | undefined, 
    show: boolean = true
  ) {
    this._show = show
  }

  toggleShow() {
    this._show = !this._show;
    this._invalidator.invalidate();
  }

  get show() {
    return this._show;
  }

  version(cache?: PollWithVersionCache): number {
    return this._invalidator.version();
  }
}

export type ExplorerFilteFn = (obj: SceneExplorerObj) => boolean;
export type ExplorerSortFn = (l: SceneExplorerObj, r: SceneExplorerObj) => number;
export class DataModel {
  public displayList: SceneExplorerObj[] = []
  public hierarchy: string = ''
  public allowedHierarchies: string[] = []
  public setHierarchy: (hier: string) => void = () => null
  public primaryProperties: SceneExplorerObjPrimaryProperty[] = []
  public filters:ObjectFilters = new ObjectFilters(()=>{});
  public search: string = ''
  public changeSearch: (newVal: string) => void = () => null
  public scrollToIndex: number | null = null
  constructor(init: Partial<DataModel>) {
    Object.assign(this, init)
  }
}
interface FilterDescription{
  label:string;
  flag:boolean;
  checkFn:(flag:boolean, instance:Readonly<SceneInstance>)=>boolean;
}
export class ObjectFilters {
    public readonly descriptions: Map<string, FilterDescription>;
    private readonly callBackFn:()=>void;
    
    constructor(callBackFn?:()=>void, filters?: FilterDescription[]) {
        if (callBackFn) {
            this.callBackFn = callBackFn;
        } else {
            this.callBackFn = () => {
                console.log("Empty call!");
            };
        }
        this.descriptions = new Map<string, FilterDescription>();

        if (filters) {
          for (const filter of filters) {
            this.add(filter);
        }
        }
    }

    public add(description: FilterDescription) {
        const value = this.descriptions.get(description.label);
        if(value===undefined){
          this.descriptions.set(description.label, description);
        }
    }

    public removeUnused(types:Map<string, number>){
      for (const [type, _description] of this.descriptions) {
        const counter = types.get(type);
        if(!counter){
          this.descriptions.delete(type);
        }
      }
    }

    public toggleShow(filter: string) {
      const value = this.descriptions.get(filter);
        if (value === undefined) {
            throw new Error("filter not found!");          
        } else {
          value.flag = !value.flag;
        }
        this.callBackFn();
    }

    public isShow(instance:Readonly<SceneInstance>): boolean {
        for (const [filter, description] of this.descriptions) {
          if(!description.checkFn(description.flag, instance)){
            return false;
          }
        }
        return true;
    }
}

export class SceneExplorerObjectsList implements LazyVersioned<DataModel> {

  readonly bim: Bim;
  hierarchy: Hierarchy;
  readonly _bimUpdatesAccumulator: StreamAccumulator<EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>>;
  
  readonly relevanObjectsDiff: SceneObjDiff;

  readonly _allObjects = new Map<IdBimScene, SceneExplorerObj>();
  readonly _filteredObjects = new Map<IdBimScene, SceneExplorerObj>();
  readonly _allTypes = new Map<string, number>();
  readonly _sortByBlocks = new LazyBasic<boolean>("sort-by-blocks", true);

  _latestResult: DataModel

  _filterFn: ExplorerFilteFn;
  _sortFn: ExplorerSortFn;

  readonly _invalidator:VersionedInvalidator; // increase version whenever result list may change

  readonly _objectFilters = new ObjectFilters(() => { this._invalidator.invalidate(); });

  readonly _primaryProperties: SceneExplorerObjPrimaryProperty[];

  _search: string = ''
  public _lastSelected: {
    id: IdBimScene
    idx: number | null
  } | null = null

  constructor(params: {
    bim: Bim,
    relevantObjectsDiff: SceneObjDiff,
    filterFn?: ExplorerFilteFn;
    sortFn?: ExplorerSortFn;
  }) {
      this.bim = params.bim;
      this.hierarchy = this.bim.instances.spatialHierarchy;
      //this.hierarchy = this.bim.instances.electricalHierarchy;
      this._bimUpdatesAccumulator = new StreamAccumulator(
          params.bim.instances.updatesStream
      );
      this.relevanObjectsDiff = params.relevantObjectsDiff;
      this._filterFn = params.filterFn ?? (() => true);
      function sortItems(a: SceneExplorerObj, b: SceneExplorerObj) {
        if (
            a.bimStateRef.type_identifier === b.bimStateRef.type_identifier &&
            a.bimStateRef.type_identifier === "transformer"
        ) {
            const block_number_a =
                a.bimStateRef.properties
                    .get("circuit | position | block_number")
                    ?.asNumber() ?? 0;
            const block_number_b =
                b.bimStateRef.properties
                    .get("circuit | position | block_number")
                    ?.asNumber() ?? 0;
            if(block_number_a || block_number_b){
              return block_number_a - block_number_b;
            }
        }

        return a.uiName().localeCompare(b.uiName());
      }
      this._sortFn = params.sortFn ?? sortItems;

      this._primaryProperties = [
          new SceneExplorerObjPrimaryProperty("Show Block Number", (s) => {
              const block_number = s.properties
                  .get("circuit | position | block_number")
                  ?.asNumber();
              return block_number ? "Block " + block_number : undefined;
          }),
          new SceneExplorerObjPrimaryProperty("Show Type Identifier", (s) =>
              StringUtils.capitalizeFirstLatterInWord(s.type_identifier)
          ),
          new SceneExplorerObjPrimaryProperty("Show Primary Property", (s) =>
              this.bim.instances.primaryPropertyLabel(s)
          ),
      ];

      this._latestResult = new DataModel({
          setHierarchy: this.setHierarchy.bind(this),
          hierarchy: "spacial",
          allowedHierarchies: ["spacial", "electrical"],
      });

      this._invalidator = new VersionedInvalidator([
          ...this._primaryProperties,
          this.bim.unitsMapper,
          this._bimUpdatesAccumulator,
          this._sortByBlocks,
      ]);
  }

  dispose() {
    this._bimUpdatesAccumulator.dispose();
  }

  setHierarchy(type: string) {
    if (type === 'spacial') {
      this.hierarchy = this.bim.instances.spatialHierarchy
      this._invalidator.invalidate();
    } else if (type === 'electrical') {
      this.hierarchy = this.bim.instances.electricalHierarchy
      this._invalidator.invalidate();
    }
  }

  swapSortFn(sortFn: ExplorerSortFn) {
    this._sortFn = sortFn;
    this._invalidator.invalidate();
  }

  swapFilterFn(filterFn: ExplorerFilteFn) {
    this._filterFn = filterFn;
    this._filteredObjects.clear();
    for (const obj of this._allObjects.values()) {
      if (this._filterFn(obj)) {
        this._filteredObjects.set(obj.id, obj);
      }
    }
    this._invalidator.invalidate();
  }

  closeAllNodes() {
    for (const obj of this._allObjects.values()) {
        obj.isNodeOpen = false;
    }
    this._invalidator.invalidate();
  }

  openNodeChildren(item: SceneExplorerObj) {
    if (!item || item.isNodeOpen) return;
    item.isNodeOpen = true;
    this._invalidator.invalidate();
  }

  toggleNodeChildren(item: SceneExplorerObj, input: InputCombo = {}) {
    const isCurrentOpened = item.isNodeOpen
    item.isNodeOpen = !isCurrentOpened
    if (input.altKey) {
      this.hierarchy.traverseRootToLeavesDepthFirstFrom(item.id, id => {
        const obj = this._allObjects.get(id);
        if (obj) {
          obj.isNodeOpen = !isCurrentOpened;
        }
        return true;
      })
    }

    this._invalidator.invalidate();
  }

  selectNode(item: SceneExplorerObj, input: InputCombo) {
    const ids = input.altKey ? this.hierarchy.gatherIdsWithSubtreesOf({ids: [item.id]}) : [item.id];
    if (input.ctrlKey) {
      this.bim.instances.toggleSelected(true, ids)
    } else if (input.shiftKey) {
        if (item.isSelected()) {
          this.bim.instances.toggleSelected(false, ids)
        } else {
            const lastSelectedId = this.bim.instances.getLastSelected()
            if (typeof lastSelectedId === 'undefined') return
            const idxOfLastSelected = this._latestResult.displayList.findIndex(x => x.id === lastSelectedId)
            const idxOfCurrentItem = this._latestResult.displayList.findIndex(x => x.id === item.id)
            if (idxOfLastSelected < 0 || idxOfCurrentItem < 0) return
            const minIdx = Math.min(idxOfLastSelected, idxOfCurrentItem)
            const maxIdx = Math.max(idxOfLastSelected, idxOfCurrentItem)
            const idsToSelect = this._latestResult.displayList
                .slice(minIdx, maxIdx+1)
                .map(x => x.id)
            this.bim.instances.toggleSelected(true, idsToSelect)
        }
    } else {
      this.bim.instances.setSelected(ids);
    }
  }

  toggleNodeVisibility(item: SceneExplorerObj, input: InputCombo) {
    const visibility = !item.bimStateRef.isHidden;
    const ids = input.altKey ? this.hierarchy.gatherIdsWithSubtreesOf({ids: [item.id]}) : [item.id];
    this.bim.instances.toggleVisibility(!visibility, ids);
  }

  get areSortByBlocks() {
    return this._sortByBlocks.poll();
  }

  set areSortByBlocks(value: boolean) { 
    this._sortByBlocks.replaceWith(value);
  }


  addObjects(objects: Iterable<[id: IdBimScene, stateRef: Readonly<SceneInstance>]>) {
    for (const [id, stateRef] of objects) {
      const uiObject = new SceneExplorerObj(id, stateRef);
      this._allObjects.set(id, uiObject);
      if (this._filterFn(uiObject)) {
        this._filteredObjects.set(id, uiObject);
      }
      let counter = this._allTypes.get(stateRef.type_identifier);
      this._allTypes.set(stateRef.type_identifier, counter === undefined ? 1: ++counter);
    }

    for (const [type, counter] of this._allTypes) {
        if (counter > 0) {
            this._objectFilters.add({
                label: type,
                flag: true,
                checkFn: (flag, instance) => {
                    return (
                        flag ||
                        !(instance.type_identifier === type)
                    );
                },
            });
        }
    }

    this._invalidator.invalidate();
  }
  updateObjects(objects: Iterable<[id: IdBimScene, diff: SceneObjDiff]>) {
    let needsResort = false;
    let expandOnSelectOnce = false;
    for (const [id, diff] of objects) {
      if ((diff & this.relevanObjectsDiff) === 0) {
        continue; // use diff to skip early
      }
      const uiObj = this._allObjects.get(id);
      if (!uiObj) {
        console.error(`unexpected abscence of ui object, updates delta is broken`, id);
        continue;
      }
      // open scene explorer on selected item
      // expand recursivly from root to selected item
      // in multiselect: expand only to the first selected item
      if (diff & SceneObjDiff.Selected && !expandOnSelectOnce) {
        this._lastSelected = null
        do {
            const x = this._allObjects.get(id);
            if (!x) break;
            // ignore if item was deselected
            if (!x.bimStateRef.isSelected) {
                if (this._lastSelected && this._lastSelected.id === x.id)
                    this._lastSelected = null
                break
            }
            // focus should happan once on multiselect
            expandOnSelectOnce = true;
            // open all components in the path
            // from root to selected item: [root, selectedItem)
            this.hierarchy.traverseLeaveToRoot(id, parentId => {
                const x = this._allObjects.get(parentId);
                if (!x) return true;
                this.openNodeChildren(x);
                return true;
            }, true);
            this._lastSelected = {
                id: id,
                idx: null,
            }
        } while (0)
      }
      const filtered = this._filterFn(uiObj);
      const wasFiltered = this._filteredObjects.has(id);
      if (filtered != wasFiltered) {

      }
      if (this._filterFn(uiObj)) {
        const wasFiltered = this._filteredObjects.has(id);
        if (!wasFiltered) {
          needsResort = true;
          this._filteredObjects.set(id, uiObj);
        }
      } else {
        if (this._filteredObjects.delete(id)) {
          needsResort = true;
        }
      }
    }
    if (needsResort) {
      this._invalidator.invalidate();
    }
  }
  removeObjects(ids: Iterable<IdBimScene>) {
    for (const id of ids) {
      const instance =this._allObjects.get(id);
      if(instance){
        let counter = this._allTypes.get(instance.bimStateRef.type_identifier);
        this._allTypes.set(instance.bimStateRef.type_identifier, counter===undefined ? 0: --counter);
      }
      this._allObjects.delete(id);
      this._filteredObjects.delete(id);
    }

    this._objectFilters.removeUnused(this._allTypes);
    this._invalidator.invalidate();
  }

  version(): number {
    return this._invalidator.version();
  }

  isObjAMatch(id: IdBimScene, search: string) {
    const normSearch = search.toLowerCase().split(' ').join('')
    const obj = this._allObjects.get(id)
    if (!obj) return false
    const inst = obj.bimStateRef
    const propMatch = normSearch.match(/^((([\w\d]+)\.?)+)=([\w\d]*)$/)
    if (propMatch) {
      const nameComps = propMatch[1].split('.')
      const value = propMatch[propMatch.length-1]
      const props = inst.properties.asArray()
      if (props) {
        const match = props.find(x => x.path.join('.').toLowerCase().includes(nameComps.join('.')))
        if (match) {
          const matchValue = ("" + match.value).toLowerCase().split(' ').join('')
          if (matchValue.includes(value)) {
            return true
          }
        }
      }
    }
    if (obj.uiName().toLowerCase().includes(normSearch)){
      return true;
    }
    return false;
  }

  poll(): Readonly<DataModel> {
    const delta = this._bimUpdatesAccumulator.consume();
    handleEntitiesUpdates(
      delta,
      (ids) => this.addObjects(this.bim.instances.peekByIds(ids)),
      (diffsPerId) => this.updateObjects(diffsPerId),
      (ids) => this.removeObjects(ids)
    );

    this._objectFilters.add({
      label: 'Show hidden',
      flag: true,
      checkFn:(flag, instance)=>{ return flag || !instance.isHidden}
    });

    const result = new DataModel({
      hierarchy: this.hierarchy === this.bim.instances.spatialHierarchy ? 'spacial' : 'electrical',
      allowedHierarchies: ['spacial', 'electrical'],
      displayList: [],
      setHierarchy: hier => {
        this.setHierarchy(hier)
      },
      filters: this._objectFilters,
      search: this._search,
      changeSearch: (value) => {
        this._search = value
        this._invalidator.invalidate()
      },
      primaryProperties : this._primaryProperties
    });

    const hierarchyChanged = this._latestResult.hierarchy !== result.hierarchy;
    if (hierarchyChanged) {
      for (const obj of this._allObjects.values()) {
        obj.isNodeOpen = false;
      }
    }

    const matchNodes: IdBimScene[] = [];
    const collectDisplayListAndMatchedListAndUpdateDepthInfo = (
      entries: Iterable<InstanceHierarchyData>, 
      depth: number = 0
    ): void => {
      const sortedEntries = Array.from(entries)
        .map(x => ({
          id: x.id, 
          sceneExplorerObj: this._allObjects.get(x.id), 
          hierarchyEntry: x
        }))
        .filter(x => x.sceneExplorerObj);

      if(this.areSortByBlocks){
        sortedEntries.sort((a, b) => this._sortFn(a.sceneExplorerObj!, b.sceneExplorerObj!));
      } else {
        sortedEntries.sort((a, b) => a.hierarchyEntry.sortKey - b.hierarchyEntry.sortKey);
      }
      for (const entry of sortedEntries) {
        const hier = entry.hierarchyEntry;
        const uiObject = entry.sceneExplorerObj;
        if (!uiObject) {
          continue;
        }
        if(!this._objectFilters.isShow(uiObject.bimStateRef)){
          continue;
        }

        let objDepth: number;
        let hasChildren: boolean;

        if (this._search) {
          if (this.isObjAMatch(hier.id, this._search)) {
            matchNodes.push(hier.id)
          }
          objDepth = 0;
          hasChildren = false;
        } else {
          objDepth = depth;
          hasChildren = Boolean(hier.children?.length);
        }

        uiObject.depth = objDepth;
        uiObject.hasChildren = hasChildren;
        result.displayList.push(uiObject);

        if (hier.children && (uiObject.isNodeOpen || this._search)) {
          collectDisplayListAndMatchedListAndUpdateDepthInfo(hier.children, depth + 1);
        }
      }
    }

    collectDisplayListAndMatchedListAndUpdateDepthInfo(this.hierarchy._rootObjects.values());

    // calculate initial scrollToIdx
    // focus on first selected item
    if (this._lastSelected && typeof this._lastSelected.idx !== 'number'){
      this._lastSelected.idx = result.displayList
          .findIndex(x => x.id === this._lastSelected!.id);
    }
    if (
        this._lastSelected &&
        typeof this._lastSelected.idx === 'number' &&
        this._lastSelected.idx >= 0
    ){
      result.scrollToIndex = this._lastSelected.idx;
    }


    if (this._search) {
      const matchedOnly = matchNodes
        .map(id => this._allObjects.get(id))
        .filter(x => x) as SceneExplorerObj[];
      result.displayList = matchedOnly;
    }
    
    this._latestResult = result;
    return result;
  }
  pollWithVersion(): PollWithVersionResult<Readonly<DataModel>> {
    return { value: this.poll(), version: this.version() };
  }

}
