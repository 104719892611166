<script lang="ts">
    export let name: string = "";
    export let count: number = 0;
    export let tag: string = "";
</script>

<div
    class="export-group-item"
    class:mdc-typography--body1-semi-strong={!!count}
>
    <span class="export-item-count">
        {#if count}
            × {count}
        {/if}
    </span>
    <span class="export-item-name">{name}</span>
    {#if !!count && !!tag}
        <span class="export-item-tag">{tag}</span>
    {/if}
</div>

<style lang="scss">
    .export-group-item {
        display: flex;
        gap: 8px;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
    .export-item-count {
        width: 60px;
        flex-shrink: 0;
    }
    .export-item-name {
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap: nowrap;
    }
    .export-item-tag {
        margin-left: auto;
        margin-right: 30px;
    }
</style>
