import type { ValueAndUnit } from "bim-ts";
import { NumberSpinnerUtils } from "../../pui/NumberSpinnerUtils";


export type MetricType = number | string | null | undefined;

export abstract class MetricNode {
    readonly name: string;

    constructor(args: {name: string}) {
        this.name = args.name;
    }
}
interface MetricPropertyNodeArgs {
    name: string;
    value: MetricType[];
    decimals?: number;
    unit?: string;
    isHeader?: boolean;
}

export function formatMetricValue(valueUnit: string | ValueAndUnit | null | undefined, decimals: number) {
    if(valueUnit == null){
        return " "
    }
    if(typeof valueUnit === 'string'){
        return valueUnit;
    }
    const isNotNumber = valueUnit == undefined;
    return NumberSpinnerUtils.addUnit(valueUnit?.value ?? null, isNotNumber, decimals, valueUnit?.unit);
}

export class MetricPropertyNode extends MetricNode {
    readonly value: MetricType[];
    readonly decimals: number;
    readonly unit?: string;
    readonly isHeader?: boolean;

    constructor(args: MetricPropertyNodeArgs) {
        super(args);
        this.value = args.value;
        this.decimals = args.decimals ?? 2;
        this.unit = args.unit;
        this.isHeader = args.isHeader;
    }
}
interface MetricGroupNodeArgs {
    name: string;
    children?: Map<string, MetricNode>;
    total?: MetricPropertyNode;
}

export class MetricGroupNode extends MetricNode {
    readonly children: Map<string, MetricNode>;
    readonly total?: MetricPropertyNode;

    constructor(args: MetricGroupNodeArgs) {
        super(args);
        this.children = args.children ?? new Map();
        this.total = args.total;
    }

    countItems(): number {
        if(this.children.size === 0){
            return 0;
        }
        const first = getFirstProperty(this);
        if(!first){
            return 0;
        }
        return first.value.length;
    }
}

function getFirstProperty(node: MetricNode): MetricPropertyNode | null {
    if(node instanceof MetricPropertyNode){
        return node;
    } else if(node instanceof MetricGroupNode){
        for (const child of node.children.values()) {
            const prop = getFirstProperty(child);
            if(prop){
                return prop;
            }
        }
    }
    return null;
}