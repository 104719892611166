<script lang="ts">
    import { getContext } from "svelte";
    import { IterUtils } from "engine-utils-ts";
    import { DxfExportContext, DxfSettingsProperties } from "files-converters";
    import ExportGroup from "./ExportGroup.svelte";
    import { Bim, TrackerPilesCollection } from "bim-ts";
    import ExportGroupItem from "./ExportGroupItem.svelte";
    import {
        LayoutGroupLabels,
        sumByProperty,
        terrainHasChanges,
    } from "./DxfGroupsUtils";
    import { DialogsListener } from "../../../dialogs/DialogsListener";

    export let context: DxfExportContext;

    const settings = context.getSettings();

    const bim = getContext<Bim>("bim");
    const dialogsListener = getContext<DialogsListener>("dialogsListener");
    const pilesCollection = getContext<TrackerPilesCollection>(
        "trackerPilesCollection",
    );

    const instancesByType = new Map(
        IterUtils.groupBy(bim.instances.perIdTypeIdent, ([_id, type]) => type),
    );

    const pilesCount = pilesCollection.poll().size;

    function exportGroup(name: keyof DxfSettingsProperties) {
        context.exportGroup(name);
        dialogsListener.closeCurrentDialog();
    }

    function getTotalLength(
        identifier: string,
        path: string = "computed_result | length",
        propertyUnit: string = "m",
    ) {
        return sumByProperty(
            bim,
            instancesByType.get(identifier) ?? [],
            path,
            propertyUnit,
        );
    }
    function getInstTypeCount(identifier: string) {
        return instancesByType.get(identifier)?.length || 0;
    }

    $: solarArrayCount =
        getInstTypeCount("tracker") +
        getInstTypeCount("any-tracker") +
        getInstTypeCount("fixed-tilt");
</script>

<div class="export-content">
    <ExportGroup
        name="Terrain"
        bind:selected={settings.terrain}
        onChange={(val) => context.updateSettings({ terrain: val })}
        onSubmit={() => exportGroup("terrain")}
    >
        {@const terrains = instancesByType.get("terrain-heightmap") ?? []}
        <div class="mdc-typography--body1-semi-strong">
            <ExportGroupItem
                name={`${terrains.length} Terrain ${terrains.length === 1 ? "file" : "files"}`}
            />
        </div>
        {#each terrains as [id]}
            {@const inst = bim.instances.perId.get(id)}
            <div class="terrain-group">
                <ExportGroupItem name={inst?.name}></ExportGroupItem>
                <div class="mdc-typography--body2">
                    <ExportGroupItem
                        name={terrainHasChanges(inst)
                            ? "Final grading, cut fill applied"
                            : "Existing grading, no cut fill"}
                    />
                </div>
            </div>
        {/each}
    </ExportGroup>

    <ExportGroup
        name="Imported images"
        bind:selected={settings.images}
        onChange={(val) => context.updateSettings({ images: val })}
        onSubmit={() => exportGroup("images")}
    >
        {#each instancesByType.get("image") ?? [] as [id]}
            <ExportGroupItem name={bim.instances.perId.get(id)?.name} />
        {/each}
    </ExportGroup>
    <ExportGroup
        name="Layout"
        bind:selected={settings.layout}
        onChange={(val) => context.updateSettings({ layout: val })}
    >
        <ExportGroupItem
            name="Boundaries"
            count={getInstTypeCount("boundary")}
            tag={getTotalLength("boundary", "dimensions | area", "ac")}
        />
        <ExportGroupItem
            name="Roads"
            count={getInstTypeCount("road")}
            tag={getTotalLength("road", "dimensions | length")}
        />
        <ExportGroupItem name="Solar arrays" count={solarArrayCount} />
        {#each Object.entries(LayoutGroupLabels) as [type, label]}
            {@const typeInfo = instancesByType.get(type)}
            {#if typeInfo}
                <ExportGroupItem name={label} count={typeInfo.length} />
            {/if}
        {/each}
    </ExportGroup>
    <ExportGroup
        name="Piles position plan"
        bind:selected={settings.pile_position_plan}
        onChange={(val) => context.updateSettings({ pile_position_plan: val })}
        onSubmit={() => exportGroup("pile_position_plan")}
    >
        <ExportGroupItem count={pilesCount} />
    </ExportGroup>
    <ExportGroup
        name="Wiring"
        bind:selected={settings.wireing}
        onChange={(val) => context.updateSettings({ wireing: val })}
    >
        <ExportGroupItem
            name="Low Voltage"
            count={getInstTypeCount("lv-wire")}
            tag={getTotalLength("lv-wire")}
        />
        <ExportGroupItem
            name="Medium Voltage"
            count={getInstTypeCount("wire")}
            tag={getTotalLength("wire")}
        />
        <ExportGroupItem
            name="Trenches"
            count={getInstTypeCount("trench")}
            tag={getTotalLength("trench", "dimensions | length")}
        />
    </ExportGroup>
    <ExportGroup
        name="SLD"
        bind:selected={settings.singleLineDiagram}
        onChange={(val) => context.updateSettings({ singleLineDiagram: val })}
        onSubmit={() => exportGroup("singleLineDiagram")}
    >
        <div class="mdc-typography--body1-semi-strong">
            <ExportGroupItem
                name={`${getInstTypeCount("transformer") + 1} pages`}
            />
        </div>
    </ExportGroup>
</div>

<style lang="scss">
    .export-content {
        width: 468px;
    }
    .terrain-group {
        margin-top: 8px;
        :global(.export-group-item) {
            margin: 0;
        }
    }
</style>
