<script lang="ts">
    import { getContext, onMount, createEventDispatcher } from "svelte";
    import type { Readable } from "svelte/store";
    import type { UnitsMapper } from "bim-ts";
    import type { Layout } from "files-converters";
    import SelectorPropertyBase from "../../SelectorPropertyBase.svelte";

    export let trackerData: Layout.ShadingDataInput;
    export let tolerance: number;
    export let options: Layout.CatalogItem[];
    export let value: Layout.CatalogItem | undefined;

    const unitsMapper = getContext<Readable<UnitsMapper>>("unitsMapper");
    const dispatch = createEventDispatcher();

    onMount(() => {
        const fullMatch = dropdownOptions.find((o) => o.match.length === 4);
        if (fullMatch) {
            dispatch("change", fullMatch.catalogItem);
        }
    });

    const typesOrder = ["fixed-tilt", "tracker", "any-tracker"];

    $: dropdownOptions = options
        .map((option) => {
            const trackerOption = option as Layout.TrackerCatalogItem;
            const match = trackerOption.match(trackerData, tolerance);
            const group = match.length
                ? "Partial match"
                : `Other ${trackerOption.sceneInstance.type_identifier}s`;
            const name = trackerOption.getName($unitsMapper);

            return {
                label: match.length
                    ? trackerOption.getHighlightedName(match, $unitsMapper)
                    : name,
                value: name,
                group,
                match,
                catalogItem: trackerOption,
                sortValue: match.length
                    ? match.reduce((acc, v) => acc + v, 0)
                    : typesOrder.indexOf(
                          trackerOption.sceneInstance.type_identifier,
                      ) - typesOrder.length,
            };
        })
        .sort(
            (a, b) =>
                b.sortValue - a.sortValue || a.value.localeCompare(b.value),
        );

    $: currentValue = value ? [value.getName($unitsMapper)] : [];
</script>

<SelectorPropertyBase
    placeholder="Do not import"
    value={currentValue}
    options={dropdownOptions}
    doubleLine={true}
    on:change={(e) => {
        const newValue = e.detail.value;
        const option = dropdownOptions.find((o) => o.value === newValue);
        dispatch("change", option?.catalogItem);
    }}
/>

<style lang="scss">
    :global(.sv-dropdown .matched-prop) {
        font-weight: 700;
    }
</style>
