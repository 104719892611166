<script lang="ts">
    //@ts-ignore
    import { itemActions } from "svelecte/item";
    import type { SelectOption, DropdownOption } from "./types";
    import { tooltip } from "./tooltip";

    export let inputValue: string;
    export let index = -1;
    export let item: DropdownOption;
    export let isSelected: boolean = false;
    export let isMultiple: boolean = false;
    export let isDisabled: boolean = false;
    export let formatter: (
        item: SelectOption,
        isSelected: boolean,
        inputValue: string
    ) => string;
</script>

{#if item.$isGroupHeader}
    <div
        class="optgroup-header mdc-typography--headline6 text-main-medium"
        on:mousedown|preventDefault
    >
        {item.label}
    </div>
{:else}
    <div
        class="sv-item custom-sv-item"
        use:itemActions={{ item, index }}
        class:is-selected={isSelected}
        class:is-disabled={isDisabled}
        class:multiple={isMultiple}
        on:select
        on:deselect
        on:hover
    >
        {#if !isSelected || !inputValue}
            <div
                class="sv-item-content"
                class:item-invalid={item.invalid}
                use:tooltip={item.text}
            >
                {@html formatter(item, isSelected, inputValue)}
            </div>
        {/if}
    </div>
{/if}

<style lang="scss">
    .is-selected.custom-sv-item {
        cursor: pointer;
        width: 100%;
        .sv-item-content {
            z-index: 1;
        }
    }
    .optgroup-header {
        padding: 8px 8px 0;
    }
</style>
