import type { ScopedLogger} from "engine-utils-ts";
import { Failure, IterUtils, PollablePromise, WorkerPool, Yield } from "engine-utils-ts";
import type { Aabb2, Matrix4, Vector3} from "math-ts";
import { Clipper, KrMath, PointsInPolygonChecker, PolygonUtils, Vector2 } from "math-ts";
import type { IdBimScene } from "../../scene/SceneInstances";
import type { CutFillServiceInput} from "./CutFillService";
import { percToTan } from "./CutFillService";
import type { MathSolversApi } from "../../mathSolversApi/MathSolversApi";
import type { HeightmapFromTileResponse } from "./HeightmapsFromTileExecutor";
import { HeightmapFromTileExecutor } from "./HeightmapsFromTileExecutor";
import { TerrainGeoVersionSelector, TerrainTile, TerrainTileId } from "../TerrainTile";
import { BimPatch } from "../../BimPatch";
import type { RegularHeightmapGeometries} from "../../geometries/RegularHeightmapGeometry";
import { RegularHeightmapGeometry } from "../../geometries/RegularHeightmapGeometry";
import { TerrainHeightMapRepresentation } from "../../representation/Representations";
import type { IdBimGeo } from "../../geometries/BimGeometries";
import type { Bim } from "../../Bim";


export interface HeightMap {
    x_offset: number;
    y_offset: number;
    cell_size_m: number;
    tile_size: number;
    tiles: Tile[];
}

export interface CutFillRequest {
    height_maps: HeightMap[];
    samples: TrackerSample[];
    trackers: TrackerType[];
    surface_tan_x: number;
    min_surface_tan_y: number;
    max_surface_tan_y: number;
    tan: number;
    tan_dev: number;
    cum_tan_dev: number;
    tolerance: number;
    time_sec_limit: number;
    num_threads: number;
    abs_eps: number;
    rel_eps: number;
    return_trackers: boolean;
}


export interface Tile {
    tile_id_x: number;
    tile_id_y: number;
    base_cm: number;
    relative_cm: number[] | Uint32Array | Uint16Array | Uint8Array | Float32Array;
}

export type PileSample = {
    vertex: number;
    preserve_tolerance: boolean;
    regular: boolean;
};

export type TrackerSample = {
    piles: PileSample[];
    motor: number;
};

type PointType = [ number, number ];
export type TrackerType = {
    beg: PointType;
    end: PointType;
    sample: number;
};


export interface CutFillResponse {
    surface: Tile[];
    trackers: Float32Array[];
}


export interface ResponseWithArgs {
    taskId: number;
    response: CutFillResponse[];
    argsForApply: ApplyCutFillArgs;
}


export interface ResponseWithMetrics {
    responseWithArgs: ResponseWithArgs;
    netBalance: number,
    area: number,
    cutArea: number,
    fillArea: number
}


export function* getAllHeightmaps(
    args: CutFillSolverJobArgs[],
    mathSolversApi: MathSolversApi, 
    input: CutFillServiceInput,
    regularHeightmapGeometries: RegularHeightmapGeometries
): Generator<Yield, ResponseWithArgs[], unknown> {
    const argsByResponse = new Map<number, Promise<ResponseWithArgs>>();
    for (let i = 0; i < args.length; i++) {
        const arg = args[i];
        const taskId = i + 1;
        const promiseResult = yield* getHeightmapAndFetchCutFill(arg, mathSolversApi, input, regularHeightmapGeometries, taskId);
        if (promiseResult) {
            argsByResponse.set(taskId, promiseResult);
        }
    }

    const heightmaps: ResponseWithArgs[] = [];
    while (argsByResponse.size > 0) {
        const finished = Promise.race(argsByResponse.values());

        const result = yield* PollablePromise.generatorWaitFor(finished);
        if (result instanceof Failure){
            throw new Error(result.errorMsg());
        }
        const response = result.value;
        if (response.response[0].surface === undefined) {
            throw new Error("Unexpected cut&fill response");
        }
        
        const max = IterUtils.max(response.response[0].surface[0].relative_cm)!;
        if (max < 0xFF) {
            response.response[0].surface[0].relative_cm = new Uint8Array(response.response[0].surface[0].relative_cm);
        } else if (max < 0xFFFF) {
            response.response[0].surface[0].relative_cm = new Uint16Array(response.response[0].surface[0].relative_cm);
        } else {
            response.response[0].surface[0].relative_cm = new Uint32Array(response.response[0].surface[0].relative_cm);
        }
        for (let i = 0; i < response.response[0].trackers.length; ++i) {
            response.response[0].trackers[i] = new Float32Array(response.response[0].trackers[i]);
        }

        heightmaps.push(response);

        argsByResponse.delete(response.taskId);
    }

    return heightmaps;
}

function* getHeightmapAndFetchCutFill(
    args: CutFillSolverJobArgs, 
    mathSolversApi: MathSolversApi,
    input: CutFillServiceInput, 
    regularHeightmapGeometries: RegularHeightmapGeometries,
    taskId: number,
): Generator<Yield, Promise<ResponseWithArgs> | null, unknown> {
    
    const heightmapBbox = args.polyBbox.clone();
    const terrain = args.terrain;

    const tile = args.terrainTiles.entries().next().value[1];
    const geo = regularHeightmapGeometries.peekById(tile?.initialGeo)!;
    const segmentSize = geo.segmentSizeInMeters;
    const cellSize = input.gridSize;
    const gridFactor = cellSize / segmentSize;
    
    prepareBbox(heightmapBbox, segmentSize, cellSize);

    let polygonsExtended: Vector2[][];
    try {
        polygonsExtended = Clipper.offsetPolygons2D([args.polygon], cellSize);
    } catch {
        return null;
    }
    if (polygonsExtended.length === 0) {
        console.warn("invalid polygon", polygonsExtended);
        return null;
    }

    const holes: Vector2[][] = [];
    if (args.holes.length > 0) {
        for (const hole of Clipper.offsetPolygons2D(args.holes, -cellSize)) {
            if (Math.abs(PolygonUtils.area(hole)) > 8) {
                holes.push(hole);
            }
        }
    }
    for (let i = 1; i < polygonsExtended.length; ++i) {
        holes.push(polygonsExtended[i]);
    }

    yield Yield.Asap;

    const minTileId: TerrainTileId = TerrainTileId.newFromPoint(heightmapBbox.min, terrain.representation.tileSize);
    const maxTileId: TerrainTileId = TerrainTileId.newFromPoint(heightmapBbox.max, terrain.representation.tileSize);
    const heightMap = yield* getHeightmapFromContour(
        polygonsExtended[0], holes, heightmapBbox, regularHeightmapGeometries, terrain.representation, terrain.worldMatrix,
        minTileId, maxTileId, segmentSize, gridFactor, (tile) => tile.selectGeoId(TerrainGeoVersionSelector.Initial));
    if (heightMap.tiles[0].relative_cm.every(h => h === 0)) {
        return null;
    }

    const request: CutFillRequest = {
        height_maps: [heightMap],
        samples: args.samples,
        trackers: args.trackers,
        surface_tan_x: percToTan(input.eastWestSlopeMaxPercent),
        min_surface_tan_y: -percToTan(input.northSlopeMaxPercent),
        max_surface_tan_y: percToTan(input.southSlopeMaxPercent),
        tan: percToTan(input.axisSlopeMaxPercent),
        tan_dev: percToTan(input.bayToBaySlopeMaxPercent),
        cum_tan_dev: percToTan(input.cumulativeSlopeMaxPercent),
        tolerance: input.toleranceMeter,
        time_sec_limit: input.timeLimit * args.timeRatio,
        num_threads: args.numThreads,
        abs_eps: input.timeLimit < 60 ? 1e-2 : (input.timeLimit < 240 ? 1e-3 : 1e-4),
        rel_eps: input.timeLimit < 60 ? 1e-2 : (input.timeLimit < 240 ? 1e-3 : 1e-4),
        return_trackers: true,
    };

    const url = "cut_fill_new";
    const responsePromise = mathSolversApi
        .callSolver<CutFillRequest, CutFillResponse[]>({
            solverName: url,
            solverType: "multi",
            request: request,
        })
        .then((response) => {
            return {
                taskId: taskId,
                response: response,
                argsForApply: {
                    logger: args.logger,
                    polygon: args.polygon,
                    holes: args.holes,
                    terrain: terrain,
                    terrainTiles: args.terrainTiles,
                    segmentSize: segmentSize,
                    cellSize: cellSize,
                    heightmapBbox: heightmapBbox,
                    minTileId: minTileId,
                    maxTileId: maxTileId,
                },
            };
        });
    
    return responsePromise;
}


export interface CutFillSolverJobArgs {
    polygon: Vector2[],
    holes: Vector2[][],
    polyBbox: Aabb2,
    terrain: {
        id: IdBimScene;
        representation: TerrainHeightMapRepresentation;
        worldMatrix: Matrix4;
        aabb2: Aabb2;
    },
    terrainTiles: Map<TerrainTileId, TerrainTile>,
    trackers: TrackerType[],
    samples: TrackerSample[],
    timeRatio: number;
    numThreads: number;
    logger: ScopedLogger,
}


interface ApplyCutFillArgs {
    logger: ScopedLogger,
    polygon: Vector2[],
    holes: Vector2[][],
    terrain: {
        id: IdBimScene;
        representation: TerrainHeightMapRepresentation;
        worldMatrix: Matrix4;
        aabb2: Aabb2;
    },
    terrainTiles: Map<TerrainTileId, TerrainTile>,
    segmentSize: number, cellSize: number,
    heightmapBbox: Aabb2,
    minTileId: TerrainTileId,
    maxTileId: TerrainTileId
}


export function prepareBbox(polyBbox: Aabb2, segmentSize: number, cellSize: number) {
    polyBbox.min.divideScalar(segmentSize).ceil().multiplyScalar(segmentSize);
    polyBbox.max.divideScalar(segmentSize).floor().multiplyScalar(segmentSize);

    const xAdd = (cellSize - polyBbox.width() % cellSize) / segmentSize;
    polyBbox.min.setX(polyBbox.min.x - segmentSize * Math.floor(xAdd / 2));
    polyBbox.max.setX(polyBbox.max.x + segmentSize * Math.ceil(xAdd / 2));
    const yAdd = (cellSize - polyBbox.height() % cellSize) / segmentSize;
    polyBbox.min.setY(polyBbox.min.y - segmentSize * Math.floor(yAdd / 2));
    polyBbox.max.setY(polyBbox.max.y + segmentSize * Math.ceil(yAdd / 2));
}


export function* getHeightmapFromContour(
    polygon: Vector2[],
    holes: Vector2[][],
    polyBbox: Aabb2,
    regularGeos: RegularHeightmapGeometries, 
    repr: TerrainHeightMapRepresentation, 
    worldMatrix: Matrix4,
    minTileId: TerrainTileId,
    maxTileId: TerrainTileId,
    segmentSize: number,
    delta: number,
    getTileId: (tile: TerrainTile) => IdBimGeo,
): Generator<Yield, HeightMap, unknown> {
    const tilePointsSize = 1 + repr.tileSize / segmentSize;
    const cellSize = segmentSize * delta;

    const heightmapWidth = 1 + polyBbox.width() / cellSize;
    const heightmapHeight = 1 + polyBbox.height() / cellSize;

    const tiles: Tile[] = [];
    const relativeCm: number[] = new Array(heightmapHeight * heightmapWidth);

    const promises = new Map<number, Promise<HeightmapFromTileResponse>>();
    for (let tileIdY = minTileId.y; tileIdY <= maxTileId.y; ++tileIdY) {
        for (let tileIdX = minTileId.x; tileIdX <= maxTileId.x; ++tileIdX) {
            const tileId = TerrainTileId.new(tileIdX, tileIdY);
            const tile = repr.tiles.get(tileId);
            if (!tile) {
                continue;
            }
            const tileGeoId = getTileId(tile);
            const tileGeo = regularGeos.peekById(tileGeoId);
            if (!tileGeo) {
                continue;
            }

            const tileOffset: Vector2 = tileId.localOffset(repr.tileSize);
            const minX = tileOffset.x <= polyBbox.min.x ? 
                (polyBbox.min.x - tileOffset.x) / segmentSize :
                (cellSize - (tileOffset.x - polyBbox.min.x) % cellSize) / segmentSize;
            const minY = tileOffset.y <= polyBbox.min.y ? 
                (polyBbox.min.y - tileOffset.y) / segmentSize :
                (cellSize - (tileOffset.y - polyBbox.min.y) % cellSize) / segmentSize;
            const maxX = tileOffset.x + repr.tileSize >= polyBbox.max.x ?
                (polyBbox.max.x - tileOffset.x) / segmentSize :
                (repr.tileSize - (tileOffset.x + repr.tileSize - polyBbox.min.x) % cellSize) / segmentSize;
            const maxY = tileOffset.y + repr.tileSize >= polyBbox.max.y ?
                (polyBbox.max.y - tileOffset.y) / segmentSize :
                (repr.tileSize - (tileOffset.y + repr.tileSize - polyBbox.min.y) % cellSize) / segmentSize;
            
            if (maxX < minX || maxY < minY) {
                continue;
            }

            promises.set(tileOffset.y * repr.tileSize + tileOffset.x / repr.tileSize, 
                WorkerPool.execute(HeightmapFromTileExecutor, {
                    polygon, holes, tileGeo, minY, maxY, minX, maxX, 
                    delta, segmentSize, tilePointsSize, tileOffset
                })
            );
        }
    }

    yield Yield.Asap;

    const newCoord = new Vector2();
    while (promises.size > 0) {
        const finished = Promise.race(promises.values());

        const result = yield* PollablePromise.generatorWaitFor(finished);
        if (result instanceof Failure){
            throw new Error(result.errorMsg());
        }

        const response = result.value;

        const tileHeightmapHeight = (response.maxY - response.minY) / delta + 1;
        const tileHeightmapWidth = (response.maxX - response.minX) / delta + 1;

        newCoord.set(response.minX, response.minY)
            .multiplyScalar(segmentSize).add(response.tileOffset)
            .sub(polyBbox.min).divideScalar(cellSize);
        for (let iy = 0; iy < tileHeightmapHeight; ++iy) {
            for (let ix = 0; ix < tileHeightmapWidth; ++ix) {
                relativeCm[(newCoord.y + iy) * heightmapWidth + (newCoord.x + ix)] = 
                    response.heightmap[iy * tileHeightmapWidth + ix];
            }
        }

        promises.delete(response.tileOffset.y * repr.tileSize + response.tileOffset.x / repr.tileSize);
    }

    let baseCm: number = NaN;
    for (let i = 0; i < relativeCm.length; ++i) {
        if (relativeCm[i] === undefined || Number.isNaN(relativeCm[i])) {
            continue;
        }
        if (Number.isNaN(baseCm) || relativeCm[i] < baseCm) {
            baseCm = relativeCm[i];
        }
    }
    if (Number.isNaN(baseCm)) {
        baseCm = 0;
    } else {
        baseCm -= 1;
    }


    for (let i = 0; i < relativeCm.length; ++i) {
        relativeCm[i] -= baseCm;
        if (Number.isNaN(relativeCm[i])) {
            relativeCm[i] = 0;
        }
    }

    tiles.push({
        tile_id_x: 0,
        tile_id_y: 0,
        base_cm: baseCm,
        relative_cm: relativeCm
    });

    const offset = polyBbox.min.clone();
    offset.applyMatrix4(worldMatrix);

    return {
        x_offset: offset.x,
        y_offset: offset.y,
        cell_size_m: cellSize,
        tile_size: heightmapWidth,
        tiles: tiles
    };
}


function binarySearch(piles: Vector3[], condition: (p: Vector3) => number): number {
    if (condition(piles[0]) >= 0) {
        return 0;
    } else if (condition(piles[piles.length - 1]) < 0) {
        return piles.length;
    }
    
    let leftIndex = 0;
    let rightIndex = piles.length - 1;

    let midIndex = Math.floor((leftIndex + rightIndex) / 2);
    while (midIndex !== leftIndex) {
        if (condition(piles[midIndex]) < 0) {
            leftIndex = midIndex;
            midIndex = Math.floor((leftIndex + rightIndex) / 2);
        } else if (condition(piles[midIndex]) >= 0) {
            rightIndex = midIndex;
            midIndex = Math.floor((leftIndex + rightIndex) / 2);
        }
    }

    return rightIndex;
}


export function refineHeightmap(
    responseWithArgs: ResponseWithArgs, 
    newCellSize: number,
    piles: Vector3[],
    input: CutFillServiceInput,
    regularHeightmapGeometries: RegularHeightmapGeometries,
) {
    const cellSize = responseWithArgs.argsForApply.cellSize;
    const cellsRate = cellSize / newCellSize;
    const tilesSize = responseWithArgs.argsForApply.terrain.representation.tileSize;

    const checker = new PointsInPolygonChecker([responseWithArgs.argsForApply.polygon], responseWithArgs.argsForApply.holes);
    const point = new Vector2();

    const responseTile = responseWithArgs.response[0].surface[0];
    const responseTileWidth = 1 + responseWithArgs.argsForApply.heightmapBbox.width() / cellSize;
    const responseTileHeight = 1 + responseWithArgs.argsForApply.heightmapBbox.height() / cellSize;

    const newTileWidth = 1 + responseWithArgs.argsForApply.heightmapBbox.width() / newCellSize;
    const newTileHeight = 1 + responseWithArgs.argsForApply.heightmapBbox.height() / newCellSize;
    const elevations = new Float32Array(newTileWidth * newTileHeight);

    let tileId = TerrainTileId.new(0, 0);
    let tile = responseWithArgs.argsForApply.terrainTiles.get(tileId);
    let tileGeo = regularHeightmapGeometries.peekById(tile?.selectGeoId(TerrainGeoVersionSelector.Latest) ?? 0);
    for (let cellIY = 0; cellIY < responseTileHeight - 1; ++cellIY) {
        for (let cellIX = 0; cellIX < responseTileWidth - 1; ++cellIX) {
            for (let subCellIY = 0; subCellIY <= cellsRate; ++subCellIY) {
                for (let subCellIX = 0; subCellIX <= cellsRate; ++subCellIX) {
                    point.set(cellIX * cellsRate + subCellIX, cellIY * cellsRate + subCellIY).multiplyScalar(newCellSize)
                        .add(responseWithArgs.argsForApply.heightmapBbox.min);
                    const newIndex = (cellIY * cellsRate + subCellIY) * newTileWidth + (cellIX * cellsRate + subCellIX);

                    const currTileId = TerrainTileId.newFromPoint(point, tilesSize);
                    if (currTileId !== tileId) {
                        tileId = currTileId;
                        tile = responseWithArgs.argsForApply.terrainTiles.get(currTileId);
                        tileGeo = regularHeightmapGeometries.peekById(tile?.selectGeoId(TerrainGeoVersionSelector.Latest) ?? 0);
                    }

                    if (!tileGeo) {
                        elevations[newIndex] = NaN;
                        continue;
                    } else {
                        const tileOffset = tileId.localOffset(tilesSize);
                        point.sub(tileOffset).divideScalar(responseWithArgs.argsForApply.segmentSize);
                        elevations[newIndex] = 100 * getFromTiles(
                            point.x, point.y, 
                            tileGeo, 
                            tilesSize / responseWithArgs.argsForApply.segmentSize, 
                            tileId.x, tileId.y, 
                            responseWithArgs.argsForApply.terrainTiles, 
                            regularHeightmapGeometries
                        );
                    }
                }
            }
        }
    }

    const subCellP = new Vector2, subCellI = new Vector2();
    const responseElevations: [number, number, number, number] = [0, 0, 0, 0];
    const areTheSame = [false, false, false, false];
    const fromResponseMask = new Int8Array((responseTileWidth - 1) * (responseTileHeight - 1));
    let fromResponse = false;
    for (let cellIY = 0; cellIY < responseTileHeight - 1; ++cellIY) {
        for (let cellIX = 0; cellIX < responseTileWidth - 1; ++cellIX) {
            responseElevations[0] = responseTile.relative_cm[cellIY * responseTileWidth + cellIX];
            responseElevations[1] = responseTile.relative_cm[cellIY * responseTileWidth + cellIX + 1];
            responseElevations[2] = responseTile.relative_cm[(cellIY + 1) * responseTileWidth + cellIX];
            responseElevations[3] = responseTile.relative_cm[(cellIY + 1) * responseTileWidth + cellIX + 1];
            for (let i = 0; i < 4; ++i) {
                if (responseElevations[i] === 0) {
                    responseElevations[i] = NaN;
                } else {
                    responseElevations[i] = responseElevations[i] + responseTile.base_cm;
                }
            }

            areTheSame[0] = Math.abs(responseElevations[0] - elevations[cellsRate * (cellIY * newTileWidth + cellIX)]) < 0.005;
            areTheSame[1] = Math.abs(responseElevations[1] - elevations[cellsRate * (cellIY * newTileWidth + cellIX + 1)]) < 0.005;
            areTheSame[2] = Math.abs(responseElevations[2] - elevations[cellsRate * ((cellIY + 1) * newTileWidth + cellIX)]) < 0.005;
            areTheSame[3] = Math.abs(responseElevations[3] - elevations[cellsRate * ((cellIY + 1) * newTileWidth + cellIX + 1)]) < 0.005;

            fromResponse = !areTheSame.every(s => s === true);

            if (!fromResponse && piles.length > 0) {
                point.set(cellIX, cellIY).multiplyScalar(cellSize)
                    .add(responseWithArgs.argsForApply.heightmapBbox.min);
                const firstI = binarySearch(piles, p => p.y - point.y);
                for (let i = firstI; i < piles.length; ++i) {
                    if (piles[i].y > point.y + cellSize) {
                        break;
                    }
                    
                    if (piles[i].x < point.x || piles[i].x > point.x + cellSize) {
                        continue;
                    }

                    subCellP.set(piles[i].x - point.x, piles[i].y - point.y).divideScalar(newCellSize);
                    subCellI.copy(subCellP).floor();
                    const elevation = checkNaNsAndGetFromSegment(
                        subCellP.x - subCellI.x, subCellP.y - subCellI.y, 
                        [
                            elevations[(cellIY * cellsRate + subCellI.y) * newTileWidth + (cellIX * cellsRate + subCellI.x)],
                            elevations[(cellIY * cellsRate + subCellI.y) * newTileWidth + (cellIX * cellsRate + subCellI.x + 1)],
                            elevations[(cellIY * cellsRate + subCellI.y + 1) * newTileWidth + (cellIX * cellsRate + subCellI.x)],
                            elevations[(cellIY * cellsRate + subCellI.y + 1) * newTileWidth + (cellIX * cellsRate + subCellI.x + 1)],
                        ], 
                        [true, true, true, true]
                    );

                    if (Math.abs(0.01 * elevation - piles[i].z) > input.toleranceMeter) {
                        fromResponse = true;
                        break;
                    }
                }
            }

            fromResponseMask[cellIY * (responseTileWidth - 1) + cellIX] = fromResponse ? 1 : 0;
        }
    }

    for (let cellIY = 0; cellIY < responseTileHeight - 1; ++cellIY) {
        for (let cellIX = 0; cellIX < responseTileWidth - 1; ++cellIX) {
            responseElevations[0] = responseTile.relative_cm[cellIY * responseTileWidth + cellIX];
            responseElevations[1] = responseTile.relative_cm[cellIY * responseTileWidth + cellIX + 1];
            responseElevations[2] = responseTile.relative_cm[(cellIY + 1) * responseTileWidth + cellIX];
            responseElevations[3] = responseTile.relative_cm[(cellIY + 1) * responseTileWidth + cellIX + 1];
            for (let i = 0; i < 4; ++i) {
                if (responseElevations[i] === 0) {
                    responseElevations[i] = NaN;
                } else {
                    responseElevations[i] = responseElevations[i] + responseTile.base_cm;
                }
            }

            const fromResponse = fromResponseMask[cellIY * (responseTileWidth - 1) + cellIX] === 1;

            let minSubY = 0, minSubX = 0, maxSubY = cellsRate, maxSubX = cellsRate;
            if (!fromResponse) {
                if (cellIY !== 0 && fromResponseMask[(cellIY - 1) * (responseTileWidth - 1) + cellIX] === 1) {
                    ++minSubY;
                }
                if (cellIY !== responseTileHeight - 2 && fromResponseMask[(cellIY + 1) * (responseTileWidth - 1) + cellIX] === 1) {
                    --maxSubY;
                }

                if (cellIX !== 0 && fromResponseMask[cellIY * (responseTileWidth - 1) + cellIX - 1] === 1) {
                    ++minSubX;
                }
                if (cellIX !== responseTileWidth - 2 && fromResponseMask[cellIY * (responseTileWidth - 1) + cellIX + 1] === 1) {
                    --maxSubX;
                }
            }
            
            for (let subCellIY = minSubY; subCellIY <= maxSubY; ++subCellIY) {
                for (let subCellIX = minSubX; subCellIX <= maxSubX; ++subCellIX) {                    
                    const newIndex = (cellIY * cellsRate + subCellIY) * newTileWidth + (cellIX * cellsRate + subCellIX);
                    point.set(cellIX * cellsRate + subCellIX, cellIY * cellsRate + subCellIY)
                        .multiplyScalar(newCellSize).add(responseWithArgs.argsForApply.heightmapBbox.min);

                    if (checker.isPointInside(point)) {
                        if (fromResponse) {
                            elevations[newIndex] = checkNaNsAndGetFromSegment(
                                subCellIX / cellsRate, subCellIY / cellsRate, responseElevations, [true, true, true, true]
                            );
                        }
                    } else {
                        elevations[newIndex] = -Math.abs(elevations[newIndex]);
                    }
                }
            }
        }
    }

    responseWithArgs.argsForApply.cellSize = newCellSize;
    responseWithArgs.response[0].surface[0] = {
        tile_id_x: responseTile.tile_id_x,
        tile_id_y: responseTile.tile_id_y,
        base_cm: 0,
        relative_cm: elevations,
    };
}


export function calculateMetrics(
    responseWithArgs: ResponseWithArgs, 
    cellSize: number, 
    regularHeightmapGeometries: RegularHeightmapGeometries
): ResponseWithMetrics {
    let netBalance = 0;
    let area = 0;
    let cutArea = 0;
    let fillArea = 0;

    const args = responseWithArgs.argsForApply;
    const repr = args.terrain.representation;
    const delta = cellSize / args.segmentSize;
    const responseTile = responseWithArgs.response[0].surface[0];
    const responseTileWidth = 1 + args.heightmapBbox.width() / cellSize;

    const tilePointsSize = 1 + repr.tileSize / args.segmentSize;

    const cellArea = cellSize * cellSize;

    const point = new Vector2();
    for (let tileIdY = args.minTileId.y; tileIdY <= args.maxTileId.y; ++tileIdY) {
        for (let tileIdX = args.minTileId.x; tileIdX <= args.maxTileId.x; ++tileIdX) {
            const tileId = TerrainTileId.new(tileIdX, tileIdY);
            const tile = repr.tiles.get(tileId);
            if (!tile) {
                continue;
            }
            const tileGeo = regularHeightmapGeometries.peekById(tile.selectGeoId(TerrainGeoVersionSelector.Latest));
            if (!tileGeo) {
                continue;
            }

            const tileOffset: Vector2 = tileId.localOffset(repr.tileSize);
            const minX = tileOffset.x <= args.heightmapBbox.min.x ? 
                (args.heightmapBbox.min.x - tileOffset.x) / args.segmentSize :
                (cellSize - (tileOffset.x - args.heightmapBbox.min.x) % cellSize) / args.segmentSize;
            const minY = tileOffset.y <= args.heightmapBbox.min.y ? 
                (args.heightmapBbox.min.y - tileOffset.y) / args.segmentSize :
                (cellSize - (tileOffset.y - args.heightmapBbox.min.y) % cellSize) / args.segmentSize;
            const maxX = tileOffset.x + repr.tileSize > args.heightmapBbox.max.x ?
                (args.heightmapBbox.max.x - tileOffset.x) / args.segmentSize :
                (repr.tileSize - (tileOffset.x + repr.tileSize - args.heightmapBbox.min.x) % cellSize) / args.segmentSize;
            const maxY = tileOffset.y + repr.tileSize > args.heightmapBbox.max.y ?
                (args.heightmapBbox.max.y - tileOffset.y) / args.segmentSize :
                (repr.tileSize - (tileOffset.y + repr.tileSize - args.heightmapBbox.min.y) % cellSize) / args.segmentSize;
            
            for (let iy = minY; iy <= maxY; iy += delta) {
                for (let ix = minX; ix <= maxX; ix += delta) {
                    if (tileGeo.elevationsInCmRelative[iy * tilePointsSize + ix] === 0) {
                        continue;
                    }

                    point.set(ix, iy).multiplyScalar(args.segmentSize).add(tileOffset).sub(args.heightmapBbox.min).divideScalar(cellSize);
                    
                    let responseElevation = responseTile.relative_cm[point.y * responseTileWidth + point.x];
                    if (responseElevation > 0) {
                        responseElevation += responseTile.base_cm;

                        area += cellArea;

                        const initialElevation = tileGeo.elevationsBaseInCm + 
                            tileGeo.elevationsInCmRelative[iy * tilePointsSize + ix];

                        netBalance += cellArea * (responseElevation - initialElevation) * 0.01;
    
                        if (responseElevation > initialElevation) {
                            fillArea += cellArea;
                        } else if (responseElevation < initialElevation) {
                            cutArea += cellArea;
                        }
                    }
                }
            }
        }
    }

    return { responseWithArgs, area, netBalance, cutArea, fillArea };
}


export function* restoreNetBalance(
    responses: ResponseWithArgs[], 
    netBalanceRequested: number, 
    regularHeightmapGeometries: RegularHeightmapGeometries
) {    
    let responsesWithMetrics = responses.map(r => calculateMetrics(r, r.argsForApply.cellSize, regularHeightmapGeometries));
    responsesWithMetrics = responsesWithMetrics.filter(r => r.area > 0);
    let diffBalance = responsesWithMetrics.reduce((balance, response) => balance + response.netBalance, 0) - netBalanceRequested;
    let i;

    yield Yield.Asap;
    
    if (diffBalance >= 0) {
        while (true) {
            responsesWithMetrics.sort((a, b) => (b.fillArea / b.area) - (a.fillArea / a.area));
            
            for (i = 0; i < responsesWithMetrics.length; ++i) {
                if (0.01 * responsesWithMetrics[i].area > diffBalance) {
                    continue;
                }

                responsesWithMetrics[i].responseWithArgs.response[0].surface[0].base_cm -= 1;
                for (const tracker of responsesWithMetrics[i].responseWithArgs.response[0].trackers) {
                    for (let i = 0; i < tracker.length; ++i) {
                        tracker[i] -= 0.01;
                    }
                }
                diffBalance -= 0.01 * responsesWithMetrics[i].area;

                responsesWithMetrics[i] = calculateMetrics(
                    responsesWithMetrics[i].responseWithArgs, 
                    responsesWithMetrics[i].responseWithArgs.argsForApply.cellSize, 
                    regularHeightmapGeometries
                );

                yield Yield.Asap;

                break;
            }
            if (i === responses.length) {
                return;
            }
        }
    } else {
        while (true) {
            responsesWithMetrics.sort((a, b) => (b.cutArea / b.area) - (a.cutArea / a.area));
            
            for (i = 0; i < responsesWithMetrics.length; ++i) {
                if (0.01 * responsesWithMetrics[i].area > -diffBalance) {
                    continue;
                }

                responsesWithMetrics[i].responseWithArgs.response[0].surface[0].base_cm += 1;
                for (const tracker of responsesWithMetrics[i].responseWithArgs.response[0].trackers) {
                    for (let i = 0; i < tracker.length; ++i) {
                        tracker[i] += 0.01;
                    }
                }
                diffBalance += 0.01 * responsesWithMetrics[i].area;

                responsesWithMetrics[i] = calculateMetrics(
                    responsesWithMetrics[i].responseWithArgs, 
                    responsesWithMetrics[i].responseWithArgs.argsForApply.cellSize,
                    regularHeightmapGeometries
                );

                yield Yield.Asap;

                break;
            }
            if (i === responses.length) {
                return;
            }
        }
    }
}


export function* applyCutFillHeightmap(
    responseTile: Tile, 
    args: ApplyCutFillArgs, 
    piles: Vector3[],
    tolerance: number,
    bim: Bim,
) {
    const delta = args.cellSize / args.segmentSize;
    const heightmapWidth = 1 + args.heightmapBbox.width() / args.cellSize;
    const heightmapHeight = 1 + args.heightmapBbox.height() / args.cellSize;
    const tilesSize = args.terrain.representation.tileSize;
    
    const point = new Vector2();
    const inTileP = new Vector2, inTileI = new Vector2();
    const responseElevations: [number, number, number, number] = [0, 0, 0, 0];
    const isPointsInside: [boolean, boolean, boolean, boolean] = [false, false, false, false];

    let tileId = TerrainTileId.new(0, 0);
    let tile = args.terrainTiles.get(tileId);
    let tileGeo = bim.regularHeightmapGeometries.peekById(tile?.selectGeoId(TerrainGeoVersionSelector.Latest) ?? 0);

    const fromResponseMask = new Int8Array((heightmapWidth - 1) * (heightmapHeight - 1));
    let fromResponse = false;
    for (let cellIY = 0; cellIY < heightmapHeight - 1; ++cellIY) {
        for (let cellIX = 0; cellIX < heightmapWidth - 1; ++cellIX) {
            responseElevations[0] = responseTile.relative_cm[cellIY * heightmapWidth + cellIX];
            responseElevations[1] = responseTile.relative_cm[cellIY * heightmapWidth + cellIX + 1];
            responseElevations[2] = responseTile.relative_cm[(cellIY + 1) * heightmapWidth + cellIX];
            responseElevations[3] = responseTile.relative_cm[(cellIY + 1) * heightmapWidth + cellIX + 1];

            for (let i = 0; i < 4; ++i) {
                isPointsInside[i] = responseElevations[i] > 0;
                responseElevations[i] = 0.01 * Math.abs(responseElevations[i]);
            }

            fromResponse = !isPointsInside.every(i => i === false);
            if (fromResponse) {
                let i = 0;
                for (; i < 4; ++i) {
                    point.set(cellIX + i % 2, cellIY + Math.floor(i / 2)).multiplyScalar(args.cellSize).add(args.heightmapBbox.min);

                    const currTileId = TerrainTileId.newFromPoint(point, tilesSize);
                    if (currTileId !== tileId) {
                        tileId = currTileId;
                        tile = args.terrainTiles.get(currTileId);
                        tileGeo = bim.regularHeightmapGeometries.peekById(tile?.selectGeoId(TerrainGeoVersionSelector.Latest) ?? 0);
                    }

                    if (tileGeo) {
                        const tileOffset = tileId.localOffset(tilesSize);
                        point.sub(tileOffset).divideScalar(args.segmentSize);
                        const initialElevation = getFromTiles(
                            point.x, point.y, 
                            tileGeo, 
                            tilesSize / args.segmentSize, 
                            tileId.x, tileId.y, 
                            args.terrainTiles, 
                            bim.regularHeightmapGeometries
                        );
                        if (!Number.isNaN(initialElevation) && Math.abs(initialElevation - responseElevations[i]) > 0.005) {
                            break;
                        }
                    }
                }

                if (i === 4) {
                    fromResponse = false;

                    if (piles.length > 0) {
                        point.set(cellIX, cellIY).multiplyScalar(args.cellSize).add(args.heightmapBbox.min);
                        const firstI = binarySearch(piles, p => p.y - point.y);
                        for (let i = firstI; i < piles.length; ++i) {
                            if (piles[i].y > point.y + args.cellSize) {
                                break;
                            }
                            
                            if (piles[i].x < point.x || piles[i].x > point.x + args.cellSize) {
                                continue;
                            }

                            
                            inTileP.set(piles[i].x, piles[i].y);
                            const currTileId = TerrainTileId.newFromPoint(inTileP, tilesSize);
                            if (currTileId !== tileId) {
                                tileId = currTileId;
                                tile = args.terrainTiles.get(currTileId);
                                tileGeo = bim.regularHeightmapGeometries.peekById(tile?.selectGeoId(TerrainGeoVersionSelector.Latest) ?? 0);
                            }

                            if (tileGeo) {
                                const tileOffset = tileId.localOffset(tilesSize);
                                inTileP.sub(tileOffset).divideScalar(args.segmentSize);
                                inTileI.copy(inTileP).floor();
                                const elevation = checkNaNsAndGetFromSegment(
                                    inTileP.x - inTileI.x, inTileP.y - inTileI.y, 
                                    [
                                        tileGeo.readElevationAtInds(inTileI.x, inTileI.y),
                                        tileGeo.readElevationAtInds(inTileI.x + 1, inTileI.y),
                                        tileGeo.readElevationAtInds(inTileI.x, inTileI.y + 1),
                                        tileGeo.readElevationAtInds(inTileI.x + 1, inTileI.y + 1),
                                    ], 
                                    [true, true, true, true]
                                );

                                if (Math.abs(elevation - piles[i].z) > tolerance) {
                                    fromResponse = true;
                                    break;
                                }
                            }
                        }
                    }
                }
            }

            fromResponseMask[cellIY * (heightmapWidth - 1) + cellIX] = fromResponse ? 1 : 0;
        }
    }

    const bimPatch = new BimPatch();

    const tilePointsSize = 1 + args.terrain.representation.tileSize / args.segmentSize;
    for (let tileIdY = args.minTileId.y; tileIdY <= args.maxTileId.y; ++tileIdY) {
        for (let tileIdX = args.minTileId.x; tileIdX <= args.maxTileId.x; ++tileIdX) {
            const tileId = TerrainTileId.new(tileIdX, tileIdY);
            const tile = args.terrainTiles.get(tileId);
            if (!tile) {
                continue;
            }
            
            const tileGeo = bim.regularHeightmapGeometries.peekById(tile.updatedGeo) 
                ?? bim.regularHeightmapGeometries.peekById(tile.initialGeo)!;

            const tileElevations = new Float32Array(tilePointsSize * tilePointsSize);
            const tileOffset: Vector2 = tileId.localOffset(args.terrain.representation.tileSize);
            
            const minX = (
                args.heightmapBbox.min.x - KrMath.ceilTo(args.heightmapBbox.min.x - tileOffset.x, args.cellSize) - tileOffset.x
            ) / args.segmentSize;
            const minY = (
                args.heightmapBbox.min.y - KrMath.ceilTo(args.heightmapBbox.min.y - tileOffset.y, args.cellSize) - tileOffset.y
            ) / args.segmentSize;

            const inResponseIndex = new Vector2();
            const isPointsInside: [boolean, boolean, boolean, boolean] = [false, false, false, false];
            const cellElevations: [number, number, number, number] = [0, 0, 0, 0];

            for (let cellIY = minY; cellIY < tilePointsSize; cellIY += delta) {
                inResponseIndex.set(minX, cellIY)
                    .multiplyScalar(args.segmentSize).add(tileOffset)
                    .sub(args.heightmapBbox.min).divideScalar(args.cellSize);

                cellElevations[1] = responseTile.relative_cm[inResponseIndex.y * heightmapWidth + inResponseIndex.x];
                cellElevations[3] = responseTile.relative_cm[(inResponseIndex.y + 1) * heightmapWidth + inResponseIndex.x];

                isPointsInside[1] = cellElevations[1] > 0;
                isPointsInside[3] = cellElevations[3] > 0;

                cellElevations[1] = 0.01 * Math.abs(cellElevations[1]);
                cellElevations[3] = 0.01 * Math.abs(cellElevations[3]);
                
                for (let cellIX = minX; cellIX < tilePointsSize; cellIX += delta, ++inResponseIndex.x) {
                    cellElevations[0] = cellElevations[1];
                    cellElevations[2] = cellElevations[3];
                    cellElevations[1] = responseTile.relative_cm[inResponseIndex.y * heightmapWidth + inResponseIndex.x + 1];
                    cellElevations[3] = responseTile.relative_cm[(inResponseIndex.y + 1) * heightmapWidth + inResponseIndex.x + 1];

                    isPointsInside[0] = isPointsInside[1];
                    isPointsInside[2] = isPointsInside[3];
                    isPointsInside[1] = cellElevations[1] > 0;
                    isPointsInside[3] = cellElevations[3] > 0;

                    cellElevations[1] = 0.01 * Math.abs(cellElevations[1]);
                    cellElevations[3] = 0.01 * Math.abs(cellElevations[3]);

                    if (inResponseIndex.y < 0 || inResponseIndex.y + 1 >= heightmapHeight ||
                        inResponseIndex.x < 0 || inResponseIndex.x + 1 >= heightmapWidth
                    ) {
                        updateElevations(
                            tileElevations, false, fromResponseMask, 
                            inResponseIndex, heightmapWidth - 1, heightmapHeight - 1,
                            cellIY, cellIX, delta, tilePointsSize,
                            cellElevations, isPointsInside, tileGeo,
                        );
                        continue;
                    }

                    updateElevations(
                        tileElevations, 
                        fromResponseMask[inResponseIndex.y * (heightmapWidth - 1) + inResponseIndex.x] === 1,
                        fromResponseMask, 
                        inResponseIndex, heightmapWidth - 1, heightmapHeight - 1,
                        cellIY, cellIX, delta, tilePointsSize,
                        cellElevations, isPointsInside, tileGeo,
                    );
                }
            }

            const updatedTileGeoRes = RegularHeightmapGeometry.newFromMetersAndNaNs(
                tilePointsSize - 1,
                tilePointsSize - 1,
                args.segmentSize,
                tileElevations
            );
            if (updatedTileGeoRes instanceof Failure) {
                args.logger.error('Failed to create updated tile geometry', updatedTileGeoRes.errorMsg());
                continue;
            }

            const newGeoId = bim.regularHeightmapGeometries.reserveNewId();
            bimPatch.geometries.toAlloc.push([newGeoId, updatedTileGeoRes.value]);
            args.terrainTiles.set(tileId, new TerrainTile(tile.initialGeo, newGeoId));
        
            yield Yield.Asap;
        }
    }

    const newRepr = new TerrainHeightMapRepresentation(
        args.terrain.representation.tileSize,
        new Map(args.terrainTiles),
    );
    bimPatch.instances.toPatch.push([args.terrain.id, {representation: newRepr}]);

    yield Yield.Asap;
    bimPatch.applyTo(bim);

    const discontinuities = newRepr.findDiscontinuities(bim.regularHeightmapGeometries, TerrainGeoVersionSelector.Latest);
    for (const d of discontinuities) {
        console.error(
            `There is a discontinuity between the tiles with ids ${d[0][0]} and ${d[1][0]}: ` +
            `elevation at the point ${d[0][1]} for the first tile is ${d[0][2]}, ` +
            `elevation at the point ${d[1][1]} for the second tile is ${d[1][2]}.`
        );
    }
}


function updateElevations(
    tileElevations: Float32Array,
    fromResponse: boolean,
    fromResponseMask: Int8Array,
    inResponseIndex: Vector2,
    fromResponseWidth: number,
    fromResponseHeight: number,
    cellIY: number, cellIX: number,
    delta: number, tilePointsSize: number,
    cellElevations: [number, number, number, number],
    isPointsInside: [boolean, boolean, boolean, boolean],
    tileGeo: RegularHeightmapGeometry,
) {
    let minSubY = 0, minSubX = 0, maxSubY = delta, maxSubX = delta;
    if (!fromResponse) {
        if (inResponseIndex.x >= 0 && inResponseIndex.x < fromResponseWidth) {
            if (inResponseIndex.y > 0 
                && fromResponseMask[(inResponseIndex.y - 1) * fromResponseWidth + inResponseIndex.x] === 1
            ) {
                ++minSubY;
                if (cellIY === 0) {
                    let iy = 0;
                    for (let ix = 0; ix <= delta; ++ix) {
                        if (cellIX + ix >= tilePointsSize) {
                            break;
                        } else if (cellIX + ix < 0) {
                            continue;
                        }
            
                        const oldElevation = tileGeo.readElevationAtInds(cellIX + ix, cellIY + iy);
                        if (Number.isNaN(oldElevation)) {
                            tileElevations[(cellIY + iy) * tilePointsSize + cellIX + ix] = NaN;
                            continue;
                        }
            
                        let newElevation = checkNaNsAndGetFromSegment(
                            ix / delta, iy / delta, cellElevations, isPointsInside
                        );
        
                        const fract = 100 * newElevation - Math.floor(100 * newElevation);
                        if (fract > 0.499 && fract < 0.501) {
                            if (newElevation > oldElevation) {
                                newElevation -= 0.005;
                            } else {
                                newElevation += 0.005;
                            }
                        }
        
                        tileElevations[(cellIY + iy) * tilePointsSize + cellIX + ix] = newElevation;
                    }
                }
            }
            if (inResponseIndex.y < fromResponseHeight - 1 
                && fromResponseMask[(inResponseIndex.y + 1) * fromResponseWidth + inResponseIndex.x] === 1
            ) {
                --maxSubY;
                if (cellIY + delta === tilePointsSize - 1) {
                    let iy = delta;
                    for (let ix = 0; ix <= delta; ++ix) {
                        if (cellIX + ix >= tilePointsSize) {
                            break;
                        } else if (cellIX + ix < 0) {
                            continue;
                        }
            
                        const oldElevation = tileGeo.readElevationAtInds(cellIX + ix, cellIY + iy);
                        if (Number.isNaN(oldElevation)) {
                            tileElevations[(cellIY + iy) * tilePointsSize + cellIX + ix] = NaN;
                            continue;
                        }
            
                        let newElevation = checkNaNsAndGetFromSegment(
                            ix / delta, iy / delta, cellElevations, isPointsInside
                        );
        
                        const fract = 100 * newElevation - Math.floor(100 * newElevation);
                        if (fract > 0.499 && fract < 0.501) {
                            if (newElevation > oldElevation) {
                                newElevation -= 0.005;
                            } else {
                                newElevation += 0.005;
                            }
                        }
        
                        tileElevations[(cellIY + iy) * tilePointsSize + cellIX + ix] = newElevation;
                    }
                }
            }
        }

        if (inResponseIndex.y >= 0 && inResponseIndex.y < fromResponseHeight) {
            if (inResponseIndex.x > 0 
                && fromResponseMask[inResponseIndex.y * fromResponseWidth + inResponseIndex.x - 1] === 1
            ) {
                ++minSubX;
                if (cellIX === 0) {
                    let ix = 0;
                    for (let iy = 0; iy <= delta; ++iy) {
                        if (cellIY + iy >= tilePointsSize) {
                            break;
                        } else if (cellIY + iy < 0) {
                            continue;
                        }
                
                        const oldElevation = tileGeo.readElevationAtInds(cellIX + ix, cellIY + iy);
                        if (Number.isNaN(oldElevation)) {
                            tileElevations[(cellIY + iy) * tilePointsSize + cellIX + ix] = NaN;
                            continue;
                        }
            
                        let newElevation = checkNaNsAndGetFromSegment(
                            ix / delta, iy / delta, cellElevations, isPointsInside
                        );
        
                        const fract = 100 * newElevation - Math.floor(100 * newElevation);
                        if (fract > 0.499 && fract < 0.501) {
                            if (newElevation > oldElevation) {
                                newElevation -= 0.005;
                            } else {
                                newElevation += 0.005;
                            }
                        }
        
                        tileElevations[(cellIY + iy) * tilePointsSize + cellIX + ix] = newElevation;
                    }
                }
            }
            if (inResponseIndex.x < fromResponseWidth - 1 
                && fromResponseMask[inResponseIndex.y * fromResponseWidth + inResponseIndex.x + 1] === 1
            ) {
                --maxSubX;
                if (cellIX + delta === tilePointsSize - 1) {
                    let ix = delta;
                    for (let iy = 0; iy <= delta; ++iy) {
                        if (cellIY + iy >= tilePointsSize) {
                            break;
                        } else if (cellIY + iy < 0) {
                            continue;
                        }
                
                        const oldElevation = tileGeo.readElevationAtInds(cellIX + ix, cellIY + iy);
                        if (Number.isNaN(oldElevation)) {
                            tileElevations[(cellIY + iy) * tilePointsSize + cellIX + ix] = NaN;
                            continue;
                        }
            
                        let newElevation = checkNaNsAndGetFromSegment(
                            ix / delta, iy / delta, cellElevations, isPointsInside
                        );
        
                        const fract = 100 * newElevation - Math.floor(100 * newElevation);
                        if (fract > 0.499 && fract < 0.501) {
                            if (newElevation > oldElevation) {
                                newElevation -= 0.005;
                            } else {
                                newElevation += 0.005;
                            }
                        }
        
                        tileElevations[(cellIY + iy) * tilePointsSize + cellIX + ix] = newElevation;
                    }
                }
            }
        }
    }

    for (let iy = minSubY; iy <= maxSubY; ++iy) {
        if (cellIY + iy >= tilePointsSize) {
            break;
        } else if (cellIY + iy < 0) {
            continue;
        }

        for (let ix = minSubX; ix <= maxSubX; ++ix) {
            if (cellIX + ix >= tilePointsSize) {
                break;
            } else if (cellIX + ix < 0) {
                continue;
            }

            const oldElevation = tileGeo.readElevationAtInds(cellIX + ix, cellIY + iy);
            if (Number.isNaN(oldElevation)) {
                tileElevations[(cellIY + iy) * tilePointsSize + cellIX + ix] = NaN;
                continue;
            }

            if (fromResponse) {
                let newElevation = checkNaNsAndGetFromSegment(
                    ix / delta, iy / delta, cellElevations, isPointsInside
                );

                const fract = 100 * newElevation - Math.floor(100 * newElevation);
                if (fract > 0.499 && fract < 0.501) {
                    if (newElevation > oldElevation) {
                        newElevation -= 0.005;
                    } else {
                        newElevation += 0.005;
                    }
                }

                tileElevations[(cellIY + iy) * tilePointsSize + cellIX + ix] = newElevation;
            } else {
                tileElevations[(cellIY + iy) * tilePointsSize + cellIX + ix] = oldElevation;
            }
        }
    }
}


function getFromTiles(
    ix: number, iy: number, 
    tileGeo: RegularHeightmapGeometry, 
    tileSegmentSize: number,
    tileIdX: number, tileIdY: number, 
    tiles: Map<TerrainTileId, TerrainTile>,
    regularHeightmapGeometries: RegularHeightmapGeometries,
): number {
    let neighbourTileId: TerrainTileId;
    
    if (iy < 0) {
        if (ix < 0) {
            ix += tileSegmentSize;
            iy += tileSegmentSize;
            neighbourTileId = TerrainTileId.new(tileIdX - 1, tileIdY - 1);
        } else if (ix > tileSegmentSize) {
            ix -= tileSegmentSize;
            iy += tileSegmentSize;
            neighbourTileId = TerrainTileId.new(tileIdX + 1, tileIdY - 1);
        } else {
            iy += tileSegmentSize;
            neighbourTileId = TerrainTileId.new(tileIdX, tileIdY - 1);
        }
    } else if (iy > tileSegmentSize) {
        if (ix < 0) {
            ix += tileSegmentSize;
            iy -= tileSegmentSize;
            neighbourTileId = TerrainTileId.new(tileIdX - 1, tileIdY + 1);
        } else if (ix > tileSegmentSize) {
            ix -= tileSegmentSize;
            iy -= tileSegmentSize;
            neighbourTileId = TerrainTileId.new(tileIdX + 1, tileIdY + 1);
        } else {
            iy -= tileSegmentSize;
            neighbourTileId = TerrainTileId.new(tileIdX, tileIdY + 1);
        }
    } else {
        if (ix < 0) {
            ix += tileSegmentSize;
            neighbourTileId = TerrainTileId.new(tileIdX - 1, tileIdY);
        } else if (ix > tileSegmentSize) {
            ix -= tileSegmentSize;
            neighbourTileId = TerrainTileId.new(tileIdX + 1, tileIdY);
        } else {
            return tileGeo.readElevationAtInds(ix, iy);
        }
    }

    const tile = tiles.get(neighbourTileId);
    if (!tile) {
        return NaN;
    }
    const neighbourTileGeo = regularHeightmapGeometries.peekById(tile.updatedGeo) 
        ?? regularHeightmapGeometries.peekById(tile.initialGeo)!;
    return neighbourTileGeo.readElevationAtInds(ix, iy);
}


function checkNaNsAndGetFromSegment(
    x: number, y: number,
    elevations: [number, number, number, number],
    isInside: [boolean, boolean, boolean, boolean],
) {
    let elevation = NaN;
    if (Number.isNaN(elevations[0])) {
        if (Number.isNaN(elevations[1])) {
            if (Number.isNaN(elevations[2])) {
                if (Number.isNaN(elevations[3])) {
                    elevation = NaN;
                } else {
                    elevation = elevations[3];
                }
            } else {
                if (Number.isNaN(elevations[3])) {
                    elevation = elevations[2];
                } else {
                    elevation = (1 - x) * elevations[2] + x * elevations[3];
                }
            }
        } else {
            if (Number.isNaN(elevations[2])) {
                if (Number.isNaN(elevations[3])) {
                    elevation = elevations[1];
                } else {
                    elevation = (1 - y) * elevations[1] + y * elevations[3];
                }
            } else {
                if (Number.isNaN(elevations[3])) {
                    elevation = 0.5 * ((elevations[1] - elevations[2]) * (x - y) + (elevations[1] + elevations[2]));
                } else {
                    elevation = getFrom3(1 - x, 1 - y, 
                        elevations[3], elevations[2], elevations[1], 
                        isInside[3], isInside[2], isInside[1]
                    );
                }
            }
        }
    } else {
        if (Number.isNaN(elevations[1])) {
            if (Number.isNaN(elevations[2])) {
                if (Number.isNaN(elevations[3])) {
                    elevation = elevations[0];
                } else {
                    elevation = 0.5 * (elevations[3] - elevations[0]) * (x + y) + elevations[0];
                }
            } else {
                if (Number.isNaN(elevations[3])) {
                    elevation = (1 - y) * elevations[0] + y * elevations[2];
                } else {
                    elevation = getFrom3(1 - y, x, 
                        elevations[2], elevations[0], elevations[3], 
                        isInside[2], isInside[0], isInside[3]
                    );
                }
            }
        } else {
            if (Number.isNaN(elevations[2])) {
                if (Number.isNaN(elevations[3])) {
                    elevation = (1 - x) * elevations[0] + x * elevations[1];
                } else {
                    elevation = getFrom3(y, 1 - x, 
                        elevations[1], elevations[3], elevations[0], 
                        isInside[1], isInside[3], isInside[0]
                    );
                }
            } else {
                if (Number.isNaN(elevations[3])) {
                    elevation = getFrom3(x, y, 
                        elevations[0], elevations[1], elevations[2], 
                        isInside[0], isInside[1], isInside[2]);
                } else {
                    elevation = getFrom4(x, y, elevations, isInside);
                }
            }
        }
    }
    return elevation;
}

function getFrom3(
    x: number,
    y: number,
    z00: number, 
    z10: number, 
    z01: number,
    p00inside: boolean,
    p10inside: boolean,
    p01inside: boolean,
) {
    if (p00inside) {
        if (p10inside) {
            if (p01inside) {
                return z00 + (z10 - z00) * x + (z01 - z00) * y;
            } else {
                return z00 + (z10 - z00) * x + (z01 - z00) * (3 - 2 * y) * y * y;
            }
        } else {
            if (p01inside) {
                return z00 + (z10 - z00) * (3 - 2 * x) * x * x + (z01 - z00) * y;
            } else {
                return z00 + (z10 - z00) * (3 - 2 * x) * x * x + (z01 - z00) * (3 - 2 * y) * y * y;
            }
        }
    } else {
        if (p10inside) {
            if (p01inside) {
                return z00 + (z10 - z00) * (3 - 2 * x) * x * x + (z01 - z00) * (3 - 2 * y) * y * y;
            } else {
                return z00 + (z10 - z00) * (3 - 2 * x) * x * x + (z01 - z00) * y;
            }
        } else {
            if (p01inside) {
                return z00 + (z10 - z00) * x + (z01 - z00) * (3 - 2 * y) * y * y;
            } else {
                return z00 + (z10 - z00) * x + (z01 - z00) * y;
            }
        }
    }
}

function getFrom4(
    x: number,
    y: number,
    elevations: [number, number, number, number],
    isInside: [boolean, boolean, boolean, boolean],
) {
    if (isInside[0]) {
        if (isInside[1]) {
            if (isInside[2]) {
                if (isInside[3]) {
                    return (1 - x) * (1 - y) * elevations[0]
                        + x * (1 - y) * elevations[1]
                        + (1 - x) * y * elevations[2]
                        + x * y * elevations[3];
                } else {
                    return getFrom3plus1(x, y, elevations[0], elevations[1], elevations[2], elevations[3]);
                }
            } else {
                if (isInside[3]) {
                    return getFrom3plus1(y, 1 - x, elevations[1], elevations[3], elevations[0], elevations[2]);
                } else {
                    const dy = y * y * (3 - 2 * y);
                    return (1 - x) * (1 - dy) * elevations[0]
                        + x * (1 - dy) * elevations[1]
                        + (1 - x) * dy * elevations[2]
                        + x * dy * elevations[3];
                }
            }
        } else {
            if (isInside[2]) {
                if (isInside[3]) {
                    return getFrom3plus1(1 - y, x, elevations[2], elevations[0], elevations[3], elevations[1]);
                } else {
                    const dx = x * x * (3 - 2 * x);
                    return (1 - dx) * (1 - y) * elevations[0]
                        + dx * (1 - y) * elevations[1]
                        + (1 - dx) * y * elevations[2]
                        + dx * y * elevations[3];
                }
            } else {
                if (isInside[3]) {
                    const dx = x * x * (3 - 2 * x);
                    const dy = y * y * (3 - 2 * y);
                    return (1 - dx) * (1 - dy) * elevations[0]
                        + dx * (1 - dy) * elevations[1]
                        + (1 - dx) * dy * elevations[2]
                        + dx * dy * elevations[3];
                } else {
                    return getFrom3plus1(1 - x, 1 - y, elevations[3], elevations[2], elevations[1], elevations[0]);
                }
            }
        }
    } else {
        if (isInside[1]) {
            if (isInside[2]) {
                if (isInside[3]) {
                    return getFrom3plus1(1 - x, 1 - y, elevations[3], elevations[2], elevations[1], elevations[0]);
                } else {
                    const dx = x * x * (3 - 2 * x);
                    const dy = y * y * (3 - 2 * y);
                    return (1 - dx) * (1 - dy) * elevations[0]
                        + dx * (1 - dy) * elevations[1]
                        + (1 - dx) * dy * elevations[2]
                        + dx * dy * elevations[3];
                }
            } else {
                if (isInside[3]) {
                    const dx = x * x * (3 - 2 * x);
                    return (1 - dx) * (1 - y) * elevations[0]
                        + dx * (1 - y) * elevations[1]
                        + (1 - dx) * y * elevations[2]
                        + dx * y * elevations[3];
                } else {
                    return getFrom3plus1(1 - y, x, elevations[2], elevations[0], elevations[3], elevations[1]);
                }
            }
        } else {
            if (isInside[2]) {
                if (isInside[3]) {
                    const dy = y * y * (3 - 2 * y);
                    return (1 - x) * (1 - dy) * elevations[0]
                        + x * (1 - dy) * elevations[1]
                        + (1 - x) * dy * elevations[2]
                        + x * dy * elevations[3];
                } else {
                    return getFrom3plus1(y, 1 - x, elevations[1], elevations[3], elevations[0], elevations[2]);
                }
            } else {
                return getFrom3plus1(x, y, elevations[0], elevations[1], elevations[2], elevations[3]);
            }
        }
    }
}

function getFrom3plus1(x: number, y: number,
    z00: number, z10: number, z01: number, z11: number
) {
    const a = z00 + (z01 + z10 - z00 - z11) * x * y;
    const b = (z10 - z00) * (1 - y);
    const c = (z01 - z00) * (1 - x);
    const d = (z11 - z01) * y;
    const e = (z11 - z10) * x;

    return a + b * x + c * y + d * (3 - 2 * x) * x * x + e * (3 - 2 * y) * y * y;
}