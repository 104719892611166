import {
    AllArrayItems, ConfigUtils, createNewEmptySolarArrayConfig, FarmLayoutConfigType, NumberProperty,
    NumberPropertyWithOptions, NumberRangeProperty, SceneInstancesProperty,
    StringProperty
} from 'bim-ts';
import type {
    ScopedLogger,
    TasksRunner
} from 'engine-utils-ts';
import {
    convertThrow, Immer, IterUtils, LazyBasic, LazyDerived, ObjectUtils, StringUtils, VersionedInvalidator
} from 'engine-utils-ts';
import { KrMath, type Vector2 } from 'math-ts';
import {
    PanelViewPosition, PUI_ConfigBasedBuilderParams, PUI_TabSelector
} from 'ui-bindings';
import { createAugmentationUI } from '../augmentation/AugmentationUi';
import { setDefaultAssets } from '../panels-config-ui/DefaultAssets';
import { createLazyUiConfig, patchObject } from '../panels-config-ui/GeneratePanelUiBindings';
import { removeDeletedSceneInstances } from '../panels-config-ui/RemoveDeletedSceneInstances';
import { createTrackerPositionsUi } from '../tracker-wind-position/TrackerWindPositionsUi';
import { createLazySiteAreasData, updateEquipmentBoundaries } from './EquipmentBoundariesCreator';
import type { SiteAreaProperties } from './FarmLayoutMetrics';
import { createLazyLayoutMetrics } from './FarmLayoutMetrics';
import { SelectedAreaData } from './SelectedArea';
import { FarmLayoutValidation } from './FarmLayoutValidation';
import {
    addNewBlockEquipment, createILRContextViewUiForBlocks, createSelectedConfigLazy,
    getFarmLayoutViews
} from './FarmLayoutViews';
import { arrangeLayout } from './LayoutService';

import type {
    AssetCatalogItemProps,
    AssetId,
    Bim,
    Catalog,
    CatalogItem,
    CatalogItemId,
    CatalogPropertyValue,
    FarmLayoutConfig,
    IdConfig,
    MathSolversApi,
    NumberPropertyWithOptionsContext,
    PropertyGroup,
    PropertyPathType,
    SceneInstance,
    SiteArea,
    UnitsMapper,
    ValueRenderFormatter
} from 'bim-ts';
import type {
    ContextMenuConfig, PUI_Builder, PUI_Node,
    UiBindings
} from 'ui-bindings';
import type { VerDataSyncer } from 'verdata-ts';
import type { Context, GlobalContext, PropertyObject } from '../panels-config-ui/GeneratePanelUiBindings';
import type { PropertyPath } from '../panels-config-ui/PropertyBuilders';
import type { ZoneBoundaryProps } from './EquipmentBoundariesCreator';
import type { InvertersGroup } from './ILRPropertyConfig';

export const activeTabLazy = new LazyBasic<string>("activeTab", "Generate");

export function createFarmLayoutUi(
    ui: UiBindings,
    builderSettings: PUI_ConfigBasedBuilderParams,
    mathSolversApi: MathSolversApi,
    tasksRunner: TasksRunner,
    logger: ScopedLogger,
    bim: Bim,
    catalog: Catalog,
    vds: VerDataSyncer,
    unitsMapper: UnitsMapper,
){
    const lazyMetrics = createLazyLayoutMetrics(bim, FarmLayoutConfigType, logger);
    const selectedConfig = createSelectedConfigLazy(bim, logger.newScope('selected-config'));
    const siteAreaDataLazy = createLazySiteAreasData(bim, logger.newScope("site-areas-data"), selectedConfig);
    const contextViews = getFarmLayoutViews(bim, catalog, ui, siteAreaDataLazy, lazyMetrics);
    const skipPaths: PropertyPathType[][] = [
        ['site_areas', AllArrayItems.new(), 'settings', 'electrical', 'solar_arrays']
    ];

    const augmentation = createAugmentationUI({
        bim,
        tasksRunner,
        catalog,
        logger,
        ui,
        mathSolversApi,
        unitsMapper,
        lazyMetrics,
        siteAreaDataLazy,
        createSelectedAreaUiConfigCallback: contextViews.createSelectedAreaUi,
    });

    const trackerPositionsUi = createTrackerPositionsUi(ui, tasksRunner, logger.newScope('trackers-positions'), bim, catalog);
 
    const isGeneratingLayoutLazy = new LazyBasic<boolean>('is-layout-generating', false);
    const generateFn = async (context: Context) => {
        const selectedConfig = bim.configs.peekById(context.propertyId);
        if(!selectedConfig){
            logger.error('Config not found with id', context.propertyId);
            return;
        }        
        const config = selectedConfig.get<FarmLayoutConfig>();

        const selectedArea = config.site_areas[config.selected_area.value];
        if(!selectedArea){
            logger.error('Area not found with index ' + config.selected_area.value, config);
            return;
        }

        await arrangeLayout(selectedConfig, context.propertyId, bim, catalog, mathSolversApi, tasksRunner, ui)
            .then(async (farmLayout) => {
                updateEquipmentBoundaries(
                    bim,tasksRunner, 
                    context.connectedTo, 
                    context.propertyId, 
                    (config) => {
                        return Immer.produce(config, draft => {
                            const draftSettings = draft.site_areas[draft.selected_area.value];
                            draftSettings.settings.spacing.max_row_to_row_space_result = NumberProperty.new({
                                    value: farmLayout.max_r2r_result ?? 0,
                                    unit: 'm',
                                    isReadonly: true,
                                });
                            draftSettings.last_calculation_hash = StringProperty.new({value: makeHash(draftSettings)});
                        });
                    }
                );
            });
    };

    function canGenerateNow(context: Context, ownedContext: GlobalContext){
        return LazyDerived.new1("farm-layout-poller", [ownedContext.configObserver], [isGeneratingLayoutLazy],  ([isGeneratingLayout]) => {
            const selectedConfig = bim.configs.peekById(context.propertyId);
            if(!selectedConfig){
                return false;
            }
            const config = selectedConfig.get<FarmLayoutConfig>();
            const selectedArea = config.site_areas[config.selected_area.value];
            if(!selectedArea){
                logger.error('Area not found with index ' + config.selected_area.value, config);
                return false;
            }
            const settings = selectedArea.settings;
            
            const checkEquipment = settings.electrical.blocks_equipment
                .every(b => b.inverter.value.length > 0 && b.transformer.value.length > 0);
            const checkBlocksEquipment = settings.capacity.number_of_blocks.selectedOption === 'ignore' 
                ? true 
                : settings.electrical.blocks_equipment.length > 0 && checkEquipment;
            const checkSubareas = checkValidArea(config);
            const hasAtLeastSingleArrayConfig = 
                !!selectedArea.settings.electrical.solar_arrays.find(x => x.preset.value.length || (x.module.value.length && x.trackerFrame.value.length))
            const check =
                !isGeneratingLayout &&
                checkSubareas &&
                selectedConfig.connectedTo !== 0 && 
                settings.electrical.combiner_box.value.length > 0 &&
                settings.electrical.solar_arrays.length > 0 &&
                checkBlocksEquipment &&
                hasAtLeastSingleArrayConfig;

            return check;
        });
    }

    function puiCallback(builder: PUI_Builder, _root: PUI_Node, context: Context, ownedContext: GlobalContext){
        const config = context.previousConfig as FarmLayoutConfig;
        if(ObjectUtils.isObjectEmpty(config)){
            return;
        }
        const selectedArea = config.site_areas[config.selected_area.value];
        if(!selectedArea){
            return;
        }

        const activeTab = activeTabLazy.poll();

        builder.addNode(
            PUI_TabSelector,
            {
                name: 'Layout generation mode',
                options: ['Generate', 'Augment', 'Wind positions'],
                value: activeTab,
                onChange: (x) => {
                    activeTabLazy.replaceWith(x);
                },
            }
        )

        if (activeTab === 'Augment') {
            augmentation.puiCallback(builder);
            return
        }

        if (activeTab === 'Wind positions') {
            trackerPositionsUi.puiCallback(builder);
            return;
        }

        const pathSettings = createFarmLayoutSettingsPath(config.selected_area.value);
        const settings = selectedArea.settings;

        const patchConfig = (changePath: PropertyPath, newValue: PropertyObject) => {
            const config = bim.configs.peekById(context.propertyId);
            if(!config){
                logger.error('config not patched')
                return;
            }
            patchObject(config.get(), changePath, newValue, (props) => {
                bim.configs.applyPatches([
                    [context.propertyId, { properties: props }],
                ]);
            });
        }
        
        const addGroupProperty = (name: string, createConfig: (pos:Vector2) => ContextMenuConfig, options: string[]) => {
            builder.addCustomProp<
                string[],
                { 
                    onClick: (pos:Vector2) => void;
                }
            >({
                name: StringUtils.capitalizeFirstLatterInWord(name),
                context: {
                    onClick: (pos: Vector2) => {
                        const contextMenuConfig = createConfig(pos);
                        ui.addContextMenuView(contextMenuConfig);
                    }
                },
                value: options.map(StringUtils.capitalizeFirstLatterInWord),
                type_ident: "group_properties",
                onChange: () => { },
            });
        }
        
        let groupNodeCounter = 0;
        const addGroupNode = (addToGroupFn: () => void) => {
            builder.inGroup(
                {
                    name: `group_${groupNodeCounter++}`,
                    collapsible: false,
                    showTitle: false,
                },
                addToGroupFn
            );
        }

        
        addGroupNode(() => {

            addSelectSubareaProperty(builder, ui, siteAreaDataLazy, config, contextViews.createSelectedAreaUi);

            const selectedSubstationName = IterUtils.find(context.substations, 
                ([_, substationId]) => substationId === context.connectedTo)?.[0];
            if(selectedSubstationName){
                builder.addSelectorProp({
                    name: 'Substation',
                    value: selectedSubstationName,
                    options: Array.from(context.substations.keys()),
                    onChange: (substationName) => {
                        const selectedSubstation = context.substations.get(
                            substationName
                        )!;
                        bim.instances.setSelected([selectedSubstation]);
                    },
                });
            }
        });

        const invertersIgnore = settings.capacity.number_of_blocks.selectedOption === 'ignore';
        function parser(args:{value: string, prevValue: number, unit?: string, option: string, context: any}) {
            const str = args.value;
            const splited = str.match(/\d+/g);
            let totalLength = 0;
            if(splited){
                for (const part of splited) {
                    totalLength += part.length;
                }
            }

            if (splited && splited.length > 1 && totalLength + 1 !== str.length) {
                return args.prevValue;
            }
            if (!args.unit) {
                return parseFloat(str);
            }
            const num = parseFloat(str);
            if (isNaN(num)) {
                return args.prevValue;
            }

            return num;
        }
        type MetricsContext = {
            layoutMetrics?: SiteAreaProperties;
            maxR2RResult: NumberProperty;
        };

        const areaProps = context.layoutMetrics?.perZone[config.selected_area.value];
        const metricsContext: MetricsContext = {
            layoutMetrics: areaProps,
            maxR2RResult: ObjectUtils.deepCloneObj(settings.spacing.max_row_to_row_space_result), 
        };
        addGroupNode(() => {
            const targetMsg:string[] = [];
            if(settings.capacity.total_dc_power.selectedOption === "find max"){
                targetMsg.push("Maximum DC capacity");
            } else {
                targetMsg.push(`${settings.capacity.total_dc_power.as("kW").toFixed(0)} kW target DC capacity`); 
            }
            const numberBlocksOption =settings.capacity.number_of_blocks.selectedOption;
            if(numberBlocksOption === 'ignore') {
                targetMsg.push("No blocking");
            } else if(numberBlocksOption === 'set'){
                targetMsg.push(`Combine solar arrays into ${settings.capacity.number_of_blocks.value} blocks`);
            } else {
                targetMsg.push(`Combine solar arrays into blocks`);
            }

            addGroupProperty(
                "target",
                contextViews.createSelectModeUi,
                targetMsg
            );
            let resultingCapacity = "New layout not generated";
            const hash = makeHash(selectedArea);
            const haveBlocks = !!areaProps?.numberOfBlocks;
            if(hash !== selectedArea.last_calculation_hash?.value){
                //need to regenerate layout
            } else if(haveBlocks) {
                const totalDc = settings.capacity.total_dc_power.selectedOption === 'target'
                    ? `<nobr>${areaProps.dcPowerKW.toFixed(0)} kW DC (${(areaProps.dcPowerKW*100 / settings.capacity.total_dc_power.as('kW')).toFixed(0)}%)</nobr>`
                    : `<nobr>${areaProps.dcPowerKW.toFixed(0)} kW DC</nobr>`;
                const dcAcRatio = areaProps.dcPowerKW && areaProps.acPowerKW ? areaProps.dcPowerKW / areaProps.acPowerKW : 0;
                resultingCapacity = `${totalDc} / <nobr>${areaProps.acPowerKW.toFixed(0)} kW AC (${dcAcRatio.toFixed(2)})</nobr>, <nobr>${areaProps.numberOfBlocks} blocks</nobr>`;
            } else if(areaProps?.dcPowerKW){
                resultingCapacity = `${areaProps.dcPowerKW.toFixed(2)} kW DC`;
            }

            builder.addCustomProp({
                name: "Resulting capacity",
                type_ident: "custom-group-name",
                value: resultingCapacity,
                onChange: () => {},
                context: {
                    isProperty: true,
                    multiline: true,
                },
                notActive: !haveBlocks,
            });


            // function calcPercentDc(targetDc: number, dcPowerKW: number, toUnit: string){
            //     const mapped =  convertUnits(dcPowerKW, 'kW', toUnit);
            //     const convertedDc = mapped instanceof Success ? mapped.value : 0;
            //     const percent = dcPowerKW !== 0 && targetDc !== 0 
            //         ?  KrMath.roundTo((dcPowerKW/targetDc)*100, 1)
            //         : 0;
            //     return [convertedDc, percent];
            // }
            // const valueRenderFormatter: ValueRenderFormatter<MetricsContext> = {
            //     context: metricsContext,
            //     textColorFormatter: (args) => {
            //         if (args.option === 'find max') {
            //             return 'green';
            //         } else if(args.option === 'target') {
            //             const [_dc, percent] = calcPercentDc(args.value, args.context.layoutMetrics?.dcPowerKW ?? 0, args.unit!);
            //             return percent < 100 ? 'red' : 'inherit';
            //         }else{
            //             return 'inherit';
            //         }
            //     },
            //     formatter: (args) => {
            //         const [convertedDc, percent] = calcPercentDc(args.value, args.context.layoutMetrics?.dcPowerKW ?? 0, args.unit!);
            //         if(args.option === 'find max'){
            //             const valAsStr = convertedDc.toFixed(0);
            //             return convertedDc ? `${valAsStr} ${args.unit}` : '—'
            //         } else {
            //             const valAsStr = args.value.toFixed(0);
            //             const percentStrOfTarget = convertedDc ? `${percent}% of ${valAsStr}`: `${valAsStr}`;
            //             return args.unit ? `${percentStrOfTarget} ${args.unit}` : percentStrOfTarget;
            //         }
            //     },
            //     isReadonly: (args) => args.option === 'find max',
            //     parser
            // };
            // builder.addCustomProp<NumberPropertyWithOptions, NumberPropertyWithOptionsContext<MetricsContext>>({
            //     name: 'Total DC power',
            //     type_ident: "number-property-with-options",
            //     value: settings.capacity.total_dc_power,
            //     context: {
            //         valueRenderFormatter
            //     },
            //     onChange: (v) => { patchConfig([...pathSettings, 'capacity', 'total_dc_power'], v); },
            // });
            const singleBlockOptions = ['auto', 'set', 'ignore'];
            const multiBlocksOptions = ['mixed', 'ignore'];
            const blocksEquipment = settings.electrical.blocks_equipment.length;
            
            let numberOfBlockProperty = settings.capacity.number_of_blocks;
            if(blocksEquipment > 1 && !multiBlocksOptions.includes(numberOfBlockProperty.selectedOption)){ 
                numberOfBlockProperty = NumberPropertyWithOptions.new({
                    ...numberOfBlockProperty,
                    selectedOption: 'mixed',
                });
            }
            if(blocksEquipment <= 1){
                const selectionOption = settings.electrical.blocks_equipment[0]?.number_of_inverters?.selectedOption;
                numberOfBlockProperty = NumberPropertyWithOptions.new({
                    ...numberOfBlockProperty,
                    options: singleBlockOptions,
                    selectedOption: selectionOption && numberOfBlockProperty.selectedOption !== 'ignore' 
                        ? selectionOption 
                        : numberOfBlockProperty.selectedOption,
                });
            }
            if(blocksEquipment > 1 && !ObjectUtils.areObjectsEqual(numberOfBlockProperty.options, multiBlocksOptions)){
                numberOfBlockProperty = NumberPropertyWithOptions.new({
                    ...numberOfBlockProperty,
                    options: multiBlocksOptions,
                });
            }
            if(!ObjectUtils.areObjectsEqual(settings.capacity.number_of_blocks, numberOfBlockProperty)){
                patchConfig([...pathSettings, 'capacity', 'number_of_blocks'], numberOfBlockProperty);
            }
    
            // const valueRenderFormatterBlockNumber: ValueRenderFormatter<MetricsContext> = {
            //     context: metricsContext,
            //     textColorFormatter: (args) => args.option === 'mixed' || args.option === 'auto' ? 'green' : 'inherit',
            //     formatter: (args) => {
            //         const number_of_blocks = args.context.layoutMetrics?.numberOfBlocks ?? 0;
            //         if(args.option === 'mixed' || args.option === 'auto'){
            //             return number_of_blocks ? number_of_blocks + "" : '—'
            //         } else if(args.option === 'ignore'){
            //             return '—'
            //         } else {
            //             const valAsStr = args.value.toFixed(0);
            //             return args.unit ? `${valAsStr} ${args.unit}` : valAsStr;
            //         }
            //     },
            //     isReadonly: (args) => args.option !== 'set',
            // }
            // builder.addCustomProp<NumberPropertyWithOptions, NumberPropertyWithOptionsContext<MetricsContext>>({
            //     name: 'Blocks number',
            //     type_ident: "number-property-with-options",
            //     value: numberOfBlockProperty,
            //     context: {
            //         valueRenderFormatter: valueRenderFormatterBlockNumber,
            //     },
            //     onChange: (prop) => { 
            //         const updatedProps = Immer.produce(config, draft => {
            //             const draftSettings = draft.site_areas[draft.selected_area.value].settings;
            //             draftSettings.capacity.number_of_blocks = prop;
            //             if(prop.selectedOption === 'set' || prop.selectedOption === 'auto'){
            //                 const value = draftSettings.capacity.number_of_blocks.value;
            //                 for (const block of draftSettings.electrical.blocks_equipment) {
            //                     block.number_of_inverters = NumberPropertyWithOptions.new({
            //                         ...block.number_of_inverters,
            //                         value: prop.selectedOption === 'set' ? value : block.number_of_inverters.value,
            //                         selectedOption: prop.selectedOption,
            //                     });
            //                 }
            //             }
            //         });

            //         bim.configs.applyPatches([
            //             [context.propertyId, { properties: updatedProps }],
            //         ]);
            //     },
            // });
    
            const invertersIgnore = settings.capacity.number_of_blocks.selectedOption === 'ignore'
            if(invertersIgnore){
                builder.addStringProp({
                    name: 'ILR range',
                    value: 'N/A',
                    onChange: () => {},
                    readonly: true,
                    notActive: true,
                });

                // builder.addCustomProp<NumberPropertyWithOptions, NumberPropertyWithOptionsContext<SiteAreaProperties | undefined>>({
                //     name: 'Total AC power',
                //     type_ident: "number-property-with-options",
                //     value: NumberPropertyWithOptions.new({
                //         value: 0,
                //         selectedOption: 'N/A',
                //         options: ['N/A'],
                //         unit: 'kW',
                //         isReadonly: true,
                //         step: 1,
                //     }),
                //     context: {
                //         valueRenderFormatter: {
                //             context: undefined,
                //             formatter: () => {
                //                 return '—';
                //             },
                //             isReadonly: () => true,
                //         },
                //     },
                //     onChange: () => {
                //         //readonly
                //     },
                //     readonly: true,
                //     notActive: true,
                // });
                
            } else {
                builder.addCustomProp<[number, number], {
                    name: string,
                    minMax: [number, number],
                    step: number | undefined
                    openBlocks?: (pos:Vector2) => void
                    inverters: InvertersGroup[]
                }>({
                    name: 'ILR range',
                    type_ident: "ilr_property",
                    value: settings.capacity.ilr_range.value,
                    context: {
                        name: 'ILR range',
                        minMax: settings.capacity.ilr_range.range ?? [0, 10],
                        step: settings.capacity.ilr_range.step,
                        inverters: createILRContextViewUiForBlocks(
                            catalog, 
                            areaProps, 
                            settings.electrical.blocks_equipment,
                            settings.capacity.ilr_range.value
                        ),
                    },
                    onChange: (v) => {
                        const updatedProps = Immer.produce(config, draft => {
                            const settingsDraft = draft.site_areas[draft.selected_area.value].settings;
                            settingsDraft.capacity.ilr_range = settingsDraft.capacity.ilr_range.withDifferentValue(v);
                            const blocksEquipment = settingsDraft.electrical.blocks_equipment;
                            const [min, max] = settingsDraft.capacity.ilr_range.value;
                            for (const block of blocksEquipment) {
                                const [minValue, maxValue] = block.ilr_range.value;
                                
                                block.ilr_range = NumberRangeProperty.new({
                                    ...block.ilr_range,
                                    value: blocksEquipment.length === 1
                                    ? [min, max]
                                    : [Math.max(minValue, min), Math.min(maxValue, max)]
                                });
                            }
                        });
                        bim.configs.applyPatches([
                            [context.propertyId, { properties: updatedProps }],
                        ]);
                    },
                });
                // const ac_power = areaProps?.acPowerKW ?? 0;
                
                // builder.addNumberProp({
                //     name: 'Total AC power',
                //     value: ac_power,
                //     unit: 'kW',
                //     step: 1,
                //     valueRenderFormatter: () => 'green',
                //     onChange: () => { },
                //     readonly: true,
                // });

                // const valueRenderFormatter: ValueRenderFormatter = {
                //     context: undefined,
                //     textColorFormatter: () => 'green',
                //     formatter: (args) => {
                //         const valAsStr = args.value.toFixed(0);
                //         return args.unit ? `${valAsStr} ${args.unit}` : valAsStr;
                //     },
                //     isReadonly: () => true,
                // }
                // builder.addCustomProp<NumberPropertyWithOptions, NumberPropertyWithOptionsContext>({
                //     name: 'Total AC power',
                //     type_ident: "number-property-with-options",
                //     value: NumberPropertyWithOptions.new({
                //         value: ac_power,
                //         selectedOption: 'auto',
                //         options: ['auto'],
                //         unit: 'kW',
                //         isReadonly: true,
                //         step: 1,
                //     }),
                //     context: {
                //         valueRenderFormatter,
                //     },
                //     onChange: (prop) => {
                //         //readonly
                //     },
                //     readonly: true,
                // });
            }
        });

        addGroupNode(() => {         
            const valueRenderFormatter: ValueRenderFormatter<MetricsContext> = {
                context: metricsContext,
                formatter: (args) => {
                    const r2r = args.context.maxR2RResult;
                    const decimals = 2;
                    const valAsStr = args.value.toFixed(decimals);
                    const targetStr = args.unit ? `${valAsStr} ${args.unit}` : valAsStr
                    if(args.option === 'find max'){
                        const minTarget = `${targetStr} min. target`;
                        const mapped = args.unit ? convertThrow(r2r.value, r2r.unit, args.unit) : r2r.value;
                        return r2r.value ? `${mapped.toFixed(decimals)} ${args.unit} (${minTarget})` : minTarget;
                    } else {
                        return targetStr;
                    }
                },
                parser: parser,
            }
            builder.addCustomProp<NumberPropertyWithOptions, NumberPropertyWithOptionsContext<MetricsContext>>({
                name: 'Row to row',
                type_ident: "number-property-with-options",
                value: settings.spacing.row_to_row_space,
                context: {
                    valueRenderFormatter,
                    doNotShowOptions: true,
                },
                onChange: (v) => { patchConfig([...pathSettings, 'spacing', 'row_to_row_space'], v); },
            });
            // if(
            //     (settings.capacity.number_of_blocks.selectedOption !== 'ignore' || settings.capacity.total_dc_power.selectedOption !== 'target')
            //     && settings.spacing.row_to_row_space.selectedOption ==='find max'
            //     ){
            //         builder.addCustomProp<string, {}>({
            //             name: "",
            //             type_ident: "error_message",
            //             value: "Please, set Total DC Power mode to “Target” and Blocks number to “Ignore” in order to find max Row to Row space.",
            //             context: {},
            //             onChange: () => { },
            //         });
            // }

            {
                const glass1 = settings.spacing.equipment_glass_to_glass.toConfiguredUnits(bim.unitsMapper);
                const glass2 = settings.spacing.support_glass_to_glass.toConfiguredUnits(bim.unitsMapper);
                const array = settings.offsets.tracker_offset.toConfiguredUnits(bim.unitsMapper);
                const combinerBox = settings.offsets.combiner_box_offset.toConfiguredUnits(bim.unitsMapper);
                const inverter = settings.offsets.inverter_offset.toConfiguredUnits(bim.unitsMapper);
                const transformer = settings.offsets.transformer_offset.toConfiguredUnits(bim.unitsMapper);
                const block = settings.offsets.block_offset.toConfiguredUnits(bim.unitsMapper);
                const options = [
                    `${array.value.toFixed(1)}/${block.value.toFixed(1)} ${array.unit} Arrays`,
                    `${glass1.value.toFixed(1)}/${glass2.value.toFixed(1)} ${glass1.unit} Glass to glass`,
                    `${transformer.value.toFixed(1)}/${inverter.value.toFixed(1)}/${combinerBox.value.toFixed(1)} ${array.unit} Equipment`,
                ];
                addGroupProperty(
                    "offsets",
                    contextViews.createOffsetsUi,
                    options
                );
            }

            {
                const eqRoadWidth = settings.roads.equipment_road_width.valueUnitUiString(bim.unitsMapper);
                const supRoadWidth = settings.roads.support_road_width.valueUnitUiString(bim.unitsMapper);
                const ewRoads = settings.roads.equipment_roads_options.value.startsWith('Generate');
                const nsRoads = settings.roads.support_roads_options.value.startsWith('Generate');
                const nsRoadsIgnore = settings.roads.support_roads_options.value.startsWith('Ignore');
                const angle = settings.roads.tracker_angle.as('deg');
                const shift = settings.roads.equipment_roads_angle.as('deg');

                const roadsGroupMessages: string[] = [];
                if(ewRoads){
                    const autoDetectShift = settings.roads.equipment_roads_angle.selectedOption === 'auto';
                    const message = autoDetectShift ?  "" : `${shift}° Rotation `;
                    roadsGroupMessages.push(`${eqRoadWidth}, ${message}new Equipment roads`);
                }
                if(!ewRoads){
                    roadsGroupMessages.push(`Existing equipment roads`);
                }
                if(nsRoads){
                    roadsGroupMessages.push(`${supRoadWidth}, new Support roads`);
                }
                const rowHeight = settings.spacing.max_row_height.value;
                roadsGroupMessages.push(`${angle}° Solar arrays rotation, Row height ${rowHeight}`);
                if(!nsRoads && !nsRoadsIgnore){
                    roadsGroupMessages.push(`Existing support roads`);
                }
                if(settings.capacity.number_of_blocks.selectedOption !== 'ignore' && ewRoads){
                    roadsGroupMessages.push(`${settings.roads.transformer_roadside.value} transformer roadside`);
                }
    
                addGroupProperty(
                    "roads and geometry",
                    contextViews.createRoadsUi,
                    roadsGroupMessages
                );
            };
        });

        addGroupNode(() => {
            const options: string[] = [];
            for (const iterator of settings.electrical.solar_arrays) {
                const preset = iterator.preset.value;
                const module = iterator.module.value;
                const trackerFrame = iterator.trackerFrame.value;
                const presetName = preset.length ? getCatalogItemName(catalog, preset[0]) : "";
                const moduleName = module.length ? getCatalogItemName(catalog, module[0]) : "";
                const trackerFrameName = trackerFrame.length ? getCatalogItemName(catalog, trackerFrame[0]) : "";
                if(presetName || moduleName || trackerFrameName){
                    options.push(`${presetName} ${moduleName} ${trackerFrameName}`.trim());   
                } else {
                    options.push('Asset not found in catalog');
                }
            }
            options.push(settings.spacing.align_arrays.value ? 'Align by roads and rows' : 'No alignment, maximize capacity');
            addGroupProperty(
                'solar arrays',
                contextViews.createSolarArraysUi,
                options,
            );
        })

        addGroupNode(() => {
            if (invertersIgnore) {
                builder.addStringProp({
                    name: 'Blocks equipment',
                    value: 'N/A',
                    onChange: () => {},
                    readonly: true,
                    notActive: true,
                });
            } else {
                const options: string[] = [];
                const scheme = config.site_areas[config.selected_area.value].settings.electrical.scheme.value;
                options.push(`${scheme} scheme`);
                const combinerBox = settings.electrical.combiner_box.value[0];
                options.push(`${getCatalogItemName(catalog, combinerBox)}<br>`);
                for (const iterator of config.site_areas[config.selected_area.value].settings.electrical.blocks_equipment) {
                    const transformer = iterator.transformer.value[0];
                    const inverter = iterator.inverter.value[0];
                    options.push(`${getCatalogItemName(catalog, transformer)}`);
                    options.push(`${getCatalogItemName(catalog, inverter)}`);
                }
                addGroupProperty(
                    "blocks_equipment",
                    contextViews.createBlockSettingsUi,
                    options,
                );
            }
        });

        builder.addCustomProp({
            name: 'end-line',
            value: {},
            type_ident: "divider",
            onChange: () =>{ },
            readonly: true,
            context: undefined,
        });

        const errorMsg = FarmLayoutValidation.checkFarmConfig(catalog, settings);
        if(errorMsg){
            builder.addCustomProp({
                name: 'last-error',
                value: errorMsg,
                type_ident: "error_message",
                onChange: () => { },
                readonly: true,
                context: undefined,
            });
        }

        builder.addActionsNode({
            name: "generateAction",
            context: undefined,
            actions: [
                {
                    label: "generate layout",
                    isEnabled: canGenerateNow(context, ownedContext),
                    action: async () => {
                        isGeneratingLayoutLazy.replaceWith(true);
                        generateFn(context)
                        .finally(() => {
                            isGeneratingLayoutLazy.replaceWith(false);
                        }); 
                    },
                    style: {
                        type: 'primary'
                    },
                }
            ]
        });

        setDefaultFarmLayoutProperties(context.propertyId, config, logger, context, bim, catalog, skipPaths);
    }

    const builderParams = PUI_ConfigBasedBuilderParams.new(
        [],
        [
            "Substations",
            "selected_area",
            "site_areas",
        ]
    ).mergedWith(builderSettings);

    ui.addViewToNavbar(
        ["Generate", "Farm Layout"],
        createLazyUiConfig({
            configBuilderParams: builderParams,
            puiBuilderParams: { sortChildrenDefault: false},
            onAfterRootNodeCallback: puiCallback,
            bim,
            logger,
            type: FarmLayoutConfigType,
            ui,
            tasksRunner,
            catalog,
            skipPaths,
            vds,
            otherInvalidator: new VersionedInvalidator([activeTabLazy, augmentation.invalidator, siteAreaDataLazy])
        }),
        {
            minWidth: 430,
            name: 'Layout',
            iconName: 'Layout',
            group: 'General',
            sortOrder: 2,
            position: PanelViewPosition.Fixed,
        }
    );
}

export function addSelectSubareaProperty(
    builder: PUI_Builder,
    ui: UiBindings,
    siteAreaDataLazy: LazyDerived<ZoneBoundaryProps[]>,
    config: FarmLayoutConfig,
    createSelectedAreaUiConfigCallback: (pos: Vector2) => ContextMenuConfig,
) {
    const selectedArea = config.site_areas[config.selected_area.value];
    let selectedAreaValue = "Buildable area";
    if (selectedArea.boundaries instanceof SceneInstancesProperty) {
        selectedAreaValue = "Buildable area"
    } else if (selectedArea.zones instanceof SceneInstancesProperty) { 
        const idx = config.selected_area.value - 1;
        selectedAreaValue = "Subarea " + (idx > 0 ? idx : "");
    } else { 
        selectedAreaValue = "Unallocated subarea";
    }
    const siteAreaData = siteAreaDataLazy.poll();
    const selectedSiteAreaData = siteAreaData[config.selected_area.value];
    if (!selectedSiteAreaData?.availableArea.value) {
        selectedAreaValue += " (empty)";
    }

    builder.addCustomProp<string, {
        onClick: (pos: Vector2) => void,
        tag: string,
        hasError: boolean,
        selectedOptionData?: SelectedAreaData,
    }>({
        name: "Selected area",
        type_ident: "select_option",
        value: selectedAreaValue,
        context: {
            onClick: (pos) => {
                const config = createSelectedAreaUiConfigCallback(pos);
                ui.addContextMenuView(config);
            },
            tag: selectedSiteAreaData ? `${KrMath.roundTo(selectedSiteAreaData.availableArea.value, 0.01)} ${selectedSiteAreaData.availableArea.unit}` : '',
            selectedOptionData: selectedSiteAreaData ? new SelectedAreaData(selectedArea, selectedSiteAreaData) : undefined,
            hasError: !!selectedSiteAreaData?.errorMessage,
        },
        onChange: () => {},
    });
}

function setDefaultValueIfSolarArraysFieldIsEmpty(
    config: FarmLayoutConfig,
    logger: ScopedLogger,
    context: Context,
    catalog: Catalog,
    callback: (newConfig: FarmLayoutConfig) => void,
) {
    const newConfig = Immer.produce(config, draft => {
        for (const area of draft.site_areas) {
            const settings = area.settings;
            if (settings.electrical.solar_arrays.length) {
                continue;
            }
            let solarArrayWithDefaultAssets = createNewEmptySolarArrayConfig();
            setDefaultAssets(
                solarArrayWithDefaultAssets,
                catalog,
                logger,
                context,
                [],
                newVal => solarArrayWithDefaultAssets = newVal,
            );
        
            const solarArray = createNewEmptySolarArrayConfig();
            solarArray.preset = solarArrayWithDefaultAssets.preset;
            settings.electrical.solar_arrays.push(solarArray);
        }
    })
    callback(newConfig);
}

function setDefaultFarmLayoutProperties(
    id: IdConfig, 
    config: FarmLayoutConfig, 
    logger: ScopedLogger, 
    context: Context, 
    bim: Bim, 
    catalog: Catalog, 
    skipPaths: PropertyPathType[][]
    ){
    let updatedConfig = config;
    for (let i = 0; i < updatedConfig.site_areas.length; i++) {
        const area = updatedConfig.site_areas[i];
        if(area.settings.electrical.blocks_equipment.length === 0){          
            addNewBlockEquipment(area.settings.electrical.blocks_equipment, area.settings, (block) => {
                updatedConfig = {
                    ...updatedConfig,
                }
                updatedConfig = Immer.produce(updatedConfig, draft => {
                    draft.site_areas[i].settings.electrical.blocks_equipment = [block];
                });
            });
        }
    }
    

    // set default asset to solar array if solar_arrays are empty
    setDefaultValueIfSolarArraysFieldIsEmpty(updatedConfig, logger, context, catalog, (newConfig) => {
        updatedConfig = newConfig;
    });

    setDefaultAssets(updatedConfig, catalog, logger, context, skipPaths, (newConfig) => {
        updatedConfig = newConfig;
    });
    
    removeDeletedSceneInstances(updatedConfig, bim, logger, skipPaths, (newConfig) => {
        updatedConfig = newConfig;
    });

    if(!ObjectUtils.areObjectsEqual(config, updatedConfig)){
        bim.configs.applyPatchTo({properties: updatedConfig}, [id]);
    }
}

function getCatalogItemName(catalog: Catalog, value: CatalogPropertyValue){
    let label:string = "";
    let assetId:number = 0;
    let item:CatalogItem| undefined = undefined;
    if(value?.type === 'catalog_item'){
        item = catalog.catalogItems.peekById(value.id);
        label = item?.name ?? "";
        assetId = item?.as<AssetCatalogItemProps>().properties?.asset_id?.value ?? 0;
    } else if(value) {
        assetId = value.id;
    }


    if(assetId !== 0){
        const asset = catalog.assets.peekById(assetId);
        const defaultName = item ? catalog.catalogItemsUiLabels.solve(
            item.typeIdentifier,
            item.properties,
        )?.title ?? label : "";
        label = asset && asset.name.length > 0 ? asset.name : defaultName;
    }

    return label;
}

export function createFarmLayoutSettingsPath(idx: number, others: PropertyPath = []): PropertyPath {
    return ['site_areas', idx, 'settings', ...others];
}

export function getInstances(instancesPerId: {perCatalogItems: Map<CatalogItemId, SceneInstance>, perAsset: Map<AssetId, SceneInstance>}, values: CatalogPropertyValue[]){
    let instances:SceneInstance[] = [];
    for (const value of values) {
        const instance = value.type === 'catalog_item' 
        ? instancesPerId.perCatalogItems.get(value.id)
        : instancesPerId.perAsset.get(value.id);
        if(instance){
            instances.push(instance);
        }
    }

    return instances;
}

export function checkValidArea(config: FarmLayoutConfig){
    const allSiteArea = config.site_areas[0];
    const selectedArea = config.site_areas[config.selected_area.value];
    const checkAllSiteArea = allSiteArea?.boundaries instanceof SceneInstancesProperty 
        ? allSiteArea.boundaries.value.length > 0 
        : false;
    let isValidArea = false;
    if(selectedArea.zones instanceof SceneInstancesProperty){
        isValidArea = selectedArea.zones.value.length > 0;
    } else if(selectedArea.boundaries instanceof SceneInstancesProperty) {
        isValidArea = selectedArea.boundaries.value.length > 0;
    } else {
        isValidArea = true;
    }
    return checkAllSiteArea && isValidArea;
}

function makeHash(props: SiteArea){
    return makeHashFromSettings(props);
}

export function makeHashFromSettings(props: PropertyGroup){
    const skipPaths = [["last_calculation_hash"]]
    let hash = "";
    ConfigUtils.traverseByProperties(
        props, 
        (p) => {
            hash += p.hash();
        }, 
        skipPaths
    );
    
    return hash;
}