import { Texture, RepeatWrapping, RGBAFormat, UnsignedByteType, UVMapping, RGBFormat, LinearFilter, LinearMipmapLinearFilter, sRGBEncoding } from '../3rdParty/three';
import type { ObjectHandleMethod } from '../utils/Utils';
import type { Disposable} from 'engine-utils-ts';
import { DiffuseDefaultPixel } from './MaterialsFactory';

export class PileIconsLoader implements Disposable {

	readonly _icons: Map<number, Texture | null> = new Map();
	_iconLoadedCallback: ((texture: Texture) => void) | undefined = undefined;
	_iconLoadErrorCallback: (iconIdent: number) => void

	constructor(onLoadCallback: ((texture: Texture) => void) | undefined = undefined) {
		this._iconLoadedCallback = onLoadCallback;
		this._iconLoadErrorCallback = (iconIdent) => {
			console.error('error loading pile icon ', iconIdent);
			this._icons.set(iconIdent, null);
		}
	}

	get(
		pileIconIdent: number,
		svgString: string,
		textureWidth?: number,
		textureHeight?: number
	): Texture {
		if (!this._icons.has(pileIconIdent)) {
			const tex = new Texture(
				new ImageData(DiffuseDefaultPixel, 1, 1 ),
				UVMapping,
				RepeatWrapping,
				RepeatWrapping,
				LinearFilter,
				LinearMipmapLinearFilter,
				RGBAFormat,
				UnsignedByteType,
				0,
				sRGBEncoding,
			);
			tex.premultiplyAlpha = true; //instruct that texture has alpha values multiplied into color values
			tex.needsUpdate = true;
			this._icons.set(pileIconIdent, tex);

			loadTexture(
				pileIconIdent,
				svgString,
				tex,
				this._iconLoadedCallback,
				this._iconLoadErrorCallback,
				textureWidth,
				textureHeight
			);
		}
		return this._icons.get(pileIconIdent)!;
	}

	getIconsWithLoadErrors(): number[] {
		const iconsFailed: number[] = [];
		for (const [ident, texture] of this._icons) {
			if (texture === null) {
				iconsFailed.push(ident);
			}
		}
		return iconsFailed;
	}

	areTherePendingTextureLoads() {
		for (const texture of this._icons.values()) {
			if (texture && texture.image === undefined) {
				return true;
			}
		}
		return false;
	}

	dispose() {
		for (const texture of this._icons.values()) {
			if (texture) {
				texture.dispose();
			}
		}
		this._icons.clear();
	}
}

function loadTexture(
	pieIconIdent: number,
	svgString: string,
	texture: Texture,
	textureLoadedCallback?: ObjectHandleMethod<Texture>,
	textureLoadErrorCallback?: ObjectHandleMethod<number>,
	textureWidth?: number,
	textureHeight?: number,
) {		
    const svgBlob = new Blob([svgString], {type: 'image/svg+xml;charset=utf-8'});
	const urlObj = URL.createObjectURL(svgBlob);
	const image = new Image(textureWidth, textureHeight);
	image.src = urlObj;

	image.onload = () => {
		texture.image = image as any;
		texture.needsUpdate = true;
		if (textureLoadedCallback) {
			textureLoadedCallback(texture);
		}
		URL.revokeObjectURL(urlObj);
	};

	image.onerror = () => {
		if (textureLoadErrorCallback) {
			textureLoadErrorCallback(pieIconIdent);
		}
	}
}
