import type { AugmentSettings, Bim, BimPropertyData, BlockEquipment, Boundary2DDescription, Catalog, FarmLayoutConfig, IdBimScene, IdConfig, MathSolversApi, RepresentationBase, SceneInstance, SceneInstancePatch } from "bim-ts";
import { BimProperty, BoundaryType, CombinerBoxTypeIdent, FixedTiltTypeIdent, InverterTypeIdent, NumberProperty, NumberRangeProperty, SceneInstances, SectionalizingCabinetIdent, TransformerIdent } from "bim-ts";
import type { TasksRunner } from "engine-utils-ts";
import { DefaultMap, Failure, Immer, LogLevel, PollablePromise, ScopedLogger, Success, Yield } from "engine-utils-ts";
import { Aabb, Aabb2, Clipper, KrMath, Matrix4, Vec2X, Vector2, Vector3 } from "math-ts";
import type { GroupedNotificationGenerator, UiBindings } from "ui-bindings";
import { NotificationDescription, NotificationType } from "ui-bindings";
import { orderContour, orderHole } from "../LayoutUtils";
import { notificationSource } from "../Notifications";
import { createVirtualZoneFromBoundaries, updateEquipmentBoundaries } from "../farm-layout/EquipmentBoundariesCreator";
import { getInstances } from "../farm-layout/FarmLayoutUi";
import { type BlockData, type TransformerData } from "../farm-layout/LayoutAlgorithms";
import type { AssetData, BlockEquipmentInput } from "../farm-layout/LayoutService";
import { convertBlocks, convertRoads } from "../farm-layout/LayoutService";
import type { Point } from "../farm-layout/LayoutSolversTypes";
import { getAssets } from "../panels-config-ui/GeneratePanelUiBindings";
import { setTrackerWindPositions } from "../tracker-wind-position/TrackerWindPositionService";
import { deleteWiresByParents } from "../wiring/WiringService";
import { Augmentation } from "./Augmentation";
import { isBoxInsideSiteArea } from "./AugmentationUi";
import type { AutoblockingSolverInput } from "./AutoblockingSolvers";
import { AutoblockingSolvers } from "./AutoblockingSolvers";
import { blockEquipmentsShouldBeGrouped, type BlockEquipmentExtended } from "./parse-scene";
import { TrackersTypes } from './common';
import { calculateTrackerDimensions } from '../TrackerCommon';


export interface AutoBlockingInput {
    substationId: IdBimScene;
    boundaries: Boundary2DDescription[];
    
    roadsIds: IdBimScene[];

    blocksEquipment: AugmentEquipmentBlockInput[];

    transformerOffsetMeter: number;

    solverVersion: boolean;
}

interface AugmentEquipmentBlockInput {
    transformer: AssetData;
    inverter: AssetData;
    ilr_min: number;
    ilr_max: number;
    power_W: number;
    min_dc_power_watt: number;    
    max_dc_power_watt: number;
}



export async function runAugmentSolvers(
    substationId: IdBimScene,
    settings: Readonly<AugmentSettings>,
    farmLayoutConfig: [IdConfig, FarmLayoutConfig],
    bim: Bim,
    catalog: Catalog,
    mathSolversApi: MathSolversApi,
    tasksRunner: TasksRunner,
    uiBindings: UiBindings,
    notificationGroup: GroupedNotificationGenerator,
) : Promise<void> {
    const logger = new ScopedLogger('augment-layout', LogLevel.Default);

    try {
        const task = tasksRunner.newLongTask({
            defaultGenerator: _augmentLayout(
                substationId,
                settings,
                farmLayoutConfig[1],
                bim,
                catalog,
                mathSolversApi,
                tasksRunner,
                uiBindings,
                notificationGroup,
                logger,
            ),
            taskTimeoutMs: 600_000,
        });

        uiBindings.addNotification(
            notificationGroup.addRootNotification(
                NotificationDescription.newWithTask({
                    source: notificationSource,
                    key: 'generateLayout',
                    taskDescription: { 
                        task,
                        resultHeaderGetter: (result) => result instanceof Success ? 'Generating layout done' : 'Layout generation failed'
                    },
                    type: NotificationType.Info,
                    addToNotificationsLog: true
                })
            )
        );

        await task.asPromise()
            .then(() => {
                updateEquipmentBoundaries(bim, tasksRunner, substationId, farmLayoutConfig[0]);
        });
    } catch (e) {
        uiBindings.addNotification(
            notificationGroup.addNotification(
                NotificationDescription.newBasic({
                    type: NotificationType.Error,
                    source: notificationSource,
                    key: 'someErrorHappen',
                    removeAfterMs: 5_000,
                    addToNotificationsLog: true,
                    descriptionArg: e.toString(),
                })
            )
        );
        throw new Error(e);
    }

}

function* _augmentLayout(
    substationId: IdBimScene,
    settings: AugmentSettings,
    farmConfig: FarmLayoutConfig,
    bim: Bim,
    catalog: Catalog,
    mathSolversApi: MathSolversApi,
    tasksRunner: TasksRunner,
    uiBindings: UiBindings,
    groupNotification: GroupedNotificationGenerator,
    logger: ScopedLogger,
){
    deleteWiresByParents([substationId], bim);
    yield* bim.runBasicUpdatesTillCompletion({forceRun: true});

    let updatedSettings: AugmentSettings = settings;
    const boundaries = yield* createVirtualZoneFromBoundaries(
        bim, farmConfig, farmConfig.selected_area.value
    );

    if(settings.electrical.generate_new_blocks.value){
        const assets = getAssets(catalog);
        const input: AutoBlockingInput = { 
            substationId: substationId,
            boundaries: boundaries,
            roadsIds: settings.equipment_roads.selected_roads.value,
            blocksEquipment: settings.electrical.blocks_equipment.map(b => {

                let powerWatt = 0;
                if(settings.electrical.scheme.value === "SI_Multiharness"){
                    const transformer = getInstances(assets, b.transformer.value)[0];
                    powerWatt = transformer.properties.get("output | power")?.as("W")!;
                } else {
                    const inverter = getInstances(assets, b.inverter.value)[0];
                    powerWatt = inverter.properties.get("inverter | max_power")?.as("W")!;
                }

                return {
                    transformer:{instance: getInstances(assets, b.transformer.value)[0]},
                    inverter:{instance: getInstances(assets, b.inverter.value)[0]},

                    min_dc_power_watt: powerWatt * b.ilr_range.value[0],
                    max_dc_power_watt: powerWatt * b.ilr_range.value[1],
                    ilr_min: b.ilr_range.value[0],
                    ilr_max: b.ilr_range.value[1],

                    power_W: powerWatt,
                };
            }),
            transformerOffsetMeter: settings.offsets.transformer_offset.as('m'),
            solverVersion: settings.electrical.solver.value,
        };

        const blocksExtended = yield*_autoblockingLayout(
            input,
            settings.electrical.blocks_equipment,
            bim,
            mathSolversApi,
            logger,
        );
        logger.debug('blocks extended', blocksExtended);
        updatedSettings = Immer.produce(updatedSettings, (draft) => {
            draft.electrical.blocks_equipment = blocksExtended;
        });
    }
    logger.debug("run augmentation", updatedSettings);
    yield*Augmentation.applySiteAreaSettingsToScene(
        bim,
        catalog,
        mathSolversApi,
        tasksRunner,
        uiBindings,
        updatedSettings,
    );

    yield* setTrackerWindPositions({ bim, logger });
    
    if(settings.electrical.colorize.value) {
        bim.instances.colorizeHierarchiesOf([substationId]);
    }

    applyAreaIndexToInstances(bim, updatedSettings, farmConfig);
}

function applyAreaIndexToInstances(bim: Bim, settings: AugmentSettings, farmConfig: FarmLayoutConfig){
    const areaIndexPropPath = ["circuit", "position", "area_index"];
    const areaIndexPatch: [string, Partial<BimPropertyData> | BimProperty | null ] = [
        BimProperty.MergedPath(areaIndexPropPath), 
        { path: areaIndexPropPath, value: farmConfig.selected_area.value, numeric_step: 1, readonly: true, }
    ];

    const patternPath = ["circuit", "pattern", "type"];
    const patternType: [string, Partial<BimPropertyData> | BimProperty | null ] =
        [BimProperty.MergedPath(patternPath), { path: patternPath, value: settings.electrical.scheme.value }];

    const patches: [IdBimScene, SceneInstancePatch][] = [];
    for (const block of settings.electrical.blocks_equipment) {
        const transformers = (block as BlockEquipmentExtended).matchingTransformers;
        for (const transformer of transformers) {
            bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(
                transformer.value, 
                (childId) => {
                    const patch: [string, BimProperty | Partial<BimPropertyData> | null][] = [areaIndexPatch];
                    if(bim.instances.peekTypeIdentOf(childId) === TransformerIdent){
                        patch.push(patternType);
                    }
                    patches.push([childId, { properties: patch }]);
                    return true;
                }
            )
        }
    }

    bim.instances.applyPatches(patches);
}

interface TrackerDescription {
    id: IdBimScene;
    isFixed: boolean;
    instance: SceneInstance;
    segment: [Vector2, Vector2];
    aabb: Aabb;
    dc_powerW: number;
    width: number;
}

function* _autoblockingLayout(
    input: Readonly<AutoBlockingInput>,
    blocksEquipment: BlockEquipment[],
    bim: Bim,
    mathSolverApi: MathSolversApi,
    logger: ScopedLogger,
    ){
    
    const trackersIds = extractTrackersFromSubareaAndCleanEquipment(
        bim, 
        input.substationId, 
        input.boundaries, 
        logger
    );

    const goemetriesAabbs = bim.allBimGeometries.aabbs.poll();
    const reprsBboxes = new DefaultMap<RepresentationBase, Aabb>(r => r.aabb(goemetriesAabbs));

    const trackers: TrackerDescription[] = [];
    for (const trackerId of trackersIds) {
        const inst = bim.instances.peekById(trackerId);
        if(!inst || !TrackersTypes.includes(inst.type_identifier) || !inst.representation){
            continue;
        }
        const aabb = reprsBboxes.getOrCreate(inst.representation);
        if(aabb.isEmpty()){
            continue;
        }
        const segment: [Vector3, Vector3] = inst.type_identifier !== FixedTiltTypeIdent 
            ? [new Vector3(aabb.minx() + (aabb.maxx() - aabb.minx())/2, aabb.miny()), new Vector3(aabb.minx()+ (aabb.maxx() - aabb.minx())/2, aabb.maxy())]
            : [new Vector3(aabb.minx(), aabb.miny() + (aabb.maxy() - aabb.miny())/2), new Vector3(aabb.maxx(), aabb.miny() + (aabb.maxy() - aabb.miny())/2)]; 
        for (const p of segment) {
            p.applyMatrix4(inst.worldMatrix);
        }

        const dimensions = calculateTrackerDimensions(inst);
        
        trackers.push({
            id: trackerId,
            instance: inst,
            isFixed: dimensions.isFixed,
            segment: [segment[0].xy(), segment[1].xy()],
            aabb,
            dc_powerW: dimensions.dc_power,
            width: dimensions.width,
        });
    }

    const blocks = convertAugmentBlocks(input.blocksEquipment, bim);
    const roadsInstances = input.roadsIds.length === 0 
        ? bim.instances.peekByTypeIdent('road') 
        : bim.instances.peekByIds(input.roadsIds);
    
    const roads = convertRoads(roadsInstances, bim);
    roads.edges.sort((a, b) => (b.length - a.length));

    const allSegments: [Point, Point][] = [];
    let maxRoadWidth = 0;
    for (const edge of roads.edges) {
        if(edge.fst === edge.snd){
            continue;
        }
        const fst = roads.points[edge.fst];
        const snd = roads.points[edge.snd];
        allSegments.push([{x: fst.x, y: fst.y}, {x: snd.x, y: snd.y}]);
        maxRoadWidth = Math.max(maxRoadWidth, edge.width);
    }

    const contours = input.boundaries.map(b => {
        if(b.boundaryType === BoundaryType.Exclude){
            return orderHole(b.pointsWorldSpace);
        } else {
            return orderContour(b.pointsWorldSpace);
        }
    });

    const request: AutoblockingSolverInput = {
        contours: contours,
        solverType: input.solverVersion ? 'basic' : 'alternative',
        segments: allSegments,
        trackers: trackers.map(t => ({fst: t.segment[0], snd: t.segment[1], value: t.dc_powerW})),
        samples: input.blocksEquipment.map(b => ({min_ilr: b.ilr_min, max_ilr: b.ilr_max, value: b.power_W})),
        radius: Math.max(maxRoadWidth * 3, 10),
    };
    const solvers = new AutoblockingSolvers(mathSolverApi);
    logger.debug('autoblocking request', request);
    const responsePromise = solvers.solve(request);

    const responseResult = yield* PollablePromise.generatorWaitFor(responsePromise);
    if(responseResult instanceof Failure){
        throw new Error(responseResult.errorMsg());
    }

    const response = responseResult.value;
    logger.debug('autoblocking response', response);

    const blockExtendedGroups:BlockEquipmentExtended[] = [];
    function addBlockExtended(block: BlockEquipment, transformerId: IdBimScene, transformerPowerkW: number, dcWatt: number){
        
        const blockExtended: BlockEquipmentExtended = {
            transformer: block.transformer,
            inverter: block.inverter,
            ilr_range: block.ilr_range,
            id: block.id,
            inverters_per_block: block.inverters_per_block,
            label: block.label,
            number_of_inverters: block.number_of_inverters,
            blocks_dc: NumberRangeProperty.new({ value: [transformerPowerkW * block.ilr_range.value[0], transformerPowerkW * block.ilr_range.value[1]], range: [0, Number.MAX_SAFE_INTEGER], step: 1, unit: 'kW' }),
            transformersDCWatt: [NumberProperty.new({value: dcWatt, unit: 'W'})],
            matchingTransformers: [NumberProperty.new({value: transformerId})],
            blockDCWatt: NumberProperty.new({value: 0}),
        };
        let notFoundGroup = true;   
        for (const group of blockExtendedGroups) {
            if (blockEquipmentsShouldBeGrouped(group, blockExtended)) {;
                group.matchingTransformers.push(NumberProperty.new({ value: transformerId }));
                group.transformersDCWatt.push(blockExtended.blockDCWatt);
                notFoundGroup = false;
                break;
            }
        }
        if(notFoundGroup){
            blockExtendedGroups.push(blockExtended);
        }
    }
    const substationMtrx = bim.instances.peekById(input.substationId)!.worldMatrix;
    const transformersToAllocate: [IdBimScene, Partial<SceneInstance>][] = [];
    const patches: [IdBimScene, SceneInstancePatch][] = [];
    const aabb = Aabb2.empty();
    for (let i = 0; i < response.blocks.length; i++) {
        const block = response.blocks[i];
        aabb.makeEmpty();
        let totalDcW: number = 0;
        let maxWidth = 0;
        const trackersInBlock: TrackerDescription[] = [];
        for (let j = 0; j < block.length; j++) {
            const tracker = trackers[block[j]];
            trackersInBlock.push(tracker);
            aabb.expandByPoint(tracker.segment[0]);
            aabb.expandByPoint(tracker.segment[1]);
            maxWidth = Math.max(maxWidth, tracker.width);
            totalDcW += tracker.dc_powerW;
        }
        aabb.expandByScalar(maxWidth / 2);

        let blockData: BlockData = blocks[0];
        if(input.blocksEquipment.length > 1){
            const sorted = input.blocksEquipment
                .map((b, index) => ({
                    index: index,
                    isInRange: b.min_dc_power_watt <= totalDcW && b.max_dc_power_watt >= totalDcW,
                    ilr: totalDcW / b.power_W,
                }))
                .filter(b => b.isInRange)
                .sort((a, b) => b.ilr - a.ilr);
            if(sorted.length > 0){
                blockData = blocks[sorted[0].index];
            } else {
                const blockEquipments = input.blocksEquipment
                    .map((b, index) => ({index, dist: Math.abs(b.power_W - totalDcW)}))
                    .sort((a, b) => a.dist - b.dist);

                blockData = blocks[blockEquipments[0].index];
                logger.debug('no block data found for block', block, 'using', blockData);
            }
        }

        if(!blockData){
            logger.error("no block data found for block", block);
            throw new Error("no block data found for block");
        }

        const position = createTransformerPosition(
            aabb,
            roads,
            blockData.transformer,
            input.transformerOffsetMeter,
            maxRoadWidth
        );
        const worldPosition = new Matrix4().setPosition(
            position.x,
            position.y,
            substationMtrx.elements[14]
        );
        const localTransform = SceneInstances.getLocalTransformRelativeToParentMatrix(
            substationMtrx,
            worldPosition
        );
        const properties = blockData.transformer.src.properties.clone();
        const powerkW = properties.get('output | power')?.as('kW')!;
        const block_label_Path = ['circuit', 'position', 'block_label'];
        properties.applyPatch([
            ['circuit | position | block_number', null],
            [BimProperty.MergedPath(block_label_Path), { path: block_label_Path, value: blockData.label, numeric_step: 1, readonly: true, }],
        ]);
        const newId = bim.instances.reserveNewId();
        transformersToAllocate.push([newId, {
            type_identifier: TransformerIdent,
            name: blockData.transformer.src.name,
            properties: properties,

            spatialParentId: input.substationId,

            localTransform,
        }]);
        addBlockExtended(blocksEquipment[blockData.label], newId, powerkW, totalDcW);

        for (const tracker of trackersInBlock) {
            patches.push([tracker.id, {
                spatialParentId: newId,
                localTransform: SceneInstances.getLocalTransformRelativeToParentMatrix(
                    worldPosition,
                    tracker.instance.worldMatrix
                ),
            }]);
        }

        if(i % 5 === 0){
            yield Yield.Asap;
        }
    }

    bim.instances.allocate(transformersToAllocate);
    bim.instances.applyPatches(patches);
    yield Yield.NextFrame;
    yield Yield.NextFrame;

    return blockExtendedGroups;
}

function extractTrackersFromSubareaAndCleanEquipment(
    bim: Bim, 
    substation: IdBimScene, 
    boundaries: Boundary2DDescription[],
    logger: ScopedLogger,
): IdBimScene[]{
    const toDeleteTypes = new Set<string>([
        TransformerIdent,
        InverterTypeIdent,
        CombinerBoxTypeIdent,
        "wire",
        "lv-wire",
        "trench",
        SectionalizingCabinetIdent,
    ]);
    const trackerPatches: [IdBimScene, SceneInstancePatch][] = [];
    const substationMtrx = bim.instances.peekById(substation)!.worldMatrix;

    const goemetriesAabbs = bim.allBimGeometries.aabbs.poll();
    const reprsBboxes = new DefaultMap<RepresentationBase, Aabb>(r => r.aabb(goemetriesAabbs));

    const trackers: IdBimScene[] = [];
    const aabb1 = Aabb.empty();
    const pos = new Vector3();
    function addTracker(id: IdBimScene, instance: SceneInstance){
        const localTransform = SceneInstances.getLocalTransformRelativeToParentMatrix(
            substationMtrx,
            instance.worldMatrix
        );
        trackerPatches.push([id, {
            spatialParentId: substation,
            localTransform,
        }]);
        trackers.push(id);
    }

    bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(
        substation, 
        (id) => { 
            const inst = bim.instances.peekById(id);
            if(!inst || !TrackersTypes.includes(inst.type_identifier) || !inst.representation){
                return true;
            }

            const aabb = reprsBboxes.getOrCreate(inst.representation);

            if(aabb.isEmpty()){
                return true;
            }

            const position = aabb.getCenter(pos).applyMatrix4(inst.worldMatrix).xy();
            const box = aabb1.copy(aabb).applyMatrix4(inst.worldMatrix);

            const isInstanceInsideArea = isBoxInsideSiteArea(boundaries, box.xy(), position);
            if(isInstanceInsideArea){
                addTracker(id, inst);
            }
            
            return true;
        }, 
        true
    );
    bim.instances.applyPatches(trackerPatches);

    const toDelete:IdBimScene[] = [];
    for (const childId of bim.instances.spatialHierarchy.iteratorOfChildrenOf(substation)) {
        const typeIdent = bim.instances.peekTypeIdentOf(childId);
        if(!typeIdent || !toDeleteTypes.has(typeIdent)){
            continue;
        }

        let haveTrackerChildren = false;
        bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(
            childId,
            (id) => {
                const typeIdent = bim.instances.peekTypeIdentOf(id);
                if(typeIdent && TrackersTypes.includes(typeIdent)){
                    haveTrackerChildren = true;
                    return false;
                }
                return true;
            },
            true
        );
        if(!haveTrackerChildren){
            toDelete.push(childId);
        }
    }

    bim.instances.delete(toDelete);

    return trackers;
}

function convertAugmentBlocks(blocksEquipment: AugmentEquipmentBlockInput[], bim: Bim){
    const [blocks] = convertBlocks(blocksEquipment.map<BlockEquipmentInput>(b => ({
        transformer: b.transformer,
        inverter: b.inverter,
        ilr_min: b.ilr_min,
        ilr_max: b.ilr_max,
    })), bim);
    return blocks;
}



function createTransformerPosition(
    blockBox: Aabb2,
    roads: ReturnType<typeof convertRoads>,
    transformer: TransformerData,
    offset: number,
    roadWidth: number,
): Vector2 {
    const center = blockBox.getCenter();
    const minX = blockBox.min.x;
    const maxX = blockBox.max.x;
    const transformerPosition = new Vector2(
        center.x, 
        blockBox.max.y + Math.max(transformer.width, transformer.length) / 2
    );
    blockBox.expandByScalar(4 * roadWidth);

    interface RoadBBox {
        fst: Vector2;
        snd: Vector2;
        width: number;
        box: Vector2[];
        distX: number;
        distY: number;
        angle: number;
    }

    const roadAabb = Aabb2.empty();
    const dir = new Vector2();
    const equipmentRoads: RoadBBox[] = [];
    const supportRoads: RoadBBox[] = [];
    for (const road of roads.edges) {
        if (road.fst === road.snd) {
            continue;
        }
        const fst = new Vector2(roads.points[road.fst].x, roads.points[road.fst].y);
        const snd = new Vector2(roads.points[road.snd].x, roads.points[road.snd].y);
        
        dir.subVectors(snd, fst).normalize();
        let angle = Vec2X.smallestAngleTo(dir);
        angle = Math.abs(angle - Math.PI) < 0.001 ? 0 : angle;
        
        
        roadAabb.makeEmpty();
        roadAabb.setFromPoints([fst, snd]);
        if(!roadAabb.intersectsBox2(blockBox)){
            continue;
        }
        const box = createBoxFromSegment(fst, snd, road.width);
        if(!Clipper.intersectsPolygons(box, blockBox.cornerPoints().slice())){
            continue;
        }
        const distY = Math.min(Math.abs(center.y - fst.y), Math.abs(center.y - snd.y));
        if (angle > Math.PI / 4) {
            supportRoads.push({
                fst,
                snd,
                width: road.width,
                box,
                angle,
                distX: 0,
                distY,
            });
        } else {
            const distX = (center.x >= Math.min(fst.x, snd.x) && center.x <= Math.max(fst.x, snd.x))
                ? 0
                : Math.min(Math.abs(center.x - fst.x), Math.abs(center.x - snd.x));
            equipmentRoads.push({
                fst, 
                snd,
                width: road.width,
                distX,
                distY,
                angle,
                box,
            });
        }
    }

    equipmentRoads.sort((a, b) => a.angle - b.angle || a.distX - b.distX || a.distY - b.distY);
    const equipmentRoad = equipmentRoads[0];
    if(equipmentRoad){
        let x = transformerPosition.x;
        const {fst, snd} = equipmentRoad;
        if (!(center.x >= Math.min(fst.x, snd.x) && center.x <= Math.max(fst.x, snd.x))) {
            x = KrMath.clamp(
                center.x,
                Math.max(Math.min(fst.x, snd.x), minX),
                Math.min(Math.max(fst.x, snd.x), maxX)
            ) - equipmentRoad.width;      
        }

        const y = snd.y - fst.y !== 0
            ? ((x - fst.x) * (snd.y - fst.y)) / (snd.x - fst.x) + fst.y - offset
            : fst.y - offset;
        transformerPosition.set(x, y);

        const transformerAabb = Aabb2.empty();
        transformerAabb.setFromCenterAndSize(transformerPosition, new Vector2(transformer.width, transformer.length));
        const transformerBox = transformerAabb.cornerPoints().slice();
        for (const supportRoad of supportRoads) {
            if(!Clipper.intersectsPolygons(supportRoad.box, transformerBox)){
                continue;
            }
            transformerPosition.setX(x + supportRoad.width * 2);
            break;
        }
    }

    return transformerPosition;
}

function createBoxFromSegment(
    fst: Vector2,
    snd: Vector2,
    width: number,
): Vector2[] {
    const dir = new Vector2().subVectors(snd, fst).normalize();
    const normal = Vector2.perpendicular(dir);
    const offset = normal.clone().multiplyScalar(width / 2);
    const p1 = fst.clone().add(offset);
    const p2 = snd.clone().add(offset);
    const p3 = snd.clone().sub(offset);
    const p4 = fst.clone().sub(offset);
    return [p1, p2, p3, p4];
}