import { SystemOfUnitsConfigTypeIdent, type Bim, type SystemOfUnitsConfig } from "bim-ts";
import { Immer, LazyDerived, StringUtils, type LazyVersioned, ScopedLogger } from "engine-utils-ts";
import type { ProjectHub } from "src/ProjectHub";
import { ProjectStatus} from "src/projects";
import { ProjectStatusToLabel } from "src/projects";
import { siteLocationSettingsUi } from "src/project-panel/SiteLocationSettingsUi";
import type { PUI_GroupNode } from "ui-bindings";
import { PUI_Builder } from "ui-bindings";

const labelToStatus = Object.fromEntries(Object.entries(ProjectStatusToLabel).map(([k, v]) => [v, k]));

export function createProjectSettingsUi(bim: Bim, projectHub: ProjectHub, projectId: number): LazyVersioned<PUI_GroupNode> { 
    const unitSystemConfig = bim.configs.getLazySingletonOf({type_identifier: SystemOfUnitsConfigTypeIdent});
    const sceneOriginObservableUi = siteLocationSettingsUi(bim);
    const logger = new ScopedLogger("project-settings-ui");
    const updateWithDelay = new UpdateWithDelay(logger);

    const lazyUi = LazyDerived.new2(
        "project-settings-ui-lazy", 
        [projectHub], 
        [unitSystemConfig, sceneOriginObservableUi], 
        ([config, sceneOriginUi]) => {
            const builder = new PUI_Builder({sortChildrenDefault: false, rootName: "Project Settings"});
            const addDivider = (name: string) =>{
                builder.addCustomProp({
                    name,
                    context: {},
                    value: {},
                    onChange: () => {},
                    type_ident: "divider",
                });
            }
            builder.addStringProp({
                name: "Project Name",
                value: projectHub._storeValue.name,
                onChange: (v) => {
                    projectHub.update(s => (s.name = v, s));
                    updateWithDelay.executeWithDelay("projectName", async () => { 
                        await projectHub.updateName();
                        logger.debug("updateName", projectHub._storeValue.name); 
                    });
                },
            });
            builder.addStringProp({
                name: "Project ID",
                value: `#${projectHub._storeValue.project?.id.toString() ?? projectId ?? ""}`,
                onChange: () => {},
                readonly: true,
                calculated: true,
            });
            // builder.addStringProp({
            //     name: "Created by",
            //     value: projectHub._storeValue.project?.createBy ?? "",
            //     onChange: () => {
            //         //readonly
            //     },
            //     readonly: true,
            //     calculated: true,
            // });
            builder.addSelectorProp({
                name: "Status",
                value: ProjectStatusToLabel[projectHub._storeValue.project?.status ?? ProjectStatus.Active],
                options: Object.values(ProjectStatusToLabel),
                onChange: (v) => {
                    projectHub.updateStatus(labelToStatus[v] as ProjectStatus);;
                },
            });
            builder.addSelectorProp({
                name: "Availability",
                value: projectHub._storeValue.project?.isShared ? "Company" : "Only me",
                options: ['Company', 'Only me'],
                onChange: (v) => {
                    projectHub.toggleShared(v === 'Company');
                },
            });

            addDivider("scene origin");
            builder.addStringProp({
                name: "WGS standard",
                value: "WGS84",
                onChange: () =>{},
                readonly: true,
                calculated: true,
            });
            for (const [_, node] of sceneOriginUi.children) {
                builder.addChild(node);
            }

            addDivider("system of units");
            const systemOfUnitsConfig = config.get<SystemOfUnitsConfig>();
            builder.addSwitcherProp({
                name: "Currency",
                value: systemOfUnitsConfig.currency.value,
                options: systemOfUnitsConfig.currency.options.map((v) => ({value: v, label: v.toLocaleUpperCase()})),
                onChange: (v) =>{
                    const updated = Immer.produce(systemOfUnitsConfig, (draft) => {
                        draft.currency = draft.currency.withDifferentValue(v.toString());
                    });
                    bim.configs.applyPatchToSingleton(SystemOfUnitsConfigTypeIdent, { properties : updated})
                },
            });
            builder.addSwitcherProp({
                name: "System of Units",
                value: systemOfUnitsConfig.systemOfUnits.value,
                options: systemOfUnitsConfig.systemOfUnits.options.map((v) => ({value: v, label: StringUtils.capitalizeFirstLatterInWord(v)})),
                onChange: (v) =>{
                    const updated = Immer.produce(systemOfUnitsConfig, (draft) => {
                        draft.systemOfUnits = draft.systemOfUnits.withDifferentValue(v.toString());
                    });
                    bim.configs.applyPatchToSingleton(SystemOfUnitsConfigTypeIdent, { properties : updated})
                },
            });

        return builder.finish();
    }).withoutEqCheck();

    return lazyUi;
}

class UpdateWithDelay {
    private readonly _delayMs;
    private readonly _timers = new Map<string, NodeJS.Timeout | null>();

    constructor(private logger: ScopedLogger, delayMs?: number) {
        this._delayMs = delayMs ?? 1000;
    }

    public executeWithDelay(ident: string, callback: () => Promise<void>) {
        const timer = this._timers.get(ident);
        if (timer) {
            clearTimeout(timer);
            this._timers.delete(ident);
        }

        const newTimeout = setTimeout(async () => {
            try {
                await callback();
            } catch (error) {
                this.logger.error(`Error updating ${ident}: ${error}`);
            } finally {
                this._timers.delete(ident);
            }
        }, this._delayMs);

        this._timers.set(ident, newTimeout);
    }
}

