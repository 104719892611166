<script lang="ts">
import type { ComparisonItemView } from "./ComparisonMode";
import { CheckBoxComponent, Checkbox } from "../libui/checkbox/index";

export let item: ComparisonItemView;
export let isRelativeMode: boolean;
export let onCheck: () => void;
export let isBaseRelativeItem: boolean = false;

$:checkBoxInput = new Checkbox(isBaseRelativeItem, () => {
    onCheck();
});

</script>

<div class="item-container">
    {#if isRelativeMode}
        <CheckBoxComponent
            input={checkBoxInput}
        />
    {/if}
    <span>
        <b>{item.projectName}</b>
    </span> 
    <span>
        { item.header }
    </span>
</div>


<style lang="scss">
    .item-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
</style>
