<script lang="ts">
    import { PUI_PropertyNodeNumber, UiBindings } from "ui-bindings";
    import InputOffset from "./InputOffset.svelte";
    import InputPilePositionWithFeatures from "./InputOffsetWithPileType.svelte";
    import { PilePositionStateManger, TrackerProps } from "../PilePositions";
    import {
        PileMotorType,
        TrackerBins,
    } from "bim-ts";
    import { PileOffsetContextMenu, PileLimits } from "./PileLimits";
    import { getContext, onDestroy } from "svelte";

    export let stateManager: PilePositionStateManger;
    export let props: TrackerProps;
    export let trackerBins: TrackerBins | undefined;

    const ui = getContext<UiBindings>('uiBindings');
    // export let isMirror: boolean;

    let modulesWidth = 0;
    let schemeWidth = 0;

    const minOffsetPx = 60;
    const overhangPx = 30;
    $: piles = props.piles ?? [];
    $: features = props.pilesFeatures ?? [];
    $: positions = props.pilesOffsetsMeter;
    $: totalLength = props.trackerLength.as("m");
    let relativeTotalLength = 0;

    let relativePositions: number[] = [];
    $: {
        const offsets = positions.slice(1).map((p, idx) => p - positions[idx]);
        const minOffset = Math.min(...offsets.filter((o) => o > 0));
        const maxOffset = Math.max(...offsets);
        const relative: number[] = [overhangPx];
        for (let i = 0; i < offsets.length; i++) {
            const offset = offsets[i];
            const prev = relative.at(-1) ?? 0;
            relative.push(
                prev +
                    minOffsetPx +
                    ((offset - minOffset) / maxOffset) * minOffsetPx,
            );
        }

        relativePositions = relative;
        relativeTotalLength = (relative.at(-1) ?? 0) + overhangPx;
    }
    let pilesContentWidth = 0;
    const contextMenus = new PileOffsetContextMenu(ui);

    $: pilesUpdates = piles.map((p, index) => new PileLimits(props, index, stateManager));

    onDestroy(() => {
        contextMenus.dispose();
    });

    const decimals = 3;
</script>

<div class="tracker-scheme">
    <div class="root mdc-typography--headline5">
        <span id="modules">Modules</span>
        <span id="scheme"> </span>
        <span id="piles" class="root">
            <span>Piles</span>
            <span></span>
            <!-- <SwitchButton
                name="Mirror"
                value={isMirror}
                onChange={() => {
                    console.info('not implemented', !isMirror)
                    isMirror = !isMirror;
                }}
            /> -->
        </span>
    </div>
    <InputOffset
        numberProperty={new PUI_PropertyNodeNumber({
            name: "overhang_north",
            value: props.overhangNorth.value,
            onChange: (value) => {
                stateManager.applyPatch({
                    overhangNorth:
                        props.overhangNorth.withDifferentValue(value),
                });
            },
            minMax: props.overhangNorth.range,
            unit: props.overhangNorth.unit,
        })}
        description={"Overhang"}
    />
    <div class="root" style="height: {relativeTotalLength}px;">
        <div id="modules" bind:offsetWidth={modulesWidth}>
            {#if props.isRelativePilePositions}
                {#each pilesUpdates as pile, index}
                    {@const relativePos =
                        index == 0
                            ? relativePositions[index] / 2
                            : (relativePositions[index] -
                                  relativePositions[index - 1]) /
                                  2 +
                              relativePositions[index - 1]}
                    <div
                        class="input"
                        style="--offset: {relativePos}px; --width: {modulesWidth}px;"
                    >
                        <InputOffset
                            numberProperty={new PUI_PropertyNodeNumber({
                                name: "modules count",
                                value: pile.modules,
                                onChange: (value) => {
                                    pile.updateModulesOffset(value);
                                },
                                step: 1,
                                minMax: pile.modulesMinMax,
                            })}
                            decimals={0}
                            description={" "}
                        />
                    </div>
                {/each}
                {@const lastPileOffset = piles.at(-1)?.offset_in_modules ?? 0}
                {@const position = relativeTotalLength - (relativeTotalLength - (relativePositions?.at(-1) ?? 0)) / 2}
                <div
                    class="input"
                    style="--offset: {position}px; --width: {modulesWidth}px;"
                >
                    <InputOffset
                        numberProperty={new PUI_PropertyNodeNumber({
                            name: "modules count",
                            value: props.modulesCount - lastPileOffset,
                            onChange: () => {
                                console.error("relative positions are disabled");
                            },
                            step: 1,
                        })}
                        decimals={0}
                        description={" "}
                        readonly = {true}
                    />
                </div>
            {:else}
                {@const position = relativeTotalLength / 2}
                <div
                    class="input"
                    style="--offset: {position}px; --width: {modulesWidth}px;"
                >
                    <InputOffset
                        numberProperty={new PUI_PropertyNodeNumber({
                            name: "modules count",
                            value: props.modulesCount,
                            onChange: (value) => {
                                console.error("relative positions are disabled");
                            },
                            step: 1,
                        })}
                        decimals={0}
                        description={" "}
                        readonly = {true}
                    />
                </div>
            {/if}
        </div>
        <div id="scheme" bind:offsetWidth={schemeWidth} class="scheme">
            <div class="tracker-axis" style="--height: {relativeTotalLength}px;" />
            {#each relativePositions as relativePos, index}
                {@const isMotor = piles[index].motor === PileMotorType.Motor}
                {@const width = props.isRelativePilePositions ? modulesWidth + schemeWidth : schemeWidth}
                {@const offset = props.isRelativePilePositions ? -modulesWidth : 0}
                <div
                    class="annotation"
                    style="--width: {width}px; --offsetX: {offset}px; --offsetY: {relativePos}px;"
                />
                <div
                    class="tracker-pile"
                    class:motor={isMotor}
                    style="--offset: {relativePos}px;"
                />
            {/each}
            <div class="tracker-edge" style="--offset: {0}px;" />
            <div class="tracker-edge" style="--offset: {relativeTotalLength}px;" />
        </div>
        <div id="piles" bind:clientWidth={pilesContentWidth}>
            {#each pilesUpdates as pile, index}
                {@const relativePos = relativePositions[index]}
                {@const feature = features[index]}
                <div
                    class="input"
                    style="--offset: {relativePos}px; --width: {pilesContentWidth}px;"
                >
                    <InputPilePositionWithFeatures
                        numberProperty={new PUI_PropertyNodeNumber({
                            name: "Offset",
                            value: pile.position,
                            onChange: (value) => {
                                pile.updatePosition(value);
                            },
                            unit: "m",
                            minMax: pile.positionMinMax,
                            step: 0.001,
                        })}
                        pileFeatures={feature}
                        settings={trackerBins}
                        onChange={(value) => {
                            pile.updateFeatures(value);
                        }}
                    />
                </div>
                {@const offsetRelativePos = index === 0 
                    ? 0 
                    : relativePositions[index] - (relativePositions[index] - relativePositions[index - 1]) / 2}
                {@const header = index === 0 ? "First pile offset" : `Offset #${index} - #${index + 1}`}
                    <div
                        class="input"
                        style="--offset: {offsetRelativePos}px; --width: {pilesContentWidth}px;"
                    >
                        <InputOffset
                            numberProperty={new PUI_PropertyNodeNumber({
                                name: "pile_offset",
                                value: index === 0 ? positions[index] : (positions[index] - (positions[index - 1] ?? 0)),
                                unit: "m",
                                onChange: (value) => {
                                    const newPos = value + (positions[index - 1] ?? 0);
                                    pile.updatePosition(newPos);
                                },
                                minMax: pile.offsetMinMax
                            })}
                            description={header}
                            decimals={decimals}
                        />
                    <!-- <PileOffset 
                        description = {header} 
                        value = {index === 0 ? positions[index] : (positions[index] - (positions[index - 1] ?? 0))}
                        unit = {"m"}
                        decimals = {decimals}
                        onClick = {p => {
                            const config = new ContextMenuConfig({
                                identity: header,
                                header: header,
                                viewSource: new UiCustomNode({
                                    context: new PileOffsetContext(
                                        index,
                                        stateManager,
                                    ),
                                }),
                                positionPx: p,
                                widthPx: 320,
                            });
                            contextMenus.open(config);
                        }}
                        readonly = {true}
                    /> -->
                </div>
            {/each}
            <div
                class="input"
                style="--offset: {relativeTotalLength}px; --width: {pilesContentWidth}px;"
            >   
                {#if true}
                    {@const lastPile = pilesUpdates[pilesUpdates.length - 1]}
                    <InputOffset
                        numberProperty={new PUI_PropertyNodeNumber({
                            name: "last_pile_offset",
                            value: totalLength - lastPile.position,
                            unit: "m",
                            onChange: (value) => {
                                const prevValue = totalLength - lastPile.position;
                                const change = value - prevValue;
                                const newPos = lastPile.position - change;
                                lastPile.updatePosition(newPos);
                            },
                            minMax: [
                                0,
                                totalLength - (positions[positions.length - 2] ?? 0) - lastPile.maxEdgeOffset,
                            ]
                        })}
                        description={"Last pile offset"}
                        decimals={decimals}
                    />
                {/if}

                <!-- <PileOffset 
                    description = {"Last pile offset"} 
                    value = {(totalLength - positions[positions.length - 1])}
                    unit = {"m"}
                    decimals = {decimals}
                    onClick = {p => {
                        const header = "Last pile offset";
                        const config = new ContextMenuConfig({
                            identity: header,
                            header: header,
                            viewSource: new UiCustomNode({
                                context: new PileOffsetContext(
                                    null,
                                    stateManager,
                                ),
                            }),
                            positionPx: p,
                            widthPx: 320,
                        });
                        contextMenus.open(config);
                    }}
                    readonly = {true}
                /> -->
            </div>
        </div>
    </div>

    <InputOffset
        numberProperty={new PUI_PropertyNodeNumber({
            name: "overhang_south",
            value: props.overhangSouth.value,
            onChange: (value) => {
                stateManager.applyPatch({
                    overhangSouth:
                        props.overhangSouth.withDifferentValue(value),
                });
            },
            minMax: props.overhangSouth.range,
            unit: props.overhangSouth.unit,
        })}
        description={"Overhang"}
    />
</div>

<style lang="scss">
    .tracker-scheme {
        padding: 10px 0;
    }
    .root {
        min-height: 30px;
        display: flex;
        justify-content: space-between;
        height: 100%;
        padding: 4px 0;
    }
    #modules {
        flex: 2;
    }
    #scheme {
        flex: 1;
    }
    #piles {
        flex: 4;
    }
    .tracker-edge {
        position: absolute;
        transform: translateY(var(--offset));
        width: 14px;
        height: 4px;
        border-radius: 1px;
        background-color: var(--color-main-dark);
    }
    .tracker-axis {
        position: absolute;
        transform: translateX(3px);
        height: var(--height);
        width: 6px;
        border: 1px solid var(--color-main-dark);
    }

    .tracker-pile {
        position: absolute;
        transform: translateY(calc(var(--offset) - 7px));
        offset: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid var(--color-main-dark);
        background-color: var(--color-white);
        &.motor {
            background-color: var(--color-main-dark);
        }
    }
    .input {
        position: absolute;
        // transform: translateY(calc(var(--offset) - 13px));
        top: calc(var(--offset) - 13px);
        width: var(--width);
    }
    .annotation {
        position: absolute;
        transform: translateX(var(--offsetX)) translateY(var(--offsetY));
        width: var(--width);
        background: var(--color-divider);
        border-color: var(--color-divider);
        border-radius: 1px;
        height: 1px;
    }
    .scheme {
        display: flex;
    }
</style>
