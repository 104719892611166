import { KrMath } from 'math-ts';
import { getModulesRows } from '../archetypes/TrackerFrame';
import { BimProperty } from '../bimDescriptions/BimProperty';
import { NumberProperty, StringProperty } from '../properties/PrimitiveProps';
import { PropsGroupsRegistry } from '../properties/PropsGroupsRegistry';
import { AnyTrackerProps, TrackerFrameCommercialProps } from './AnyTracker';
import { PilesConfigModuleBaySize, PilesConfigModulesRow } from './PilesProps';
import type { SceneInstance } from '../scene/SceneInstances';
import { ModuleProps } from './ModuleProps';
import type { SceneInstanceSerializable } from '../persistence/SceneInstancesSerializer';
import { SmallNumericArrayProperty } from '../properties/SmallNumberArrayProperty';
import { IterUtils } from 'engine-utils-ts';
import { ModulesGapContent, trackersPartsCache } from '../trackers/Tracker';



export function convertLegacyTrackerPropsIntoAnyTrackerProps(instance: SceneInstanceSerializable): Partial<SceneInstance> {

    if (instance.type_identifier !== 'tracker') {
        throw new Error('Not a tracker instance:' + instance.type_identifier);
    }

    const _oldTrackerPropsType = {
        module_size_x: BimProperty.NewShared({ path: ["module", "width"], value: 0, unit: 'm' }),
        module_size_y: BimProperty.NewShared({ path: ["module", "length"], value: 0, unit: 'm' }),
        string_modules_count_x: BimProperty.NewShared({ path: ['tracker-frame', "string", "modules_count_x"], value: 0 }),
        string_modules_count_y: BimProperty.NewShared({ path: ['tracker-frame', "string", "modules_count_y"], value: 0 }),
        tracker_strings_count: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "strings_count"], value: 0 }),
        tracker_module_bay_size: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "module_bay_size"], value: 1 }),
        tracker_pile_reveal: BimProperty.NewShared({ path: ["tracker-frame", "piles", "max_reveal"], value: 0.5, unit: 'm' }),
        tracker_pile_embedment: BimProperty.NewShared({ path: ["tracker-frame", "piles", "min_embedment"], value: 0.5, unit: 'm' }),
        tracker_pile_bearings_gap: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "pile_bearings_gap"], value: 0, unit: 'm' }),
        tracker_strings_gap: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "strings_gap"], value: 0, unit: 'm' }),
        modules_gap: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "modules_gap"], value: 0, unit: 'm' }),
        tracker_motor_placement: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "motor_placement"], value: 0 }),
        tracker_motor_gap: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "motor_gap"], value: 0, unit: 'm' }),
        modules_row: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "modules_row"], value: "", readonly: false }),
        use_modules_row: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "use_modules_row"], value: false, readonly: false }),
        mounting_module_width: BimProperty.NewShared({ path: ["tracker-frame", "module_mounting", "module_width"], value: 0, unit: 'm' }),
        // slope: BimProperty.NewShared({ path: ["position", "slope_first_to_last"], value: 0, unit: '%' }),
        // slope_direction: BimProperty.NewShared({ path: ["position", "_local-slope-direction"], value: 1 }),
        // segments_slopes: BimProperty.NewShared({ path: ["position", "_segments-slopes"], value: "", readonly: false }),
    };

    const old = instance.properties.getPropsOrDefaults(_oldTrackerPropsType, true);

    let pilesConfig: AnyTrackerProps['tracker_frame']['piles_configurations']; 
    let stringsCount: {value: number};
    let oldPilesCount: number;
    let oldModulesRowsCount: number;

    if (old.use_modules_row.value) {
        const oldModulesRows = getModulesRows(
            old.modules_row.asText(),
        );
        let oldModulesRowText = old.modules_row.asText();
        oldModulesRowText = oldModulesRowText.split('').reverse().join('').trim();
        pilesConfig = new PilesConfigModulesRow({
            modules_row: StringProperty.new({value: oldModulesRowText}),
        });
        oldModulesRowsCount = 0;
        for (const row of oldModulesRows[0]) {
            oldModulesRowsCount += row;
        }
        oldPilesCount = oldModulesRows[0].length;
        const oldStringXCount = old.string_modules_count_x.asNumber();
        const oldStringYCount = old.string_modules_count_y.asNumber();
        const oldStringsCount = Math.floor(oldModulesRowsCount * oldStringYCount / oldStringXCount);

        stringsCount = {value: oldStringsCount};

    } else {
        stringsCount = {value: old.tracker_strings_count.asNumber()};

        const oldStringXCount = old.string_modules_count_x.asNumber();
        const oldMotorMultiplier = old.tracker_motor_placement.asNumber();
        oldPilesCount = ((oldStringXCount * stringsCount.value) / old.tracker_module_bay_size.asNumber()) + 1;
        oldModulesRowsCount = oldStringXCount * stringsCount.value;

        let motorAtPile = KrMath.clamp(
            Math.round(
                oldPilesCount * oldMotorMultiplier
            ),
            1,
            oldPilesCount
        );
        motorAtPile = oldPilesCount - motorAtPile;


        pilesConfig = PropsGroupsRegistry.newPropsGroupFromJsonLikeArgs(PilesConfigModuleBaySize,{
            first_pile_offset: {value: 0},
            module_bay_size: {value: old.tracker_module_bay_size.asNumber()},
            motor_at_pile: {value: motorAtPile},
        });
    };


    const modulePropsJson: any = {};
    for (const prop of instance.properties.getPropStartingWith("module")) {
        if (prop.path.length > 2) {
            const groupName = prop.path[1];
            if (!modulePropsJson[groupName]) {
                modulePropsJson[groupName] = {};
            }
            modulePropsJson[groupName][prop.path.at(-1)!] = {value: prop.value, unit: prop.unit};
        } else {
            modulePropsJson[prop.path.at(-1)!] = {value: prop.value, unit: prop.unit};
        }
    }
    const moduleProps = PropsGroupsRegistry.newPropsGroupFromJsonLikeArgs(ModuleProps, modulePropsJson);

    const commercialPropsJson: any = {};
    for (const prop of instance.properties.getPropStartingWith("tracker-frame | commercial")) {
        commercialPropsJson[prop.path.at(-1)!] = {value: prop.value, unit: prop.unit};
    }
    const commercialProps = PropsGroupsRegistry.newPropsGroupFromJsonLikeArgs(TrackerFrameCommercialProps, commercialPropsJson);

    const oldPilesEmbedment = old.tracker_pile_embedment.as('m');
    const oldPilesReveal = old.tracker_pile_reveal.as('m');
    const pilesLength = oldPilesEmbedment + oldPilesReveal;

    const windLoadPositionPropertyValue = instance.properties.getPropStringValue('position | load_wind_position', "");

    const anyTrackerProps = PropsGroupsRegistry.newPropsGroupFromJsonLikeArgs(AnyTrackerProps, {
        module: moduleProps,
        tracker_frame: {
            commercial: commercialProps,
            dimensions: {
                strings_count: stringsCount,
                modules_gap_x: {value: old.modules_gap.value, unit: old.modules_gap.unit},
                modules_gap_y: {value: old.modules_gap.value, unit: old.modules_gap.unit},
                motor_gap: {value: old.tracker_motor_gap.value, unit: old.tracker_motor_gap.unit},
                pile_bearings_gap: {value: old.tracker_pile_bearings_gap.value, unit: old.tracker_pile_bearings_gap.unit},
                tube_overhang_north: {value: 0, unit: 'm'},
                tube_overhang_south: {value: 0, unit: 'm'},
            },
            string: {
                modules_count_x: {value: old.string_modules_count_x.value},
                modules_count_y: {value: old.string_modules_count_y.value},
            },
            piles_configurations: pilesConfig,
        },
        piles: {
            lengths: new SmallNumericArrayProperty({values: IterUtils.newArray(oldPilesCount, () => pilesLength), unit: 'm'}),
            _pile_tops_distance_to_ground: new SmallNumericArrayProperty({values: IterUtils.newArray(oldPilesCount, () => oldPilesReveal), unit: 'm'}),
        },
        position: {
            wind_load_position: StringProperty.new({value: windLoadPositionPropertyValue}),
        }
    });

    const positionsCompensation = old.tracker_pile_reveal.as('m');
    const localTransform = instance.localTransform.clone();
    localTransform.position.z += positionsCompensation;

    const oldStringsGap = old.tracker_strings_gap.as('m');
    const oldModulesGap = old.modules_gap.as('m');
    if (oldStringsGap > oldModulesGap) {

        const oldTrackerGeometry = trackersPartsCache.acquire({
            moduleBayCount: old.tracker_module_bay_size.asNumber(),
            modulesGap: oldModulesGap,
            moduleSize: old.module_size_x.as('m'),
            modulesPerStringCountHorizontal: old.string_modules_count_x.asNumber(),
            modulesRow: old.modules_row.asText(),
            motorGap: old.tracker_motor_gap.as('m'),
            motorPlacementCoefficient: old.tracker_motor_placement.asNumber(),
            pileGap: old.tracker_pile_bearings_gap.as('m'),
            useModulesRow: old.use_modules_row.asBoolean(),
            stringGap: oldStringsGap,
            stringsPerTrackerCount: stringsCount.value,
        });

        let excessGapCausedByStringsGap = 0;
        
        for (const gap of oldTrackerGeometry._gaps) {
            let excessGap = 0;
            let nonExcessiveGap = 0;
            if (gap & ModulesGapContent.StringGap) {
                excessGap += oldStringsGap;
            }
            if (gap & ModulesGapContent.Motor) {
                nonExcessiveGap = Math.max(nonExcessiveGap, _oldTrackerPropsType.tracker_motor_gap.as('m'));
            }
            if (gap & ModulesGapContent.Pile) {
                nonExcessiveGap = Math.max(nonExcessiveGap, _oldTrackerPropsType.tracker_pile_bearings_gap.as('m'));
            }
            if (excessGap > nonExcessiveGap) {
                excessGapCausedByStringsGap += excessGap - nonExcessiveGap;
            }
        }

        // we need to compensate abscence of string gap in the new tracker
        // by increasing modules gaps for the length of the tracker to stay the same
        // calculation is not perfect, because it doesn't take into account
        // overlaping gaps of strings and piles, but it should be close
        if (excessGapCausedByStringsGap > 0) {
            const modulesGapIncrease = excessGapCausedByStringsGap / (oldModulesRowsCount - 1);
            console.log("Excess gap caused by strings gap", excessGapCausedByStringsGap, "module gap increase", modulesGapIncrease);
            if (modulesGapIncrease > 0 && modulesGapIncrease < Infinity) {
                anyTrackerProps.tracker_frame.dimensions.modules_gap_x = anyTrackerProps.tracker_frame.dimensions.modules_gap_x.with({
                    value: oldModulesGap + modulesGapIncrease,
                    unit: 'm',
                });
            }
        }
    }

    return {
        type_identifier: 'any-tracker',
        props: anyTrackerProps,
        localTransform,
        spatialParentId: instance.spatialParentId,
        colorTint: instance.colorTint,
        name: instance.name,
        hierarchySortKey: instance.hierarchySortKey,
    };
}
