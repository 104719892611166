import type { Bim } from "bim-ts";
import { Failure, LazyVersioned, Success, type Yield } from "engine-utils-ts";
import { PUI_ConfigBasedBuilderParams, PUI_ConfigPropertyTransformer, PUI_PropertyNodeSelector, PUI_PropertyNodeSelectorArgs, type ExportedFileDescription, type FileExporter, type FileExporterContext, type PropertyFitChecker } from "ui-bindings";
import { CommonExportSettings, getExportProjectName, selectExportResolution } from "../CommonExportSettings";
import { convertDem } from "./ConvertTerrain";
import { ProjectInfo, VerdataSyncerType } from "src";
import { unpackGeometries } from "src/tiff/TiffExporter";


export class DemExporter implements FileExporter<CommonExportSettings> {

    constructor(
        readonly bim: Bim,
        readonly projectInfo: LazyVersioned<ProjectInfo | undefined>,
        readonly projectVerdataSyncer: VerdataSyncerType,
    ) {
    }

    initialSettings() {
        return {defaultValue: new CommonExportSettings()};
    }

    *startExport(settings: CommonExportSettings, context: FileExporterContext): Generator<Yield, ExportedFileDescription[]> {
        
        const bimInstancesIds = settings.export_only_selected ?
            this.bim.instances.getSelected() :
            Array.from(this.bim.instances.allIds());

        const {maxSegmentSize, totalArea, representations} = unpackGeometries(this.bim, bimInstancesIds);
        const options = getAvailableOptions(maxSegmentSize, totalArea);

        const resolutionParam = yield * selectExportResolution(context, options, "grid_spacing", maxSegmentSize)
        
        const baseProjectName = getExportProjectName(this.projectVerdataSyncer, this.projectInfo.poll());

        const files = yield* convertDem(this.bim, context.logger, settings, resolutionParam, baseProjectName);

        const result: ExportedFileDescription[] = [];
        for (const fileResult of files) {
            if (fileResult instanceof Success) {
                result.push(fileResult.value)
            } else {
                //todo: notifications
                context.logger.error(fileResult.errorMsg())
            }
        }
        return result;
    }

}


export function getAvailableOptions(maxSegmentSize: number, totalArea: number): Map<number, string> {
    const options = new Map<number, string>();
    const bytesPerPixel =  6;

    for (let i = 1; i <= 4; i *= 2) {
        const pixelSize = maxSegmentSize * i;
        const totalPixels = totalArea / (pixelSize * pixelSize);
        const demFileSizeMB = (totalPixels * bytesPerPixel) / (1024 * 1024);

        let details = `1px = ${pixelSize}m (~${demFileSizeMB.toFixed(1)} MB)`;
        options.set(pixelSize, details);
    }

    return options;
}



