<script lang="ts">
    import { getContext } from "svelte";
    import { IconComponent } from "../libui/icon";
    import { NavbarContext } from "../navbar/Navbar";

    const navbar = getContext<NavbarContext>("ui-navbar");
</script>

<div class="content">
    <p>
        PVFARM Layout generation tool optimally places solar arrays and roads
        within buildable area. Solar arrays and roads define the placement of
        all other elements of PV power station on the later stages of the design
        process.
    </p>
    <ul>
        <li>
            Each MW of solar arrays requires 4-6 ac of buildable area, or
            approximately 25 ac for a typical 5 MW block. These numbers may vary
            based on boundary complexity, block size, and the length of the
            solar array models.
        </li>
        <li>
            Layout generation tool might not be able to place any solar arrays
            at all if the boundaries area is too small.
        </li>
    </ul>
    <div class="images-container mdc-typography--body2-strong text-main-medium">
        <div>
            <img src="static/images/simple-boundaries.png" alt="" />
            <span>Simple boundaries</span>
        </div>
        <div>
            <img src="static/images/complex-boundaries.png" alt="" />
            <span>Complex boundaries</span>
        </div>
        <div>
            <img src="static/images/do-not-fit-boundaries.png" alt="" />
            <span>Boundaries could be too small to fit an array</span>
        </div>
    </div>
    <div class="mdc-typography--headline4">
        Adding buildable area to the project
    </div>
    <p>
        PVFarm has tools to add and edit boundaries, but the primary source of
        the initial boundaries is the <span
            class="link-button mdc-typography--body1-strong"
            on:click={() => {
                navbar.openTab("Add.Import");
            }}
        >
            <span>Import</span><IconComponent name="OpenWindow" />
        </span>:
    </p>
    <ul>
        <li>
            Draw and export polygons that define Buildable area for your PV
            power plant in <span
                class="link-button mdc-typography--body1-strong"
                on:click={() => {
                    window.open("https://earth.google.com/");
                }}
            >
                <span>Google Earth</span><IconComponent name="OpenExternal" />
            </span>.
        </li>
        <li>
            Export data as DXF from AutoCAD/Civil3D or other professional tools.
        </li>
    </ul>
    <p>
        All boundaries in the project are added to the project's buildable area
        by default. Open <span class="mdc-typography--body1-strong"
            >Selected area</span
        > menu to add or remove boundaries from the buildable area.
    </p>
    <div class="images-container mdc-typography--body2-strong text-main-medium">
        <div>
            <img src="static/images/added-boundaries.png" alt="" />
            <span>Added boundaries</span>
        </div>
        <div>
            <img src="static/images/as-buildable-area.png" alt="" />
            <span>Boundaries selected as the Buildable area</span>
        </div>
        <div>
            <img src="static/images/generated-layout.png" alt="" />
            <span>Generated layout</span>
        </div>
    </div>
    <div class="mdc-typography--headline4">Blocked layout generation</div>
    <p>
        Once blocking is enabled the generator will only add solar arrays where
        it's possible to group them into an electrical blocks with specified ILR
        range and row height. No solar arrays will be added within small
        boundaries that can't fit at least one full block.
    </p>
    <p>
        Connect isolated buildable areas via additional extra thin boundaries to
        unite such areas and enable blocking across those areas.
    </p>
    <div class="images-container mdc-typography--body2-strong text-main-medium">
        <div>
            <img src="static/images/layout-with-one-block.png" alt="" />
            <span>One electrical block fits within boundaries</span>
        </div>
        <div>
            <img src="static/images/connected-boundaries.png" alt="" />
            <span>Boundaries connected via extra small patches</span>
        </div>
        <div>
            <img src="static/images/layout-with-several-blocks.png" alt="" />
            <span>Layout generated with more blocks</span>
        </div>
    </div>
    <div class="mdc-typography--headline4">Exclude boundaries</div>
    <p>
        Exclusion boundaries prevents objects and equipment from being generated
        within, just like the gap in the boundaries. Unlike simple gap between
        boundaries, exclusion zones could be used to route MV wires.
    </p>
    <p>
        Exclusion boundaries added to buildable area have a highest priority not
        affected in any way by order or grouping in the model explorer.
    </p>
    <div class="images-container mdc-typography--body2-strong text-main-medium">
        <div>
            <img src="static/images/regular-mv-wires.png" alt="" />
            <span>MV Wires on a regular buildable area</span>
        </div>
        <div>
            <img src="static/images/exclusion-zone.png" alt="" />
            <span>The same area drawn via the exclusion zone</span>
        </div>
        <div>
            <img src="static/images/mv-wires-with-exclusion.png" alt="" />
            <span>MV wires don't cross exclusion boundary</span>
        </div>
    </div>
</div>

<style>
    .images-container {
        display: flex;
        justify-content: space-between;
        margin: 16px 0 40px;
    }
    .images-container div {
        display: flex;
        flex-direction: column;
        width: 128px;
    }
    .images-container span {
        margin-top: 4px;
        margin-left: 8px;
    }
    .content {
        padding: 0 8px;
    }
</style>
