import { Bim } from "bim-ts";
import dayjs from "dayjs";
import { KreoEngine } from "engine-ts";
import { ScopedLogger, Yield, Result, Success, Failure } from "engine-utils-ts";
import jsPDF from "jspdf";
import { Aabb2, Vector2 } from "math-ts";
import { addLatoFonts } from "./fonts/fonts";
import { logoPng } from "./images/pvFarmLogo";
import { LayoutDrawing } from "./LayoutDrawing";
import { ScreenshotMaker } from "./ScreenshotMaker";
import { convertPxToMm } from "./PdfCommon";


export interface PageFrameOffsets {
    leftPartContentWidthRatio: number;
    upOffsetPx: number;
    downOffsetPx: number;
    leftRightOffsetPx: number;
    topContentOffsetPx: number;
}

export interface PdfReportContext{
    page: jsPDF;
    screenshotMaker: ScreenshotMaker;
    layoutDrawing: LayoutDrawing;
    pageFrameOffsets: PageFrameOffsets;
}

export abstract class PdfElement {
    abstract draw(context: Readonly<PdfReportContext>): Generator<Yield, void, unknown>;

    calculateContentSize(pageSize: Vector2, context: Readonly<PdfReportContext>): Aabb2 | undefined {
        return;
    }
}

interface PdfPageParams{
    name: string;
    format: "a3";
    elements: PdfElement[];
    overflow?: boolean;
    isEmpty?: () => boolean;
}

export class PdfReportBuilder {
    private doc: jsPDF;
    private readonly pageFrame: PageFrame;
    private readonly pages: PdfPageParams[];
    private readonly logger: ScopedLogger;

    private readonly _screenshotMaker: ScreenshotMaker;
    private readonly _layoutDrawing: LayoutDrawing;

    constructor(args: { 
        bim: Bim;
        engine: KreoEngine;
        frame: PageFrame;
        logger: ScopedLogger;
     }) {
        this.logger = args.logger;
        this.doc = new jsPDF({
            unit: "mm",
            compress: true,
        });
        this.doc.deletePage(1);
        addLatoFonts(this.doc);
        this.pages = [];
        this.pageFrame = args.frame;

        this._screenshotMaker = new ScreenshotMaker(args.engine, args.bim, this.logger);
        this._screenshotMaker.saveCurrentDisplaySettings();

        this._layoutDrawing = new LayoutDrawing(args.bim, this.logger);
    }

    addPage(params: PdfPageParams) {
        if(!params.isEmpty?.()){
            this.pages.push(params);
        }
        return this;
    }

    *createReport(): Generator<Yield, Result<ArrayBuffer>> {
        try {
            const pageAabb = Aabb2.empty(); 
            const context: PdfReportContext = {
                page: this.doc,
                screenshotMaker: this._screenshotMaker,
                layoutDrawing: this._layoutDrawing,
                pageFrameOffsets: this.pageFrame,
            };
            for (const pageParams of this.pages) {
                let page = this.doc.addPage(pageParams.format, 'landscape');
                if(pageParams.overflow){
                    const totalPageSize = page.internal.pageSize;
                    const pageSize = new Vector2(totalPageSize.getWidth(), totalPageSize.getHeight());
                    pageAabb.setFromPoints([{x: 0, y: 0}, pageSize]);
                    const totalPageSizeAabb = Aabb2.empty();
                    for (const element of pageParams.elements) {
                        const content = element.calculateContentSize(pageSize, context);
                        if(!content) {
                            continue;
                        }
                        totalPageSizeAabb.union(content);
                    }
                    if(!pageAabb.containsBox(totalPageSizeAabb)){
                        const pageInfo = page.getCurrentPageInfo();
                        this.doc.deletePage(pageInfo.pageNumber);
                        const w = Math.max(Math.ceil(totalPageSizeAabb.max.x), pageSize.x);
                        const h = Math.max(Math.ceil(totalPageSizeAabb.max.y), pageSize.y);
                        page = this.doc.addPage([w, h], w > h ? 'l' : 'p');
                    }
                }
                for (const e of pageParams.elements) {
                   yield* e.draw(context);
                }
    
                this.pageFrame.addFrame({page, pageName: pageParams.name, pagesCount: this.pages.length});
                yield Yield.Asap;
            }
            
            const pdfBytes = this.doc.output("arraybuffer");
    
            return new Success(pdfBytes);
        } catch (error) {
            this.logger.error('Error while creating pdf', error);
            return new Failure({msg: 'Error while creating pdf'});
        } finally {
            this._screenshotMaker.restoreDisplaySettings();
        }
    }
}

export class PageFrame implements PageFrameOffsets {
    readonly leftPartContentWidthRatio: number = 0.4;
    readonly upOffsetPx: number = 56;
    readonly downOffsetPx: number = 66;
    readonly leftRightOffsetPx: number = 20;
    readonly topContentOffsetPx: number = 8;

    constructor(
       readonly projectName: string,
       readonly projectVersion: {version: number, modified: boolean},
       readonly catalogVersion: {version: number, modified: boolean},
       readonly companyName: string,
    ) {}

    addFrame({page, pageName, pagesCount}: {page: jsPDF, pageName: string, pagesCount: number}) {
        const pageWidth = page.internal.pageSize.getWidth();
        const pageHeight = page.internal.pageSize.getHeight();
        const textHorOffsetPx = this.leftRightOffsetPx;
        const pageNumber = page.getCurrentPageInfo().pageNumber;

        page.setFont("Lato-Bold", "normal");
        page.setFontSize(24);
        page.setTextColor(0, 0, 0);
        page.text(`${this.projectName.toUpperCase()}, ${pageName}`, convertPxToMm(textHorOffsetPx), convertPxToMm(48), {
            // charSpace:0.72,
        });

        const logoSizePx = new Vector2(144, 40);
        page.addImage(
            logoPng,
            "png",
            pageWidth - convertPxToMm(logoSizePx.x + textHorOffsetPx),
            convertPxToMm(16),
            convertPxToMm(logoSizePx.x),
            convertPxToMm(logoSizePx.y),
            undefined,
            "FAST"
        );

        page.setLineWidth(convertPxToMm(0.5));
        page.setDrawColor(15 / 255, 19 / 255, 20 / 255, 0.7);
        const horOffsetPx = 16;
        page.line(
            convertPxToMm(horOffsetPx),
            convertPxToMm(this.upOffsetPx),
            pageWidth - convertPxToMm(horOffsetPx),
            convertPxToMm(this.upOffsetPx)
        );

        page.stroke();

        page.setLineWidth(convertPxToMm(0.5));
        page.line(
            convertPxToMm(horOffsetPx),
            pageHeight - convertPxToMm(this.downOffsetPx),
            pageWidth - convertPxToMm(horOffsetPx),
            pageHeight - convertPxToMm(this.downOffsetPx)
        );
        page.stroke();


        page.setFont("Lato-Regular", "normal");
        page.setFontSize(10);
        const textFooterOffsetPx = 18;

        page.setTextColor(26 / 255, 30 / 255, 31 / 255, 0.4);
        page.text(
            `Page ${pageNumber} of ${pagesCount}`,
            convertPxToMm(textHorOffsetPx),
            pageHeight - convertPxToMm(textFooterOffsetPx)
        );

        const isProjectModified = this.projectVersion.modified
            ? " (modified)"
            : "";
        const isCatalogModified = this.catalogVersion.modified
            ? " (modified)"
            : "";
        page.text(
            `Project v ${this.projectVersion.version}${isProjectModified}, Catalog v ${this.catalogVersion.version}${isCatalogModified}`,
            convertPxToMm(155),
            pageHeight - convertPxToMm(textFooterOffsetPx)
        );

        const date = dayjs().format('MMM DD, YYYY');
        page.text(
            date,
            convertPxToMm(366),
            pageHeight - convertPxToMm(textFooterOffsetPx)
        );

        const footerMsgOffsetMm = pageWidth * this.leftPartContentWidthRatio;
        const footerMsgMaxWidth = pageWidth - footerMsgOffsetMm - convertPxToMm(textHorOffsetPx);
        page.text(
            `This technical drawing is the proprietary property of ${this.companyName}. It contains sensitive information and trade secrets pertaining to our construction project. Unauthorized sharing, reproduction, or distribution of this drawing, in part or in whole, is strictly prohibited. This drawing is intended solely for the use of authorized personnel involved in the project. Any unauthorized use or disclosure may result in legal action.`,
            footerMsgOffsetMm,
            pageHeight - convertPxToMm(44),
            {
                maxWidth: footerMsgMaxWidth,
                lineHeightFactor: 1.4,
            }
        );
    }
}