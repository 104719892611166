<script lang="ts">
    import ContextMenu from "../../reusable/ContextMenu.svelte";
    import { type ContextMenuConfig, type UiBindings } from "ui-bindings";
    import { onDestroy } from "svelte";
    import { ContextMenuViewsListener } from "./ContextMenuViewsListener";
    import { VersionedStore } from "../../VersionedStore";
    import EngineUiPanelsConstructors from "../../ui-panels/EngineUiPanelsConstructors.svelte";

    export let uiBindings: UiBindings;

    let contextMenuViewsListener = new ContextMenuViewsListener();
    $: {
        contextMenuViewsListener.updateSubscription(uiBindings.contextMenuViews);
    }

    $: contextMenusToShow = new VersionedStore(contextMenuViewsListener.contextMenuViewsToShow);


    onDestroy(() => {
        contextMenuViewsListener.dispose();
    })

    function onClosed(config: ContextMenuConfig){
        config.close();
    }
</script>

{#each $contextMenusToShow as view (view.identity)}
        <ContextMenu
            settings={{
                isMenuOpened: true,
                offsetPx: view.positionPx,
                closeIcon: true,
                menuAction: view.action,
                dispose: () => {
                    onClosed(view)
                },
                zIndex: 900,
                header: view.header,
                widthPx: view.widthPx,
                minWidthPx: view.widthPx ?? 400,
                maxHeightPx: view.maxHeightPx,
            }}
        >
            <div slot="body">
                <EngineUiPanelsConstructors
                    identifier = {view.identity}
                    name = {view.identity}
                    uiNodeDescription = {view.viewSource}
                    close = {undefined}
                    inContextMenu = {true}
                />
            </div>
        </ContextMenu>
        <slot />
{/each}

<style>
</style>
