import type { PileFeaturesFlags } from "bim-ts";
import {
    PileFeaturesAndOffsets,
    PileMotorType,
    unpackPileFeatures,
} from "bim-ts";
import type { PilePositionStateManger, TrackerProps } from "../PilePositions";
import type { ContextMenuConfig, UiBindings } from "ui-bindings";

export class PileLimits {
    private readonly piles: PileFeaturesAndOffsets[];
    private readonly pile: PileFeaturesAndOffsets;
    private readonly positions: number[];

    constructor(
        readonly props: TrackerProps,
        readonly index: number,
        readonly stateManager: PilePositionStateManger
    ) {
        this.piles = props.piles ?? [];
        this.pile = this.piles[index];
        this.positions = props?.pilesOffsetsMeter ?? [];
    }

    get position() { 
        return this.props.pilesOffsetsMeter[this.index];
    }

    get modules() {
        return this.index === 0
            ? this.pile.offset_in_modules ?? 0
            : (this.pile.offset_in_modules ?? 0) -
                  (this.piles[this.index - 1].offset_in_modules ?? 0);
    }

    get modulesMinMax(): [number, number] {
        const range: [number, number] =
            this.index === 0
                ? [0, this.piles[this.index + 1].offset_in_modules ?? 0]
                : [
                      1,
                      this.index === this.piles.length - 1
                          ? this.props.modulesCount - (this.piles[this.index - 1].offset_in_modules ?? 0)
                          : (this.piles[this.index + 1].offset_in_modules ?? 0) 
                          - (this.piles[this.index - 1].offset_in_modules ?? 0),
                ];
        return range;
    }

    get shiftMinMax(): [number, number] { 
        const offsetMax =
        this.pile.motor === PileMotorType.Motor
            ? this.props.modulesGapOnMotorPiles.as("m") / 2
            : this.props.modulesGapOnOtherPiles.as("m") / 2;
        return [-offsetMax, offsetMax];
    }

    get maxEdgeOffset(): number {
        return 0;
    }

    get offsetMinMax(): [number, number] { 
        const min = this.maxEdgeOffset;
        const max = this.index === this.positions.length - 1 
            ? this.props.trackerLength.as("m") - this.maxEdgeOffset
            : this.positions[this.index + 1] - this.maxEdgeOffset;
        return [min, max];
    }

    get positionMinMax(): [number, number] {
        const offsetMax = this.maxEdgeOffset;
        const totalLength = this.props.trackerLength.as("m");
        // if (this.props.isRelativePilePositions) {
        //     const absolutePosition = this.positions[this.index];
        //     const offset = this.pile.offset_in_meters;
        //     const withoutOffsetPos = absolutePosition - offset;

        //     const min = this.index === 0 && this.pile.offset_in_modules === 0
        //             ? offsetMax
        //             : withoutOffsetPos - offsetMax;
        //     const max = this.index === this.positions.length - 1
        //             ? Math.min(
        //                   withoutOffsetPos + offsetMax,
        //                   totalLength - offsetMax
        //               )
        //             : withoutOffsetPos + offsetMax;

        //     return [min, max];
        // } 
        const prevPile = this.piles[this.index - 1];
        const prevPileOffset = prevPile?.motor === PileMotorType.Motor 
            ? this.props.modulesGapOnMotorPiles.as("m") 
            : this.props.modulesGapOnOtherPiles.as("m");
        const nextPile = this.piles[this.index + 1];
        const nextPileOffset = nextPile?.motor === PileMotorType.Motor 
            ? this.props.modulesGapOnMotorPiles.as("m") 
            : this.props.modulesGapOnOtherPiles.as("m");
        const min = this.index === 0 
            ? offsetMax 
            : this.positions[this.index - 1] + prevPileOffset / 2;
        const max = this.index === this.positions.length - 1
            ? totalLength - offsetMax
            : this.positions[this.index + 1] - nextPileOffset / 2;

        return [min, max];
    }

    updatePosition(offset: number) {
        if (this.props.isRelativePilePositions) {
            const pos = this.positions[this.index];
            const offsetRelative = offset - pos;
            this.updatePile({
                offset_in_meters: this.pile.offset_in_meters + offsetRelative,
            });
        } else {
            this.updatePile({ offset_in_meters: offset });
        }
    }

    updatePile(pile: Partial<PileFeaturesAndOffsets>) {
        const piles = this.props.piles?.slice();
        if (!piles) {
            return;
        }
        piles[this.index] = new PileFeaturesAndOffsets({
            ...this.pile,
            ...pile,
        });

        this.stateManager.applyPatch({ piles });
    }

    updateModulesOffset(offset: number) {
        const prevModuleOffset =
            this.piles[this.index - 1]?.offset_in_modules ?? 0;
        this.updatePile({ offset_in_modules: prevModuleOffset + offset });
    }

    updateFeatures(features: PileFeaturesFlags) {
        const unpackedFeatures = unpackPileFeatures(features);
        this.updatePile({
            motor: unpackedFeatures.motor,
            weight_class: unpackedFeatures.weight_class,
            modifier: unpackedFeatures.modifier,
            damper: unpackedFeatures.damper,
            undulation: unpackedFeatures.undulation,
        });
    }
}

export class PileOffsetContextMenu {
    private _openedMenus: (() => void)[] = [];
    constructor(readonly ui: UiBindings) {}

    open(config: ContextMenuConfig) {
        this.dispose();

        this.ui.addContextMenuView(config);
        this._openedMenus.push(() => { config.close(); });
    }

    dispose() {
        for (const cb of this._openedMenus) {
            cb();
        }
    }
}
