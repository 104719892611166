import type {
    BimPropertyData, Boundary2DDescription, Catalog, CatalogItemId, Config, DC_CNSTS,
    FarmLayoutConfig,
    IdBimGeo, IdBimScene, IdConfig, IdInEntityLocal, LocalIdsEdge,
    MathSolversApi,
    RepresentationBase,
    SceneInstance, SegmentInterp,
    SiteArea,
    SolarArrayConfig
} from 'bim-ts';
import {
    BasicAnalyticalRepresentation,
    Bim,
    BimPatch,
    BimProperty,
    BooleanProperty,
    BoundaryType,
    calculateStdRepresentationLocalBBox,
    CombinerBoxTypeIdent,
    FixedTiltTypeIdent,
    GraphGeometry,
    InverterTypeIdent,
    LocalIdsCounter, PolylineGeometry,
    PropertiesCollection,
    RoadTypeIdent,
    SceneInstances,
    SceneInstancesProperty,
    SegmentInterpLinearG,
    TrackerTypeIdent,
    TransformerIdent,
    trimRoadByBoundary
} from 'bim-ts';
import type { Aabb } from 'math-ts';
import { Aabb2, Clipper, KrMath, Matrix4, PointsInPolygonChecker, Segment2, Transform, Vector2, Vector3 } from 'math-ts';

import { PropsFieldFlags } from 'bim-ts';
import type { TasksRunner } from 'engine-utils-ts';
import { DefaultMap, Failure, IterUtils, LegacyLogger, ObjectUtils, PollablePromise, ScopedLogger, Success, Yield } from 'engine-utils-ts';
import type { UiBindings } from 'ui-bindings';
import { GroupedNotificationGenerator, NotificationDescription, NotificationType } from 'ui-bindings';
import { runAugmentSolvers } from '../augmentation/AugumentationLayout';
import { createDebugBoxes } from '../DebugUtils';
import { getTransformedBBox2AndCenter } from '../LayoutUtils';
import { notificationSource } from '../Notifications';
import { getAssets } from '../panels-config-ui/GeneratePanelUiBindings';
import { PointsSet } from '../PointsSet';
import { setTrackerWindPositions } from '../tracker-wind-position/TrackerWindPositionService';
import { calculateTrackerDimensions } from '../TrackerCommon';
import { deleteWiresByParents } from '../wiring/WiringService';
import { createVirtualZoneFromBoundaries } from './EquipmentBoundariesCreator';
import { getInstances } from './FarmLayoutUi';
import type {
    BlockData, Contour, InverterData, LayoutInput, ObjectPosition, RoadDef,
    TrackerData, TransformerData
} from './LayoutAlgorithms';
import {
    EquipmentRoadsOption,
    getLayout,
    SupportRoadsOption
} from './LayoutAlgorithms';
import type { RoadSideType } from './LayoutSolversTypes';


type PatternName = DC_CNSTS.PatternName;

const roadSiteValues = new Map<RoadsideNames, RoadSideType>([['north', 1], ['south', -1], ['optimize', 0]]);
export type RoadsideNames = 'north'| 'south' | 'optimize';

export interface AssetData {
    instance: SceneInstance;
}
export interface FarmConfigWizard {
    substationId: IdBimScene;
    selectedAreaIndex: number;
    toDeleteIds: IdBimScene[];
    boundaries: Boundary2DDescription[];
    
    selectedPattern: PatternName | "";
    trackers: AssetData[];
    equipmentRoadsOption: EquipmentRoadsOption;
    supportRoadsOption: SupportRoadsOption;
    globalSupportRoads:boolean;
    roadStepMeters:number;
    roadsIds: IdBimScene[];
    combinerBox: AssetData;
    blocksEquipment: BlockEquipmentInput[];
    pixelHeight: number;
    alignArrays: boolean;
    rowToRowSpaceMeter: number;
    equipmentGlassToGlassMeter: number;
    supportGlassToGlassMeter: number;
    equipmentRoadWidthMeter: number;
    supportRoadWidthMeter: number;
    colorizeBlocks: boolean;
    necMultiplier: number;
    crossRoad: boolean;
    outerOffsetMeter:number;
    inverterOffsetMeter: number;
    transformerOffsetMeter: number;
    trackerOffsetMeter: number;
    blockOffsetMeter: number;
    roadSide: RoadsideNames;
    shiftDeg: number | undefined;
    angleDeg: number;
    targetPowerW: number | undefined;
    find_max_power: boolean,
    invertersIgnore: boolean,
    find_max_r2r: boolean,
}

export interface BlockEquipmentInput {
    transformer: AssetData;
    inverter: AssetData;

    ilr_min: number;
    ilr_max: number;
    number_of_blocks?: number;
    number_of_inverters?: number;
}

export async function arrangeLayout(
    selectedConfig: Config,
    configId: IdConfig,
    bim: Bim,
    catalog: Catalog,
    mathSolversApi: MathSolversApi,
    tasksRunner: TasksRunner,
    uiBindings: UiBindings,
): Promise<LayoutEquipment> {
    const logger = new ScopedLogger('layout-inscriber');

    const layouts = await runAlgorithm({
        selectedConfig,
        configId,
        bim,
        catalog,
        mathSolversApi,
        tasksRunner,
        uiBindings,
        logger,
    });

    return layouts;
}

async function runAlgorithm(args: {
    selectedConfig: Config,
    configId: IdConfig,
    bim: Bim,
    catalog: Catalog,
    mathSolversApi: MathSolversApi,
    tasksRunner: TasksRunner;
    uiBindings: UiBindings;
    logger: ScopedLogger;
}): Promise<LayoutEquipment> {
    const TIMEOUT = 600_000;
    let farmLayout: LayoutEquipment;
    const notificationGroup = new GroupedNotificationGenerator('Generating Layout');
    
    const config = args.selectedConfig.get<FarmLayoutConfig>();
    const selectedAreaIndex = config.selected_area.value;
    const selectedArea = config.site_areas[selectedAreaIndex];
    const substationId = args.selectedConfig.connectedTo;
    const assets = getAssets(args.catalog);
    
    try {
        const boundaries = await args.tasksRunner.newLongTask<Boundary2DDescription[]>({
            defaultGenerator: createVirtualZoneFromBoundaries(
                args.bim, config, config.selected_area.value
            ),
        }).asPromise();
        const trackers = await args.tasksRunner.newLongTask({
            defaultGenerator: generateTrackersFromSolarArray(
                assets.perCatalogItems,
                selectedArea.settings.electrical.solar_arrays
            ),
        })
        .asPromise();

        const input = ObjectUtils.deepFreeze(
            convertInput(selectedArea, substationId, boundaries, trackers, selectedAreaIndex, args.bim, args.catalog)
        );

        const excludeIds = new Set([
            substationId,
            ...boundaries.map((p) => p.bimObjectId),
            ...selectedArea.settings.roads.roads.value,
        ]);
        
        if (input.equipmentRoadsOption === EquipmentRoadsOption.UseExisting 
            || input.supportRoadsOption === SupportRoadsOption.UseExisting
        ) {
            for (const edge of input.roads.edges) {
                excludeIds.add(edge.id);
            }
        }
        const mainParentIds = [substationId];

        const toDeleteIds: IdBimScene[] = [];
        if (selectedArea.boundaries instanceof SceneInstancesProperty) {
            for (const area of config.site_areas) {
                if(area.equipmentBoundaries instanceof SceneInstancesProperty){
                    IterUtils.extendArray(toDeleteIds, area.equipmentBoundaries.value);
                }
            }
            for (const id of args.bim.instances.spatialHierarchy.iteratorOfChildrenOf(substationId)) {
                toDeleteIds.push(id);
            }
        }

        deleteInstances(
            mainParentIds,
            excludeIds,
            toDeleteIds,
            new Set([
                TrackerTypeIdent, 
                FixedTiltTypeIdent, 
                'any-tracker',
                CombinerBoxTypeIdent,
                InverterTypeIdent, 
                TransformerIdent, 
                RoadTypeIdent, 
                'wire', 
                'lv-wire',
                'trench', 
                'sectionalizing-cabinet'
            ]),
            new Set(['trench', 'wire', 'sectionalizing-cabinet']),
            input.contours,
            config.selected_area.value,
            args.bim,
        );
        const task = args.tasksRunner.newLongTask({
            defaultGenerator: _runAlgorithm({
                input,
                colorize: selectedArea.settings.electrical.colorize.value,
                bim: args.bim,
                mathSolversApi: args.mathSolversApi,
                logger: args.logger,
                mainParentId: substationId,
            }),
            taskTimeoutMs: TIMEOUT,
        });


        args.uiBindings.addNotification(
            notificationGroup.addRootNotification(
                NotificationDescription.newWithTask({
                    source: notificationSource,
                    key: 'generateLayout',
                    taskDescription: { 
                        task,
                        resultHeaderGetter: (result) => result instanceof Success 
                            ?  'Generating layout done' 
                            : 'Layout generation failed'
                    },
                    type: NotificationType.Info,
                    addToNotificationsLog: true
                })
            )
        );

        farmLayout = await task.asPromise();
        if(farmLayout.equipment.length === 0){
            args.uiBindings.addNotification(
                notificationGroup.addNotification(
                    NotificationDescription.newBasic({
                        type: NotificationType.Error,
                        source: notificationSource,
                        key: 'noFeasibleOptions',
                        removeAfterMs: 5_000,
                        addToNotificationsLog: true
                    })
                )
            );
        } else if (input.equipmentRoadsOption === EquipmentRoadsOption.UseExisting && !input.invertersIgnore) {
            await runAugmentSolvers(
                substationId, 
                {
                    capacity: {
                        ilr_range: selectedArea.settings.capacity.ilr_range,
                    },
                    offsets: {
                        transformer_offset: selectedArea.settings.offsets.transformer_offset,
                        inverter_offset: selectedArea.settings.offsets.inverter_offset,
                        combiner_box_offset: selectedArea.settings.offsets.combiner_box_offset,
                    },
                    equipment_roads: {
                        selected_roads: selectedArea.settings.roads.roads,
                    },
                    electrical: {
                        combiner_box: selectedArea.settings.electrical.combiner_box,
                        blocks_equipment: selectedArea.settings.electrical.blocks_equipment,
                        scheme: selectedArea.settings.electrical.scheme,
                        nec_multiplier: selectedArea.settings.electrical.nec_multiplier,
                        colorize: selectedArea.settings.electrical.colorize,
                        generate_new_blocks: BooleanProperty.new({
                            value: true,
                        }),
                        solver: BooleanProperty.new({
                            value: false,
                        }),
                    },
                },
                [args.configId, config], 
                args.bim, 
                args.catalog, 
                args.mathSolversApi, 
                args.tasksRunner, 
                args.uiBindings, 
                notificationGroup
            );
        }
    } catch (e) {
        args.uiBindings.addNotification(
            notificationGroup.addNotification(
                NotificationDescription.newBasic({
                    type: NotificationType.Error,
                    source: notificationSource,
                    key: 'someErrorHappen',
                    removeAfterMs: 5_000,
                    addToNotificationsLog: true,
                    descriptionArg: e.toString(),
                })
            )
        );
        throw new Error(e);
    }
    return farmLayout;
}

export interface LayoutEquipment{
    equipment: SceneInstanceDescription[];
    roads: SceneInstanceDescription[];
    max_r2r_result: number | undefined;
}
export interface SceneInstanceDescription{
    id: IdBimScene;
    type: string;
}

function* _runAlgorithm(args:{
    input: LayoutInput,
    colorize: boolean,
    bim: Bim,
    mathSolversApi: MathSolversApi,
    logger: ScopedLogger,
    mainParentId: IdBimScene,
}): Generator<Yield, LayoutEquipment> { 

    const layout = yield* _arrangeLayout(args);

    yield* setTrackerWindPositions(args);

    return layout;
}

function* _arrangeLayout({input, colorize, bim, mainParentId, logger, mathSolversApi}:{
    input: LayoutInput,
    colorize: boolean,
    bim: Bim,
    mathSolversApi: MathSolversApi,
    logger: ScopedLogger,
    mainParentId: IdBimScene,
}): Generator<Yield, LayoutEquipment> {
    const pr = getLayout(input, mathSolversApi);
    const layoutResult = yield* PollablePromise.generatorWaitFor(pr);

    if (layoutResult instanceof Failure) {
        const message = `layout fetch error` + layoutResult.errorMsg();
        logger.error(message);
        throw new Error(message);
    }
    const debug = false;
    if(debug){
        createDebugBoxes({boxes: input.contours.map(c => c.contour), bim, mainParentId });
    }

    const farmLayout = layoutResult.value;

    const allObjectsToAlloc: ObjectPosition[] = [];
    IterUtils.extendArray(allObjectsToAlloc, farmLayout.layout.transformers);
    IterUtils.extendArray(allObjectsToAlloc, farmLayout.layout.centralInverters);
    IterUtils.extendArray(allObjectsToAlloc, farmLayout.layout.combinerBoxes);
    IterUtils.extendArray(allObjectsToAlloc, farmLayout.layout.stringInverters);
    IterUtils.extendArray(allObjectsToAlloc, farmLayout.layout.trackers);

    const idsProvider = bim.instances.idsProvider;

    const sceneInstancesToAlloc: [IdBimScene, Partial<SceneInstance>][] = [];

    const objectsPositions = new Map<IdBimScene, Matrix4>();
    const indexToIdRemap = new Map<number, IdBimScene>();

    const reusableMatrix1 = new Matrix4();
    function addPropertiesToInstance(properties: PropertiesCollection){
        const path = ["circuit", "position", "area_index"];
        const patches: [string, BimProperty | Partial<BimPropertyData> | null][] = [];
        patches.push([
            BimProperty.MergedPath(path),
            { path: path, value: input.selectedAreaIndex, numeric_step: 1, readonly: true, }
        ]);
        properties.applyPatch(patches);
    }

    function addClonedSceneInstanceWith(
        stateSource: SceneInstance,
        newPosition: Vector3,
        solverIndex: number,
        solverParentIndex: number | undefined,
        label: number | undefined,
    ): IdBimScene | 0 {
        if (stateSource == undefined) {
            throw new Error(`unexpected absence of bim instance ${stateSource}`);
        }

        const newId = idsProvider.reserveNewId();
        let objectWorldMatrix = new Matrix4().setPositionV(newPosition);

        objectsPositions.set(newId, objectWorldMatrix);
        indexToIdRemap.set(solverIndex, newId);

        const localTransform = new Transform();
        let bimParentId: IdBimScene | 0 = 0;
        if (solverParentIndex != undefined) {
            bimParentId = indexToIdRemap.get(solverParentIndex)!;
            const parentMatrix = objectsPositions.get(bimParentId);
            if (!parentMatrix) {
                LegacyLogger.deferredError(`unexpected parent matrix absence`, bimParentId);
                return 0;
            }
            reusableMatrix1.getInverse(parentMatrix).premultiply(objectWorldMatrix);

            localTransform.setFromMatrix4(reusableMatrix1);
        } else if (mainParentId) {
            const substationMatrix = bim.instances.peekById(mainParentId)?.worldMatrix;
            bimParentId = mainParentId;
            const parentMatrix = substationMatrix;
            if (!parentMatrix) {
                LegacyLogger.deferredError(`unexpected parent matrix absence`, bimParentId);
                return 0;
            }
            reusableMatrix1.getInverse(parentMatrix).premultiply(objectWorldMatrix);
            localTransform.setFromMatrix4(reusableMatrix1);
        } else {
            localTransform.setFromMatrix4(objectWorldMatrix);
        }


        const properties = stateSource.properties.clone();
        const props = stateSource.props.cloneWithoutFlags(PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization);

        properties.applyPatch([
            ['circuit | position | block_number', null],
        ]);
        if (stateSource.type_identifier === TransformerIdent){
            const path = ['circuit', 'pattern', 'type'];
            properties.applyPatch([[
                BimProperty.MergedPath(path),
                { path: path, value: input.pattern }
            ]]);
        }
        const block_label_path = ["circuit", "position", "block_label"];
        if(label){
            properties.applyPatch([[
                BimProperty.MergedPath(block_label_path),
                { path: block_label_path, value: label, numeric_step: 1, readonly: true, }
            ]]);
        } else {
            properties.applyPatch([[BimProperty.MergedPath(block_label_path), null]]);
        }

        addPropertiesToInstance(properties);

        const clonedState: Partial<SceneInstance> = {
            flags: stateSource.flags,

            type_identifier: stateSource.type_identifier,
            name: stateSource.name,
            properties,
            props,

            spatialParentId: bimParentId ? bimParentId : undefined,

            localTransform,
        };

        sceneInstancesToAlloc.push([newId, clonedState]);
        return newId;
    }
    function rotate(pos: Vector2): Vector2 {
        const rotated = new Vector2(pos.x, pos.y).rotateAround(input.basePos, -KrMath.degToRad(input.angleDeg));
        return rotated;
    }
    const newIds: [IdBimScene, Matrix4][] = [];
    const layoutEquipment: LayoutEquipment = { 
        equipment: [], 
        roads: [], 
        max_r2r_result: farmLayout.max_r2r_result 
    };
    for (const obj of allObjectsToAlloc) {
        const rotated = rotate(obj.position);
        const vector = new Vector3(rotated.x, rotated.y, 0);
        const newId = addClonedSceneInstanceWith(obj.src, vector, obj.id, obj.parentId, obj.label);
        layoutEquipment.equipment.push({id: newId, type: obj.type});
        if (newId) {
            const rotateAngle =(-KrMath.degToRad(input.angleDeg) + KrMath.degToRad(obj.rotateDeg));
            newIds.push([newId, new Matrix4().makeRotationZ(rotateAngle).setPositionV(vector)]);
        }
    }

    yield Yield.NextFrame;

    const ALLOCATION_SIZE = 3000;
    logger.debug(`count of instances to allocate - ${sceneInstancesToAlloc.length}`);
    for (const objectsChunk of IterUtils.splitArrayIntoChunks(sceneInstancesToAlloc, ALLOCATION_SIZE)) {
        bim.instances.allocate(objectsChunk);
        yield Yield.NextFrame;
        yield Yield.NextFrame;
    }

    if (colorize && mainParentId) {
        bim.instances.colorizeHierarchiesOf([mainParentId]);
    }

    yield* rotateEquipment(newIds, bim, logger);

    if(input.equipmentRoadsOption === EquipmentRoadsOption.Generate || input.supportRoadsOption === SupportRoadsOption.Generate){
        const rotatedRoads: RoadDef[] = [];
        for (const road of farmLayout.layout.roads) {
            rotatedRoads.push({
                width: road.width, 
                polyline: [ rotate(road.polyline[0]), rotate(road.polyline[1]) ]
            });
        }
        const roads = yield* _arrangeRoads(rotatedRoads, bim, mainParentId, addPropertiesToInstance);
        IterUtils.extendArray(layoutEquipment.roads, roads);
    }

    logger.debug('allocating finished');
    return layoutEquipment;
}

function* rotateEquipment(patches:[IdBimScene, Matrix4][], bim: Bim, logger: ScopedLogger){
    const ALLOCATION_SIZE = 5000;
    logger.debug(`count of instances to rotate - ${patches.length}`);

    for (const patchChunk of IterUtils.splitArrayIntoChunks(patches, ALLOCATION_SIZE)) {
        bim.instances.patchWorldMatrices(new Map<IdBimScene, Matrix4>(patchChunk), {});
        yield Yield.NextFrame;
    }
}

function deleteInstances(
    parents: IdBimScene[], 
    excludeIds: Set<IdBimScene>, 
    toDeleteIds: IdBimScene[],
    canBeDeleteTypes: Set<string>,
    mustBeDeleteTypes: Set<string>,
    contours: Contour[],
    subAreaIdx: number,
    bim: Bim
    ) {
    deleteWiresByParents(parents, bim, Array.from(mustBeDeleteTypes));
    const excludeIdsSet = new Set(excludeIds);
    const deleteIdsSet = new Set<IdBimScene>();
    for (const id of toDeleteIds) {
        const typeIdnt = bim.instances.peekTypeIdentOf(id);
        if(!typeIdnt || canBeDeleteTypes.has(typeIdnt)){
            deleteIdsSet.add(id);
        }
    }

    for (const parent of parents) {
        bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(
            parent, 
            (id) => {
                if(excludeIdsSet.has(id)){
                    return true
                }
                const inst = bim.instances.peekById(id);
                if(!inst){
                    return true;
                }
                if(mustBeDeleteTypes.has(inst.type_identifier)){
                    deleteIdsSet.add(id);
                    return true
                }
                const prop = inst.properties.get('circuit | position | area_index')?.asNumber();
                if(prop === subAreaIdx && canBeDeleteTypes.has(inst.type_identifier)){                     
                    deleteIdsSet.add(id);
                }
                return true;
            }, 
            true
        );
    }

    for (const id of excludeIdsSet) {
        deleteIdsSet.delete(id);
    }

    bim.instances.delete(Array.from(deleteIdsSet));


    deleteByPolygons(parents, contours, excludeIdsSet, canBeDeleteTypes, bim);
}

function deleteByPolygons(
    parents: IdBimScene[], 
    contours: Contour[], 
    excludeIds: Set<IdBimScene>, 
    canBeDeleteTypes: Set<string>, 
    bim: Bim
    ) {
    const goemetriesAabbs = bim.allBimGeometries.aabbs.poll();
    const reprsBboxes = new DefaultMap<RepresentationBase, Aabb>(r => r.aabb(goemetriesAabbs));
    const contourByBBox = IterUtils.filterMap(contours, (c) => {
        if(c.include || c.equipmentBoundary){
            return {
                contour: c.contour,
                bBox: Aabb2.empty().setFromPoints(c.contour),
                equipmentBoundary: c.equipmentBoundary,
            }
        }
        return;
    });
    const allContours = IterUtils.filterMap(bim.extractBoundaries(), (c) => {
        if(c.boundaryType === BoundaryType.Include && c.pointsWorldSpace.length > 2){
            return {
                contour: c.pointsWorldSpace,
                bBox: Aabb2.empty().setFromPoints(c.pointsWorldSpace)
            }
        }
        return;
    });
    const { contoursSegments, pointsInContourChecker } = unpackContoursForRoadsTrim(
        contours.filter(c => c.include).map(c => c.contour),
        contours.filter(c => !c.include).map(c => c.contour)
    );

    const includeBoundaries = contourByBBox.filter(c => !c.equipmentBoundary);
    const eqBoundaries = contourByBBox.filter(c => c.equipmentBoundary);

    const instances = bim.instances.readAll();

    const idsToDelete: IdBimScene[] = [];
    function addToDelete(id: IdBimScene, type: string){
        if(excludeIds.has(id) || !canBeDeleteTypes.has(type)){
            return;
        }
        idsToDelete.push(id);
    }
    const bimPatch = new BimPatch();
    const reused = Aabb2.empty();
    for (const [id, instance] of instances) {
        if (excludeIds.has(id)) {
            continue;
        }
        const instanceContours: Vector2[][] = [];
        if(instance.representation){  
            const aabb = reprsBboxes.getOrCreate(instance.representation);
            if(aabb.isEmpty()){
                continue;
            }
            const [bBox] = getTransformedBBox2AndCenter(aabb, instance);
            instanceContours.push(bBox);
        } else if (instance.representationAnalytical) {
            const geomId = instance.representationAnalytical?.geometryId ?? 0;
            const geom = bim.allBimGeometries.peekById(geomId);
            if (geom instanceof PolylineGeometry) {
                if(geom.points3d.length === 0){
                    addToDelete(id, instance.type_identifier);
                    continue;
                }
                const points = Vector3.arrayFromFlatArray(geom.points3d)
                    .map(v => v.applyMatrix4(instance.worldMatrix).xy());
                for (let i = 0; i < points.length - 1; i++) {
                    instanceContours.push(makeBBox(points[i], points[i+1], 0.5));
                }
            } else if(geom instanceof GraphGeometry){
                if(geom.points.size === 0){
                    addToDelete(id, instance.type_identifier);
                    continue;
                }

                const trimmedRoads = trimRoadByBoundary(geom, instance.worldMatrix, contoursSegments, pointsInContourChecker);
                if (trimmedRoads === false 
                    || (trimmedRoads instanceof GraphGeometry && trimmedRoads.calcAabb().getSize().length() < 0.1)) {
                    addToDelete(id, instance.type_identifier);
                } else if (trimmedRoads !== true) {
                    bimPatch.geometries.toPatch.push([geomId, trimmedRoads]);
                }
                continue;
            }
        } else {
            continue;
        }
        const bBox = reused.setFromPoints(instanceContours.flatMap(c => c));
        const intersectEqContours = eqBoundaries.filter(c => c.bBox.intersectsBox2(bBox));
        if (intersectEqContours.some((c) => isSomePointInsidePolygon(instanceContours, c.contour))) {
            continue;
        }
        const intersectIncludeContours = includeBoundaries.filter(c => c.bBox.intersectsBox2(bBox));
        if(intersectIncludeContours.some((c) => isSomePointInsidePolygon(instanceContours, c.contour))){
            addToDelete(id, instance.type_identifier);
        }
    }
    IterUtils.extendArray(bimPatch.instances.toDelete, idsToDelete);
    bimPatch.applyTo(bim);
}

function makeBBox(start: Vector2, end: Vector2, width: number){
    const dir = end.clone().sub(start).normalize();
    const normal = new Vector2(-dir.y, dir.x).addScalar(width);
    const bbox = [
        start.clone().add(normal.clone().negate()),
        start.clone().add(normal),
        end.clone().add(normal),
        end.clone().add(normal.clone().negate()),
    ];
    return bbox;
}

function isSomePointInsidePolygon(instanceContours: Vector2[][], polygon: Vector2[]): boolean {
    for (const contour of instanceContours) {
        if (Clipper.intersectsPolygons(contour, polygon)) {
            return true;
        }
    }
    return false;
}

function convertInput(
    selectedArea: SiteArea, 
    substationId: IdBimScene, 
    boundaries: Boundary2DDescription[], 
    trackers: SceneInstance[],
    selectedAreaIndex: number,
    bim: Bim, 
    catalog: Catalog
): LayoutInput {
    const settings = selectedArea.settings;
    const assets = getAssets(catalog);

    const shiftDeg = settings.roads.equipment_roads_angle.selectedOption === 'auto'
        ? undefined
        : -settings.roads.equipment_roads_angle.as('deg') + settings.roads.tracker_angle.as('deg');
    const angleDeg = settings.roads.tracker_angle.as('deg');
    const blocksEquipment = settings.electrical.blocks_equipment.map(b => ({
        transformer:{instance: getInstances(assets, b.transformer.value)[0]},
        inverter: {instance: getInstances(assets, b.inverter.value)[0]},

        ilr_min: b.ilr_range.value[0],
        ilr_max: b.ilr_range.value[1],
        number_of_blocks: b.number_of_inverters.selectedOption === 'set' ? b.number_of_inverters.value : undefined,
        number_of_inverters: b.inverters_per_block.selectedOption === 'set' ? b.inverters_per_block.value : undefined,
    }));

    const [blockCases, max_length] = convertBlocks(blocksEquipment, bim);
    const angleRad = KrMath.degToRad(angleDeg);
    const basePos = new Vector2(0, 0);
    const contours = convertContours(substationId, boundaries, basePos, angleRad, bim);
    const combinerBox = getInstances(assets, settings.electrical.combiner_box.value);
    if (!combinerBox) throw new Error(`No combiner box found with id: ${settings.electrical.combiner_box.value}`);
    const equipment_offset = settings.offsets.tracker_offset.as('m');
    const roads = convertRoads(bim.instances.peekByIds(settings.roads.roads.value), bim, basePos, angleRad);
    const equipmentGlassToGlass = settings.spacing.equipment_glass_to_glass.as('m');
    const supportGlassToGlass = settings.spacing.support_glass_to_glass.as('m');

    const input: LayoutInput = {
        contours,
        selectedAreaIndex,
        pattern: settings.electrical.scheme.value as DC_CNSTS.PatternName,
        rowToRowSpace: settings.spacing.row_to_row_space.as('m'),
        equipmentRoadWidth: settings.roads.equipment_road_width.as('m'),
        supportRoadWidth: settings.roads.support_road_width.as('m'),
        equipmentGlassToGlass: equipmentGlassToGlass - equipment_offset > 0 ? equipmentGlassToGlass - equipment_offset : 0.01,
        supportGlassToGlass: supportGlassToGlass - equipment_offset > 0 ? supportGlassToGlass - equipment_offset : 0.01,
        heightPreference: settings.spacing.max_row_height.value,
        combinerBox: {
            src: combinerBox[0],
            current: combinerBox[0].properties.get("input | current")?.as("A")!,
        },
        blockCases,
        trackers: convertTrackers(trackers.map(t => ({ instance: t}))),
        max_length,
        necMultiplier: settings.electrical.nec_multiplier.value,
        crossRoad: settings.electrical.road_sharing.value,
        outerOffset: settings.offsets.combiner_box_offset.as('m'),
        shiftTan: shiftDeg !== undefined ? Math.tan(KrMath.degToRad(shiftDeg)) : undefined,
        road_side: roadSiteValues.get(settings.roads.transformer_roadside.value as RoadsideNames)!,
        inverter_offset: settings.offsets.inverter_offset.as('m'),
        transformer_offset: settings.offsets.transformer_offset.as('m'),
        equipmentOffset: equipment_offset,
        targetValue: settings.capacity.total_dc_power.selectedOption === 'target' 
            ? settings.capacity.total_dc_power.as("W")
            : undefined,
        angleDeg,
        shiftDeg,
        basePos,
        equipmentRoadsOption: settings.roads.equipment_roads_options.value.startsWith('Generate') 
            ? EquipmentRoadsOption.Generate 
            : EquipmentRoadsOption.UseExisting,
        supportRoadsOption: settings.roads.support_roads_options.value.startsWith('Generate') 
            ? SupportRoadsOption.Generate 
            : settings.roads.support_roads_options.value.startsWith('Ignore') 
                ? SupportRoadsOption.Ignore 
                : SupportRoadsOption.UseExisting,
        globalSupportRoads: settings.roads.global_ns_roads.value,
        roadStep: Math.ceil(settings.roads.roads_step.as('m')),
        roads: roads,
        block_offset: settings.offsets.block_offset.as('m'),
        find_max_power: settings.capacity.total_dc_power.selectedOption === 'find max',
        invertersIgnore: settings.capacity.number_of_blocks.selectedOption === 'ignore',
        find_max_r2r: settings.spacing.row_to_row_space.selectedOption === 'find max',
        alignArrays: settings.spacing.align_arrays.value,
    };

    return input;
}

export function convertRoads(roadsInstances: Iterable<[IdBimScene, SceneInstance]>, bim: Bim, center?: Vector2, angle?: number){
    const edges: {id: IdBimScene, fst: number, snd: number, width: number, length: number }[] = [];
    const points = new PointsSet();
    for (const [id, inst] of roadsInstances) {
        if(inst.type_identifier !== RoadTypeIdent){
            continue;
        }
        const width = inst.properties.get("road | width")?.as("m")
            ?? inst.properties.get("width")?.as("m")
            ?? 1;
        const geomId = inst.representationAnalytical?.geometryId!;
        const geo = bim.allBimGeometries.peekById(geomId);
        if (geo instanceof PolylineGeometry) {
            const geoPoints = Vector3.arrayFromFlatArray(geo.points3d);
            for (let i = 0; i < geoPoints.length-1; i++) {
                const fst = geoPoints[i].applyMatrix4(inst.worldMatrix);
                const snd = geoPoints[i+1].applyMatrix4(inst.worldMatrix);
                edges.push({
                    id,
                    fst: points.Idx(fst),
                    snd: points.Idx(snd),
                    width: width,
                    length: fst.xyDistanceTo(snd)
                });
            }
        } else if (geo instanceof GraphGeometry) {
            for (const [point1, point2] of geo.iterEdgesPoints()) {
                const point1WS = point1.clone().applyMatrix4(inst.worldMatrix);
                const point2WS = point2.clone().applyMatrix4(inst.worldMatrix);
                edges.push({
                    id,
                    fst: points.Idx(point1WS),
                    snd: points.Idx(point2WS),
                    width: width,
                    length: point1WS.xyDistanceTo(point2WS)
                });
            }
        } else {
            console.log("unknown geometry for roads", geo);
        }
    }

    if (center !== undefined && angle !== undefined) {
        return { 
            points: points.ToVector2Array().map(p => p.rotateAround(center, angle)), 
            edges 
        };
    }

    return { points: points.ToVector2Array(), edges };
}

export function convertBlocks(blocks: BlockEquipmentInput[], bim: Bim): [BlockData[], number] {
    const outBlocks: BlockData[] = [];
    let outMaxSize = 0;
    for (let index = 0; index < blocks.length; index++) {
        const block = blocks[index];
        const instance = block.transformer.instance;
        if (!instance) throw new Error(`No transformer found with id: ${block}`);
        const length = instance.properties.get("dimensions | length")?.as("m")!;
        const width = instance.properties.get("dimensions | width")?.as("m")!;
        const transformer: TransformerData = {
            src: instance,
            power: instance.properties.get("output | power")?.as("W")!,
            length,
            width
        };

        const inverterInstance = block.inverter.instance;
        if (!inverterInstance) throw new Error(`No inverter found with id: ${block.inverter}`);

        const inverter: InverterData = {
            src: inverterInstance,
            dc_inputs_number: inverterInstance.properties.get("inverter | dc_inputs_number")?.asNumber()!,
            max_current_output: inverterInstance.properties.get("inverter | max_current_output")?.as("A")!,
            max_power: inverterInstance.properties.get("inverter | max_power")?.as("W")!,
            max_current_input: inverterInstance.properties.get("inverter | max_current_input")?.as("A")!,
            length: inverterInstance.properties.get("dimensions | length")?.as('m')!,
            width: inverterInstance.properties.get("dimensions | width")?.as('m')!,
        };
        const inverter_length = inverterInstance.properties.get("dimensions | length")?.as("m")!;
        const inverter_width = inverterInstance.properties.get("dimensions | width")?.as("m")!;
        outMaxSize = Math.max(outMaxSize, transformer.length, transformer.width, inverter_length, inverter_width);

        const outBlock:BlockData = {
            label: index,
            transformer,
            inverter,

            number_of_blocks: block.number_of_blocks,
            number_of_inverters: block.number_of_inverters,
            ilr_min: block.ilr_min,
            ilr_max: block.ilr_max, 
            
        }
        outBlocks.push(outBlock);
    }

    return [outBlocks, outMaxSize];
}

function convertTrackers(assets: AssetData[]): TrackerData[] {
    return assets.map(tracker => calculateTrackerDimensions(tracker.instance));
}

function convertContours(substation: IdBimScene, boundaries: Boundary2DDescription[], center: Vector2, angle: number, bim: Bim):Contour[] {
    if (boundaries.length == 0) {
        throw new Error('Boundaries are not defined!');
    }
    const contours: Contour[] = [];
    const boundariesInstances = bim.instances.peekByIds(boundaries.map(b => b.bimObjectId));
    for (const boundary of boundaries) {
        const inst = boundariesInstances.get(boundary.bimObjectId);
        const equipmentBoundary = inst?.properties.get('boundary | source_type')?.asText() === 'equipment';
        const rotated = boundary.pointsWorldSpace.map(p => p.clone().rotateAround(center, angle));
        const contour: Contour = {
            id: boundary.bimObjectId,
            contour: boundary.pointsWorldSpace,
            rotated,
            include: boundary.boundaryType === BoundaryType.Include,
            equipmentBoundary
        };
        contours.push(contour);
    }
    const substationInst = bim.instances.peekById(substation);
    if(!substationInst){
        throw new Error(`Substation with id [${substation}] not found in Bim!`);
    }
    const aabbResult = calculateStdRepresentationLocalBBox(substationInst.representation, bim.allBimGeometries);
    if(aabbResult instanceof Failure){
        throw new Error(`Substation with id [${substation}] has no representation!`);
    }

    const [bBox] = getTransformedBBox2AndCenter(aabbResult.value, substationInst);
    const rotated = bBox.map(p => p.clone().rotateAround(center, angle));
    const contour: Contour = {
        id: substation,
        contour: bBox,
        rotated,
        include: false,
        equipmentBoundary: false,
    };
    contours.push(contour);

    return contours;
}

function* _arrangeRoads(roadsToAlloc: RoadDef[], bim: Bim, mainParentId: IdBimScene | undefined, addProps: (props: PropertiesCollection) => void)
: Generator<Yield, SceneInstanceDescription[]> {

    const idsGeoProvider = bim.graphGeometries.idsProvider;
    const idsProvider = bim.instances.idsProvider;

    const geometriesToAlloc: [IdBimGeo, GraphGeometry][] = [];
    const sceneInstancesToAlloc: [IdBimScene, Partial<SceneInstance>][] = [];
    let reusableMatrix1 = new Matrix4();
    function addClonedSceneInstanceWith(
        polyline: Vector3[],
        width: number
    ): IdBimScene {
        const newId = idsProvider.reserveNewId();
        const newGeoId = idsGeoProvider.reserveNewId();

        const objectWorldMatrix = new Matrix4().setPositionV(polyline[0]);
        const inverseWorldMatrix = reusableMatrix1.getInverse(objectWorldMatrix);
        const localPoints = polyline.map(p => p.clone().applyMatrix4(inverseWorldMatrix));
		const graphPoints = new Map(localPoints.map((p, index) => [index as IdInEntityLocal, p]));
		const graphEdges = new Map<LocalIdsEdge, SegmentInterp>();
		for (let i = 1; i < polyline.length; ++i) {
			const edge = LocalIdsCounter.newEdge(i - 1 as IdInEntityLocal, i as IdInEntityLocal);
			graphEdges.set(edge, SegmentInterpLinearG);
		}
		const geometry = new GraphGeometry(graphPoints, graphEdges);
        geometriesToAlloc.push([newGeoId, geometry]);

		const roadInstance = bim.instances.archetypes.newDefaultInstanceForArchetype('road');

        if (mainParentId) {
            const mainParentMatrix = bim.instances.peekById(mainParentId)?.worldMatrix;
            roadInstance.localTransform = SceneInstances.getLocalTransformRelativeToParentMatrix(mainParentMatrix, objectWorldMatrix);
			roadInstance.spatialParentId = mainParentId;
        }

		roadInstance.representationAnalytical = new BasicAnalyticalRepresentation(
			newGeoId
		);
		const props = [
			BimProperty.NewShared({path: ['road', 'width'], value: width, unit: 'm', }),
		];

		roadInstance.properties.applyPatch(props.map(p => [p._mergedPath, p]));
        addProps(roadInstance.properties);

        sceneInstancesToAlloc.push([newId, roadInstance]);

        return newId;
    }

    const newIds: number[] = [];
    const sceneInstancesDescription: SceneInstanceDescription[] = [];
    for (const obj of roadsToAlloc) {
        const vectors = obj.polyline.map(v=> new Vector3(v.x, v.y, 0));
        const newId = addClonedSceneInstanceWith(vectors, obj.width);
        sceneInstancesDescription.push({id: newId, type: 'road'});
        if (newId) {
            newIds.push(newId);
        }
    }
    yield Yield.NextFrame;

    const ALLOCATION_SIZE = 3000;
    for (const objectsChunk of IterUtils.splitArrayIntoChunks(geometriesToAlloc, ALLOCATION_SIZE)) {
        // console.log('Test chunk:', JSON.stringify(objectsChunk));
        bim.allBimGeometries.allocate(objectsChunk);
        yield Yield.NextFrame;
        yield Yield.NextFrame;
    }

    for (const objectsChunk of IterUtils.splitArrayIntoChunks(sceneInstancesToAlloc, ALLOCATION_SIZE)) {
        // console.log('Test chunk:', JSON.stringify(objectsChunk));
        bim.instances.allocate(objectsChunk);
        yield Yield.NextFrame;
        yield Yield.NextFrame;
    }
    return sceneInstancesDescription;
}

function unpackContoursForRoadsTrim(includedContours: Vector2[][], excludedContours: Vector2[][]): {
    contoursSegments: Segment2[], pointsInContourChecker: PointsInPolygonChecker
} {
    const includedPolylines: Vector2[][] = [];
    const excludedPolylines: Vector2[][] = [];
    const contoursSegments: Segment2[] = [];

    for (const polyline of includedContours) {
        contoursSegments.push(new Segment2(polyline[polyline.length - 1], polyline[0]));
        for (let i = 1; i < polyline.length; ++i) {
            contoursSegments.push(new Segment2(polyline[i - 1], polyline[i]));
        }

        includedPolylines.push(polyline);
    }

    for (const polyline of excludedContours) {
        contoursSegments.push(new Segment2(polyline[polyline.length - 1], polyline[0]));
        for (let i = 1; i < polyline.length; ++i) {
            contoursSegments.push(new Segment2(polyline[i - 1], polyline[i]));
        }

        excludedPolylines.push(polyline);
    }

    const pointsInContourChecker = new PointsInPolygonChecker(includedPolylines, excludedPolylines, true);

    return { contoursSegments, pointsInContourChecker };
}

export function* generateTrackersFromSolarArray(
    siPerCatalogItem: Map<CatalogItemId, SceneInstance>,
    solarArrays: SolarArrayConfig[],
) {
    const bim = new Bim({});
    const instances: SceneInstance[] = [];
    const frameAndModuleIds: IdBimScene[] = [];
    for (const array of solarArrays) {

        const preset =
            siPerCatalogItem.get(array.preset.value.at(0)?.id as CatalogItemId);
        const frame =
            siPerCatalogItem.get(array.trackerFrame.value.at(0)?.id as CatalogItemId);
        const module =
            siPerCatalogItem.get(array.module.value.at(0)?.id as CatalogItemId);

        if (preset) {
            // as preset
            instances.push(preset);
        } else if (module && frame) {
            // as frame + module
            const trackerProps = new PropertiesCollection([
                ...frame.properties.values(),
                ...module.properties.values(),
            ]);
            const newId = bim.instances.idsProvider.reserveNewId();
            bim.instances.allocate([
                [
                    newId,
                    {
                        type_identifier: TrackerTypeIdent,
                        properties: trackerProps,
                    }
                ]
            ])
            frameAndModuleIds.push(newId);
        }
    }

    yield * bim.runBasicUpdatesTillCompletion({ forceRun: false });
    return [
        ...instances,
        ...bim.instances.peekByIds(frameAndModuleIds).values(),
    ]
}