<script lang="ts">
    import { getContext } from "svelte";
    import {
        ContextMenuStructure,
        type ContextMenu,
        Action,
    } from "../../../libui/context-menu";
    import { ObjectUtils, StringUtils } from "engine-utils-ts";
    import SelectOption from "../SelectOption.svelte";

    export let selected: string | undefined;
    export let values: string[] = [];
    export let readonly: boolean = false;
    export let onChange: (option: string) => void;

    const contextMenu = getContext<ContextMenu>("context-menu");
    $: contextMenuStr = new ContextMenuStructure([]);
    $: {
        const items: Action[] = [];
        for (const value of values) {
            items.push(
                new Action(StringUtils.capitalizeFirstLatterInWord(value), () => {
                    if (selected?.toLowerCase() !== value.toLowerCase()) {
                        onChange(value);
                    }
                    selected = value;
                })
            );
        }
        if (!ObjectUtils.areObjectsEqual(contextMenuStr.items, items)) {
            contextMenuStr.items = items;
            contextMenu.invalidator.invalidate();
        }
    }
</script>

<SelectOption
    value={StringUtils.capitalizeFirstLatterInWord(selected ?? "")}
    {readonly}
    onClick={(pos) => {
        contextMenu.openMenuAtPosition(pos.x, pos.y, contextMenuStr);
    }}
/>

<style lang="scss">
</style>
