import type { LazyVersioned , PollWithVersionCache, VersionedValue} from 'engine-utils-ts';
import {
	LazyBasic, LazyDerived, ObjectUtils, ScopedLogger, PollWithVersionCacheBasic, requestExecutionFrame
} from 'engine-utils-ts';
import type {
	Subscriber, Unsubscriber, Updater, Writable} from 'svelte/store';
import { writable
} from 'svelte/store';

type TimeMs = number; // performance.now() time

class VersionedValuesPoller {

    logger: ScopedLogger = new ScopedLogger('versioned-store-poller');

    _isRunning: boolean = false;
    _loopCallback: (time: number) => void;

    _storesToPoll = new Map<VersionedStore<any>, TimeMs>();

    constructor() {
        this._loopCallback = () => {
            if (this._storesToPoll.size === 0 && this._isRunning) {
                this._isRunning = false;
                this.logger.debug('stop running');
            }
            if (this._isRunning) {
                requestExecutionFrame(this._loopCallback);
            }
            this._pollStores();
        }
        (window as any)['vs_poller'] = this;
    }

    _pollStores() {
        const currentTime = performance.now();
        const toUpdate: VersionedStore<any>[] = [];
        for (const [s, lastUpdateTime] of this._storesToPoll) {
            if (!(currentTime < lastUpdateTime + s.pollingIntervalMs)) {
                toUpdate.push(s);
            }
        }
        const cache = new PollWithVersionCacheBasic();
        for (const s of toUpdate) {
            s.tryUpdateSvelteStore(cache);
            this._storesToPoll.set(s, currentTime);
        }
    }

    _tryStart() {
        if (this._storesToPoll.size > 0 && !this._isRunning) {
            this._isRunning = true;
            requestExecutionFrame(this._loopCallback);
            this.logger.debug('start running');
        }
    }

    addToPolling(store: VersionedStore<any>) {
        this._storesToPoll.set(store, 0);
        this._tryStart();
    }

    removeFromPolling(store: VersionedStore<any>) {
        if (!this._storesToPoll.delete(store)) {
            this.logger.warn(`failed attempt to remove from store polling, probably double unsubscribe`, store);
        }
    }

}

const poller = new VersionedValuesPoller();

export class VersionedStore<T> implements Writable<T> {

    set!: (this: void, value: T) => void;
    update!: (this: void, updater: Updater<T>) => void;
    subscribe: (this: void, run: Subscriber<T>, invalidate?: (value?: T) => void) => Unsubscriber;

    _svelteStoreImpl: Writable<T>;
    _versionedObj: LazyBasic<T> | LazyVersioned<T>;
    _lastNotifiedVersion: number = -100;
    _skipDispose = false;

    _subsCount: number = 0;

	readonly pollingIntervalMs: number;

    constructor(versionedObjArg: LazyBasic<T> | LazyVersioned<T> | (T & VersionedValue), pollingIntervalMs?: number) {

		this.pollingIntervalMs = pollingIntervalMs ?? 15;

        if (versionedObjArg.version == undefined) {
            throw new Error('should have version() method');
        }

        if ((versionedObjArg as LazyVersioned<T>).poll == undefined) {
            this._versionedObj = LazyDerived.fromVersionedObject(versionedObjArg as (VersionedValue & T)).withoutEqCheck();
        } else {
            this._versionedObj = versionedObjArg  as any;
        }

        this._svelteStoreImpl = writable(
            this._versionedObj.poll()
        );
        this.subscribe = (run, invalidate) => {
            poller.addToPolling(this);
            const unsub = this._svelteStoreImpl.subscribe(run, invalidate);
            this._subsCount += 1;
            return () => {
                this._subsCount -= 1;
                if (this._subsCount === 0) {
                    poller.removeFromPolling(this);
                    if (
                        !this._skipDispose &&
                        typeof (this._versionedObj as any).dispose == 'function'
                    ) {
                        (this._versionedObj as any).dispose();
                    }
                }
                unsub();
            }
        }

        if (this._versionedObj instanceof LazyBasic) {
            this.set = (value) => {
                (this._versionedObj as LazyBasic<T>).replaceWith(value);
                this.tryUpdateSvelteStore();
            };
            this.update = (udpater) => {
                this.set(udpater(this._versionedObj.poll()));
            };
        } else {
            console.assert(typeof this._versionedObj.poll == 'function', 'lazy version typecheck');
            console.assert(typeof this._versionedObj.version == 'function', 'lazy version typecheck');
            this.set = (newVal) => {
                if (newVal != this._versionedObj.poll()) {
                    console.error(`this VersionedStore is readonly ${JSON.stringify(ObjectUtils.cloneOnlyPrimitives(this._versionedObj))}`);
                }
            };
            this.update = () => {
                throw new Error('this VersionedStore is readonly')
            };
        }
        this.tryUpdateSvelteStore();
    }

    skipDispose() {
        this._skipDispose = true;
        return this;
    }

    tryUpdateSvelteStore(cache?: PollWithVersionCache) {
        cache = cache ?? new PollWithVersionCacheBasic();
        const result = cache.getOrCreate(this._versionedObj);
        const vo = result.version;
        if (this._lastNotifiedVersion != vo) {
            this._lastNotifiedVersion = vo;
            this._svelteStoreImpl.set(result.value);
        }
    }

	static simplePollingLambda<T>(lambda: () => T, pollingIntervalMs: number): VersionedStore<T> {
		return new VersionedStore(
			LazyDerived.fromMutatingObject(lambda),
			pollingIntervalMs,
		);
	}
}



// export class ReadableVersioned<T> implements Readable<T> {

//     _versionedObj: LazyVersioned<T>;

//     _lastNotifiedVersion: number;

//     _storeImpl: Readable<T>;

//     constructor(versionedObj: LazyVersioned<T>) {
//         this._versionedObj = versionedObj;
//         this._storeImpl = readable(versionedObj.poll(), (_setFn) => {

//             return () => listener.dispose();
//         })
//     }

//     subscribe(run: Subscriber<T>, invalidate?: (value?: T) => void): Unsubscriber {
//         return this._storeImpl.subscribe(run, invalidate);
//     }

// }
