<script lang="ts">
import type { SolarArrayConfig } from "bim-ts";
import type { SolarArraysViewContext } from "layout-service";
import SolarArrayWithPreset from "./SolarArrayWithPreset.svelte";
import SolarArrayWithoutPreset from "./SolarArrayWithoutPreset.svelte";
import PresetField from "./PresetField.svelte";
import { Button, ButtonComponent, ButtonType } from "../../../libui/button";
import { getContext } from "svelte";
import type { VersionedStore } from "../../../VersionedStore";

const context = getContext<VersionedStore<SolarArraysViewContext>>('context');

export let arrayConfig: SolarArrayConfig;

</script>

<div style="background: var(--color-alt); padding: 10px 10px; margin: 10px 0;">
    <PresetField config={arrayConfig} />

    {#if arrayConfig.preset.value.length}
        <SolarArrayWithPreset config={arrayConfig} />
    {:else}
        <SolarArrayWithoutPreset config={arrayConfig} />
    {/if}
    <div style="margin-top: 10px; width: 100%; display: flex; justify-content: flex-end">
        <ButtonComponent compact desc={new Button(
            'remove',
            ButtonType.Outlined,
            () => $context.removeConfig(arrayConfig.id.value)
        )}/>
    </div>
</div>


<style lang="scss">
</style>
