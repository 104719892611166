<script lang="ts">
    import ColorInput from "./ColorInput.svelte";
    import { SelectItem, Delete } from "../../../libui/icon/svg";
    import type { ControlItemConfig } from "./SelectedArea";
    import Lock from '../../../libui/icon/svg/lock.svelte';
    export let config: ControlItemConfig;
</script>

<div class="holder">
    {#if config.onChangeColor}
        <div class="icon-holder">
            <ColorInput value = {config.color} onChangeColor = {config.onChangeColor} />
        </div>
        
    {/if}
    {#if config.onSelect}
        <div class="icon-holder" on:click={config.onSelect}>
            <SelectItem/>
        </div>
    {/if}
    <div class="icon-holder" style="cursor: {config.readonly ? "default" : "pointer"};" on:click={config.onDelete}>
        {#if config.readonly}
            <Lock />
        {:else}
            <Delete/>
        {/if}
    </div>
</div>

<style lang="scss">
    .holder {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        padding: 0 4px;
    }
    .icon-holder {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-main-medium);
    }
</style>
