import type { MetricsGroup, MathSolversApi, ProjectMetrics, UnitsMapper, MathSolverQueuePriorityType} from "bim-ts";
import { deserializeProjectMetricsResponse, AreaTypeEnum, URLS } from "bim-ts";
import type { TasksRunner, ResultAsync, LazyVersioned} from "engine-utils-ts";
import { ScopedLogger, LogLevel, LazyBasic, PollablePromise, LazyDerived, Success, IterUtils, ProjectNetworkClient , DeferredPromise} from "engine-utils-ts";
import type { PUI_Node, UiBindings} from "ui-bindings";
import { NotificationDescription, NotificationType, PUI_Builder, PUI_GroupNode, PUI_Lazy, PUI_PropertyNodeNumber } from "ui-bindings";
import type { VerDataSyncer } from "verdata-ts";
import { notificationSource } from "../Notifications";
import { buildUiFromMetric, getVersionDescription } from "../project-panel/ProjectPanel";
import type { Project } from "../projects";
import { ComparisonMode, CurrentVersion, ComparisonItem } from "./ComparisonMode";
import { KrMath } from "math-ts";

interface PrepareMetricsRequest {
    projectVersion: number,
}

interface BuildVersionDescription {
    version: string,
}

class TempFilesApi {
    private readonly _logger: ScopedLogger = new ScopedLogger("temp-files-api", LogLevel.Info);
    private readonly _networkClient: ProjectNetworkClient;

    constructor(
        baseNetwork: ProjectNetworkClient,
    ) {
        this._networkClient = new ProjectNetworkClient({
            ...baseNetwork.config,
            basePath: "api",
        });
    }

    async getFile(filePath: string): Promise<ArrayBuffer | undefined> {
        try {
            const query = this._createQueryParams({filePath});
            const response = await this._networkClient.get(query);
            if(response.status === 200){
                return await response.arrayBuffer();
            }
        } catch (error) {
            if(error.status !== 404){
                throw error;
            }
        }
 
        return;
    }

    async saveFile(filePath: string, data: ArrayBuffer): Promise<boolean> {
        const formData = new FormData();
        formData.append(
            "request",
            new Blob([data]),
        );
        const query = this._createQueryParams({filePath});
        const response = await this._networkClient.postFormData(query, formData);
        return response.status === 200;
    }

    async deleteFile(filePath: string): Promise<boolean> {
        const query = this._createQueryParams({filePath});
        const response = await this._networkClient.delete(query);
        return response.status === 200;
    }

    private _createQueryParams(params: Record<string, string | number | boolean>): string {
        const searchParams = new URLSearchParams();
        for (const [key, value] of Object.entries(params)) {
            searchParams.append(key, value.toString());
        }
        return `temp-files?${searchParams.toString()}`;
    }
}

class MetricsCalculator {
    private readonly _logger: ScopedLogger = new ScopedLogger("metrics-calculator", LogLevel.Info);
    private readonly _networkClient: ProjectNetworkClient;
    private _buildVersion: string | null = null;
    private readonly _metricsSolverName = "prepare_project_metrics";
    private readonly _cacheFilesApi: TempFilesApi;

    constructor(
        private readonly projectId: number,
        private readonly solverApi: MathSolversApi, 
        networkClient: ProjectNetworkClient,
    ) {
        this._networkClient = new ProjectNetworkClient({
            ...networkClient.config,
            basePath: "dist"
        });
        this._cacheFilesApi = new TempFilesApi(networkClient);
    }

    async calculate(request: PrepareMetricsRequest, priority?: MathSolverQueuePriorityType) {
        const computedMetrics = await this.fetchComputedMetrics({projectId: this.projectId, projectVersion: request.projectVersion});
        if(computedMetrics){
            const response = deserializeProjectMetricsResponse(
                new Uint8Array(computedMetrics), 
                this._logger
            );

            this._logger.debug("Cached metrics response", response);
            return response;
        }

        let metricsRaw: ArrayBuffer | undefined;
        const response = await this.solverApi.callSolver<PrepareMetricsRequest, MetricsGroup[]>({
            solverName: this._metricsSolverName,
            solverType: "multi",
            request: request,
            priority,
            deserialize: (body) => {
                const arr = new Uint8Array(body);
                metricsRaw = arr.buffer;
                this._logger.debug("Metrics response", body);
                this._logger.debug("Metrics response", arr);
                const response = deserializeProjectMetricsResponse(arr, this._logger);                
                this._logger.debug("Metrics response", response);
                return response;
            }
        });

        if(metricsRaw){
            const hash = await this.createHash({projectId: this.projectId, projectVersion: request.projectVersion});
            await this._cacheFilesApi.saveFile(hash, metricsRaw);
        }

        return response;
    }

    async fetchComputedMetrics(args:{ projectId: number, projectVersion: number}) {
        const hash = await this.createHash(args);
        const metrics = await this._cacheFilesApi.getFile(hash);
        return metrics;
    }

    async createHash(args:{ projectId: number, projectVersion: number}): Promise<string> {
        const buildVersion = await this._getBuildVersion();
        if(!buildVersion){
            throw new Error("Failed to get build version");
        }

        const dependencies = [buildVersion, args.projectId, this._metricsSolverName, args.projectVersion];
        this._logger.debug("Hash of dependencies", dependencies);
        return dependencies.join("/");
    }

    private async _getBuildVersion() { 
        if(this._buildVersion){
            return this._buildVersion;
        }

        try {
            const response = await this._networkClient.get("version.json");
            const json = await response.json() as BuildVersionDescription;
            if(!json.version){
                this._logger.error("Failed to get build version", json);
                return null;
            }
            this._buildVersion = json.version;
    
            return this._buildVersion;
        } catch (e) {
            if(URLS.isLocalOrigin()){
                return "local";
            }
            this._logger.error("Failed to get build version", e);
            throw e;
        }
    }
}

export class ProjectComparisonItems {
    private _logger: ScopedLogger;
    private _catalogSyncer: VerDataSyncer;
    private _projectSyncer: VerDataSyncer;
    private _taskRunner: TasksRunner;
    private _comparisonMode: ComparisonMode;
    private _metrics: ProjectMetrics;
    private _project: Project;
    private _metricsCalculator: MetricsCalculator;
    private _uiBindings: UiBindings;

    private _notificationTimeoutMs: number = 15*60*1000;
    private _calculatingPromise: DeferredPromise<any> | undefined = undefined;

    private _inProgressTasks = new Map<string, {projectVersion: number}>();
    private _backgroundTasks = new Map<string, Promise<void>>(); 

    private readonly catalogVersion = -1;// Metrics not affected by catalog

    constructor(args: {
        networkClient: ProjectNetworkClient;
        catalogSyncer: VerDataSyncer;
        projectSyncer: VerDataSyncer;
        taskRunner: TasksRunner;
        solverApi: MathSolversApi;
        comparisonMode: ComparisonMode;
        metrics: ProjectMetrics;
        project: Project,
        uiBindings: UiBindings,
    }) {
        this._logger = new ScopedLogger("project-comparison-items", LogLevel.Info);
        this._catalogSyncer = args.catalogSyncer;
        this._projectSyncer = args.projectSyncer;
        this._taskRunner = args.taskRunner;
        this._comparisonMode = args.comparisonMode;
        this._metrics = args.metrics;
        this._project = args.project;
        this._metricsCalculator = new MetricsCalculator(this._project.id, args.solverApi, args.networkClient);

        this._uiBindings = args.uiBindings;

        this._projectSyncer.addListener('afterSaveNewVersion', async () => {
            const projectVersion = this._projectSyncer.getCurrentVersionId();
            this._logger.debug("Project version saved", projectVersion);
            const taskId = ComparisonMode.createItemId(this._project.id, projectVersion, this.catalogVersion);
            const calcMetrics = async () => { 
                try {
                    await this._metricsCalculator.calculate({projectVersion}, "low");
                } catch (error) {
                    this._logger.error("Failed to calculate metrics", error);
                }
            };

            const pr = calcMetrics().finally(() => {
                this._backgroundTasks.delete(taskId);
            });

            this._backgroundTasks.set(taskId, pr);
            await pr;
        });
    }

    isAlreadyCalculating(projectVersion: number) {
        const taskId = ComparisonMode.createItemId(this._project.id, projectVersion, this.catalogVersion);
        return this._inProgressTasks.has(taskId);
    }

    async addComparisonItemFromVersion(projectVersion: number) {
        const taskId = ComparisonMode.createItemId(this._project.id, projectVersion, this.catalogVersion);
        if(this._comparisonMode.items.has(taskId)){
            this._uiBindings.addNotification(NotificationDescription.newBasic({
                type: NotificationType.Warning,
                source: notificationSource,
                key: "addToComparisionFailed",
                descriptionArg: `Item already added to comparison with project version ${projectVersion}`,
                addToNotificationsLog: false,
            }));
            
            return;
        }

        if(!this._calculatingPromise){
            this._calculatingPromise = new DeferredPromise(this._notificationTimeoutMs);
            this.addCalculationNotification(this._calculatingPromise.promise);
        }
        this._inProgressTasks.set(taskId, {projectVersion});
        await this._backgroundTasks.get(taskId);
        await this._addComparisonItemFromVersion(projectVersion)
            .finally(() => {
                this._inProgressTasks.delete(taskId);
                if (this._inProgressTasks.size === 0) {
                    this._calculatingPromise?.resolve(null);
                    this._calculatingPromise = undefined;
                }
        });
    }


    private async _addComparisonItemFromVersion(projectVersion: number, priority?: MathSolverQueuePriorityType) {
        try {
            const request: PrepareMetricsRequest = {
                projectVersion,
            }
            const response = await this._metricsCalculator.calculate(request, priority);
    
            const responseLazy = new LazyBasic<MetricsGroup[]>(
                `project-metrics-${request.projectVersion}`, 
                response
            );
    
            this._addComparisonItem(responseLazy, projectVersion, this.catalogVersion);
        } catch (e) {
            this._uiBindings.addNotification(NotificationDescription.newBasic({
                type: NotificationType.Error,
                source: notificationSource,
                key: "addToComparisionFailed",
                addToNotificationsLog: true,
            }));
            console.error(e);
        }
    }

    private addCalculationNotification<T>(promise: Promise<T>) {
        const task = this._taskRunner.newLongTask({
            defaultGenerator: function*() { 
                yield* PollablePromise.generatorWaitFor(promise);
            }(),
            taskTimeoutMs: 600_000,
        });
        this._uiBindings.addNotification(NotificationDescription.newWithTask({
            source: notificationSource,
            key: 'addToComparision',
            taskDescription: { task },
            type: NotificationType.Info,
            addToNotificationsLog: true
        }));
    }

    addComparisonItemFromCurrentState(){
        const projectVer = new CurrentVersion(new LazyBasic("current-ver-descr", "Current"));
        const catalogVer = new CurrentVersion(new LazyBasic("current-ver-descr", "Current"));
        const id = ComparisonMode.createItemId(this._project.id, projectVer.id, catalogVer.id);
        if(this._comparisonMode.items.has(id)){
            this._uiBindings.addNotification(NotificationDescription.newBasic({
                type: NotificationType.Warning,
                source: notificationSource,
                key: "addToComparisionFailed",
                descriptionArg: `Current metrics already added to comparison`,
                addToNotificationsLog: false,
            }));
            
            return;
        }
        const metrics = LazyDerived.new1<
            MetricsGroup[] | null,
            ResultAsync<MetricsGroup[]>
        >(
            "project-metrics", 
            null, 
            [this._metrics], 
            ([metricsGroupsResult], prev) => {
            let metricsGroups: MetricsGroup[] | null = prev ?? null;
            if(metricsGroupsResult instanceof Success){
                metricsGroups = metricsGroupsResult.value;
            }

            return metricsGroups;
        });
        const totalMetrics = LazyDerived.new1(
            "total-metrics",
            null,
            [metrics],
            ([metricsGroups]) => {
                return metricsGroups?.find(m => m.type === AreaTypeEnum.Total)?.metrics ?? null; 
            }
        );
        const item = new ComparisonItem(
            this._project,
            projectVer,
            catalogVer,
            totalMetrics
        );

        this._comparisonMode.add(item);
    }

    private _addComparisonItem(metrics: LazyVersioned<MetricsGroup[] | null>, projectVersion: number, catalogVersion: number){
        const totalMetrics = LazyDerived.new1(
            "total-metrics",
            null,
            [metrics],
            ([metricsGroups]) => {
                return metricsGroups?.find(m => m.type === AreaTypeEnum.Total)?.metrics ?? null; 
            }
        );

        const item = new ComparisonItem(
            this._project,
            getVersionDescription(this._projectSyncer, projectVersion),
            getVersionDescription(this._catalogSyncer, catalogVersion),
            totalMetrics
        );

        this._comparisonMode.add(item);
    }

    get comparisonVersions(): Set<number> {
        const set = new Set<number>();
        for (const item of this._comparisonMode.items.values()) {
            set.add(item.projectVersion.id);
        }
        for (const item of this._inProgressTasks.values()) {
            set.add(item.projectVersion);
        }
        return set;
    }

    removeComparisonItem(projectVersion: number) {
        const id = ComparisonMode.createItemId(this._project.id, projectVersion, this.catalogVersion);
        this._comparisonMode.remove(id);
    }
}

export function convertToRelativeItems(idx: number, items: ComparisonItem[], unitsMapper: UnitsMapper): PUI_Lazy[] {
    if(!items.length){
        return [];
    }
    const baseItem = items[idx];
    const createKey = (path: string[]) => path.join(' | ');
    const relatedValuesLazy = LazyDerived.new1(
        "related-items",
        null,
        [baseItem.metrics],
        ([metrics]) => {
            const relativeValues = new Map<string, PUI_PropertyNodeNumber>();
            const pui = buildUiFromMetric(metrics);
            iteratePuiValues([], pui, (p, n)=>{
                if(n instanceof PUI_PropertyNodeNumber){
                    const key = createKey(p);
                    relativeValues.set(key, n);
                }
            });
            return relativeValues;
        }
    );

    function renderValue(newValue: number, baseValue: number, step: number) {
        const roundedNewValue =  KrMath.roundTo(newValue, step);
        const roundedBaseValue = KrMath.roundTo(baseValue, step);
        if(roundedNewValue === roundedBaseValue){
            return 'inherit';
        } else if (roundedNewValue > roundedBaseValue){
            return "red";
        } else {
            return "green";
        }
    }
    const relativeItems:PUI_Lazy[] = [];
    for (const item of items) {
        const puiLazy = LazyDerived.new2(
            "related-pui",
            null,
            [item.metrics, relatedValuesLazy],
            ([metrics, relativeValues]) => {
                const root = new PUI_GroupNode({name: "", sortChildren: false});
                const pui = buildUiFromMetric(metrics);
                clonePui(pui, root, [], (p, n, g) => {
                    let node = n;
                    if(n instanceof PUI_PropertyNodeNumber){
                        const unitDef = n.unit ? unitsMapper.converter.getUnitDefinition(n.unit) : undefined;
                        node = new PUI_PropertyNodeNumber({
                            name: n.name,
                            value: n.value,
                            unit: n.unit,
                            hint: n.hint,
                            minMax: n.minMax,
                            readonly: n.readonly,
                            step: n.step,
                            onChange: () => {},
                        });
                        const key = createKey(p);
                        const baseValue = relativeValues.get(key);
                        if(baseValue && node instanceof PUI_PropertyNodeNumber){
                            let step = node.step;
                            const baseVal = baseValue.value as number;
                            node.valueRenderFormatter = (v) => renderValue(Number(v), baseVal, step);
                            if(unitDef?.dimension['price']){
                                step = 0.01;
                                node.value = n.value ? n.value / baseVal * 100 : n.value;
                                node.unit = "%";
                                node.step = step;
                                node.valueRenderFormatter = (v) => renderValue(Number(v), n.value ? 100 : n.value as number, step);
                            }
                        }
                    }
                    g.addMaybeChild(node);
                });

                return root;
            }
        ).withoutEqCheck();

        relativeItems.push(new PUI_Lazy(puiLazy));
    }

    return relativeItems;
}

function iteratePuiValues(path: string[], node: PUI_Node, callBack: (path: string[], node: PUI_Node) => void){
    if(node instanceof PUI_GroupNode){
        for (const [groupName, n] of node.children) {
            iteratePuiValues([...path, groupName], n, callBack);
        }
    } else {
        callBack(path, node);
    }
}

function clonePui(source: PUI_Node, output: PUI_GroupNode, path: string[], callBack: (path: string[], node: PUI_Node, parent: PUI_GroupNode) => void){
    if(source instanceof PUI_GroupNode){
        let newGroup = output;
        if(!(source.isRootNode())){
            newGroup = new PUI_GroupNode({name: source.name});
            output.addMaybeChild(newGroup);
        }
        for (const [groupName, n] of source.children) {
            clonePui(n, newGroup, [...path, groupName], callBack);
        }
    } else {
        callBack(path, source, output);
    }
}

export function buildUiFromComparisonItems(items: ComparisonItem[]): PUI_Lazy[] {
    const pui_lazy: PUI_Lazy[] = [];
    for (const item of items) {
       pui_lazy.push(new PUI_Lazy(LazyDerived.new1(
            "metrics-ui",
            null,
            [item.metrics],
            ([metrics]) => {
                return buildUiFromMetric(metrics);
            }
        ).withoutEqCheck()));
    }
    return pui_lazy;
}

const selectedVerdataVersions = new LazyBasic<number[]>("selected-versions", []);

export function createSelectVersionPui(
    selectedMode: LazyBasic<boolean>,
    comparisonItems: ProjectComparisonItems,
): PUI_Lazy {
    const currentStateId = -1;
    const lazyUi = LazyDerived.new2(
        "comparison-ui-lazy",
        null,
        [selectedMode, selectedVerdataVersions],
        ([mode, selectedVersions]) => {
            const builder = new PUI_Builder({
                sortChildrenDefault: false,
                rootName: "Comparison Mode",
            });
            const addDivider = (name: string) => {
                builder.addCustomProp({
                    name,
                    context: {},
                    value: {},
                    onChange: () => {},
                    type_ident: "divider",
                });
            };

            builder.addSelectorProp({
                name: "Cost display",
                value: mode ? "Relative" : "Absolute",
                onChange: (v) => { selectedMode.replaceWith(v === "Relative"); },
                options: ["Absolute", "Relative"],
            });

            addDivider("Selection of versions start");

            builder.addSceneInstancesSelectorProp({
                name: "Selected versions",
                value: selectedVersions.map(v => ({ value: v })),
                onChange: async (newSelectedVersions) => {
                    const currentSelected = comparisonItems.comparisonVersions;
                    const newSelected = new Set(newSelectedVersions.map(v => v.value));

                    const versionsDiff = IterUtils.setsDiff(currentSelected, newSelected);
                    const tasks: Promise<void>[] = [];
                    for (const projectVersion of versionsDiff.absentInL) {
                        if (!projectVersion) {
                            console.error("No version selected");
                            continue;
                        }
                        if(comparisonItems.isAlreadyCalculating(projectVersion)){
                            continue;
                        }
                        if (projectVersion === currentStateId) {
                            comparisonItems.addComparisonItemFromCurrentState();
                        } else {
                            const task = comparisonItems.addComparisonItemFromVersion(projectVersion);
                            tasks.push(task);
                        }
                    }
                    await Promise.all(tasks);

                    for (const projectVersion of versionsDiff.absentInR) {
                        if (!projectVersion) {
                            console.error("No version selected");
                            continue;
                        }
                        comparisonItems.removeComparisonItem(projectVersion);
                    }

                    selectedVerdataVersions.forceUpdate(Array.from(comparisonItems.comparisonVersions));
                },
                types: ['version'],
            });

            addDivider("Selection of versions end");

            return builder.finish();
        }
    ).withoutEqCheck();
    
    return new PUI_Lazy(lazyUi);
}
