import { AreaTypeEnum, Bim, BoundaryTypeIdent, EquipmentArea, FarmLayoutConfig, FarmLayoutConfigType, IdBimScene, MetricsGroup, MetricsTable, NumberProperty, ProjectMetricsType, RoadTypeIdent, SceneInstancesProperty, SubstationTypeIdent } from "bim-ts";
import { DefaultMap, Failure, IterUtils, LazyBasic, RGBA, RGBAHex, ScopedLogger, Yield } from "engine-utils-ts";
import jsPDF, { GState } from "jspdf";
import { createSubareasMetricsCalculator } from "layout-service";
import { Aabb, Aabb2, Vector2 } from "math-ts";
import { flattenPropertyGroup } from "../../metrics-to-xlsx/ConvertMetricsToXlsx";
import { AnnotationDescription, DrawColor, LayoutDrawing } from "../LayoutDrawing";
import { convertPxToMm, EquipmentDefaultColor, PalettePerType, SolarArraysTypes, SubstationColor } from "../PdfCommon";
import { PageFrameOffsets, PdfElement, PdfReportContext } from "../PdfReportBuilder";
import { setTextStyle } from "../TextBuilder";

export class ScreenshotBySubarea extends PdfElement {
    readonly maxImageSizePx = 330;
    readonly headerOffsetPx = 30;

    private offsetBetweenBars: number;
    private readonly widthBar1 = 10;
    private readonly widthBar2 = 6;
    private readonly overlapBars = 1.5;

    constructor(
        readonly bim: Bim,
        readonly logger: ScopedLogger,
        readonly metrics: MetricsGroup<Partial<ProjectMetricsType>>[],
        readonly areasContext: EquipmentArea[],
        readonly pallete: PalettePerType,
    ){
        super();

        this.offsetBetweenBars = areasContext.filter(a => a.type === AreaTypeEnum.Subarea).length < 8 ? 12 : 8; 
    }

    *draw({page, layoutDrawing, pageFrameOffsets}: PdfReportContext) {
        yield Yield.Asap;
        const types = [
            RoadTypeIdent,
            SubstationTypeIdent,
            ...SolarArraysTypes
        ];
        // const focusIds = this.bim.instances.peekByTypeIdents(trackerTypes);

        const allIds = IterUtils.filterMap(this.bim.instances.peekByTypeIdents(types), 
        ([id, si]) => {
            if (si.type_identifier !== BoundaryTypeIdent) {
                return id;
            }
            const boundaryType = si.properties.get('boundary | source_type')?.asText();
            if(boundaryType === 'origin' || boundaryType == undefined){
                return id;
            }
            return undefined;
        });

        const allTypes = new DefaultMap<string, IdBimScene[]>(() => []);
        const undefinedType = 'unknown';

        const idPerSubAreaId = new Map<IdBimScene, string>();
        for (const area of this.areasContext) {
            if(area.type === AreaTypeEnum.Total){
                continue;
            }

            for (const id of area.equipment) {
                idPerSubAreaId.set(id, area.name);
            }
        }

        for (const id of allIds) {
            const subAreaId = idPerSubAreaId.get(id);
            if (subAreaId) {
                allTypes.getOrCreate(subAreaId).push(id);
            } else {
                allTypes.getOrCreate(undefinedType).push(id);
            }
        }
        const allTypesArray = Array.from(allTypes.entries()).sort((a, b) => a[0].localeCompare(b[0]));
        const idPerColor = new Map<IdBimScene, DrawColor>();
        for (const [name, ids] of allTypesArray) {
            const colorTint = name === undefinedType ? EquipmentDefaultColor : this.pallete.getNext(name);
            for (const id of ids) {
                const typeIdent = this.bim.instances.peekTypeIdentOf(id);
                if(typeIdent === SubstationTypeIdent){
                    idPerColor.set(id, { borderColor: SubstationColor });
                } else {
                    idPerColor.set(id, { borderColor: colorTint });
                }
            }
        }
        const geos = this.bim.allBimGeometries.aabbs.poll();
        const annotations: AnnotationDescription[] = [];
        for (const area of this.areasContext) {
            if(area.type !== AreaTypeEnum.Subarea){
                continue;
            }
            const farmConfig = this.bim.configs.peekByTypeAndConnectedTo(FarmLayoutConfigType, area.connectedTo);
            const config = farmConfig?.[0]?.[1]?.get<FarmLayoutConfig>();
            if(!config){
                continue;
            }
            const equipmentBoundaries = config.site_areas[area.areaIndex]?.equipmentBoundaries;
            if(equipmentBoundaries instanceof SceneInstancesProperty){
                for (const id of equipmentBoundaries.value) {
                    const inst = this.bim.instances.peekById(id);
                    const areaAc = inst?.properties.get('dimensions | area')?.as("ac") ?? 0;
                    if(!inst || areaAc < 1){
                        continue;
                    }
                    const aabbLocal = inst.representationAnalytical?.aabb(geos);
                    if(aabbLocal){
                        const aabb = Aabb.empty().copy(aabbLocal).applyMatrix4(inst.worldMatrix);
                        annotations.push({
                            aabb: aabb,
                            metrics: [
                                `${area.areaIndex - 1}`,
                            ],
                            fontSizes: [140]
                        });
                    }
                }
            }

            yield Yield.Asap;
        }

        yield* drawLayoutOnPageByTemplate2({
            page, 
            ids: allIds,
            layoutDrawing: layoutDrawing,
            layoutName: "Subareas map",
            offsets: pageFrameOffsets,
            maxWidthPx: this.maxImageSizePx,
            headerOffsetPx: this.headerOffsetPx,
            colorize: (id) => {
                return idPerColor.get(id) ?? {borderColor: EquipmentDefaultColor};
            },
            annotations: annotations,
        });

        yield* this.addSubareasAnnotations(page, pageFrameOffsets);
    }

    private *addSubareasAnnotations(
        page: jsPDF,
        pageFrameOffsets: PageFrameOffsets
    ){ 
        const lazyCalculators = new DefaultMap<IdBimScene, ReturnType<typeof createSubareasMetricsCalculator>>((id) => {
            const config = this.bim.configs.peekByTypeAndConnectedTo(FarmLayoutConfigType, id);
            if(config.length !== 1){
                throw new Error(`Invalid config for ${id}`);
            }
            return createSubareasMetricsCalculator(this.bim, this.logger, new LazyBasic("config-" + id, config[0][1].get<FarmLayoutConfig>()));
        });

        const mainSubareasMetrics: {name: string, color: RGBAHex, dc?: NumberProperty, area?: NumberProperty}[] = [];
        let maxDcMW = 0;
        let maxAreaAc = 0;
        for (const area of this.areasContext) {
            const metric = this.metrics.find(m => m.id === area.id);
            if(!metric){
                continue;
            }

            if(area.type === AreaTypeEnum.Total || area.type === AreaTypeEnum.AllSiteArea){
                continue;
            }

            const calculatorResult = yield* lazyCalculators.getOrCreate(area.connectedTo).waitTillCompletion();
            let calculatorResultValue: NumberProperty | undefined = undefined;
            if(calculatorResult instanceof Failure){ 
                this.logger.error('Error while calculating subarea metrics', calculatorResult.errorMsg);
            } else {
                calculatorResultValue = calculatorResult.value[area.areaIndex]?.availableArea;
            }

            const totalDc = metric.metrics?.['dc_total'];
            maxDcMW = Math.max(maxDcMW, totalDc?.as("MW") ?? 0);
            maxAreaAc = Math.max(maxAreaAc, calculatorResultValue?.as("ac") ?? 0);

            if(!totalDc?.as("MW") && area.type === AreaTypeEnum.UnallocatedArea){
                continue;
            }

            mainSubareasMetrics.push({
                name: area.name,
                color: this.pallete.getNext(area.name),
                dc: totalDc,
                area: calculatorResultValue,
            });
    
            yield Yield.Asap;
        }
        if(mainSubareasMetrics.length === 0){
            return;
        }

        //Add text
        const pageWidth = page.internal.pageSize.getWidth();
        const leftOffset = Math.ceil(pageWidth - convertPxToMm(pageFrameOffsets.leftRightOffsetPx + this.maxImageSizePx));
        const fontSize = 15;
        const upOffset = convertPxToMm(this.maxImageSizePx + this.headerOffsetPx + pageFrameOffsets.upOffsetPx + pageFrameOffsets.topContentOffsetPx + fontSize);
        setTextStyle(page, "bold", fontSize);
        page.text("Subareas by DC Capacity and Available area", leftOffset, upOffset);
        const maxWidth = 75;
 
        let startOffset = Math.ceil(upOffset + this.offsetBetweenBars);
        for (const subarea of mainSubareasMetrics) {
            const x = leftOffset;
            const y = startOffset;
            setTextStyle(page, "bold", 10);
            page.text(subarea.name, x, y - convertPxToMm(5));

            setTextStyle(page, "normal", 13);
            const dcPower = subarea.dc?.toConfiguredUnits(this.bim.unitsMapper);
            const powerUnit = "MW";
            const powerStr = (dcPower?.as(powerUnit) ?? 0).toFixed(1);
            const unitOffsetX = pageWidth - convertPxToMm(pageFrameOffsets.leftRightOffsetPx) - 12;
            const valueOffsetX = convertPxToMm(9);
            page.text(powerStr, unitOffsetX - page.getTextWidth(powerStr) - valueOffsetX, y + this.widthBar1 / 2);
            page.text(powerUnit, unitOffsetX,  y + this.widthBar1 / 2);
            setTextStyle(page, "normal", 13);
            const area = subarea.area?.toConfiguredUnits(this.bim.unitsMapper);
            const areaStr = (area?.value ?? 0).toFixed(1);
            page.text(areaStr, unitOffsetX - page.getTextWidth(areaStr) - valueOffsetX, y + this.widthBar1 + (this.widthBar2-this.overlapBars)/2);
            page.text(area?.unit ?? "", unitOffsetX, y + this.widthBar1 - this.overlapBars + this.widthBar2 / 2);

            const [r, g, b] = RGBA.toRgbArray(subarea.color);
            page.setFillColor(r, g, b);
            const powerBarWidth = (subarea.dc?.as("MW") ?? 0) / maxDcMW * maxWidth;
            if(powerBarWidth){
                page.rect(
                    x,
                    y,
                    powerBarWidth,
                    this.widthBar1,
                    "F",
                );
            }
 
            const areaBarWidth = (subarea.area?.as("ac") ?? 0) / maxAreaAc * maxWidth;
            if(areaBarWidth){ 
                page.setGState(new GState({opacity: 0.64}));    
                page.rect(
                    x,
                    y + this.widthBar1 - this.overlapBars,
                    areaBarWidth,
                    this.widthBar2,
                    "F",
                );
                page.setGState(new GState({opacity: 1}));
            }

            startOffset += this.offsetBetweenBars + this.widthBar1 + this.widthBar2 - this.overlapBars;
        }
    }

    calculateContentSize(pageSize: Vector2, {pageFrameOffsets}: PdfReportContext){
        const countDiagrams = IterUtils.filter(
            this.areasContext, 
            (a) => a.type !== AreaTypeEnum.Total && a.type !== AreaTypeEnum.AllSiteArea
        ).length;
        const diagramHeight = (this.offsetBetweenBars + this.widthBar1 + this.widthBar2 - this.overlapBars) * countDiagrams;
        return Aabb2.empty().setFromPoints([
            new Vector2(pageSize.x - convertPxToMm(this.maxImageSizePx), convertPxToMm(pageFrameOffsets.upOffsetPx + pageFrameOffsets.topContentOffsetPx)),
            new Vector2(
            pageSize.x - convertPxToMm(pageFrameOffsets.leftRightOffsetPx), 
            convertPxToMm(this.headerOffsetPx*2 
                + pageFrameOffsets.upOffsetPx 
                + pageFrameOffsets.topContentOffsetPx
                + pageFrameOffsets.downOffsetPx
                + 30
                + this.maxImageSizePx
            )
            + diagramHeight
        )]);
    }
}

export function* drawLayoutOnPageByTemplate2(args: {
    page: jsPDF, 
    ids: IdBimScene[], 
    focusIds?: IdBimScene[], 
    layoutDrawing: LayoutDrawing, 
    offsets: PageFrameOffsets,
    layoutName: string,
    maxWidthPx: number,
    headerOffsetPx: number,
    colorize: (id: IdBimScene) => DrawColor;
    annotations?: AnnotationDescription[],
}){
    const fontSize = 15;
    const pageWidth = args.page.internal.pageSize.getWidth();
    const leftOffset = pageWidth - convertPxToMm(args.offsets.leftRightOffsetPx + args.maxWidthPx);
    const upOffsetPx = args.offsets.upOffsetPx + args.offsets.topContentOffsetPx + args.headerOffsetPx - fontSize;
    

    const drawingCenter = new Vector2(
        pageWidth - convertPxToMm(args.offsets.leftRightOffsetPx + args.maxWidthPx * 0.5),
        convertPxToMm(upOffsetPx + fontSize + args.maxWidthPx * 0.5)
    );

    yield* args.layoutDrawing.drawLayout({
        page: args.page,
        imageSize: new Vector2(convertPxToMm(args.maxWidthPx), convertPxToMm(args.maxWidthPx)),
        position: drawingCenter,
        ids: args.ids,
        focusIds: args.focusIds,
        colorize: args.colorize,
        annotations: args.annotations,
    });
    //Add text
    setTextStyle(args.page, "bold", fontSize);
    args.page.text(args.layoutName, leftOffset, convertPxToMm(upOffsetPx));
}

export class SubareasMetricsTable extends PdfElement {
    private readonly nameOffsetPx = 230;
    private readonly valuesOffsetPx = 90;
    private readonly unitOffsetPx = 40;
    private readonly baseOffsetPx = 8;

    constructor(
        readonly bim: Bim,
        readonly logger: ScopedLogger,
        readonly table: MetricsTable,
        readonly pallete: PalettePerType,
    ) {
        super();
    }

    *draw({page, pageFrameOffsets}: PdfReportContext): Generator<Yield, void, unknown> {
        yield Yield.Asap;
        const leftOffsetPx = pageFrameOffsets.leftRightOffsetPx;
        const flattenedData = flattenPropertyGroup(this.table.rows);
        //header


        let offsetYPx = pageFrameOffsets.upOffsetPx + 16;
        let headerOffsetXPx = leftOffsetPx + this.nameOffsetPx + this.unitOffsetPx + this.valuesOffsetPx;
        let fontSize = 10;
        offsetYPx += fontSize;
        for (let i = 0; i < this.table.headers.length; i++) {
            setTextStyle(page, 'bold', fontSize);
            const header = this.table.headers[i];
            const width = page.getTextWidth(header);
            const xOffset = convertPxToMm(headerOffsetXPx - 8) - width;
            if(i < this.table.headers.length - 1){
                const color = this.pallete.getNext(header);
                const [r, g, b] = RGBA.toRgbArray(color);
                page.setTextColor(r, g, b);

                const rectSize = convertPxToMm(8);
                const round = convertPxToMm(2);
                page.setFillColor(r, g, b);
                page.roundedRect(
                    xOffset - rectSize - convertPxToMm(1),
                    convertPxToMm(offsetYPx) - rectSize,
                    rectSize,
                    rectSize,
                    round,
                    round,
                    'F'
                );
            }
            page.text(header, xOffset, convertPxToMm(offsetYPx));
            headerOffsetXPx += this.valuesOffsetPx;
        }

        for (const row of flattenedData) {
            const fontSize =row?.groupLevel != undefined && row.groupLevel <= 1 ? 12 : 10;
            offsetYPx += fontSize;

            const style = row.groupLevel && row.groupLevel <= 2 ? 'bold' : 'normal';
            setTextStyle(page, style, fontSize);
            const lines = page.splitTextToSize(row.key, convertPxToMm(this.nameOffsetPx));
            page.text(lines.length > 1 ? `${lines[0]}...` : lines[0], convertPxToMm(leftOffsetPx), convertPxToMm(offsetYPx));
            let offsetXPx = leftOffsetPx + this.nameOffsetPx;
            if(row.unit){
                setTextStyle(page, 'transparent', fontSize);
                page.text(row.unit, convertPxToMm(offsetXPx), convertPxToMm(offsetYPx));
            }
            offsetXPx += this.unitOffsetPx + this.valuesOffsetPx;
            for (let i = 0; i < row.value.length; i++) {
                const v = row.value[i];
                const valueStyle = i === row.value.length - 1 ? "bold" : "normal";
                setTextStyle(page, valueStyle, fontSize);
                let valueStr = "";
                if(typeof v === 'number'){ 
                    valueStr = v.toFixed(row.decimals === 0 ? 0 : 2);
                } else if (typeof v === 'string'){
                    valueStr = v;
                }
                const width = page.getTextWidth(valueStr);
                page.text(valueStr, convertPxToMm(offsetXPx - 4) - width, convertPxToMm(offsetYPx));
    
                offsetXPx += this.valuesOffsetPx;
            }

            offsetYPx += this.baseOffsetPx;
        }
        // add total value background
        const x = leftOffsetPx + this.nameOffsetPx + this.unitOffsetPx + this.valuesOffsetPx*(this.table.headers.length - 1);
        const y = pageFrameOffsets.upOffsetPx + pageFrameOffsets.topContentOffsetPx;
        page.setGState(new GState({opacity: 0.08}));
        page.setFillColor(6, 58, 82);

        page.rect(
            convertPxToMm(x),
            convertPxToMm(y),
            convertPxToMm(this.valuesOffsetPx),
            convertPxToMm(offsetYPx - y + 8),
            "F",
        );
        page.setGState(new GState({opacity: 1}));
    }

    calculateContentSize(pageSize: Vector2, {pageFrameOffsets}: PdfReportContext){
        const flattenedData = flattenPropertyGroup(this.table.rows);
        const left = new Vector2(0, 0);
        const right = new Vector2(
            convertPxToMm(pageFrameOffsets.leftRightOffsetPx + this.nameOffsetPx + this.unitOffsetPx + (this.valuesOffsetPx) * this.table.headers.length + 30 + 330 + pageFrameOffsets.leftRightOffsetPx),
            convertPxToMm(pageFrameOffsets.upOffsetPx + pageFrameOffsets.topContentOffsetPx + 20)
                + convertPxToMm(flattenedData.length * 18)
                + convertPxToMm(30)
                + convertPxToMm(pageFrameOffsets.downOffsetPx) 
        );
        return Aabb2.empty().setFromPoints([left, right]);
    }
}