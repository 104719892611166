<script lang="ts">
    import { onMount } from "svelte";
    import type { MultiSelectorValue } from "ui-bindings";
    import { IterUtils } from "engine-utils-ts";
    import {
        Select,
        MultiSelect,
        type SelectedValue,
        type SelectOption,
    } from "../libui/select";

    export let value: SelectedValue[];
    export let options: MultiSelectorValue[] = [];
    export let placeholder: string;
    export let readonly: boolean = false;
    export let calculated: boolean = false;
    export let multiSelector: boolean = false;
    export let maxSelect: number = 0;
    export let enableSelectAll: boolean = false;
    export let doubleLine: boolean = false;
    export let description: string = "";
    export let ignoreScrollContainer: boolean = false;
    export let customContainerWidth: number | undefined = undefined;
    export let customLeftOffset: number | undefined = undefined;
    export let tag: string = "";
    $: dataReloaded = options.length && options[0].label;

    let container: HTMLDivElement;
    let containerWidth: number = 0;
    let containerHeight: number = 0;
    let scrollContainer: HTMLDivElement | null;
    let selectControl: HTMLDivElement;
    let topOffset = 0;
    let containerRect: DOMRect;

    $: offset =
        !ignoreScrollContainer && topOffset
            ? containerHeight + topOffset
            : undefined;
    function onScroll() {
        selectControl.blur();
        containerRect = container.getBoundingClientRect();
        topOffset = scrollContainer!.scrollTop
            ? containerRect.top -
              scrollContainer!.getBoundingClientRect().top +
              (scrollContainer?.offsetTop ?? 0)
            : 0;
    }
    onMount(() => {
        containerRect = container.getBoundingClientRect();
        scrollContainer = container.closest(".custom-scrollbar");
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", onScroll);
        }
        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener("scroll", onScroll);
            }
        };
    });

    function mapToOption(option: MultiSelectorValue): SelectOption {
        return {
            text: (option.label ?? option.value).toString(),
            value: option.value,
            invalid: !!option.invalid,
            disabled: !!option.disabled,
        };
    }
    $: hasGroups = options.some((o) => "group" in o);
    $: groupedOptions = hasGroups
        ? [...IterUtils.groupBy(options, (o) => o.group)].map(
              ([key, value]) => ({
                  label: key as string,
                  options: value.map(mapToOption),
              }),
          )
        : options.map(mapToOption);

    $: resultContainerWidth = customContainerWidth ?? containerWidth;
</script>

<div
    class="property-value"
    class:show-top={window.innerHeight - containerRect?.bottom < 255}
    class:fixed-position={!ignoreScrollContainer}
    style="--offset-top:{offset}px; --dropdown-width:{resultContainerWidth}px; --container-height:{containerHeight}px; --panel-left-offset:{customLeftOffset ? `${customLeftOffset}px` : "auto"}"
    bind:this={container}
    bind:clientWidth={containerWidth}
    bind:offsetHeight={containerHeight}
    on:click={() => {
        containerRect = container.getBoundingClientRect();
    }}
>
    {#if calculated}
        <div class="read-only mdc-typography--body1 calculated multiline">
            {#if value.length === 0}
                {"N/A"}
            {:else}
                {#each value as v}
                    {@const item = options.find((o) => o.value === v)}
                    <div class="selected-item">
                        {item?.label || item?.value}
                    </div>
                {/each}
            {/if}
        </div>
    {:else}
        {#key dataReloaded}
            {#if multiSelector && maxSelect !== 1}
                <MultiSelect
                    {value}
                    options={groupedOptions}
                    disabled={readonly}
                    actionButtons={enableSelectAll}
                    {maxSelect}
                    {placeholder}
                    on:change
                    bind:selectControl
                />
            {:else}
                <Select
                    value={value[0]}
                    options={groupedOptions}
                    disabled={readonly}
                    {placeholder}
                    clearable={maxSelect === 1}
                    {doubleLine}
                    {tag}
                    on:change
                    bind:selectControl
                />
            {/if}
        {/key}
    {/if}
    {#if description}
        <div class="message mdc-typography--tooltip">
            {description}
        </div>
    {/if}
</div>

<style>
    .selected-item {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
        width: 100%;
    }
    .selected-item:only-child {
        padding: 2px 0;
    }
    .ui-config-property.property-row .property-value .calculated {
        flex-direction: column;
        align-items: start;
    }
    .property-value :global(.sv-dropdown) {
        width: var(--dropdown-width);
        top: calc(var(--offset-top) + var(--panel-top-offset, 0px));
        left: var(--panel-left-offset, 0px);
    }
    .property-value.fixed-position :global(.sv-dropdown) {
        position: fixed;
    }
    .property-value.show-top :global(.sv-dropdown) {
        transform: translateY(calc(-100% - var(--container-height)));
        bottom: auto !important;
    }
    .message {
        padding: 2px 8px 0;
    }
</style>
