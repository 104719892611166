import { type Catalog } from 'bim-ts';
import { URLS } from 'bim-ts';
import { Failure, IterUtils, LazyBasic, LazyDerived, ObservableObject, Success } from 'engine-utils-ts';
import type { FileImporterContext} from 'ui-bindings';
import { PUI_Builder } from 'ui-bindings';
import { GroundImportSettings, LayoutJson, ShadingDataInput, SkidCatalogItem, TrackerCatalogItem, getCatalogSkid, getCatalogTrackers, getTrackerName } from './civil/LayoutParser';
import { ImportMode } from './bim-assets/BimAssetsOriginHandling';

interface SkidSelector {
    name: string;
    selected: SkidCatalogItem | undefined;
    count: number;
}

interface TrackerSelector {
    trackerData: ShadingDataInput;
    name: string;
    selected: TrackerCatalogItem | undefined;
    count: number;
}

export interface ImportForm {
    trackers: Record<string, TrackerSelector>;
    mode: ImportMode;
    skid: Record<string, SkidSelector>;
    surfaces: string[];
    surfaceSettings: GroundImportSettings;
}

export function buildLayoutFormUi(context: FileImporterContext, layout: LayoutJson, catalog: Catalog, submit: (data: ImportForm) => void) {
    const total = layout.Contour3D.length + layout.Roads.length + layout.Trackers.length + layout.Transformers.length + layout.Surfaces.length;
    if (total) {
        context.setMessage(`${total} Objects found.`);
    } else {
        context.setMessage("No Objects have been found in the file. Please check the Import guide for better results.", true);
    }

    const allTrackers = getCatalogTrackers(catalog);
    const allSkid = getCatalogSkid(catalog);
    const allSurfaces = layout.Surfaces.map(s => s.Name);

    const formDataObs = new ObservableObject<ImportForm>({
        identifier: 'import-props',
        initialState: {
            trackers: getTrackersForm(layout, catalog),
            skid: getSkidForm(layout),
            mode: ImportMode.Add,
            surfaces: allSurfaces,
            surfaceSettings: new GroundImportSettings(),
        }
    });

    const paramsUi = LazyDerived.new1(
        'layout-import-process',
        [catalog.unitsMapper],
        [formDataObs],
        ([formData]) => {
            const builder = new PUI_Builder({});

            if (allSurfaces.length) {
                buildSurfacesGroup(builder, allSurfaces, formData, formDataObs);
            }
            buildObjectsGroup(builder, layout, catalog);
            if (layout.Trackers.length) {
                buildTrackersGroup(builder, allTrackers, formData, formDataObs, layout.Trackers.length);
            }
            if (layout.Transformers.length) {
                buildSkidGroup(builder, allSkid, formData, formDataObs, layout.Transformers.length);
            }

            const selectedTrackers = Object.values(formData.trackers).reduce((res, group) => (group.selected ? res + group.count : res), 0);
            const selectedTransformers = Object.values(formData.skid).reduce((res, group) => (group.selected ? res + group.count : res), 0);
            const selected = layout.Contour3D.length + layout.Roads.length + selectedTrackers + selectedTransformers + formData.surfaces.length;

            builder.inGroup(
                {
                    name: 'summary',
                    sortChildren: false,
                    collapsible: false,
                    showTitle: false,
                    typeSortKeyOverride: 4
                },
                () => {
                    builder.addCustomProp({
                        name: 'divider-line',
                        type_ident: "divider",
                        value: {},
                        onChange: () =>{ },
                        context: undefined,
                    });
                    builder.addStringProp({
                        name: 'Summary',
                        value: `${selected || 'None'} of ${total} items ${selectedTrackers ? 'will be imported' : 'selected for import'}`,
                        calculated: true,
                        onChange: () => {},
                        validator: (value) => {
                            if (layout.Trackers.length && !selectedTrackers || layout.Transformers.length && !selectedTransformers) {
                                return new Failure({uiMsg: `equipment should be selected`});
                            }
                            return new Success(value);
                        }
                    });

                    builder.addSwitcherProp({
                        name: "Import mode",
                        value: formData.mode,
                        options: [{
                            label: "Add imported to the layout",
                            value: ImportMode.Add
                        },
                        {
                            label: "Clear layout before importing",
                            value: ImportMode.Replace
                        }],
                        onChange: (value) => {
                            formDataObs.applyPatch({
                                patch: {
                                    mode: value as ImportMode,
                                },
                            });
                        },
                    });
                    builder.addActionsNode({
                        name: "importButton",
                        context: undefined,
                        actions: [
                            {
                                label: formData.mode === ImportMode.Replace ? "Replace the layout" : "Add to the layout",
                                isEnabled: new LazyBasic('', !!selected),
                                action: () => {
                                    submit(formDataObs.poll());
                                },
                                style: {
                                    type: 'primary'
                                },
                            }
                        ]
                    });
                }
            );
            return builder.finish();
        }
    ).withoutEqCheck();

    context.setForm(paramsUi);
}

export function buildSurfacesGroup(builder: PUI_Builder, allSurfaces: string[], formData: ImportForm, formDataObs: ObservableObject<ImportForm>) {
    builder.inGroup(
        {
            name: 'Surfaces',
            sortChildren: false,
            typeSortKeyOverride: -1
        },
        () => {
            allSurfaces.forEach(s => {
                builder.addBoolProp({
                    name: s,
                    value: formData.surfaces.includes(s),
                    onChange: (v) => { 
                        formDataObs.applyPatch({
                            patch: {
                                surfaces: v ? [...formData.surfaces, s] : formData.surfaces.filter(fs => fs !==s),
                            },
                        });
                    },
                });
            });
            if (!URLS.isProduction()) {
                builder.addBoolProp({
                    name: 'as regular geometry',
                    value: formData.surfaceSettings.as_regular_geometry,
                    onChange: (newValue) => {
                        formDataObs.applyPatch({
                            patch: {
                                surfaceSettings: new GroundImportSettings(newValue),
                            },
                        });
                    }
                });
            }
        }
    );
}

function getTrackersForm(layout: LayoutJson, catalog: Catalog) {
    const formData: Record<string, TrackerSelector> = {};
    const trackersByGroup = Array.from(IterUtils.groupBy(layout.Trackers, (t) => t.Name));
    trackersByGroup.forEach(([name, trackers]) => {
        const trackerData = trackers[0];
        formData[name] = {
            trackerData,
            selected: undefined,
            count: trackers.length,
            name: getTrackerName(trackerData, catalog.unitsMapper)
        };
    });
    return formData;
}

function getSkidForm(layout: LayoutJson) {
    const formData: Record<string, SkidSelector> = {};
    const transformersByGroup = Array.from(IterUtils.groupBy(layout.Transformers, (t) => t.Name));
    transformersByGroup.forEach(([name, transformers]) => {
        formData[name] = {
            selected: undefined,
            count: transformers.length,
            name: `${name} Transformer`
        };
    });
    return formData;
}

function buildObjectsGroup(builder: PUI_Builder, layout: LayoutJson, catalog: Catalog) {
    const groupedRoads = Array.from(IterUtils.groupBy(layout.Roads, road => road.Width));
    const getInfo = ([width, roads]: [number, any[]]) => {
        const {value, unit} = catalog.unitsMapper.mapToConfigured({value: width, unit: 'm'});
        return `${roads.length} segments ${value.toFixed(1)} ${unit}`;
    };
    builder.inGroup(
        {
            name: 'Objects',
            sortChildren: false,
            typeSortKeyOverride: 1
        },
        () => {
            builder.addStringProp({
                name: 'Boundaries',
                value: layout.Contour3D.length ? `${layout.Contour3D.length} polygons` : 'None',
                calculated: true,
                onChange: () => {},
            });
            builder.addStringProp({
                name: 'Roads',
                value: layout.Roads.length ? groupedRoads.map(getInfo).join('\n') : 'None',
                calculated: true,
                isTextarea: true,
                onChange: () => {},
            });
        }
    );
}

function buildTrackersGroup(
    builder: PUI_Builder,
    allOptions: TrackerCatalogItem[],
    formData: ImportForm,
    formDataObs: ObservableObject<ImportForm>,
    count: number
) {
    builder.addCustomGroup({
        name: 'import-layout-trackers',
        typeSortKeyOverride: 2,
        context: {
            name: `&times; ${count} Solar arrays found in the file`,
            items: formData.trackers,
            options: allOptions,
            activeMatching: true,
            onChange: (name: string, newValue: TrackerSelector) => {
                formDataObs.applyPatch({
                    patch: {
                        trackers: {
                            ...formData.trackers,
                            [name]: newValue
                        }
                    },
                });
            },
        },
        children: {},
    });
}

function buildSkidGroup(
    builder: PUI_Builder,
    allOptions: SkidCatalogItem[],
    formData: ImportForm,
    formDataObs: ObservableObject<ImportForm>,
    count: number
) {
    builder.addCustomGroup({
        name: 'import-layout-transformers',
        typeSortKeyOverride: 3,
        context: {
            name: `&times; ${count} Transformers found in the file`,
            items: formData.skid,
            options: allOptions,
            onChange: (name: string, newValue: SkidSelector) => {
                formDataObs.applyPatch({
                    patch: {
                        skid: {
                            ...formData.skid,
                            [name]: newValue
                        }
                    },
                });
            },
        },
        children: {},
    });
}
