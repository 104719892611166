<script lang="ts">
    import { PUI_PropertyNodeBool } from "ui-bindings";
    import { SelectAugmentationModeProperty } from "layout-service";
    import RadioButtonOption from "../../../libui/radio-buttons/RadioButtonOption.svelte";
    import { Immer } from "engine-utils-ts";
    import PropertyHelp from "../../../pui/PropertyHelp.svelte";
    import { CheckboxStandard } from "../../../libui/checkbox";
    import { NumberPropertyWithOptions } from "bim-ts";
    
    export let property: SelectAugmentationModeProperty;

    $: genBlocksProp = new PUI_PropertyNodeBool({
        name: "Combine solar arrays into blocks",
        value: property.settings.electrical.generate_new_blocks.value,
        readonly: property.settings.electrical.generate_new_blocks.isReadonly,
        onChange: (v) => {
            const updated = Immer.produce(property.settings, draft => {
                draft.electrical.generate_new_blocks = draft.electrical.generate_new_blocks.withDifferentValue(v);
                for (const block of draft.electrical.blocks_equipment) {
                    block.number_of_inverters = NumberPropertyWithOptions.new({
                        ...block.number_of_inverters,
                        selectedOption: v ? 'auto' : 'existing',
                        options: ['auto', 'existing'],
                    });
                }
                if(!v){
                    draft.electrical.blocks_equipment = property.autoFill();
                }
                return draft;
            });
            property.patch(updated);
        }
    });

    $: placeEquipment = new PUI_PropertyNodeBool({
        name: "Place block equipment",
        value: true,
        readonly: true,
        onChange: () => {}
    })
    $: isSolverPropReadonly = !property.settings.electrical.generate_new_blocks.value;

</script>

<div class="root">
    <div class="property-view property-offset">
        <span class="checkbox">
            <CheckboxStandard
                value={placeEquipment.value}
                readonly={placeEquipment.readonly}
                onChange={(newVal) => {
                    placeEquipment.value = newVal;
                }}
            />
        </span>
        <span
            class="mdc-typography--body1"
            class:text-main-dark={!placeEquipment.readonly}
            class:text-main-medium={placeEquipment.readonly}
        >
            {placeEquipment.name}
        </span>
    </div>
    <div class="property-view property-offset">
        <span class="checkbox">
            <CheckboxStandard
                value={genBlocksProp.value}
                readonly={genBlocksProp.readonly}
                onChange={(newVal) => {
                    genBlocksProp.value = newVal;
                }}
            />
        </span>
        <span
            class="mdc-typography--body1"
            class:text-main-dark={!genBlocksProp.readonly}
            class:text-main-medium={genBlocksProp.readonly}
        >
            {genBlocksProp.name}
        </span>
    </div>
    <div class="property-view">
        <div class="ui-config-property property-row bool-prop">
            <div class="property-value property-order">
                <RadioButtonOption 
                    name = {"Use experimental blocking algorithm"}
                    value = {isSolverPropReadonly ? false : !property.settings.electrical.solver.value}
                    readonly = {isSolverPropReadonly}
                    onClickCheckbox = {(v) => {
                        const newSettings = Immer.produce(property.settings, draft => {
                            draft.electrical.solver = draft.electrical.solver.withDifferentValue(!v);
                        });
                        property.patch(newSettings);
                    }}
                />
                <PropertyHelp
                    name = {"Experimental algo tooltip"}
                    content = {`
                        Standard and Experimental blocking algorithms may
                        yield varying results based on the nuances of your
                        site, layout, and equipment. Outcomes can differ
                        depending on the context.
                        <br/>
                        Please consider exploring the results from both
                        algorithms and choose the one that looks optimal.
                    `}
                />
            </div>
        </div>
    </div>
    <div class="property-view">
        <div class="ui-config-property property-row bool-prop">
            <div class="property-value">
                <RadioButtonOption 
                    name = {"Colorize blocks"}
                    value = {property.settings.electrical.colorize.value}
                    readonly = {property.settings.electrical.colorize.isReadonly}
                    onClickCheckbox = {(v) => {
                        const updated = Immer.produce(property.settings, draft => {
                            draft.electrical.colorize = draft.electrical.colorize.withDifferentValue(v);
                        });
                        property.patch(updated);
                    }}
                />
            </div>
        </div>
    </div>
</div>

<style lang="scss">
    .root {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .property-offset {
        display: flex;
        flex-direction: row;
        padding-bottom: 4px;
        gap: 4px;
    }
    .property-order {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
</style>