<script lang="ts">
    import type { PUI_ActionDescr } from "ui-bindings";
    import { ButtonComponent, Button, ButtonType } from "../libui/button";
    import type { IconName } from "../libui/icon";
    import { VersionedStore } from "../VersionedStore";

    export let actionDescr: PUI_ActionDescr<any>;
    export let context: any;

    function actionFnAsync() {
        actionDescr.action(context);
    }

    $: store = actionDescr.isEnabled
        ? new VersionedStore(actionDescr.isEnabled)
        : undefined;
    $: disabled = false;
    $: if (store !== undefined) {
        const value = $store;
        disabled = !value;
    }

    const actionButtonType: Record<string, ButtonType> = {
        primary: ButtonType.Unelevated,
        secondary: ButtonType.Text,
        outlined: ButtonType.Outlined,
        text: ButtonType.Text,
    };
    $: type =
        (actionDescr.style && actionButtonType[actionDescr.style?.type]) ??
        ButtonType.Outlined;
    $: iconName = actionDescr.style?.icon as IconName | undefined;
</script>

<div
    class="action-button"
    class:secondary-button={actionDescr.style?.type === 'secondary' ||
        type === ButtonType.Outlined}
>
    <ButtonComponent
        desc={new Button(
            actionDescr.label,
            type,
            actionFnAsync,
            disabled,
            iconName,
            false,
            actionDescr.hint
        )}
        compact = {actionDescr.style?.compact}
    />
</div>

<style lang="scss">
    @use "@material/button/_index.scss" as button;

    .action-button,
    .action-button :global(button) {
        width: 100%;
        cursor: pointer;
    }
    .action-button.secondary-button :global(button) {
        @include button.ink-color(var(--color-main-medium));
    }
</style>
