import type { AssetCatalogItemProps, Bim, Catalog, CatalogItem, CatalogItemIdType, CatalogItemsReferenceProperty, SceneInstance, SolarArrayConfig} from "bim-ts";
import { AssetCatalogItemTypeIdentifier, createNewEmptySolarArrayConfig, TrackerFrameTypeIdentifier } from "bim-ts";
import type { ScopedLogger } from "engine-utils-ts";
import { Immer, LazyDerived } from "engine-utils-ts";
import type { Vector2 } from "math-ts";
import type { PUI_CustomPropertyNodeArgs, PUI_GroupNode} from "ui-bindings";
import { ContextMenuConfig, PUI_Builder, PUI_CustomGroupNodeChildren, PUI_Lazy } from "ui-bindings";
import type { FarmConfigProperties } from "./FarmLayoutViews";

export type SolarArraysViewContext = {
    arrays: readonly SolarArrayConfig[]
    createNew: () => void
    updateConfig: (id: string, updated: SolarArrayConfig) => void
    removeConfig: (id: string) => void
}

export class SolarArraysProperties extends PUI_CustomGroupNodeChildren {
    
}

export function createSolarArraysUi(
    bim: Bim,
    logger: ScopedLogger,
    lazyConfig: LazyDerived<FarmConfigProperties>,
    pos: Vector2,
) {
    const pui = LazyDerived.new1<
        PUI_GroupNode,
        FarmConfigProperties
    >(
        'solar arrays ui',
        [],
        [lazyConfig],
        ([farmConfigProps]) => {
            const builder = new PUI_Builder({});
            if (!farmConfigProps?.config) {
                return builder.finish()
            }
            const selectedArea = farmConfigProps.config.site_areas[farmConfigProps.selectedArea];
            const areEquipmentRoadsSelectedExistedOpt = selectedArea.settings.roads.equipment_roads_options.value === "Select existing";
            const alignArraysProp = selectedArea.settings.spacing.align_arrays;
            builder.addSwitcherProp({
                name: 'Arrays alignment',
                onChange: (v) => {
                    const updatedConfig = Immer.produce(farmConfigProps.config, draft => {
                        const spacingSettings = draft.site_areas[farmConfigProps.selectedArea].settings.spacing;
                        const prop = spacingSettings.align_arrays;
                        spacingSettings.align_arrays = prop.withDifferentValue(!!v);
                    });
                    bim.configs.applyPatchTo({ properties: updatedConfig }, [farmConfigProps.id]);
                },
                value: Number(alignArraysProp.value),
                options: [{label: 'No alignment, maximise capacity', value: 0, disabled: areEquipmentRoadsSelectedExistedOpt }, {label: 'Align arrays rows', value: 1}],
            });
            
            const solarArrayConfigs = farmConfigProps.settings.electrical.solar_arrays;
            function updateConfig(id: string, updated: SolarArrayConfig) {
                const newSolarArrayConfigs = solarArrayConfigs.slice();
                const idxToUpdate = solarArrayConfigs.findIndex(x => x.id.value === id);
                if (idxToUpdate < 0) {
                    return;
                }
                newSolarArrayConfigs.splice(idxToUpdate, 1, updated);
                const updatedConfig = Immer.produce(farmConfigProps?.config, draft => {
                    if(draft && farmConfigProps){
                        const settings = draft.site_areas[draft.selected_area.value].settings;
                        settings.electrical.solar_arrays = newSolarArrayConfigs;
                    }
                });
                if(updatedConfig && farmConfigProps?.id){
                    bim.configs.applyPatchTo({
                        properties: updatedConfig,
                    }, [farmConfigProps?.id])
                }
            }
            function createNew() {
                const updatedConfig = Immer.produce(farmConfigProps?.config, draft => {
                    if(draft && farmConfigProps){
                        const settings = draft.site_areas[draft.selected_area.value].settings;
                        settings.electrical.solar_arrays.unshift(createNewEmptySolarArrayConfig());
                    }
                });
                if(updatedConfig && farmConfigProps?.id){
                    bim.configs.applyPatchTo({
                        properties: updatedConfig,
                    }, [farmConfigProps?.id])
                }
            }
            function removeConfig(id: string) {
                const updatedConfig = Immer.produce(farmConfigProps?.config, draft => {
                    if(draft && farmConfigProps){
                        const settings = draft.site_areas[draft.selected_area.value].settings;
                        settings.electrical.solar_arrays = 
                            settings.electrical.solar_arrays.filter(x=>x.id.value !== id);
                    }
                });
                if(updatedConfig && farmConfigProps?.id){
                    bim.configs.applyPatchTo({
                        properties: updatedConfig,
                    }, [farmConfigProps?.id])
                }
            }
            const context: SolarArraysViewContext = {
                arrays: solarArrayConfigs,
                createNew, 
                updateConfig,
                removeConfig,
            };
            //this logic was moved to FarmLayoutUi
            //if (!context.arrays.length) {
            //    createNew();
            //}
            builder.addCustomGroup({
                name: 'solar-arrays-view',
                context,
                children: new SolarArraysProperties(),
            });
            return builder.finish();
        }
    ).withoutEqCheck();
    const puiLazy = new PUI_Lazy(pui)
    return new ContextMenuConfig({
        identity: 'solarArraysView',
        header: 'Solar arrays configurations',
        viewSource: puiLazy,
        action: { name: 'Ok' },
        widthPx: 460,
        positionPx: pos
    });
}

function createUiFromPreset(
    preset: CatalogItemsReferenceProperty,
    applyPatch: (newPresetProperty: CatalogItemsReferenceProperty) => void,
) {
    const nodeArgs: PUI_CustomPropertyNodeArgs<any, any> = {
        name: 'Preset',
        value: preset,
        context: {},
        onChange: applyPatch,
        type_ident: 'catalog-items-selector',
    };
    return nodeArgs;
}

function createTrackerFramesLazy(catalog: Catalog) {
    return LazyDerived.new1(
        'tracker-frames',
        [catalog.assets.sceneInstancePerAsset.assetIdToSceneInstanceId],
        [catalog.catalogItems.getLazyListOf(AssetCatalogItemTypeIdentifier)],
        ([catalogItems]) => {
            const trackerFrames: Array<[
                sceneInstance: SceneInstance,
                catalogItemId: CatalogItemIdType,
                catalogItem: CatalogItem<AssetCatalogItemProps>,
            ]> = []
            const assetIdToSceneInstanceId =
                catalog.assets.sceneInstancePerAsset.assetIdToSceneInstanceId.poll();
            const catalogBim = catalog.assets.sceneInstancePerAsset.bim;
            for (const [catalogItemId, catalogItem] of catalogItems) {
                const assetCatalogItem = catalogItem.as<AssetCatalogItemProps>();
                const assetId = assetCatalogItem.properties.asset_id.value;
                const siId = assetIdToSceneInstanceId.get(assetId);
                if (!siId) {
                    continue;
                }
                const si = catalogBim.instances.peekById(siId);
                if (!si) {
                    continue;
                }
                if (si.type_identifier !== TrackerFrameTypeIdentifier) {
                    continue;
                }
                trackerFrames.push([si, catalogItemId, assetCatalogItem])
            }
            return trackerFrames;
        }
    )
}
