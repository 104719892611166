<script lang="ts">
    import { LazyBasic, LazyDerived } from "engine-utils-ts";
    import { getContext } from "svelte";
    import { VersionedStore } from "../VersionedStore";
    import {
        ComparisonMode,
        ZeroVersionIdentifier,
        VerdataVersionDescription,
        ComparisonItemView,
        CurrentVersion,
    } from "./ComparisonMode";
    import ComparisonModeMenuItemBody from "./ComparisonModeMenuItemBody.svelte";
    import ComparisonModeMenuItemHeader from "./ComparisonModeMenuItemHeader.svelte";
    import type { Catalog } from "bim-ts";
    import EngineUiPanel from "../ui-panels/EngineUiPanel.svelte";
    import PUI_Root from '../pui/PUI_Root.svelte'
    import { ProjectComparisonItems, convertToRelativeItems, buildUiFromComparisonItems, createSelectVersionPui } from "./ProjectComparisonItems";

    const comparison = getContext<ComparisonMode>("comparison");
    const catalog = getContext<Catalog>("catalog");
    const projectComparison = getContext<ProjectComparisonItems>(
        "projectComparisonItems",
    );

    const isRelativeModeLazy = new LazyBasic("relative-mode", false);
    const checkedItemIdxLazy = new LazyBasic("checked-item-idx", 0);

    const comparisonItemsHeaders = LazyDerived.new2<
        ComparisonItemView[],
        boolean,
        number
    >(
        "comparison-items-headers",
        [comparison],
        [isRelativeModeLazy, checkedItemIdxLazy],
        ([mode, checkedIdx]) => {
            if (checkedIdx >= comparison.items.size) {
                checkedItemIdxLazy.replaceWith(0);
            }
            const itemsArray = Array.from(comparison.items.values());
            const pui = mode
                ? convertToRelativeItems(
                      checkedIdx,
                      itemsArray,
                      catalog.unitsMapper,
                  )
                : buildUiFromComparisonItems(itemsArray);

            const displayItems: ComparisonItemView[] = [];
            for (let i = 0; i < itemsArray.length; i++) {
                const item = itemsArray[i];
                const getVersionDescr = (
                    ver: VerdataVersionDescription | ZeroVersionIdentifier | CurrentVersion,
                ) => {
                    return ver instanceof ZeroVersionIdentifier
                        ? `v${ver.id}`
                        : ver?.textDescription?.poll() || `v${ver.id}`;
                };
                const header = `${getVersionDescr(item.projectVersion)}`; //| Catalog ${getVersionDescr(item.catalogVersion)}

                displayItems.push({
                    id: item.id,
                    projectName:
                        item.project.name || item.project.id.toString(),
                    header,
                    pui: pui[i],
                });
            }

            return displayItems;
        },
    );

    $: comparisonItemsStore = new VersionedStore<ComparisonItemView[]>(
        comparisonItemsHeaders,
    );

    function isCheckedValue(idx: number) {
        checkedItemIdxLazy.replaceWith(idx);
    }

    $: isRelative = new VersionedStore(isRelativeModeLazy);
    $: checkedItemIdx = new VersionedStore(checkedItemIdxLazy);

    const selectVersion = createSelectVersionPui(isRelativeModeLazy, projectComparison);
</script>

<EngineUiPanel>
    <div class="button-wrapper">
        <PUI_Root configIdentifier='edit catalogItem' puiSource={selectVersion} />
    </div>
    <div
        class="main"
        style="--column-width: {100 / $comparisonItemsStore.length}%"
    >
        <div style="display: flex;">
            {#each $comparisonItemsStore as item, idx}
                <div class="column">
                    <ComparisonModeMenuItemHeader
                        {item}
                        isRelativeMode={$isRelative}
                        onCheck={() => {
                            isCheckedValue(idx);
                        }}
                        isBaseRelativeItem={idx === $checkedItemIdx}
                    />
                </div>
            {/each}
        </div>
        <div style="display: flex;">
            {#each $comparisonItemsStore as item}
                <div class="column">
                    <ComparisonModeMenuItemBody {item} />
                </div>
            {/each}
        </div>
    </div>
</EngineUiPanel>

<style lang="scss">
    .main {
        .column {
            width: var(--column-width);
            flex: 1;
            min-width: 500px;
        }
    }
    .button-wrapper {
        padding: 0 8px;
    }
</style>
