<script lang="ts">
    import { ButtonComponent, IconButton } from "src/libui/button";
    import { CheckboxStandard } from "src/libui/checkbox";

    export let name: string;
    export let selected: boolean = false;
    export let onChange: ((newVal: boolean) => void) | undefined = undefined;
    export let onSubmit: (() => void) | undefined = undefined;

    function changeValue(val: boolean) {
        if (!!onChange) {
            onChange(val);
        }
        selected = val;
    }
</script>

<div class="group-wrapper">
    <div class="export-group">
        <span class="checkbox-container">
            {#if !!onChange}
                <CheckboxStandard value={selected} onChange={changeValue} />
            {/if}
        </span>

        <div class="export-group-name mdc-typography--subheader2">
            <span>{name}</span>
        </div>
        <div class="export-group-items">
            <slot></slot>
        </div>
        {#if !!onSubmit}
            <ButtonComponent desc={new IconButton("Export", onSubmit)} />
        {/if}
    </div>
</div>

<style lang="scss">
    .group-wrapper {
        &:not(:last-child) {
            border-bottom: 1px solid var(--color-divider);
        }
        :global(.group-wrapper) {
            border-bottom: 0 !important;
        }
        :global(.group-wrapper .export-group) {
            padding-top: 0;
        }
    }
    .export-group {
        display: flex;
        gap: 8px;
        padding: 8px 0 6px;

        :global(.custom-icon-container svg) {
            height: 16px;
        }
    }
    .checkbox-container {
        height: 16px;
        width: 16px;
        padding-top: 1px;
    }
    .export-group-name {
        width: 140px;
        flex-shrink: 0;
    }
    .export-group-items {
        overflow: hidden;
        flex: 1;
    }
</style>
