<script lang="ts">
import { LazyBasic } from "engine-utils-ts";
import type { SolarArraysViewContext, SolarArraysProperties } from "layout-service";
import { setContext } from "svelte";
import type { PUI_CustomGroupNode } from "ui-bindings";
import { Button, ButtonComponent, ButtonType } from "../../../libui/button";
import { VersionedStore } from "../../../VersionedStore";
import SolarArray from "./SolarArray.svelte";
import PropertyHelp from '../../PropertyHelp.svelte';
import AlignmentHelp from "./AlignmentHelp.svelte";

export let property: PUI_CustomGroupNode<SolarArraysProperties, SolarArraysViewContext>


const contextLazy = new LazyBasic<SolarArraysViewContext>('contextLazy', property.context)
$: contextLazy.replaceWith(property.context)
const contextStore = new VersionedStore(contextLazy);
setContext('context', contextStore);

</script>

<div class="icon-help-container">
    <PropertyHelp name={property.name}>
        <AlignmentHelp />
    </PropertyHelp>
</div>

{#each property.context.arrays as array (array.id.value)}
    <SolarArray arrayConfig={array} />
{/each}

<div class="btn-container">
    <ButtonComponent
        desc={new Button(
            "Add array configuration",
            ButtonType.Text,
            () => $contextStore.createNew(),
            false,
            "AddPlus"
        )}
    />
</div>

<style lang="scss">
    .icon-help-container {
        position: relative;
        top: -26px;
        right: 4px;
        float: right;
    }
    .btn-container {
        margin-bottom: 16px;
    }
</style>
