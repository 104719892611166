<script lang="ts">
    import { onMount, createEventDispatcher } from "svelte";
    //@ts-ignore
    import Svelecte, { addFormatter } from "svelecte";
    import { IconComponent } from "../icon";
    import type { SelectedValue, Options, SelectOption } from "./types";
    import SelectItem from "./SelectItem.svelte";
    import { ButtonComponent, IconButton } from "../button";

    const dispatch = createEventDispatcher();

    export let value: SelectedValue;
    export let options: Options;
    export let disabled: boolean = false;
    export let placeholder: string;
    export let clearable: boolean = false;
    export let doubleLine: boolean = false;
    export let tag: string = "";

    addFormatter("default-renderer", function (item: SelectOption) {
        return item.text;
    });

    const overrideI18n = {
        empty: "No items",
        nomatch: "No matches found",
    };

    let selectElement: HTMLDivElement;
    export let selectControl: HTMLDivElement | null;
    let setSelection: (values: SelectedValue[], trigger?: boolean) => void;

    onMount(() => {
        selectControl = selectElement.querySelector("#singleSelectId");
    });

    function changeOnSelect(event: CustomEvent) {
        const newValue = (event.detail as SelectOption[]).find(
            (o) => o.value !== value,
        );
        if (event.detail.length && newValue) {
            setSelection([newValue.value]);
            dispatch("change", newValue);
            selectControl?.blur();
        } else if (!event.detail.length && clearable) {
            setSelection([]);
            dispatch("change", []);
            selectControl?.blur();
        } else {
            setSelection([value]);
        }
    }
</script>

<div
    class="mdc-typography--body1 custom-svelecte custom-scrollbar-light"
    class:double-line-select={doubleLine}
    bind:this={selectElement}
>
    <Svelecte
        {options}
        inputId="singleSelectId"
        class="svelecte-control custom-svelecte-control"
        renderer="default-renderer"
        value={value ? [value] : []}
        {placeholder}
        {disabled}
        disabledField="disabled"
        labelField="text"
        valueField="value"
        searchField="text"
        clearable={!disabled}
        multiple={true}
        closeAfterSelect={true}
        disableHighlight={true}
        controlItem={SelectItem}
        dropdownItem={SelectItem}
        bind:setSelection
        on:change={changeOnSelect}
        i18n={overrideI18n}
    >
        <div slot="control-end" class:lock-icon={disabled}>
            {#if disabled}
                <IconComponent name="Lock" />
            {/if}
        </div>
        <svelte:fragment slot="clear-icon" let:selectedOptions>
            {#if clearable && selectedOptions.length}
                <IconComponent name="Close" />
            {/if}
        </svelte:fragment>
        <svelte:fragment slot="indicator-icon" let:hasDropdownOpened>
            {#if tag}
                <div class="text-main-light tag-label">
                    {tag}
                </div>
            {/if}
            {#if !disabled}
                <ButtonComponent
                    desc={new IconButton("Dropdown", () => {
                        if (!hasDropdownOpened) {
                            selectControl?.blur();
                        }
                    })}
                />
            {/if}
        </svelte:fragment>
    </Svelecte>
</div>

<style lang="scss" global>
    .custom-svelecte-control {
        &.svelecte-control {
            --sv-min-height: 26px;
            --sv-border: none;
            --sv-active-border: none;
            --sv-bg: var(--color-alt);
            --sv-disabled-bg: var(--color-alt);
            --sv-placeholder-color: var(--color-main-light);
            --sv-dropdown-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.04),
                0px 2px 8px 0px rgba(0, 0, 0, 0.12);
            --sv-item-color: var(--color-main-dark);
            --sv-item-active-bg: var(--color-alt);
            --sv-item-active-color: inherit;
            --sv-item-selected-bg: transparent;
            --sv-border-color: var(--color-divider);
            --sv-dropdown-height: 247px;
        }
        .has-multiSelection .sv-item {
            margin: 0;
        }
        .sv-control {
            border-radius: 1px;
            cursor: pointer;
            &.is-active {
                --sv-bg: var(--color-alt-active);
                .tag-label {
                    display: none;
                }
            }
            &:not(.is-active) {
                .close-icon,
                .indicator-separator {
                    display: none;
                }
            }

            &.is-disabled {
                --sv-item-color: var(--color-main-medium);
            }
        }
        .inputBox {
            cursor: pointer;
            caret-color: var(--mdc-theme-primary);
        }

        .indicator {
            padding-right: 8px;
        }
        .indicator-container {
            color: var(--color-main-medium);
            padding: 0;
            height: 16px;
            justify-content: center;
            align-items: center;
            &.close-icon {
                color: var(--color-main-light);
            }
            svg {
                width: 16px;
            }
        }
        .custom-icon-container {
            width: 100%;
        }
        .indicator-separator {
            margin: 8px;
        }
        &:hover .custom-icon-container {
            color: var(--color-main-dark);
        }

        .sv-content > div {
            overflow: hidden;
            animation: none !important;
        }
        .sv-dropdown {
            &.sv-dropdown {
                background-color: white;
                border: none;
                border-radius: 1px;
                padding-top: 4px;
            }
            .sv-dropdown-scroll {
                padding: 0 0 8px;
                min-height: 52px;
            }
        }

        .sv-dropdown-content {
            .sv-item {
                cursor: pointer;
                border-radius: 0;
                padding: 0px 8px;
                line-height: 26px;
                .double-line-select & {
                    padding: 4px 8px;
                    line-height: inherit;
                }
            }
            .empty-list-row {
                line-height: 26px;
                padding: 0px 8px;
                color: var(--color-main-light);
            }
        }
        .sv-item.is-disabled {
            opacity: 1;
            --sv-item-color: var(--color-main-light);
            background-color: transparent;
        }

        .sv-group-header {
            background: white;
            position: sticky;
            top: 0px;
            &:first-child .optgroup-header {
                padding-top: 0;
            }
        }

        .sv-hidden-element {
            overflow: hidden;
            width: 100%;
        }
        .lock-icon {
            padding: 4px 8px 4px 4px;
            width: 16px;
            height: 16px;
            color: var(--color-default);
        }
        .tag-label {
            margin-right: 4px;
            text-wrap: nowrap;
        }
    }
    .custom-svelecte {
        .sv-content {
            margin-left: 8px;
            padding: 4px 0;
            min-height: 26px;
        }
        .inputBox {
            position: absolute;
            top: 4px;
        }
        &.double-line-select .sv-item-content {
            white-space: pre-line;
        }
    }
</style>
