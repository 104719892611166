import { GeoTIFFImage, ReadRasterResult, TypedArray, fromArrayBuffer } from 'geotiff';
import { ProjectionInfo, type Bim, type IdBimScene } from 'bim-ts';
import { Failure, IterUtils, PollablePromise, Yield } from 'engine-utils-ts';
import type { FileImporter, FileImporterContext, FileToImport } from 'ui-bindings';
import { FeetToMeters } from '../dxf/DxfUnitsConverters';
import { convertTerrainElevationsGridToTerrainHeightmapInstance } from 'src/TerrainToRegularGridConverter';
import { epsg } from '../WGSConverter';


export class TifImporter implements FileImporter {

    fileExtensions = ['.tif'];

    constructor(
        readonly bim: Bim
    ) {
    }

    *startImport(context: FileImporterContext, file: FileToImport): Generator<Yield, void> {
        const tiffParsedResultRes = yield* PollablePromise.generatorWaitFor(fromArrayBuffer(file.fileArrayBuffer));
        if (tiffParsedResultRes instanceof Failure) {
            throw new Error('Failed to parse tiff file: ' + tiffParsedResultRes.uiMsg ?? '');
        }
        const tiffParsed = tiffParsedResultRes.value;
        const imageCount = yield* PollablePromise.generatorWaitForOrThrow(tiffParsed.getImageCount());
        const resultIds: IdBimScene[] = [];

        for (let i = 0; i < imageCount; ++i) {
            try {
                const image = yield* PollablePromise.generatorWaitForOrThrow<GeoTIFFImage>(tiffParsed.getImage(i));

                const width = image.getWidth();
                const height = image.getHeight();

                const modelType = image.geoKeys?.GTModelTypeGeoKey;
                const unit = image.geoKeys?.ProjLinearUnitsGeoKey ?? 9001;
                let epsgCode = image.geoKeys?.ProjectedCSTypeGeoKey;
                let projectionInfo: ProjectionInfo | undefined = undefined;
                if (!epsgCode) {
                    const projectionName = image.geoKeys?.GTCitationGeoKey;
                    switch (projectionName) {
                        case "AL83-EF":
                            epsgCode = 3465;
                            break;
                        case "AL83-WF":
                            epsgCode = 3466;
                            break;
                        default:
                            break;
                    }
                }
                if (epsgCode) {
                    const proj4datum = epsg.get(epsgCode.toString())?.proj4;
                    if (proj4datum) {
                        projectionInfo = ProjectionInfo.fromProj4Datum(proj4datum, epsgCode, context.sendNotification);
                    }
                }

                const bbox: number[] = image.getBoundingBox();
                if (modelType !== 2) {
                    if (unit === 9002) {
                        bbox[0] = FeetToMeters(bbox[0]);
                        bbox[1] = FeetToMeters(bbox[1]);
                        bbox[2] = FeetToMeters(bbox[2]);
                        bbox[3] = FeetToMeters(bbox[3]);
                    } else if (unit === 9003) {
                        bbox[0] = bbox[0] * 1200 / 3937;
                        bbox[1] = bbox[1] * 1200 / 3937;
                        bbox[2] = bbox[2] * 1200 / 3937;
                        bbox[3] = bbox[3] * 1200 / 3937;
                    }
                }

                const elevationsResult: ReadRasterResult =
                    yield* PollablePromise.generatorWaitForOrThrow<ReadRasterResult>(image.readRasters());

                let elevationsForBim: Float32Array;
                if (elevationsResult instanceof Array) {
                    elevationsForBim = this.remapElevations(elevationsResult[0], width, height, unit);
                } else {
                    elevationsForBim = this.remapElevations(elevationsResult, width, height, unit);
                }                
                
                yield Yield.NextFrame;

                const bimPatch = yield* convertTerrainElevationsGridToTerrainHeightmapInstance({
                    context: context,
                    bim: this.bim,
                    elevationsInMeters: elevationsForBim,
                    pointsCountX: width,
                    pointsCountY: height,
                    pixelIsArea: image.pixelIsArea(),
                    minMaxCoords: bbox,
                    isMinMaxGeo: modelType === 2,
                    projectionInfo: projectionInfo,
                    fileName: file.filename,
                    sendNotification: context.sendNotification,
                });

                yield Yield.NextFrame;
                const ids = bimPatch.applyTo(this.bim);
                IterUtils.extendArray(resultIds, ids);
            } catch { }
        }
        this.bim.instances.setSelected(resultIds);
        yield* context.onFinish(resultIds);
    }

    remapElevations(tiffElevations: TypedArray, width: number, height: number, unit: number) {
        // Looks like tiff elevations rows are stored from top to down
        // we should remaps them
        let elevationsForBim = new Float32Array(tiffElevations.length);;

        for (let iy = 0; iy < height; ++iy) {
            for (let ix = 0; ix < width; ++ix) {
                const tiffElevation = tiffElevations[iy * width + ix];
                if (unit === 9002) {
                    elevationsForBim[(height - iy - 1) * width + ix] = FeetToMeters(tiffElevation);
                } else if (unit === 9003) {
                    elevationsForBim[(height - iy - 1) * width + ix] = tiffElevation * 1200 / 3937;
                } else { 
                    elevationsForBim[(height - iy - 1) * width + ix] = tiffElevation;
                }
            }
        }

        return elevationsForBim;
    }
}