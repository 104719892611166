import type { LayoutEquipment, MetricsGroup, PropertyGroup, UnitsMapper} from "bim-ts";
import { AreaTypeEnum, MetricsRows} from "bim-ts";
import { type Bim, SceneObjDiff, MetricTableValues, mergeMetricsToTable } from "bim-ts";
import { ErrorUtils, ObservableObject, ScopedLogger} from "engine-utils-ts";
import { IterUtils, LazyDerived, type LazyVersioned } from "engine-utils-ts";
import type { MultiSelectorValue} from "ui-bindings";
import { PUI_Builder, type PUI_GroupNode } from "ui-bindings";
import { MetricGroupNode, MetricPropertyNode } from "./metrics-view/MetricProperty";
import type { VerdataVersionDescription, ZeroVersionIdentifier } from "../ComparisonMode/ComparisonMode";
import { PanelPosition, type NavbarContext } from "../navbar/Navbar";

export interface VerDataCurrentVersions {
    projectVersion: VerdataVersionDescription | ZeroVersionIdentifier,
    catalogVersion: VerdataVersionDescription | ZeroVersionIdentifier,
}
export const projectOverviewSettings = new ObservableObject<ProjectOverviewSettingsType>({
    identifier: 'selected-areas',
    initialState: { 
        selectedAreas: [],
        initWidthPanelPx: 400,
    },
});

export interface ProjectOverviewSettingsType {
    selectedAreas: string[];
    initWidthPanelPx: number;
}

export interface VerDataDescription {
    projectMessage: string;
    catalogMessage: string;
}

export function resizePanel(navBar: NavbarContext, countItems: number){
    const initialWidthPx = projectOverviewSettings.poll().initWidthPanelPx;
    const viewWidth = window.innerWidth;
    const propWidthPx = 380;
    const valueWidthPx = 120;
    const maxCountItems = Math.max(Math.trunc((viewWidth - propWidthPx) / valueWidthPx) - (navBar.isExpanded() ? 1: 0), 1);
    
    const widthPx = countItems < 2 
        ? initialWidthPx 
        : propWidthPx + valueWidthPx * Math.min(countItems, maxCountItems);
    navBar.setPanelWidth(PanelPosition.Left, widthPx);
}

export function createProjectOverviewUi(
    bim: Bim,
    layoutEquipmentLazy: LazyVersioned<LayoutEquipment>,
    settingsConfig: ObservableObject<ProjectOverviewSettingsType>,
    verdataDescription: LazyVersioned<VerDataDescription>,
    navBar: NavbarContext,
): LazyVersioned<PUI_GroupNode> {
    const substationsList = bim.instances.getLazyListOf({
        type_identifier: "substation",
        relevantUpdateFlags: SceneObjDiff.Name,
    });

    const lazyUi = LazyDerived.new3(
        "project-overview-ui-lazy",
        [substationsList],
        [layoutEquipmentLazy, settingsConfig, verdataDescription],
        ([layoutConfig, settings, verdataDescr]) => {
            const builder = new PUI_Builder({
                sortChildrenDefault: false,
                rootName: "Project Metrics Overview",
            });
            const addDivider = (name: string) => {
                builder.addCustomProp({
                    name,
                    context: {},
                    value: {},
                    onChange: () => {},
                    type_ident: "divider",
                });
            };
            builder.addCustomProp<
                string[],
                { 
                    onClick: () => void;
                    iconName?: string;
                }
            >({
                name: "Catalog version",
                context: {
                    onClick: () => {
                        navBar.openOverlayPanel("Catalog Versions");
                    },
                },
                value: [verdataDescr.catalogMessage],
                type_ident: "group_properties",
                onChange: () => { },
            });

            builder.addCustomProp<
                string[],
                { 
                    onClick: () => void;
                    iconName?: string;
                }
            >({
                name: "Project version",
                context: {
                    onClick: () => {
                        navBar.openOverlayPanel("Project Versions");
                    },
                },
                value: [verdataDescr.projectMessage],
                type_ident: "group_properties",
                onChange: () => { },
            });
            // addDivider("Report profile start");
            // builder.addSelectorProp({
            //     name: "Report profile",
            //     value: "Default",
            //     onChange: () => {},
            //     options: ["Default", "Custom"],
            // });
            addDivider("Selected area start");
            const options = new Map<string, MultiSelectorValue>();
            for (const area of layoutConfig.areas) {
                options.set(area.id, { label: area.name, value: area.id });
            }
            if(settings.selectedAreas.length === 0){
                settingsConfig.applyPatch({ patch: { 
                        selectedAreas: layoutConfig.areas.filter(a => a.type === AreaTypeEnum.Total).map(a => a.id) 
                    }
                });
                resizePanel(navBar, settingsConfig.poll().selectedAreas.length);
            } else if(!settings.selectedAreas.every(a => options.has(a))){
                settingsConfig.applyPatch({ patch: { selectedAreas: settings.selectedAreas.filter(a => options.has(a)) } });
                resizePanel(navBar, settingsConfig.poll().selectedAreas.length);
            }

            builder.addMultiSelectorProp({
                name: "Selected area",
                value: IterUtils.filterMap(settings.selectedAreas, (a) => options.get(a)),
                onChange: (v) => {
                    settingsConfig.applyPatch({ patch: { selectedAreas: v.map(o => o.value.toString()) } });
                    resizePanel(navBar, v.length);
                },
                options: Array.from(options.values()),
                enableSelectAll: true,
                doubleLine: true,
            });
            addDivider("Selected area end");

            return builder.finish();
        }
    ).withoutEqCheck();

    return lazyUi;
}

export function convertMetricsPropsToUiNodes(
    settings:  ProjectOverviewSettingsType,
    layoutEquipment: LayoutEquipment,
    metrics: MetricsGroup<Partial<PropertyGroup>>[],
    unitsMapper: UnitsMapper,
): MetricGroupNode {
    const logger = new ScopedLogger('convertMetricsPropsToUiNodes');
    const areasIdsSet = new Set(settings.selectedAreas);
    const rootNode = new MetricGroupNode({
        name: "Project Metrics",
    });
    const selectedAreas = IterUtils.filterMap(layoutEquipment.areas, (area) => (areasIdsSet.has(area.id) ? area : undefined));
    if(!(selectedAreas.length === 1 && selectedAreas[0].type === AreaTypeEnum.Total)){
        const headerNames = selectedAreas.map((a) => a.name);
        const header = new MetricPropertyNode({name: "   ", value: headerNames, isHeader: true});
        rootNode.children.set("header", header);
    }

    const metricsPerId = new Map(metrics.map((m) => [m.id, m]));
    const filteredMetrics = IterUtils.filterMap(settings.selectedAreas, (id) => metricsPerId.get(id));
    const filteredAreas = IterUtils.filterMap(settings.selectedAreas, (id) => layoutEquipment.areas.find((a) => a.id === id));
    const table = mergeMetricsToTable(filteredMetrics, filteredAreas, unitsMapper, logger);
    convertTableToUiNodes(table.rows, rootNode);

    return rootNode;
}

function convertTableToUiNodes(
    rows: MetricsRows,
    rootNode: MetricGroupNode,
) {
    for (const [key, prop] of rows.rows) {
        if (prop instanceof MetricTableValues) {
            const node = new MetricPropertyNode({
                name: prop.name,
                value: prop.value,
                unit: prop.unit,
                decimals: prop.displayDecimals,
            });
            rootNode.children.set(key, node);
        } else if(prop instanceof MetricsRows) {
            const total = prop.tryCalculateTotal();
            const group = new MetricGroupNode({
                name: prop.name, 
                total: total 
                    ? new MetricPropertyNode({
                        name: total.name, 
                        value: total.value, 
                        unit: total.unit, 
                        decimals: total.displayDecimals,
                        isHeader: true,
                    })
                    : undefined,
            });

            convertTableToUiNodes(prop, group);
            rootNode.children.set(key, group);
        } else {
            ErrorUtils.logThrow('unexpected type', prop);
        }
    }
}

