import type { CostModel, TrackerBins} from 'bim-ts';
import { AnyTrackerProps, PileBinsConfig, type IdBimScene, type IdPile, type ProjectMetrics} from 'bim-ts';
import { BimProperty, BooleanProperty, EntitiesUpdated, MetricTableValues, MetricsRows, NumberProperty, PropertyBase, SceneInstance, SceneObjDiff, StringProperty, TrackerPile, handleEntitiesUpdates, mergeMetricsToTable, type Bim, type EntitiesCollectionUpdates, type ICollection, type TrackerPilesCollection } from 'bim-ts';
import type { OneToMany, PollWithVersionResult, TasksRunner } from 'engine-utils-ts';
import { DefaultMap, ErrorUtils, LazyDerived, ScopedLogger, StreamAccumulator, Success, VersionedInvalidator, Yield, type LazyVersioned } from 'engine-utils-ts';

import type { FilterModel } from './Filter';
import { filterList } from './Filter';
import { GroupTypesMap } from './GroupTypesMap';
import { getData } from './PrepareData';

import type {
    ColDef, IServerSideGetRowsRequest, LoadSuccessParams,
} from "ag-grid-enterprise";
import { IdsProvider } from 'verdata-ts';
import { PropertyTypeToTableRowValue, type RowModel, type RowValue } from './Models';
import { QuantitiesItemsCollection, type CommonIdType, type CommonType } from './QuantityItemsCollection';
import { TableColumnsStore } from './TableColumnsStore';
import { traverseByProperties } from './TableUtils';


export interface LayoutMetricProperty {
    path: string[],
    property: PropertyBase,
}
  
export class LayoutMetricsConfig {
    readonly type: string;
    readonly properties: LayoutMetricProperty[];
  
    constructor(properties: LayoutMetricProperty[]) {
      this.type = 'layout';
      this.properties = properties;
    }
  }

interface InstanceDescription {
    readonly id: CommonIdType;
    readonly stateRef: CommonType;
}

class QuantitiesStore {
    columns: TableColumnsStore;
    allTypes: Set<string>;
    items: Map<CommonIdType, InstanceDescription>;

    constructor(args:{
        columns: TableColumnsStore,
        allTypes?: Set<string>,
        items?: Map<CommonIdType, InstanceDescription>,
    }){
        this.columns =  args.columns;
        this.allTypes = args.allTypes===undefined ? new Set(): args.allTypes;
        this.items = args.items===undefined ? new Map(): args.items;
    }
}

export interface GetRowsResponse {
    params: LoadSuccessParams;
    secondaryColDefs: ColDef[] | null;
    columnsDefs: ColDef[];
    version: number;
}

export class QuantitiesItems {
    private parentsPerId: DefaultMap<CommonIdType, Set<CommonIdType>> | undefined;
    constructor(
        private readonly logger: ScopedLogger,
        private readonly bim: Bim,
        private readonly tasksRunner: TasksRunner,
        private readonly trackerByPilesRefs: OneToMany<IdBimScene, IdPile>,
        private readonly trackerPerBin: LazyVersioned<Map<IdBimScene, TrackerBins>>,
        private readonly pilesCosts: LazyVersioned<CostModel.InstanceCost[]>,
        public readonly store: QuantitiesStore,
        public readonly version: number
    ) {
    }

    private getParentsMap(){
        if(!this.parentsPerId){
            this.parentsPerId = this._getParentsMap(this.store.items, this.bim);
        }
        return this.parentsPerId;
    }

    private getColDefs(items?: Set<string>):ColDef[]{
        const cols = this.store.columns.getAll(this.store.allTypes);

        const visibleCols: ColDef[] = [];
        for (const [field, col] of cols) {
            if(!items || items.size === 0){
                visibleCols.push(col);
            } else if (items.has(field)) {
                visibleCols.push(col);
            }
        }
        return visibleCols;
    }

    public async getRowsWithColumnsAsync(request: IServerSideGetRowsRequest): Promise<GetRowsResponse> {
        const task = this.tasksRunner.newLongTask({
            identifier: 'getRowsWithColumnsAsync',
            defaultGenerator: this.getRowsWithColumnsGenerator(request),
        }).asPromise();
        return task;
    }

    private *getRowsWithColumnsGenerator(request: IServerSideGetRowsRequest) {
        for (let i = 0; i < 3; i++) {
            yield Yield.NextFrame;
        }
        const items = yield* this.buildItemsAsync(request);
        return this.prepareResponse(items, request);
    }

    public getRowsWithColumns(request: IServerSideGetRowsRequest): GetRowsResponse {
        this.logger.debug('request', request);

        const items = this.buildItems(request);

        const response = this.prepareResponse(items, request);

        this.logger.debug('response', response);
        return response;
    }

    private prepareResponse(items: ReturnType<typeof getData>, request: IServerSideGetRowsRequest): GetRowsResponse {
        const rowData: RowModel[] = [];
        const startIndex = request.startRow ? request.startRow : 0;
        const endIndex = request.endRow ? request.endRow : items.rows.length;
        for (let i = startIndex; i <= endIndex; i++) {
            if (i < items.rows.length) {
                rowData.push(items.rows[i]);
            }
        }

        this.logger.debug('usedColumns ', items.usedColumns);
        const columnsDefs = this.getColDefs(items.usedColumns);

        const response: GetRowsResponse = {
            params: {
                rowData,
                rowCount: this.getRowCount(request, rowData),
            },
            secondaryColDefs: items.secondaryColDefs,
            columnsDefs: columnsDefs,
            version: this.version,
        };
        return response;
    }

    public *getGroupsRows(groups:string[], filterModel:FilterModel|undefined, isPivotMode: boolean){
        yield Yield.Asap;
        const rows = yield* this.getAllRowsAsync();
        const filtered = filterList(rows, filterModel);
        yield Yield.Asap;
        const map = GroupTypesMap.init(filtered, groups, isPivotMode);
        yield Yield.Asap;
        return map;
    }

    private convertSceneInstanceToRow(counter: number, id:CommonIdType, instance: SceneInstance):RowModel {
        const parent_type = this.getParentType(instance.spatialParentId);

        const row: RowModel = {
            //index: counter,
            id,
            type: instance.type_identifier,
            name: instance.name,
            parent_id: instance.spatialParentId,
            parent_type,
            is_hidden: instance.isHidden,
        };
        const addProperty = (mergedPath: string, rowValue: RowValue) => {
            const columnDef = this.store.columns.get(mergedPath);
            if (columnDef !== undefined) {
                row[columnDef.field] = rowValue;
            } else {
                this.logger.batchedWarn(`properties - ${mergedPath} doesn't found in instance with id ${id}`, [mergedPath, rowValue]);
            }
        }
        for (const prop of instance.properties.values()) {
            let rowValue: RowValue;
            if (typeof prop.value === 'number') {
              rowValue = {
                value: prop.value,
                unit: prop.unit ?? undefined,
              }
            } else {
              rowValue = prop.value;
            }
            addProperty(prop._mergedPath, rowValue);
        }

        traverseByProperties(instance.props, (prop, path) => {
            if(!(prop instanceof PropertyBase)){
                return;
            }
            const mergedPath = BimProperty.MergedPath(path.map(p => p.toString()));
            if(prop instanceof NumberProperty){
                const rowValue: RowValue = {
                    value: prop.value,
                    unit: prop.unit ?? undefined,
                }
                addProperty(mergedPath, rowValue);
            } else if(prop instanceof StringProperty || prop instanceof BooleanProperty){
                const rowValue: RowValue = prop.value;
                addProperty(mergedPath, rowValue);
            } else {
                this.logger.batchedDebug(`unprocessed property type ${prop.constructor.name}`, prop);
            }
        });

        this.setParents(id, row);

        return row;
    }

    private getParentType(parent_id: CommonIdType){
        const parentInst = this.store.items.get(parent_id);
        let parent_type = "none";
        if (parentInst && parentInst.stateRef instanceof SceneInstance) {
            parent_type = parentInst.stateRef.type_identifier;
        }

        return parent_type;
    }


    private convertPileToRow(counter: number, id:CommonIdType, pile:TrackerPile) {
        const pileParentId = this.trackerByPilesRefs.getParent(id);
        if(pileParentId === undefined){
            this.logger.error(`pile-${id} doesn't have parent`);
            return;
        }
        const parent_type = this.getParentType(pileParentId);
        const row:RowModel ={
            //index: counter,
            id,
            type: `tracker-pile`,
            parent_id: pileParentId,
            parent_type: parent_type,
            is_hidden:  pile.isHidden,
        };
        const bin = this.trackerPerBin.poll().get(pileParentId);
        const area_index = this.bim.instances.peekById(pileParentId)?.properties.get("circuit | position | area_index")?.asNumber();
        for (const prop of pile.asBimProperties({trackerBin: bin, area_index: area_index, instanceCosts: this.pilesCosts.poll()})) {
            row[prop._mergedPath] = typeof prop.value === 'number' ? {
                value: prop.value,
                unit: prop.unit ?? undefined
            } : prop.value;
        }

        this.setParents(id, row);

        return row;
    }

    private convertLayoutMetricsToRow(counter: number, id: CommonIdType, metrics: LayoutMetricsConfig) {

        const row: RowModel = {
            //index: counter,
            id: id as number,
            type: metrics.type,
        };

        for (const metric of metrics.properties) {
            const mergePath = BimProperty.MergedPath(metric.path);
            row[mergePath] = PropertyTypeToTableRowValue(metric.property);
        }

        this.setParents(id, row);

        return row;
    }

    private setParents(id:CommonIdType, row: RowModel){
        const parents = this.getParentsMap().get(id);
        if(!parents){
            return;
        }
        for (const p of parents) {
            const parent_type = this.getParentType(p);
            row[`parents ${parent_type}`] = p;
        }
    }

    private getRowCount(request: IServerSideGetRowsRequest, results: RowModel[]) {
        if (results === null || results === undefined) {
            return;
        }
        if(results.length === 0){
            return 0;
        }
        const endRow = request.endRow ? request.endRow : 0;
        const startRow = request.startRow ? request.startRow : 0;
        const currentLastRow = startRow + results.length;
        return currentLastRow <= endRow ? currentLastRow : -1;
    }

    private cachedAllRows?: RowModel[]
    private buildItems(request: IServerSideGetRowsRequest){
        const rows = this.cachedAllRows ?? this.getAllRows();
        this.cachedAllRows = rows;

        const ordered = getData(request, rows, this.bim.unitsMapper);
        return ordered;
    }

    private *buildItemsAsync(request: IServerSideGetRowsRequest){
        const rows = yield* this.getAllRowsAsync();
        const ordered = getData(request, rows, this.bim.unitsMapper);
        yield Yield.NextFrame;
        
        return ordered;
    }
    
    private *getAllRowsAsync(){
        const rows: RowModel[] = [];
        let counter = 0;
        for (const [id, desc] of this.store.items) {
            const row = this.convertRow(desc, counter, id);
            if(row){
                rows.push(row);
            }
            counter++;
            if(counter % 2000 === 0){
                yield Yield.NextFrame;
            }
        }
        yield Yield.NextFrame;
        return rows;
    }

    private getAllRows(){
        const rows: RowModel[] = [];
        let counter = 0;

        for (const [id, desc] of this.store.items) {
            const row = this.convertRow(desc, counter, id);
            if(row){
                rows.push(row);
            }

            counter++;
        }
        return rows;
    }

    private convertRow(desc: InstanceDescription, counter: number, id: number): RowModel | undefined {
        let row: RowModel | undefined = undefined;
        if (desc.stateRef instanceof SceneInstance) {
            row = this.convertSceneInstanceToRow(counter, desc.id, desc.stateRef);
        } else if (desc.stateRef instanceof TrackerPile) {
            row = this.convertPileToRow(counter, desc.id, desc.stateRef);
        } else if (desc.stateRef instanceof LayoutMetricsConfig) {
            row = this.convertLayoutMetricsToRow(counter, desc.id, desc.stateRef);
        } else {
            this.logger.error(`unrecognition item-${id} type, ignoring `, desc.stateRef);
        }
        return row;
    }
    private _getParentsMap(collection: Map<CommonIdType, InstanceDescription>, bim: Bim): DefaultMap<CommonIdType, Set<CommonIdType>>{
        const result = new DefaultMap<CommonIdType, Set<CommonIdType>>(() => new Set<CommonIdType>());
    
        function addParents(id: CommonIdType, decr: InstanceDescription | undefined, trackerByPilesRefs: OneToMany<IdBimScene, IdPile>) {
            if(!decr){
                console.error('Not found item with id: ', id);
                return;
            }
    
            let parent = 0;
            if(decr.stateRef instanceof SceneInstance){
                parent = decr.stateRef.spatialParentId;
            } else if(decr.stateRef instanceof TrackerPile){
                const pileParentId = trackerByPilesRefs.getParent(decr.id);
                if(pileParentId === undefined){
                    console.error(`pile-${decr.id} doesn't have parent`);
                    
                } else {
                    parent = pileParentId;
                }
            } else if(decr.stateRef instanceof LayoutMetricsConfig) {
                //metrics without parent
            } else {
                console.error('undefined type', decr);
            }
            if(parent){
                const parents = result.getOrCreate(id);
                const grandParents = result.get(parent);
                if(grandParents){
                    for (const p of grandParents) {
                        parents.add(p);
                    }
                }
                parents.add(parent);
            }
        }
    
        for (const rootId of bim.instances.spatialHierarchy._rootObjects.keys()) {
            bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(rootId, (id) => {
                const decr = collection.get(id);
                addParents(id, decr, this.trackerByPilesRefs);
                return true;
            });
        }

        for (const [id, decr] of collection) {
            if(decr.stateRef instanceof SceneInstance){
                continue;
            }
            addParents(id, decr, this.trackerByPilesRefs);
        }

        return result;
    }
}

export class QuantityItemsLazy implements LazyVersioned<QuantitiesItems> {
    readonly updatesAccumulator: StreamAccumulator<
        EntitiesCollectionUpdates<CommonIdType, SceneObjDiff>
    >;
    readonly relevantObjectsDiff: SceneObjDiff;

    _latestState: QuantitiesItems;
    readonly idsProvider: IdsProvider<number>;

    readonly _allObjects: Map<CommonIdType, InstanceDescription>;
    readonly _collectionItems: Map<CommonIdType, InstanceDescription>;
    readonly _columns: TableColumnsStore;
    readonly _allTypes = new Set<string>();

    readonly logger: ScopedLogger;
    readonly bim: Bim;
    readonly tasksRunner: TasksRunner;

    readonly itemsCollection: ICollection<CommonIdType, CommonType>;
    private _versionInvalidator: VersionedInvalidator;
    private readonly _updates: LazyVersioned<null>;
    private readonly _trackersByPilesRefs: OneToMany<IdBimScene, IdPile>;

    constructor(args: {
        identifier: string;
        logger: ScopedLogger;
        bim: Bim;
        itemsCollection: ICollection<CommonIdType, CommonType>;
        versionedItems: LazyVersioned<CommonType | CommonType[]>[];
        tasksRunner: TasksRunner;
        trackersByPilesRefs: OneToMany<IdBimScene, IdPile>,
        trackerPerBinLazy: LazyVersioned<Map<IdBimScene, TrackerBins>>,
        pilesCosts: LazyVersioned<CostModel.InstanceCost[]>,
    }) {
        this.tasksRunner = args.tasksRunner;
        this.relevantObjectsDiff =
                SceneObjDiff.Hidden
            | SceneObjDiff.Name
            | SceneObjDiff.LegacyProps
            | SceneObjDiff.NewProps
            | SceneObjDiff.SpatialParentRef
            | SceneObjDiff.SpatialChildrenList;
        this._trackersByPilesRefs = args.trackersByPilesRefs;

        this.itemsCollection = args.itemsCollection;
        this.bim = args.bim;
        this.logger = args.logger.newScope(args.identifier);
        this.idsProvider = new IdsProvider<number>(30);

        this._allObjects = new Map();
        this._collectionItems = new Map();
        this._columns = new TableColumnsStore(
            this.bim.unitsMapper,
            this.logger.newScope("columns-store")
        );
        this._versionInvalidator = new VersionedInvalidator([
            args.bim.unitsMapper,
            args.trackerPerBinLazy,
            args.pilesCosts,
        ]);
        this._allTypes = new Set([`tracker-pile`]);

        this._latestState = new QuantitiesItems(
            this.logger,
            this.bim,
            this.tasksRunner,
            args.trackersByPilesRefs,
            args.trackerPerBinLazy,
            args.pilesCosts,
            new QuantitiesStore({
                columns: this._columns,
                allTypes: this._allTypes,
                items: this._allObjects,
            }),
            this._versionInvalidator.version()
        );

        this.updatesAccumulator = new StreamAccumulator(
            args.itemsCollection.updatesStream,
            (update) => {
                if (
                    update instanceof EntitiesUpdated &&
                    (update.allFlagsCombined & this.relevantObjectsDiff) === 0
                ) {
                    return false;
                }
                return true;
            }
        );

        const collectionUpdates = LazyDerived.new0(
            "collectionUpdates",
            [this.updatesAccumulator],
            () => {
                const deltas = this.updatesAccumulator.consume();
                if (!deltas) {
                    return null;
                }
                handleEntitiesUpdates(
                    deltas,
                    (ids) => this.addObjectsCollection(ids),
                    (changes) => this.updateObjects(changes),
                    (ids) => this.removeObjects(ids)
                );
                return null;
            }
        );
        const addNewItem = (item: CommonType) => {
            if(item) {
                const newId = this.idsProvider.reserveNewId();
                this.addObject(newId, item);
            }
        }
        const versionedItems = LazyDerived.fromArr(
            "versionedItems",
            null,
            args.versionedItems,
            (items) => {
                if (items.length === 0) {
                    return null;
                }
                for (const [id] of this._allObjects) {
                    if (this.idsProvider.isValidId(id)) {
                        this._allObjects.delete(id);
                    }
                }
                for (const item of items) {
                    if(Array.isArray(item)){
                        for (const i of item) {
                            addNewItem(i);
                        }
                    } else {
                        addNewItem(item);
                    }
                }

                this._versionInvalidator.invalidate();
                return null;
            }
        );
        this._updates = LazyDerived.new0(
            "updates",
            [collectionUpdates, versionedItems, this._versionInvalidator],
            () => {
                this._latestState = new QuantitiesItems(
                    this.logger,
                    this.bim,
                    this.tasksRunner,
                    this._trackersByPilesRefs,
                    args.trackerPerBinLazy,
                    args.pilesCosts,
                    new QuantitiesStore({
                        items: this._allObjects,
                        columns: this._columns,
                        allTypes: this._allTypes,
                    }),
                    this._versionInvalidator.version(),
                );
                // this.logger.info("poll " + this._versionInvalidator.version());
                return null;
            }
        );
    }

    pollWithVersion(): PollWithVersionResult<QuantitiesItems> {
        return {
            value: this.poll(),
            version: this._versionInvalidator.version(),
        };
    }

    poll(): QuantitiesItems {
        this._updates.poll();
        return this._latestState;
    }

    dispose(): void {
        this.updatesAccumulator.dispose();
    }

    version(): number {
        this._updates.poll();
        return this._versionInvalidator.version();
    }

    private addObjectsCollection(ids: Iterable<CommonIdType>) {

        const objects = this.itemsCollection.peekByIds(ids);
        for (const [id, stateRef] of objects) {
            this.addObject(id, stateRef);
        }

        this._versionInvalidator.invalidate();
    }

    private addObject(id: CommonIdType, stateRef: CommonType) {
        const description = { id, stateRef };
        this._allObjects.set(id, description);
        this._columns.add(stateRef);
        if (stateRef instanceof SceneInstance) {
            this.addType(stateRef.type_identifier);
        } else if (stateRef instanceof TrackerPile) {
        } else if (stateRef instanceof LayoutMetricsConfig) {
            this.addType(stateRef.type);
        } else {
            this.logger.error("unprocessed type", [id, stateRef]);
        }
    }

    private addType(type: string) {
        this._allTypes.add(type);
    }

    private updateObjects(
        objects: Iterable<[id: CommonIdType, diff: SceneObjDiff]>
    ) {
        let updateVersion = false;
        for (const [id, diff] of objects) {
            if ((diff & this.relevantObjectsDiff) === 0) {
                continue; // use diff to skip early
            }

            const stateRef = this.itemsCollection.peekById(id);
            if (stateRef) {
                this._columns.add(stateRef);
            } else {
                this.logger.error("item not found: ", [id, diff]);
            }

            updateVersion = true;
        }

        if (updateVersion) {
            this._versionInvalidator.invalidate();
        }
    }

    private removeObjects(ids: Iterable<CommonIdType>) {
        let hasUpdates = false;
        for (const id of ids) {
            this._allObjects.delete(id);
            hasUpdates = true;
        }
        if (hasUpdates) {
            this._versionInvalidator.invalidate();
        }
    }
}


export function createQuantityItemsLazy(
    identifier: string,
    bim: Bim,
    tasksRunner: TasksRunner,
    layoutMetrics: Readonly<ProjectMetrics>,
    pilesCollection: TrackerPilesCollection,
    costsProvider: CostModel.CostsConfigProvider,
): {items:LazyDerived<QuantitiesItems>, dispose: () => void} {
    const mergedLayoutMetrics = LazyDerived.new2(
        "mergedLayoutMetrics", 
        null, 
        [layoutMetrics, layoutMetrics.areasContext],
        ([metrics, areaContext]) => {
            const metricsProps = metrics instanceof Success 
                ? metrics.value 
                : [];
            const layoutMetricsByType: LayoutMetricsConfig[] = [];
            if(metricsProps.length > 0){
                const table = mergeMetricsToTable(metricsProps, areaContext.areas, bim.unitsMapper, new ScopedLogger('quantities'));
                convertMetricsToQuantityItems(layoutMetricsByType, table.rows);
            }
        return layoutMetricsByType;
    });
    const pilesCosts = costsProvider.lazyInstanceCostsByType(`tracker-pile`);

    const trackerPerBinLazy = LazyDerived.new2(
        "bindPerTrackerLazy",
        null,
        [
            bim.configs.getLazySingletonProps({type_identifier: PileBinsConfig.name, propsClass: PileBinsConfig}), 
            bim.instances.getLazyListOf({type_identifier: "any-tracker"}),
        ],
        ([props, trackers]) => {
            const binsPerTracker = new Map<IdBimScene, TrackerBins>();
            for (const [id, inst] of trackers) {
                const trackerName = inst.propsAs(AnyTrackerProps).tracker_frame.commercial.model.value;
                const trackerBins = props.getBinsByTracker(trackerName);
                if(!trackerBins){
                   continue;
                }
                binsPerTracker.set(id, trackerBins);
            }

            return binsPerTracker;
        }
    );

    const itemsCollection = new QuantitiesItemsCollection(
    [
        bim.instances,
        pilesCollection,
    ]);

    const instancesCollection = new QuantityItemsLazy({
        identifier: identifier,
        bim,
        tasksRunner: tasksRunner,
        logger: new ScopedLogger('quantities'),
        itemsCollection,
        versionedItems: [mergedLayoutMetrics],
        trackersByPilesRefs: pilesCollection.pilesPerTrackerId,
        trackerPerBinLazy: trackerPerBinLazy,
        pilesCosts: pilesCosts,
    });
    
    const lazy = LazyDerived.new1(
        'createBimInstancesObservable',
        [layoutMetrics, pilesCollection],
        [instancesCollection],
        ([collection]) => {
            layoutMetrics.poll();
            pilesCollection.poll();
            return collection;
        }
    ).withoutEqCheck();
    return {items: lazy, dispose: () => instancesCollection.dispose()};
}

function convertMetricsToQuantityItems(layoutMetricsByType: LayoutMetricsConfig[], table:MetricsRows, path: string[] = []) {
    function addProperty(prop: MetricTableValues, localPath: string[]){
        for (let i = 0; i < prop.value.length; i++) {
            const value = prop.value[i];
            if(value == null){
                continue;
            }
            let layoutMetric = layoutMetricsByType[i];
            if(!layoutMetric){
                layoutMetric = new LayoutMetricsConfig([]);;
                layoutMetricsByType[i] = layoutMetric;
            }
            if(typeof value === 'number'){
                layoutMetric.properties.push({
                    property: NumberProperty.new({value, unit: prop.unit}),
                    path: localPath,
                });
            } else if(typeof value === 'string'){
                layoutMetric.properties.push({
                    property: StringProperty.new({value}),
                    path: localPath,
                });
            } else {
                console.error('unexpected type', value);
            }
        }
    }

    for (const [_key, prop] of table.rows) {
        const localPath = [...path, prop.name];
        if (prop instanceof MetricTableValues) {
            addProperty(prop, localPath);
        } else if(prop instanceof MetricsRows) {
            const total = prop.tryCalculateTotal();
            if(total){
                addProperty(total, localPath);
            }
            convertMetricsToQuantityItems(layoutMetricsByType, prop, [...path, prop.name]);
        } else {
            ErrorUtils.logThrow('unexpected type', prop);
        }
    }
}
