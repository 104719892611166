import { PollablePromise, Success, WorkerPool, Yield } from "engine-utils-ts";
import { Aabb2, KrMath, Vector2, Vector3 } from "math-ts";
import type { FileImporterContext} from "ui-bindings";
import { NotificationDescription, NotificationType } from "ui-bindings";
import { notificationSource } from "./Notifications";
import { Bim, BimPatch, BimSceneOrigin, IrregularHeightmapGeometry, newDefaultTerrainInstance, ProjectionInfo, RegularHeightmapGeometry, TerrainElevation, TerrainHeightMapRepresentation, TerrainTile, TerrainTileId, WGSCoord, WgsProjectionOrigin } from "bim-ts";
import { TilesRowToRegularHeightmapExecutor } from "./TerrainToRegularHeightmapExecutor";
import { getBimPatchOrigin, ImportMode } from "./bim-assets/BimAssetsOriginHandling";
import { WGSConverter } from "./WGSConverter";


function optimizeTilesSegmentSize(sourceSegmentSize: number, terrainBbox: Aabb2, context: FileImporterContext): number {
    let segmentSize = KrMath.clamp(KrMath.floorPowerOfTwo(sourceSegmentSize), 0.5, 2);

    if (terrainBbox.width() * terrainBbox.height() > 480_000_000) {
        context.sendNotification(NotificationDescription.newBasic({
            source: notificationSource,
            key: 'terrainAreaTooLarge',
            descriptionArg: [(terrainBbox.width() * terrainBbox.height() / 1_000_000).toFixed(2), '120'],
            type: NotificationType.Error,
            addToNotificationsLog: true,
        }));

        throw new Error('Terrain area is too large: ' + 
            (terrainBbox.width() * terrainBbox.height() / 1_000_000).toFixed(2) + 
            " km^2, while maximum is 120 km^2");
    }

    while (terrainBbox.width() * terrainBbox.height() / (segmentSize * segmentSize) > 30_000_000) {
        segmentSize *= 2;
    }

    return segmentSize;
}


export function* convertTerrainGeometryToTerrainHeightmapInstance(
    triangulation: IrregularHeightmapGeometry, bim: Bim, fileName: string, context: FileImporterContext
): Generator<Yield, BimPatch> {

    const fullGeoAabb = triangulation.calcAabb();

    const SegmentSizeMeters = optimizeTilesSegmentSize(0.5, fullGeoAabb.xy(), context);
    const TileSizeInSegments = 255;
    const TileSizeInPoints = TileSizeInSegments + 1;

    const TileSizeMeters = SegmentSizeMeters * TileSizeInSegments;

    const fullGeoAabb2 = fullGeoAabb.xy().roundTo(TileSizeMeters);

    const tilesMinId = fullGeoAabb2.min.clone().divideScalar(TileSizeMeters).round();

    const sizeInTiles = fullGeoAabb2.max.clone().sub(fullGeoAabb2.min).divideScalar(TileSizeMeters).round();

    interface TilesGroupToConvert {
        tilesIds: TerrainTileId[],
        combinedAabb: Aabb2;
    }

    const tilesGroupsToConvert: TilesGroupToConvert[] = [];

    // we dont want to try to calculate all tiles at once, to not risk out of memory errors
    // instead, gather smaller groups of tiles, contained inside single aabb2
    // and later handle them group by group

    {
        const groupXCount = 4;
        const groupYCount = 4;

        let maxTileIX = 0, maxTileIY;
        for (let gIX = 0; gIX < groupXCount; ++gIX) {
            const minTileIX = maxTileIX;
            maxTileIX = Math.round((gIX + 1) * (sizeInTiles.x / groupXCount));

            maxTileIY = 0
            for (let gIY = 0; gIY < groupYCount; ++gIY) {
                const minTileIY = maxTileIY;
                maxTileIY = Math.round((gIY + 1) * (sizeInTiles.y / groupYCount));

                const tilesIdsInGroup: TerrainTileId[] = [];
                for (let i = minTileIX; i < maxTileIX; ++i) {
                    for (let j = minTileIY; j < maxTileIY; ++j) {
                        const tileId = TerrainTileId.new(i + tilesMinId.x, j + tilesMinId.y);
                        tilesIdsInGroup.push(tileId);
                    }
                }
                if (tilesIdsInGroup.length) {
                    tilesGroupsToConvert.push({
                        combinedAabb: new Aabb2(
                            fullGeoAabb2.min.clone().add(new Vector2(minTileIX, minTileIY).multiplyScalar(TileSizeMeters)), 
                            fullGeoAabb2.min.clone().add(new Vector2(maxTileIX, maxTileIY).multiplyScalar(TileSizeMeters)),
                        ),
                        tilesIds: tilesIdsInGroup,
                    });
                }
            }
        }
    }

    yield Yield.Asap;

    const geometriesResult = new Map<TerrainTileId, RegularHeightmapGeometry>();

    for (const { combinedAabb, tilesIds } of tilesGroupsToConvert) {

        combinedAabb.roundTo(SegmentSizeMeters);

        const elevations = TerrainElevation.rectangleGridFromPointsTriangulation(
            triangulation.points3d, triangulation.trianglesIndices, combinedAabb, SegmentSizeMeters);

        yield Yield.Asap;

        let tileHeightsData = new Float32Array(TileSizeInPoints * TileSizeInPoints).fill(NaN);

        for (const tileId of tilesIds) {
            // now create every tile geometry

            const tileBounds = tileId.aabb(TileSizeMeters);
            const tileIX = (tileBounds.min.x - combinedAabb.min.x) / SegmentSizeMeters;
            const tileIY = (tileBounds.min.y - combinedAabb.min.y) / SegmentSizeMeters;

            for (let iy = 0; iy < TileSizeInPoints; ++iy) {
                for (let ix = 0; ix < TileSizeInPoints; ++ix) {

                    const pointIX = tileIX + ix;
                    const pointIY = tileIY + iy;
                    
                    if (elevations[pointIY] !== undefined) {
                        const elevation = elevations[pointIY][pointIX];
                        if (elevation !== undefined && elevation.distToRealSample < SegmentSizeMeters * 0.1) {
                            tileHeightsData[iy * TileSizeInPoints + ix] = elevation.elevation!;
                        }
                    }
                }
            }

            if (tileHeightsData.some(v => !Number.isNaN(v))) {
                const tileGeoRes = RegularHeightmapGeometry.newFromMetersAndNaNs(
                    TileSizeInSegments, TileSizeInSegments, SegmentSizeMeters, tileHeightsData
                );
                if (tileGeoRes instanceof Success) {
                    geometriesResult.set(tileId, tileGeoRes.value);
                } else {
                    console.error('coudnt create tile geometry', tileGeoRes.errorMsg());
                }
                tileHeightsData.fill(NaN);
            }

            yield Yield.Asap;
        }

    }


    { // check neighouring tiles border elevations
        for (const [tileId, tileGeo] of geometriesResult) {
            {
                const neightbourRight = geometriesResult.get(TerrainTileId.new(tileId.x + 1, tileId.y));
                const unequalElevations: [number, number, number][] = [];
                if (neightbourRight) {
                    
                    for (let iy = 0; iy < TileSizeInPoints; ++iy) {
                        const thisZ = tileGeo.readElevationAtInds(tileGeo.xSegmentsCount, iy);
                        const neighbourZ = neightbourRight.readElevationAtInds(0, iy);

                        if (!Object.is(thisZ, neighbourZ)) {
                            unequalElevations.push([iy, thisZ, neighbourZ]);
                        }
                    }
                }
                if (unequalElevations.length) {
                    console.error('neighour(right) border elevations arent equal', unequalElevations);
                }
            }
            {
                const neightbourUp = geometriesResult.get(TerrainTileId.new(tileId.x, tileId.y + 1));
                const unequalElevations: [number, number, number][] = [];
                if (neightbourUp) {
                    for (let ix = 0; ix < TileSizeInPoints; ++ix) {
                        const thisZ = tileGeo.readElevationAtInds(ix, tileGeo.ySegmentsCount);
                        const neighbourZ = neightbourUp.readElevationAtInds(ix, 0);

                        if (!Object.is(thisZ, neighbourZ)) {
                            unequalElevations.push([ix, thisZ, neighbourZ]);
                        }
                    }
                }
                if (unequalElevations.length) {
                    console.error('neighour(up) border elevations arent equal', unequalElevations);
                }
            }
            yield Yield.Asap;
        }

    }

    const bimPatch = new BimPatch();

    const representationTiles = new Map<TerrainTileId, TerrainTile>();
    for (const [tileId, geometry] of geometriesResult) {
        const geoId = bim.regularHeightmapGeometries.reserveNewId();
        bimPatch.geometries.toAlloc.push([geoId, geometry]);
        representationTiles.set(tileId, new TerrainTile(geoId, 0));
    }
    
    const representation = new TerrainHeightMapRepresentation(TileSizeMeters, representationTiles);
    const instance = newDefaultTerrainInstance(bim, fileName, representation);
    bimPatch.instances.toAlloc.push([bim.instances.reserveNewId(), instance]);
    return bimPatch;
}



export function* convertTerrainElevationsGridToTerrainHeightmapInstance(args: {
    context: FileImporterContext,
    bim: Bim,
    elevationsInMeters: Float32Array,
    pointsCountX: number,
    pointsCountY: number,
    pixelIsArea: boolean,
    minMaxCoords: number[],
    isMinMaxGeo: boolean,
    projectionInfo: ProjectionInfo | undefined,
    fileName: string,
    sendNotification: (notification: NotificationDescription) => void,
}): Generator<Yield, BimPatch> {

    if (args.pointsCountX * args.pointsCountY !== args.elevationsInMeters.length) {
        throw new Error('invalid elevationsInMeters size');
    }

    let sceneOrigin = args.bim.instances.getSceneOrigin();

    let bboxFlat: Aabb2, geoMin: Vector2, geoToPixelRate: Vector2, datum: string | undefined;
    const imageOrigin = new Vector3(
        0.5 * (args.minMaxCoords[2] + args.minMaxCoords[0]),
        0.5 * (args.minMaxCoords[3] + args.minMaxCoords[1]),
        0
    );
    for (let i = 0; i < args.elevationsInMeters.length; ++i) {
        if (args.elevationsInMeters[i] < -1000 || args.elevationsInMeters[i] > 5000) {
            args.elevationsInMeters[i] = NaN;
        }
    }
    
    let sceneOriginPatch: { origin: BimSceneOrigin, correction: Vector3 };
    const wgsOrigin = WGSCoord.new(imageOrigin.y, imageOrigin.x);
    if (args.isMinMaxGeo && wgsOrigin) {
        // minMaxCoords contains Latitude and Longitude

        sceneOriginPatch = getBimPatchOrigin(
            sceneOrigin, 
            ImportMode.Add, 
            undefined, 
            new WgsProjectionOrigin(wgsOrigin, new ProjectionInfo()), 
            args.sendNotification
        );

        datum = WGSConverter.getDatum(sceneOriginPatch.origin.wgsProjectionOrigin!);

        let contourGeo: WGSCoord[];

        contourGeo = [
            WGSCoord.new(args.minMaxCoords[1], args.minMaxCoords[0])!,
            WGSCoord.new(args.minMaxCoords[1], args.minMaxCoords[2])!,
            WGSCoord.new(args.minMaxCoords[3], args.minMaxCoords[2])!,
            WGSCoord.new(args.minMaxCoords[3], args.minMaxCoords[0])!
        ];

        const contourFlat = contourGeo.map(point => WGSConverter.projectWgsToFlatMap(point, datum!));
        
        geoMin = new Vector2(
            Math.min(...contourGeo.map(p => p.longitude)),
            Math.min(...contourGeo.map(p => p.latitude))
        );
        const geoMax = new Vector2(
            Math.max(...contourGeo.map(p => p.longitude)),
            Math.max(...contourGeo.map(p => p.latitude))
        );
        geoToPixelRate = geoMax.sub(geoMin).divide(new Vector2(args.pointsCountX - 1, args.pointsCountY - 1));

        bboxFlat = Aabb2.empty().setFromPoints(contourFlat);
    } else {
        // minMaxCoords contains coordinates in m

        const origin = args.projectionInfo?.getLonLatXY(args.sendNotification);

        sceneOriginPatch = getBimPatchOrigin(
            sceneOrigin, 
            ImportMode.Add, 
            imageOrigin, 
            origin ? new WgsProjectionOrigin(origin.lonlat, args.projectionInfo!, origin.xy) : undefined, 
            args.sendNotification
        );

        const baseVector = sceneOriginPatch.correction.xy();
        datum = undefined;

        bboxFlat = Aabb2.calcFromArray(args.minMaxCoords);
        bboxFlat.translate(baseVector.clone().negate());

        geoMin = bboxFlat.min;
        geoToPixelRate = bboxFlat.max.clone().sub(geoMin).divide(new Vector2(args.pointsCountX - 1, args.pointsCountY - 1));
    }

    const sourcePixelSize = bboxFlat.getSize().divide(new Vector2(args.pointsCountX - 1, args.pointsCountY - 1));
    let segmentSizeMeters = Math.min(sourcePixelSize.x, sourcePixelSize.y);
    segmentSizeMeters = optimizeTilesSegmentSize(segmentSizeMeters, bboxFlat, args.context);

    const tileSegmentsCount = 255;
    const tileSizeMeters = segmentSizeMeters * tileSegmentsCount;

    const sourceGeoRes = RegularHeightmapGeometry.newFromMetersAndNaNs(
        args.pointsCountX - 1,
        args.pointsCountY - 1,
        1,
        args.elevationsInMeters,
    );

    if (!(sourceGeoRes instanceof Success)) {
        throw new Error('sourceGeo creation failed');
    }

    console.log('tiles segments size', segmentSizeMeters);
    const minTileId = TerrainTileId.newFromPoint(bboxFlat.min, tileSizeMeters);
    const maxTileId = TerrainTileId.newFromPoint(bboxFlat.max, tileSizeMeters);

    const geometries = new Map<TerrainTileId, RegularHeightmapGeometry>();

    yield Yield.Asap;

    const tilesRowToRegularPromises: PollablePromise<{ tileId: TerrainTileId, geometry: RegularHeightmapGeometry }[] | null>[] = [];
    for (let tileY = minTileId.y; tileY <= maxTileId.y; ++tileY) {
        const tileToRegularArgs = { 
            tilesYId: tileY, 
            tilesXIds: { min: minTileId.x, max: maxTileId.x },
            tileSegmentsCount,
            segmentSizeMeters,
            geoMin,
            geoToPixelRate,
            datum,
            sourceGeo: sourceGeoRes.value
        };
        
        const tilesRowToRegularPromise = WorkerPool.execute(TilesRowToRegularHeightmapExecutor, tileToRegularArgs);
        tilesRowToRegularPromises.push(new PollablePromise(tilesRowToRegularPromise));
    }

    for (const tilesRowToRegularPromise of tilesRowToRegularPromises) {
        const tileToRegularResult = yield* tilesRowToRegularPromise.generatorWaitForResult();

        if (tileToRegularResult instanceof Success) {
            const heatmaps = tileToRegularResult.value;
            if (heatmaps === null) {
                continue;
            }
            for (const heatmap of heatmaps) {
                geometries.set(heatmap.tileId, heatmap.geometry);
            }
        } else {
            console.error(tileToRegularResult.errorMsg());
        }
    }

    const bimPatch = new BimPatch();

    bimPatch.sceneOrigin = sceneOriginPatch.origin;

    const tiles = new Map<TerrainTileId, TerrainTile>();
    for (const [tileId, geo] of geometries) {
        const geoId = args.bim.regularHeightmapGeometries.reserveNewId();
        bimPatch.geometries.toAlloc.push([geoId, geo]);
        tiles.set(tileId, new TerrainTile(geoId, 0));
    }

    yield Yield.Asap;

    const representation = new TerrainHeightMapRepresentation(tileSizeMeters, tiles);
    const instance = newDefaultTerrainInstance(args.bim, args.fileName, representation);
    bimPatch.instances.toAlloc.push([args.bim.instances.reserveNewId(), instance]);

    return bimPatch;
}
