<script lang="ts">
  import { onMount } from 'svelte'
  import { MDCTextField } from '@material/textfield'
import { IconComponent, type IconName } from '../icon';

  export let labelText: string = ""
  export let labelId: string = Math.round(Math.random() * 10 ** 4) + ""
  export let value: string = ""
  export let isValid: boolean = true;

  export let leadingIcon: IconName | null = null;

  let textFieldTarget: Element
  onMount(() => {
    if (textFieldTarget) {
      new MDCTextField(textFieldTarget)
    }
  })

</script>

<!-- svelte-ignore a11y-label-has-associated-control -->

<label
  bind:this={textFieldTarget}
  class="mdc-text-field mdc-text-field--outlined"
  class:mdc-text-field--no-label={!!labelText}
  class:mdc-text-field--with-leading-icon={!!leadingIcon}
  class:invalidTextField={!isValid}
>
  <span class="mdc-notched-outline">
    <span class="mdc-notched-outline__leading"></span>
    {#if !!labelText}
      <span class="mdc-notched-outline__notch">
        <span class="mdc-floating-label" id="{labelId}">{labelText}</span>
      </span>
    {/if}
    <span class="mdc-notched-outline__trailing"></span>
  </span>
  {#if leadingIcon !== null}
    <i
      class="mdc-text-field__icon mdc-text-field__icon--leading"
      tabindex="0"
      role="button"
    >
      <IconComponent name={leadingIcon}/>
    </i>
  {/if}
  <input bind:value={value} type="text" class="mdc-text-field__input" aria-labelledby="{labelId}">
</label>
