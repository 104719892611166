import type { Bim, Catalog } from 'bim-ts';
import { SceneInstanceFlags, numberSelectedTransformers, } from 'bim-ts';
import {
    EnumUtils,
    LazyBasic,
    LazyDerived,
    type ProjectNetworkClient,
    type ScopedLogger, type TasksRunner
} from 'engine-utils-ts';

import { saveBimAssetToCatalog } from './exports/bim-assets/DownloadBimAssets';
import { downloadVerData } from './exports/verdata/DownloadVerData';
import { deleteByParents, colorizeTrackersByWindPosition } from 'layout-service';

import type { VerDataSyncer } from 'verdata-ts';
import { UiBindings, UiCustomNode, ActionVisibilityFlags, NotificationDescription, NotificationType, KeyModifiersFlags, DialogDescription, PanelViewPosition } from 'ui-bindings';
import { RuntimeSystemsCombinedStatus, RuntimeSystemsUiContext } from './runtime-systems-ui/RuntimeSystemsUiContext';
import { notificationSource } from './Notifications';
import { PropsChartsContext } from './props-charts/PropsChartsContext';
import type { KreoEngine } from 'engine-ts';
import { SelectByPropsContext } from './select-by-props/SelectByPropsContext';
import type { EngineUiLayout } from './ui-panels/EngineUiLayout';
import { EnergyViewContext } from './energy-view/EnergyViewContext';

export function createCoreUiBindings({
    logger,
    bim,
    tasksRunner,
    syncer,
    catalog,
    network,
    externalUiBindings,
    engine,
    layout,
}: {
    logger: ScopedLogger;
    bim: Bim;
    tasksRunner: TasksRunner;
    syncer: VerDataSyncer;
    catalog: Catalog;
    network: ProjectNetworkClient;
    externalUiBindings: UiBindings[],
    engine: KreoEngine,
    layout: EngineUiLayout,
}): UiBindings {

    const ui = new UiBindings(logger);

    // ui.addView(['View', 'Electrical Patterns'], new UiCustomNode({context: null}));
    ui.addViewToNavbar(['View', 'Terrain Analysis'], new UiCustomNode({context: null}), {
        name: 'Terrain analysis',
        iconName: 'TerrainAnalysis',
        group: 'Analysis',
        sortOrder: 6,
        position: PanelViewPosition.Float
    });
    ui.addViewToNavbar(['View', 'Equipment Slope Analysis'], new UiCustomNode({context: null}), {
        name: 'Equipment slopes',
        iconName: 'Slopes',
        group: 'Analysis',
        sortOrder: 7,
    });
    // ui.addViewToNavbar(['View', 'Cost Analysis'], new UiCustomNode({context: null}), {
    //     name: 'Cost analysis',
    //     iconName: 'CustomReport',
    //     group: 'Analysis',
    //     sortOrder: 8,
    // });
    ui.addViewToNavbar(['View', 'Comparison'], new UiCustomNode({context: null}), {
        name: 'Compare',
        iconName: 'Compare',
        group: 'Analysis',
        sortOrder: 13,
        position: PanelViewPosition.None
    });
    ui.addViewToNavbar(['View', 'Piles'], new UiCustomNode({context: null}), {
        name: 'Piles',
        iconName: 'Piles',
        group: 'General',
        sortOrder: 6,
        position: PanelViewPosition.Fixed
    });
    ui.addViewToNavbar(['View', 'Energy (BETA)'], new UiCustomNode({context: new EnergyViewContext(bim)}), {
        name: 'Energy',
        iconName: 'Energy',
        group: 'General',
        sortOrder: 7,
        position: PanelViewPosition.Fixed
    });
    
    ui.addViewToNavbar(['View', 'Catalog'], new UiCustomNode({context: null}), {
        name: 'Catalog',
        iconName: 'List',
        group: 'Catalog',
        sortOrder: 1,
    });
    ui.addViewToNavbar(['View', 'Properties'], new UiCustomNode({context: null}), {
        name: 'Object details',
        iconName: 'Details',
        group: 'Analysis',
        sortOrder: 2,
        position: PanelViewPosition.Mixed
    });
    ui.addViewToNavbar(['View', 'Model Explorer'], new UiCustomNode({context: null}), {
        name: 'Model explorer',
        iconName: 'Model',
        group: 'Analysis',
        sortOrder: 1,
        position: PanelViewPosition.Mixed
    });
    ui.addViewToNavbar(['View', 'Project'], new UiCustomNode({context: null}), {
        name: 'Project',
        iconName: 'Project',
        group: 'General',
        sortOrder: 0,
        position: PanelViewPosition.Fixed,
    });
    ui.addViewToNavbar(['View', 'Property Charts'], new UiCustomNode({context: new PropsChartsContext(bim)}), {
        name: 'Property analysis',
        iconName: 'Property',
        group: 'Analysis',
        sortOrder: 4,
        position: PanelViewPosition.Mixed
    });
    ui.addView(['Generate', 'LV Wiring 2D'], new UiCustomNode({context: null}), {
        name: 'LV Wiring 2d',
    });
    ui.addViewToNavbar(['View', 'Quantities'], new UiCustomNode({context: null}), {
        name: 'Custom report',
        iconName: 'CustomReport',
        group: 'Analysis',
        sortOrder: 9,
        position: PanelViewPosition.None
    });
    ui.addViewToNavbar(['View', 'Cost Report'], new UiCustomNode({context: null}), {
        name: 'Cost report',
        iconName: 'Cost',
        group: 'Analysis',
        sortOrder: 10,
        position: PanelViewPosition.None
    });

    ui.addViewToNavbar(['Add', 'Import'], new UiCustomNode({context: null}), {
        name: 'Import',
        iconName: 'Import',
        group: 'General',
        sortOrder: 1,
        position: PanelViewPosition.Fixed
    });

    ui.addViewToNavbar(['Project', 'Export'], new UiCustomNode({context: null}), {
        name: 'Export',
        iconName: 'Export',
        group: 'ImportExport',
        sortOrder: 2,
        position: PanelViewPosition.Fixed
    });

    const allRuntimeSystems = externalUiBindings.flatMap((b) => Array.from(b.runtimeSystemsUi.values()));
    const runtimeSystemsUiContext = new RuntimeSystemsUiContext(allRuntimeSystems);

    const runtimeStatusHeader = LazyDerived.new1(
        '',
        null,
        [runtimeSystemsUiContext.totalStatusType()],
        ([status]): string => {
            return EnumUtils.enumStringFromKey(RuntimeSystemsCombinedStatus, status, 'runtime').value;
        }
    );

    ui.addDropdownView(['runtime-systems'], {
        header: runtimeStatusHeader,
        viewSource: new UiCustomNode({context: runtimeSystemsUiContext}),
    });


    ui.addAction({
        name: ["Add", "Add selected item to the catalog as .bimasset"],
        canUseNow: LazyDerived.new1(
            '',
            null,
            [bim.instances.selectHighlight.getVersionedFlagged(SceneInstanceFlags.isSelected)],
            ([ids]) => {
                return ids.length === 1;
            }
        ),
        visibility: ActionVisibilityFlags.Menu | ActionVisibilityFlags.Search,
        priority: 7,
        divider: true,
        action: async () => {
            layout.focusPanel('View.Catalog');
            // TODO: make focusPanel wait for panel to actually open
            await new Promise(res => setTimeout(res, 100));
            await saveBimAssetToCatalog(bim, ui, tasksRunner, catalog)
        },
    });

    ui.addAction<undefined>({
		name: ['Project', 'Download Project VerData (.zip)'],
        canUseNow: new LazyBasic('', true),
        visibility: ActionVisibilityFlags.Search,
		action: async () =>  downloadVerData(syncer, ui, tasksRunner),
	});

    ui.addAction<undefined>({
		name: ['Project', 'Download Catalog VerData (.zip)'],
        canUseNow: new LazyBasic('', true),
        visibility: ActionVisibilityFlags.Search,
		action: async () =>  downloadVerData(catalog.getSyncer(), ui, tasksRunner),
	});

    ui.addAction<undefined>({
		name: ['Edit', 'Number the selected blocks'],
		canUseNow: LazyDerived.new1(
            '',
            null,
            [bim.instances.selectHighlight.getVersionedFlagged(SceneInstanceFlags.isSelected)],
            ([ids]) => {
                return ids.length > 0;
            }
        ),
        visibility: ActionVisibilityFlags.Menu | ActionVisibilityFlags.Search,
        priority: 2,
		action: async () => {
			const ids = bim.instances.getSelected();
            const task = tasksRunner.newLongTask({
                defaultGenerator: numberSelectedTransformers (ids, bim),
            });
            ui.addNotification(NotificationDescription.newWithTask({
                addToNotificationsLog: false,
                taskDescription: {task},
                type: NotificationType.Info,
                source: notificationSource,
                key: 'blockNumbering',
            }));
            await task.asPromise();
		},
	});

    ui.addAction<undefined>({
		name: ['Edit', 'Delete children wires'],
		canUseNow: LazyDerived.new1(
            '',
            null,
            [bim.instances.selectHighlight.getVersionedFlagged(SceneInstanceFlags.isSelected)],
            ([ids]) => {
                return ids.length > 0;
            }
        ),
        visibility: ActionVisibilityFlags.Menu | ActionVisibilityFlags.Search,
        divider: true,
        priority: 5,
		action: () => {
			const ids = bim.instances.getSelected();
            deleteByParents(ids, bim);
		},
	});

    ui.addAction<undefined>({
		name: ['View', 'Colorize trackers by position'],
        keyCombinations: [{keyCode: 'KeyT', modifiers: KeyModifiersFlags.Alt}],
		canUseNow: LazyDerived.new1(
            '',
            null,
            [bim.instances.selectHighlight.getVersionedFlagged(SceneInstanceFlags.isSelected)],
            ([ids]) => {
                for (const id of ids) {
                    const inst = bim.instances.peekById(id);
                    if(inst?.type_identifier.includes('tracker')){
                        return true;
                    }
                }
                return false;
            }
        ),
        visibility: ActionVisibilityFlags.Menu | ActionVisibilityFlags.Search,
        priority: 4,
		action: () => {
			const ids = bim.instances.getSelected();
            colorizeTrackersByWindPosition(ids, bim);
		},
	});

    ui.addAction<undefined>({
        name: ['Select', 'By property value'],
        visibility: ActionVisibilityFlags.Search | ActionVisibilityFlags.Menu,
        keyCombinations: [{keyCode: 'KeyS', modifiers: KeyModifiersFlags.Shift}],
		canUseNow: new LazyBasic('', true),
        priority: 5,
		action: () => {
            ui.addDialog(new DialogDescription({
                name: 'Select by property value',
                context: null,
                uiSource: new UiCustomNode({
                    context: new SelectByPropsContext(bim)
                }),
                userActions: [{
                    name: 'Close',
                    action: () => {},
                }]
            }))
		},
    });

    return ui;
}
