import type { IdBimScene, SceneInstancePatch } from 'bim-ts';
import { newFlagsPatch, SceneInstanceFlags } from 'bim-ts';
import type { UiBindings} from 'ui-bindings';
import {
    ActionVisibilityFlags as Vis, KeyModifiersFlags, ActionVisibilityFlags, ActionDescription, NotificationDescription, NotificationType
} from 'ui-bindings';
import { Aabb2, KrMath, Transform, Vector2 } from 'math-ts';
import { CameraProjectionMode, EngineControlsMode } from '../EngineConsts';

import type { KreoEngineImpl } from '../KreoEngineImpl';
import type { LazyVersioned} from 'engine-utils-ts';
import { LazyDerived, LazyBasic, IterUtils, EnumUtils, StringUtils, PollablePromise, Success, Yield } from 'engine-utils-ts';
import { EngineNotifications } from '../EngineNotitfications';

export type KeyCombHandler = (e: MouseEvent | KeyboardEvent, engine: KreoEngineImpl) => void;

type CameraAngles = [number, number];
const frontCamerAngles: CameraAngles = [Math.PI / 2, 0];
const backCameraAngles: CameraAngles = [Math.PI / 2, Math.PI];

const topCameraAngles: CameraAngles = [0, 0];
const botCameraAngles: CameraAngles = [Math.PI, 0];

const rightCameraAngles: CameraAngles = [Math.PI / 2, Math.PI / 2];
const leftCameraAngles: CameraAngles = [Math.PI / 2, Math.PI / 2 * 3];




function rotateCamera(engine: KreoEngineImpl, vertAngle: number, horAngle: number) {
	const angles = engine.getCameraPolarAnglesTarget();
	if (engine.movementControls.isFirstPerson()) {
		vertAngle *= -1;
		horAngle *= -1;
	}
	if (vertAngle) {
		angles[0] = KrMath.roundTo(angles[0] + vertAngle, vertAngle);
	}
	if (horAngle) {
		angles[1] = KrMath.roundTo(angles[1] + horAngle, horAngle);
	}
	engine.setCameraAngles(angles[0], angles[1], 0.3);
}

function ControlsFlyingSet(engine:KreoEngineImpl, component: number, sign: number) {
	engine.movementControls.flyVector.setComponent(component, sign);
}


function getSelectedOrHighlighted(engine: KreoEngineImpl): IdBimScene[] {
	const selected = engine.bim.instances.getSelected();
	if (selected.length) {
		return selected;
	}
	return engine.bim.instances.getHighlighted();
}


export function addEngineKeyBindings(engine: KreoEngineImpl, uiBindings: UiBindings) {

	const instances = engine.bim.instances;
	const interactive = engine.interactiveObjectsActive;

    function allConditions(...conditions: LazyVersioned<boolean>[]): LazyDerived<boolean> {
        return LazyDerived.fromArr('-', [], conditions, (args) => {return args.every(it => it == true)});
    }
    function anyOfConditions(...conditions: LazyVersioned<boolean>[]): LazyDerived<boolean> {
        return LazyDerived.fromArr('-', [], conditions, (args) => {return args.some(it => it == true)});
    }


    const tru = new LazyBasic('true', true);

    const bimSelection = instances.selectHighlight.getVersionedFlagged(SceneInstanceFlags.isSelected);
    // const editSelection = es.editSceneGizmos.objects.selectHighlight.getVersionedFlagged(GizmoHighlightFlags.IsSubstateSelected);

    const isNotEditMode = LazyDerived.new1('isedit', [], [engine.controlsState], ([s]) => s.controlsMode != EngineControlsMode.Edit);
    const isMultipleBimObjectsSelected = LazyDerived.new1('isSelected', [], [bimSelection], ([s]) => s.length > 1);
    const isBimObjectsSelected = LazyDerived.new1('isSelected', [], [bimSelection], ([s]) => s.length > 0);
	const isEditModeAvailable = LazyDerived.new2('isEditable', [], [bimSelection, engine.controlsState], ([s, controlsState]) => {
		return controlsState.controlsMode === EngineControlsMode.Edit || s.some(id => {
			const inst = instances.peekById(id);
			return inst && ['road', 'boundary', 'lv-wire', 'wire', 'trench', 'polyline'].includes(inst.type_identifier);
		});
	});
    const canBimVisibility = allConditions(isBimObjectsSelected, isNotEditMode);

    const isAnyObjSelected = anyOfConditions(isBimObjectsSelected/*, isEditObjectsSelected*/);


	uiBindings.addAction<undefined>({
		name: ['Edit', 'Undo'],
        visibility: Vis.Search | Vis.Menu,
		canUseNow: LazyDerived.new1('canundo', [], [engine.undoStack.observableState], ([s]) => s.canUndo),
		keyCombinations: [{keyCode: 'KeyZ', modifiers: KeyModifiersFlags.Ctrl}],
		priority: -4,
		action: () => {
			engine.undoStack.undo();
		},
	});

	uiBindings.addAction<undefined>({
		name: ['Edit', 'Redo'],
        visibility: Vis.Search | Vis.Menu,
		canUseNow: LazyDerived.new1('canredo', [], [engine.undoStack.observableState], ([s]) => s.canRedo),
		keyCombinations: [{keyCode: 'KeyY', modifiers: KeyModifiersFlags.Ctrl}],
		priority: -3,
		action: () => {
			engine.undoStack.redo();
		},
	});

	uiBindings.addAction<undefined>({
		name: ['Select', 'Clear Selection'],
		keyCombinations: [{keyCode: 'Escape'}],
		visibility: Vis.Menu,
		canUseNow: new LazyBasic('clear select', true),
		priority: 7,
		action: () => {
			if (engine.controlsState.poll().controlsMode !== EngineControlsMode.Default) {
				engine.controlsState.applyPatch({patch: {controlsMode: EngineControlsMode.Default}});
			} else {
				engine.bim.instances.setSelected([]);
			}
		},
	});

	uiBindings.addAction<undefined>({
		name: ['View', 'Hide'],
		keyCombinations: [{keyCode: 'KeyH'}],
        visibility: Vis.ContextMenu | Vis.Menu | Vis.Search,
		canUseNow: canBimVisibility,
		priority: 1,
		action: () => {
			const ids = getSelectedOrHighlighted(engine);
			let flagsPatch = newFlagsPatch(SceneInstanceFlags.isSelected | SceneInstanceFlags.isHighlighted, false);
			flagsPatch |= newFlagsPatch(SceneInstanceFlags.isHidden, true);
			engine.bim.instances.applyPatchTo(
				{ flags: flagsPatch },
				ids
			);
		},
	});

	uiBindings.addContextualActions('toolbar-perspective', LazyDerived.new1(
		'perspective',
		[],
		[engine.movementControls.obs_state],
		([movementControlsState]) => {
			const state = movementControlsState.projectionMode === CameraProjectionMode.Perspective ? 'On' : 'Off';
			return [
				new ActionDescription({
					name: [state, 'Toggle Perspective'],
					visibility: Vis.Toolbar,
					keyCombinations: [{keyCode: 'Numpad5'}, {keyCode: 'Digit0'}],
					canUseNow: tru,
					action: () => {
						engine.toggleParallelProjection(!engine.isProjectionParallel());
					},
				}),
			]
		}
	));

	uiBindings.addAction<undefined>({
		name: ['View', 'Isolate'],
		keyCombinations: [{keyCode: 'KeyH', modifiers: KeyModifiersFlags.Shift}],
        visibility: Vis.ContextMenu | Vis.Menu | Vis.Search,
		canUseNow: canBimVisibility,
		priority: 2,
		action: () => {
			const ids = getSelectedOrHighlighted(engine);
			if (ids.length == 0) {
				return;
			}
			const elementsToHide = engine.bim.instances.getIdsExcept(ids);
			engine.bim.instances.toggleVisibility(false, elementsToHide);
			engine.bim.instances.toggleVisibility(true, ids);
			isIsolateActive.replaceWith(true);
		},
	});

	const isIsolateActive = new LazyBasic('isolate-active', false);
	uiBindings.addContextualActions('toolbar-isolate', LazyDerived.new1(
		'isolate',
		[],
		[isIsolateActive],
		([isActive]) => {
			const name = ['View', isActive ? 'Show all' : 'Isolate'];
			const actionBinding = uiBindings.actions.get(name.join());
			return [
				new ActionDescription({
					name: name,
					visibility: Vis.Toolbar,
					canUseNow: canBimVisibility,
					keyCombinations: actionBinding?.keyCombinations,
					action: () => {
						if (actionBinding && actionBinding.action) {
							actionBinding.action(undefined);
						}
					},
				}),
			]
		}
	));

	uiBindings.addAction<undefined>({
		name: ['View', 'Show all'],
        visibility: Vis.ContextMenu | Vis.Menu | Vis.Search,
		keyCombinations: [{keyCode: 'KeyH', modifiers: KeyModifiersFlags.Ctrl}],
		canUseNow: tru,
		priority: 0,
		action: () => {
			engine.bim.instances.toggleVisibility(true);
			isIsolateActive.replaceWith(false);
		},
	});

	uiBindings.addAction<undefined>({
		name: ['Select', 'All'],
		keyCombinations: [{keyCode: 'KeyA', modifiers: KeyModifiersFlags.Ctrl}],
        visibility: Vis.ContextMenu | Vis.Menu | Vis.Search,
		canUseNow: tru,
		action: () => {
			const selected = interactive.getSelected();
			interactive.selectVisible();
			const selected2 = interactive.getSelected();
			if (IterUtils.areArraysEqual(selected, selected2)) {
				interactive.setSelected([]);
			}
		},
	});

	uiBindings.addAction<undefined>({
		name: ['Select', 'Invert Selection'],
        visibility: Vis.ContextMenu | Vis.Menu | Vis.Search,
		keyCombinations: [{keyCode: 'KeyA', modifiers: KeyModifiersFlags.Shift}],
		canUseNow: tru,
		priority: 6,
		action: () => {
			interactive.invertSelection();
		},
	});

	uiBindings.addAction<undefined>({
		name: ['Select', 'Parents'],
        visibility: Vis.Search | Vis.Menu,
		keyCombinations: [{keyCode: 'PageUp'}],
		canUseNow: allConditions(isBimObjectsSelected, isNotEditMode),
		priority: 2,
		action: () => {
			const bim = engine.bim.instances;
			const idsToMakeSelected = new Set<IdBimScene>();
			const ids = engine.bim.instances.getSelected();
			bim.spatialHierarchy.sortByDepth(ids);
			for (const id of ids) {
				idsToMakeSelected.delete(id);
				const s = bim.peekById(id);
				if (s?.spatialParentId) {
					idsToMakeSelected.add(s.spatialParentId);
				}
			}
			bim.setSelected(Array.from(idsToMakeSelected));
		},
	});

	uiBindings.addAction<undefined>({
		name: ['Select', 'Children'],
        visibility: Vis.Search | Vis.Menu,
		keyCombinations: [{keyCode: 'PageDown'}],
		canUseNow: allConditions(isBimObjectsSelected, isNotEditMode),
		priority: 3,
		action: () => {
			const bim = engine.bim.instances;
			const idsToMakeSelected = new Set<IdBimScene>();
			const ids = engine.bim.instances.getSelected();
			bim.spatialHierarchy.sortByDepth(ids);
			for (const id of ids) {
				idsToMakeSelected.delete(id);
				const iter = bim.spatialHierarchy.iteratorOfChildrenOf(id);
				if (iter) {
					for (const childId of iter) {
						idsToMakeSelected.add(childId);
					}
				}
			}
			bim.setSelected(Array.from(idsToMakeSelected));
		},
	});

    uiBindings.addAction<undefined>({
		name: ['Select', 'Hierarchies'],
        visibility: Vis.Search | Vis.Menu,
		canUseNow: allConditions(isBimObjectsSelected, isNotEditMode),
		priority: 4,
		action: () => {
			const bim = engine.bim.instances;
            const hierarchyIds = bim.spatialHierarchy.gatherIdsWithSubtreesOf({ids: bim.getSelected()});
            bim.setSelected(hierarchyIds);
		},
	});

	uiBindings.addAction<undefined>({
		name: ['Edit', 'Delete'],
        visibility: Vis.Search | Vis.Menu | Vis.ContextMenu,
		keyCombinations: [{keyCode: 'Delete'}, {keyCode: 'Backspace'}],
		canUseNow: isAnyObjSelected,
		priority: 6,
		action: () => {
			interactive.deleteObjects(interactive.getSelected());
		},
	});


	uiBindings.addAction<undefined>({
		name: ['View', 'Colorize hierarchy'],
		keyCombinations: [{keyCode: 'KeyC', modifiers: KeyModifiersFlags.Alt}],
        visibility: Vis.Search | Vis.Menu,
		canUseNow: allConditions(isBimObjectsSelected, isNotEditMode),
		priority: 3,
		divider: true,
		action: () => {
			const selected = engine.interactiveBimObjects.getSelected();
			engine.bim.instances.colorizeHierarchiesOf(selected);
		},
	});

	uiBindings.addAction<undefined>({
		name: ['View', 'Clear colorization'],
		keyCombinations: [{keyCode: 'KeyC', modifiers: KeyModifiersFlags.Shift}],
        visibility: Vis.Search | Vis.Menu,
		canUseNow: isNotEditMode,
		priority: 5,
		action: () => {
			let ids = engine.bim.instances.getSelected();
			if (ids.length === 0) {
				ids = Array.from(engine.bim.instances.perId.keys());
			}
			const patch: SceneInstancePatch = {colorTint: 0};
			const idsToPatch: IdBimScene[] = [];
			for (const [id, instance] of engine.bim.instances.peekByIds(ids)) {
				if (instance.colorTint) {
					idsToPatch.push(id);
				}
			};
			engine.bim.instances.applyPatchTo(patch, idsToPatch);
		},
	});

	uiBindings.addAction<undefined>({
		name: ['Edit', 'Set last selected as Parent'],
		keyCombinations: [{keyCode: 'KeyP', modifiers: KeyModifiersFlags.Ctrl}],
        visibility: Vis.Search | Vis.Menu,
		canUseNow: isMultipleBimObjectsSelected,
		divider: true,
		priority: 3,
		action: () => {
			const ids = engine.bim.instances.getSelected();
			if (ids.length < 2) {
				throw new Error('too few objects');
			}
			const parentId = ids[ids.length - 1];
			const childPatches: [IdBimScene, SceneInstancePatch][] = [];
			for (let i = 0; i < ids.length - 1; ++i) {
				const id = ids[i];
				const currentWM = engine.engineScene.peekWorldMatrix(id);
				if (currentWM) {
					const newLocalTransform = engine.engineScene.getLocalTransformRelativeTo(parentId, currentWM);
					childPatches.push([id, {
						spatialParentId: parentId,
						localTransform: newLocalTransform
					}]);
				}
			}
			engine.bim.instances.applyPatches(childPatches, {});
		},
	});

	uiBindings.addAction<undefined>({
		name: ['Edit', 'Clear Parent'],
		keyCombinations: [{keyCode: 'KeyP', modifiers: KeyModifiersFlags.Shift}],
        visibility: Vis.Search | Vis.Menu,
		canUseNow: isBimObjectsSelected,
		priority: 4,
		action: () => {
			const ids = engine.bim.instances.getSelected();
			const childPatches: [IdBimScene, SceneInstancePatch][] = [];
			for (const id of ids) {
				const currentWM = engine.engineScene.peekWorldMatrix(id);
				if (currentWM) {
					const newLocalTransform = new Transform();
					newLocalTransform.setFromMatrix4(currentWM);
					childPatches.push([id, {
						spatialParentId: 0,
						localTransform: newLocalTransform
					}]);
				}
			}
			engine.bim.instances.applyPatches(childPatches, {});
		},
	});

	uiBindings.addAction<undefined>({
		name: ['Global', 'Clipbox'],
		canUseNow: tru,
		keyCombinations: [{keyCode: 'KeyC'}],
		action: () => {
			const ids = getSelectedOrHighlighted(engine);
			engine._clipboxToggleAndFocus(ids);
		},
	});

	uiBindings.addContextualActions('toolbar-crop', LazyDerived.new1(
		'crop',
		[],
		[engine.clipBox.state],
		([state]) => {
			const name = ['View', state.isActive ? 'Cancel Crop' : 'Crop'];
			return [
				new ActionDescription({
					name: name,
					visibility: Vis.Toolbar,
					canUseNow: tru,
					keyCombinations: [{keyCode: 'KeyC'}],
					action: () => {
						if (state.isActive) {
							engine._clipboxToggleAndFocus([]);
							engine.clipBox.state.applyPatch({ patch: { isActive: false } });
						} else {
							engine._clipboxToggleAndFocus(getSelectedOrHighlighted(engine));
						}
					},
				}),
			]
		}
	));

	uiBindings.addAction<undefined>({
		name: ['Camera', 'Undo camera change'],
        visibility: Vis.Search | Vis.Menu,
		canUseNow: LazyDerived.new1('canundo', [], [engine.movementControls.camera_undo.observableState], ([s]) => s.canUndo),
		keyCombinations: [{keyCode: 'KeyZ', modifiers: KeyModifiersFlags.Shift}],
		action: () => {
			engine.movementControls.camera_undo.undo();
		},
	});

	uiBindings.addAction<undefined>({
		name: ['Camera', 'Redo camera change'],
        visibility: Vis.Search | Vis.Menu,
		canUseNow: LazyDerived.new1('canredo', [], [engine.movementControls.camera_undo.observableState], ([s]) => s.canRedo),
		keyCombinations: [{keyCode: 'KeyY', modifiers: KeyModifiersFlags.Shift}],
		action: () => {
			engine.movementControls.camera_undo.redo();
		},
	});

	uiBindings.addAction<undefined>({
		name: ['Camera', 'Focus Selected'],
        visibility: Vis.Search | Vis.Menu | Vis.Toolbar,
		keyCombinations: [{keyCode: 'KeyF'}],
		canUseNow: tru,
		divider: true,
		action: () => {
			engine.tasksRunner.newLongTask({
				defaultGenerator: (function* () {
					yield Yield.NextFrame;
					yield* engine.waitForEngineSceneToBeReady()
					const selection = engine.interactiveObjectsActive.getSelected();
					const bounds = engine.interactiveObjectsActive.calcBboxOf(selection);
					if (bounds.isEmpty()) {
						const visibleIds = engine.interactiveObjectsActive.getVisible();
						const viisbleBounds = engine.interactiveObjectsActive.calcBboxOf(visibleIds);
						bounds.copy(viisbleBounds);
					}
					if (bounds.isEmpty()) {
						bounds.copy(engine.totalBounds.bounds);
					}
					engine.movementControls.focusCameraOnBounds(bounds);
				})(),
			})
		},
	});

    let prevSelectionSinglesId: IdBimScene | 0 = 0;

    uiBindings.addAction<undefined>({
		name: ['Camera', 'Focus Single (Next)'],
        visibility: Vis.Search | Vis.Menu,
		canUseNow: isAnyObjSelected,
		keyCombinations: [{keyCode: 'KeyF', modifiers: KeyModifiersFlags.Shift}],
		action: () => {
            const ids = bimSelection.poll();
            const prevIndex = ids.indexOf(prevSelectionSinglesId);
            if (prevIndex === -1) {
                prevSelectionSinglesId = ids[0];
            } else {
                const nextIndex = (prevIndex + 1) % ids.length;
                if (!Number.isInteger(nextIndex)) {
                    return;
                }
                prevSelectionSinglesId = ids[nextIndex];
            }
			engine.focusCamera([prevSelectionSinglesId]);
		},
	});

    uiBindings.addAction<undefined>({
		name: ['Camera', 'Focus Single (Previous)'],
        visibility: Vis.Search | Vis.Menu,
		canUseNow: isAnyObjSelected,
		action: () => {
			const ids = bimSelection.poll();
            const prevIndex = ids.indexOf(prevSelectionSinglesId);
            if (prevIndex === -1) {
                prevSelectionSinglesId = ids[0];
            } else {
                const nextIndex = (prevIndex + ids.length - 1) % ids.length;
                if (!Number.isInteger(nextIndex)) {
                    return;
                }
                prevSelectionSinglesId = ids[nextIndex];
            }
			engine.focusCamera([prevSelectionSinglesId]);
		},
	});

	uiBindings.addAction<undefined>({
		name: ['Camera', 'Toggle Perspective'],
        visibility: Vis.Search | Vis.Menu,
		keyCombinations: [{keyCode: 'Numpad5'}, {keyCode: 'Digit0'}],
		canUseNow: tru,
		divider: true,
		action: () => {
			engine.toggleParallelProjection(!engine.isProjectionParallel());
		},
	});

	uiBindings.addAction<undefined>({
		name: ['Camera', 'Home'],
        visibility: Vis.Search | Vis.Menu,
		keyCombinations: [{keyCode: 'KeyF', modifiers: KeyModifiersFlags.Ctrl}],
		canUseNow: tru,
		action: () => {
			engine.cameraToHome();
		},
	});

	uiBindings.addAction<undefined>({
		name: ['Camera', 'First Person', 'Move Forward'],
		keyCombinations: [{keyCode: 'KeyW'}],
		visibility: 0,
		canUseNow: tru,
		action: () => {
			ControlsFlyingSet(engine, 1, 1);
		},
		actionOnUp: () => {
			ControlsFlyingSet(engine, 1, 0);
		}
	});
	uiBindings.addAction<undefined>({
		name: ['Camera', 'First Person', 'Move Backwards'],
		keyCombinations: [{keyCode: 'KeyS'}],
		visibility: 0,
		canUseNow: tru,
		action: () => {
			ControlsFlyingSet(engine, 1, -1);
		},
		actionOnUp: () => {
			ControlsFlyingSet(engine, 1, 0);
		}
	});
	uiBindings.addAction<undefined>({
		name: ['Camera', 'First Person', 'Move Left'],
		keyCombinations: [{keyCode: 'KeyA'}],
		visibility: 0,
		canUseNow: tru,
		action: () => {
			ControlsFlyingSet(engine, 0, -1);
		},
		actionOnUp: () => {
			ControlsFlyingSet(engine, 0, 0);
		}
	});
	uiBindings.addAction<undefined>({
		name: ['Camera', 'First Person', 'Move Right'],
		keyCombinations: [{keyCode: 'KeyD'}],
		visibility: 0,
		canUseNow: tru,
		action: () => {
			ControlsFlyingSet(engine, 0, 1);
		},
		actionOnUp: () => {
			ControlsFlyingSet(engine, 0, 0);
		}
	});
	uiBindings.addAction<undefined>({
		name: ['Camera', 'First Person', 'Move Up'],
		keyCombinations: [{keyCode: 'KeyE'}],
		visibility: 0,
		canUseNow: tru,
		action: () => {
			ControlsFlyingSet(engine, 2, 1);
		},
		actionOnUp: () => {
			ControlsFlyingSet(engine, 2, 0);
		}
	});
	uiBindings.addAction<undefined>({
		name: ['Camera', 'First Person', 'Move Down'],
		keyCombinations: [{keyCode: 'KeyQ'}],
		visibility: 0,
		canUseNow: tru,
		action: () => {
			ControlsFlyingSet(engine, 2, -1);
		},
		actionOnUp: () => {
			ControlsFlyingSet(engine, 2, 0);
		}
	});

	uiBindings.addAction<undefined>({
		name: ['Camera', 'First Person', 'Teleport'],
		keyCombinations: [{keyCode: 'Space'}],
		visibility: 0,
		canUseNow: tru,
		action: () => {
			engine.input.activateTeleport();
		},
		actionOnUp: () => {
			engine.input.teleport();
		}
	});

	uiBindings.addAction<undefined>({
		name: ['Camera', 'Top'],
        visibility: Vis.Search | Vis.Menu,
		keyCombinations: [{keyCode: 'Numpad7'}, {keyCode: 'Digit2'}],
		canUseNow: tru,
		action: () => {
			engine.setCameraAngles(...topCameraAngles);
		},
	});
	uiBindings.addAction<undefined>({
		name: ['Camera', 'Bottom'],
        visibility: Vis.Search | Vis.Menu,
		keyCombinations: [{keyCode: 'Numpad7', modifiers:KeyModifiersFlags.Ctrl}, {keyCode: 'Digit2', modifiers:KeyModifiersFlags.Ctrl}],
		canUseNow: tru,
		action: () => {
			engine.setCameraAngles(...botCameraAngles);
		},
	});
	uiBindings.addAction<undefined>({
		name: ['Camera', 'Front'],
        visibility: Vis.Search | Vis.Menu,
		keyCombinations: [{keyCode: 'Numpad1'}, {keyCode: 'Digit1'}],
		canUseNow: tru,
		action: () => {
			engine.setCameraAngles(...frontCamerAngles);
		},
	});
	uiBindings.addAction<undefined>({
		name: ['Camera', 'Back'],
        visibility: Vis.Search | Vis.Menu,
		keyCombinations: [{keyCode: 'Numpad1', modifiers:KeyModifiersFlags.Ctrl}, {keyCode: 'Digit1', modifiers:KeyModifiersFlags.Ctrl}],
		canUseNow: tru,
		action: () => {
			engine.setCameraAngles(...backCameraAngles);
		},
	});
	uiBindings.addAction<undefined>({
		name: ['Camera', 'Right'],
        visibility: Vis.Search | Vis.Menu,
		keyCombinations: [{keyCode: 'Numpad3'}, {keyCode: 'Digit3'}],
		canUseNow: tru,
		action: () => {
			engine.setCameraAngles(...rightCameraAngles);
		},
	});
	uiBindings.addAction<undefined>({
		name: ['Camera', 'Left'],
        visibility: Vis.Search | Vis.Menu,
		keyCombinations: [{keyCode: 'Numpad3', modifiers:KeyModifiersFlags.Ctrl}, {keyCode: 'Digit3', modifiers:KeyModifiersFlags.Ctrl}],
		canUseNow: tru,
		action: () => {
			engine.setCameraAngles(...leftCameraAngles);
		},
	});
	uiBindings.addAction<undefined>({
		name: ['Camera', 'Rotate Up'],
		keyCombinations: [{keyCode: 'Numpad8'}, {keyCode: 'ArrowUp'}],
		visibility: 0,
		canUseNow: tru,
		action: () => {
			rotateCamera(engine, -cameraRotationStep, 0);
		},
	});
	uiBindings.addAction<undefined>({
		name: ['Camera', 'Rotate Down'],
		keyCombinations: [{keyCode: 'Numpad2'}, {keyCode: 'ArrowDown'}],
		visibility: 0,
		canUseNow: tru,
		action: () => {
			rotateCamera(engine, cameraRotationStep, 0);
		},
	});
	uiBindings.addAction<undefined>({
		name: ['Camera', 'Rotate Left'],
		keyCombinations: [{keyCode: 'Numpad4'}, {keyCode: 'ArrowLeft'}],
		visibility: 0,
		canUseNow: tru,
		action: () => {
			rotateCamera(engine, 0, -cameraRotationStep);
		},
	});
	uiBindings.addAction<undefined>({
		name: ['Camera', 'Rotate Right'],
		keyCombinations: [{keyCode: 'Numpad6'}, {keyCode: 'ArrowRight'}],
		visibility: 0,
		canUseNow: tru,
		action: () => {
			rotateCamera(engine, 0, cameraRotationStep);
		},
	});

	function downloadFile(fileName: string, data: ArrayBuffer | Uint8Array | Blob) {
		const url = URL.createObjectURL(new globalThis.Blob([data]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", fileName);
		document.body.insertAdjacentElement("beforeend", link);
		link.click();
		link.remove();
	}

	function getScreenshotName() {
		const vd = engine._verdata;
		if (!vd) {
			return 'pvfarm_screenshot.png';
		}
		const vdState = vd.status.poll();
		const currentProjectVersion = vdState.activeVersionId;
		const currentProjectUrl = window.location.pathname.split('/')[1] ?? 'X';
		let screenShotName = `pvfarm_${currentProjectUrl}_v${currentProjectVersion}`;
		if (vd.canStartSaveNow()) {
			screenShotName += '*';
		}
		screenShotName += '.png';
		return screenShotName;
	}
	
	uiBindings.addAction<undefined>({
		name: ['Camera', 'Take top view screenshot'],
		keyCombinations: [],
		visibility: ActionVisibilityFlags.Menu | ActionVisibilityFlags.Search,
		canUseNow: tru,
		divider: true,
		action: async () => {
			const task = engine.tasksRunner.newLongTask({
                defaultGenerator: function*() {
					yield* engine.waitForEngineSceneToBeReady();
					const aabb = engine.totalBounds.bounds.clone();
					const aabbSize = aabb.getSize();
					const inHorPlaneSize = aabbSize.xy();

					const screenshotSize = new Vector2(3840, 2160);
					if (inHorPlaneSize.x < inHorPlaneSize.y * 0.95) {
						screenshotSize.set(screenshotSize.y, screenshotSize.x);
					}

					const screenshotRatio = screenshotSize.x / screenshotSize.y;
					const inHorPlaneSizeRatio = inHorPlaneSize.x / inHorPlaneSize.y;
					if (inHorPlaneSizeRatio > screenshotRatio) {
						inHorPlaneSize.y = inHorPlaneSize.x / screenshotRatio;
					} else {
						inHorPlaneSize.x = inHorPlaneSize.y * screenshotRatio;
					}
					const aabb2 = Aabb2.fromCenterAndSize(
						aabb.getCenter_t().xy(),
						inHorPlaneSize.multiplyScalar(1.01)
					);
                    const rawScreenshotPromise = engine.takecreenshotTopdown(aabb2, screenshotSize);
                    const screenshotResult = yield* PollablePromise.generatorWaitFor(rawScreenshotPromise);
                    if (screenshotResult instanceof Success) {
			            downloadFile(getScreenshotName(), screenshotResult.value);
                    }
                }(),
            });
            uiBindings.addNotification(NotificationDescription.newWithTask({
                addToNotificationsLog: false,
                taskDescription: {task},
                type: NotificationType.Info,
                source: EngineNotifications,
                key: 'takingScreenshot',
            }));
		},
	});

	uiBindings.addAction<undefined>({
		name: ['Camera', 'Take current view screenshot'],
        visibility: ActionVisibilityFlags.Search | ActionVisibilityFlags.Menu,
		canUseNow: new LazyBasic('', true),
		action: async () => {
            const task = engine.tasksRunner.newLongTask({
                defaultGenerator: function*() {
                    const rawScreenshotPromise = engine.takeScreenshotRawPng(3840, 2160);
                    const screenshotResult = yield* PollablePromise.generatorWaitFor(rawScreenshotPromise);
                    if (screenshotResult instanceof Success) {
			            downloadFile(getScreenshotName(), screenshotResult.value);
                    }
                }(),
            });
            uiBindings.addNotification(NotificationDescription.newWithTask({
                addToNotificationsLog: false,
                taskDescription: {task},
                type: NotificationType.Info,
                source: EngineNotifications,
                key: 'takingScreenshot',
            }));
		},
	});


	// const contextualActions = LazyDerived.new1(
	// 	'--',
	// 	[],
	// 	[isBimObjectsSelected],
	// 	([isSelected]) => {
	// 		if (!isSelected) {
	// 			return [];
	// 		}
	// 		return [
	// 			new ActionDescription({
	// 				name: ['Edit', 'Boundary', 'Prikol'],
	// 				keyCombinations: [{keyCode: 'Numpad6'}, {keyCode: 'ArrowRight'}],
	// 				visibility: ActionVisibilityFlags.Menu,
	// 				canUseNow: tru,
	// 				action: () => {
	// 					rotateCamera(engine, 0, cameraRotationStep);
	// 				},
	// 			})
	// 		] as ActionDescription<any>[]
	// 	}
	// )

	// uiBindings.addContextualActions('test', contextualActions);

    // new KeyHandlerDescriptor(['Numpad9', null],
    //     (_event, engine) => {
    //         const angles = engine.getCameraPolarAnglesTarget();
    //         const vert = angles[0];
    //         const hor = angles[1] + Math.PI;
    //         setCamerAngles(engine, vert, hor);
    //     }
    // ),

	// uiBindings.addAction<undefined>({
	// 	name: ['Camera', 'Left'],
	// 	keyCombinations: [{keyCode: 'Numpad3', modifiers:KeyModifiersFlags.Ctrl}, {keyCode: 'Digit3', modifiers:KeyModifiersFlags.Ctrl}],
	// 	canUseNow: () => true,
	// 	action: () => {
	// 		setCamerAngles(engine, ...leftCameraAngles);
	// 	},
	// });


	uiBindings.addAction<undefined>({
		name: ['Controls', 'Toggle Transform Gizmo'],
		keyCombinations: [{keyCode: 'KeyT'}],
		canUseNow: tru,
		action: () => {
			engine.transformGizmo.toggleActive(!engine.transformGizmo.gizmoState.isActive());
		},
	});

	uiBindings.addAction<undefined>({
		name: ['Controls', 'Next Controls Mode'],
		keyCombinations: [{keyCode: 'Tab'}],
		canUseNow: isEditModeAvailable,
		action: () => {
			const m = engine.controlsState.poll().controlsMode;
			const newM = EnumUtils.nextEnumValue(EngineControlsMode, m);
			engine.controlsState.applyPatch({patch: {controlsMode: newM }});
		},
	});

	const extendAvailable = LazyDerived.new1('isExtendable', [], [bimSelection], ([s]) => {
		if (s.length === 1) {
			const instance = engine.bim.instances.peekById(s[0]);
			return instance ? instance.type_identifier === 'road' || instance.type_identifier === 'trench' : false;
		}
		return false;
	});

	const contextualActions = LazyDerived.new1(
		'edit-points',
		[engine.editModeControls.editOperators.stateInvalidator()],
		[engine.controlsState],
		([controlsState]) => {
			const isVisibleInSelection = !engine.editModeControls.editOperators.getActiveOperator();
			const actionName = controlsState.controlsMode === EngineControlsMode.Default
				? 'Edit points' : 'Finish editing points';
			return [
				new ActionDescription({
					name: ['Edit', actionName],
					visibility: isVisibleInSelection
						? (ActionVisibilityFlags.Menu | ActionVisibilityFlags.SelectionMenu)
						: ActionVisibilityFlags.Menu,
					keyCombinations: [{keyCode: 'Tab'}],
					canUseNow: isEditModeAvailable,
					priority: -1,
					action: () => {
						const newMode = EnumUtils.nextEnumValue(EngineControlsMode, controlsState.controlsMode);
						engine.controlsState.applyPatch({patch: {controlsMode: newMode }});
					},
				}),
				new ActionDescription({
					name: ['Edit', actionName, 'Extend from last point'],
					visibility: ActionVisibilityFlags.Menu,
					canUseNow: extendAvailable,
					priority: -1,
					action: () => {
						const ids = engine.bim.instances.getSelected();
						const s = engine.bim.instances.peekById(ids[0]);
						if (s) {
							engine.editModeControls.editOperators.startEditOperation(
								["Add", StringUtils.capitalizeFirstLatterInWord(s.type_identifier)]
							);
						}
					},
				})
			]
		}
	)
	uiBindings.addContextualActions('engine-controls-mode', contextualActions);
}

const cameraRotationStep = Math.PI / 18; // 10 degrees


