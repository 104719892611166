<script lang="ts">
  import { getContext } from 'svelte'
  import { onMount } from 'svelte'
  import {MDCMenuSurface} from '@material/menu-surface';
  import type { DataModel } from './SceneExplorer';
  import type { VersionedStore } from '../VersionedStore';
  import CheckBox from './CheckBox.svelte';
  import { ButtonComponent, IconButton } from '../libui/button';

  let sceneList: VersionedStore<DataModel> = getContext('sceneManager');

  $: filtres = Array.from($sceneList.filters.descriptions.values());

  let el: Element
  let menu: MDCMenuSurface
  onMount(() => {
    menu = new MDCMenuSurface(el)
  })

  function toggleMenu() {
    if (menu.isOpen()){
      menu.close()
    }
    else{
      menu.open();
    }
  }

  function cleanAndUpLabel(name: string): string {
    const label = name.replace(/[_\-]/g, " ").replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    return label ? label : "Unknown";
  }

</script>

<style>
  .root {
    position: relative;
  }
  .mdc-menu-surface--fixed {
    z-index: 10;
  }
  .mdc-menu-surface{
    width: max-content;
  }
  .mdc-list{
    padding: 0 0;
  }
  .mdc-list-group__subheader{
    margin: 6px 16px;
  }
</style>


<span class="root">
  <ButtonComponent desc={new IconButton("Tune", toggleMenu)} />
  <div class="mdc-menu-surface--fixed">
    <div bind:this={el} class="with-selects mdc-menu-surface">
      <h3 class="mdc-list-group__subheader">View</h3>
      <ul class="mdc-list">
        {#each $sceneList.primaryProperties as primaryProperty}
          <li style="z-index: 10" class="mdc-list-item mdc-list-item--disabled">
            <span class="mdc-list-item__ripple"></span>
            <span class="mdc-list-item__text">
            <CheckBox
                id = 'checkbox-show-primary-property'
                bind:checked={primaryProperty.show}
                action = {()=>primaryProperty.toggleShow()}
                label = {primaryProperty.name}
            />
          </span>
          </li>
        {/each}     
      </ul>
      <!-- <li role="separator" class="mdc-list-divider extra-space"></li> -->
      <h3 class="mdc-list-group__subheader">Filter</h3>
      <ul class="mdc-list">
        {#each filtres as filter, i}
        <li style="z-index: 10" class="mdc-list-item mdc-list-item--disabled">
          <span class="mdc-list-item__ripple"></span>
          <span class="mdc-list-item__text">
          <CheckBox
              id = {`checkbox-${i}`}
              bind:checked={filter.flag}
              action = {()=>$sceneList.filters.toggleShow(filter.label)}
              label = {cleanAndUpLabel(filter.label)}
          />
          </span>
        </li>
        {/each}
      </ul>
    </div>
  </div>
</span>
