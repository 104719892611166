<script lang="ts">
    import { HierarchyDown } from "../../libui/icon/svg";
    import { CheckboxStandard } from "../../libui/checkbox";

    export let name: string;
    export let value: boolean;
    export let readonly: boolean = false;
    export let onClickCheckbox: ((newValue: boolean) => void) | undefined =
        undefined;

    $: isCheckBox = onClickCheckbox !== undefined;
</script>

<div class="selected-item-root">
    <div class="selected-item">
        <span class="name">
            <div class="hierarchy-holder">
                <HierarchyDown />
            </div>
            <span class:checkbox={isCheckBox}>
                {#if isCheckBox}
                    <CheckboxStandard
                        {value}
                        {readonly}
                        onChange={(newVal) => {
                            if (onClickCheckbox) {
                                onClickCheckbox(newVal);
                            }
                        }}
                    />
                {/if}
            </span>
            <span
                class="mdc-typography--body1"
                class:text-main-dark={!readonly}
                class:text-main-medium={readonly}>
                {name}
            </span>
        </span>
    </div>
</div>

<style lang="scss">
    .selected-item-root {
        width: 100%;
    }
    .selected-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .name {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .hierarchy-holder {
        padding: 0 4px 2px 0px;
    }
    .checkbox {
        height: fit-content;
        padding-right: 4px;
        padding-top: 4px;
    }
</style>
