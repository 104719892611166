<script lang="ts">
import { Bim, CostModel as CM } from 'bim-ts';
import 'ag-grid-enterprise/styles/ag-theme-balham.min.css';
import EngineUiPanel from "../../ui-panels/EngineUiPanel.svelte";
import { UiPersistentState } from 'src/UiPersistentState';
import { UiSettings } from './types';
import LCOE from '../lcoe/LCOE.svelte';
import CapitalCost from './CapitalCost.svelte';
import { Switch } from 'src/libui/checkbox';
import { ButtonComponent, IconButton } from 'src/libui/button';
import { getContext } from 'svelte';
import { Action, ContextMenu, ContextMenuStructure } from 'src/libui/context-menu';
import { LazyDerived, LazyDerivedAsync, Success } from 'engine-utils-ts';
import { getLcoeContext } from '../lcoe/LcoeContext';
import { KreoEngine } from 'engine-ts';
import { exportCapitalCostsAsExcel } from './utils';
import { exportLcoeAsExcel } from '../lcoe/table/utils';
import { downloadSpreadsheets } from '../utils';

const uiSettings = new UiPersistentState<UiSettings>('cost-model-ui-settings', {}).store;

const contextMenu = getContext<ContextMenu>('context-menu');
const engine = getContext<KreoEngine>('engine');

const capitalCost = getContext<LazyDerivedAsync<CM.CostModel>>('costModelLazy')
const bim = getContext<Bim>('bim');
const lcoeContext = getLcoeContext();

const header = "Cost report"
let isLoading = false;
$: postHeader = [isLoading && 'loading...'].filter(x => x).join(' ');
let onMenuClick: undefined | ((e: MouseEvent) => void)

const menu = LazyDerived.new0<ContextMenuStructure>(
    'menuLazy',
    [],
    ([]) => {
        return new ContextMenuStructure([
            new Action(
                'Excel Export',
                async () => {
                    engine.tasksRunner.newLongTask({
                        defaultGenerator: (function*() {
                            const capitalCosts = yield* capitalCost.waitTillCompletion();
                            const lcoe = yield* lcoeContext.lcoe.waitTillCompletion();
                            const spreadsheets: string[] = []
                            if (capitalCosts instanceof Success) {
                                spreadsheets.push(...exportCapitalCostsAsExcel(bim, capitalCosts.value, { isReadonly: true }))
                            }
                            if (lcoe instanceof Success) {
                                spreadsheets.push(...exportLcoeAsExcel(bim, lcoe.value))
                            }
                            downloadSpreadsheets(spreadsheets)
                        })()

                    })
                },
                undefined,
                undefined,
            )
        ])
    }
)
</script>

<EngineUiPanel header={header + " " + postHeader}>
    <div slot="buttons" class="buttons">
        <button
            class="unstyled-button"
            class:disabled={$uiSettings.isReadonly}
            on:click={() => {
                $uiSettings.isReadonly = !$uiSettings.isReadonly
            }}
        >
            <Switch value={!$uiSettings.isReadonly} />
            <span class="mdc-typography--body1">
                {`Edit`}
            </span>
        </button>
        <ButtonComponent
            desc={
                new IconButton(
                    "Export",
                    (e) => {
                        contextMenu.openMenuAtPosition(e.clientX, e.clientY, menu)
                    }
                )
            }
        />
    </div>
    <div
        class="container"
        class:cost-model-loading={isLoading}
    >
        <div class="toolbar">
            <div class="tabs">
                <div
                    class="mdc-typography--headline5 item"
                    class:selected={!!$uiSettings.showLCOE}
                    on:click={() => $uiSettings.showLCOE = true}
                >
                    LCOE
                </div>
                <div
                    class="mdc-typography--headline5 item"
                    class:selected={!$uiSettings.showLCOE}
                    on:click={() => $uiSettings.showLCOE = false}
                >
                    Capital Cost
                </div>
            </div>
            <div class="menus">
                {#if onMenuClick}
                    <ButtonComponent desc={new IconButton(
                        'MenuList',
                        (e) => onMenuClick?.(e),
                        !onMenuClick
                    )}/>
                {/if}
            </div>
        </div>
        <div style="flex: 1">
            {#if $uiSettings.showLCOE}
                <LCOE
                    isReadonly={!!$uiSettings.isReadonly}
                    bind:isLoading={isLoading}
                />
            {:else}
                <CapitalCost
                    isReadonly={!!$uiSettings.isReadonly}
                    bind:isLoading
                    bind:onMenuClick
                />
            {/if}
        </div>
    </div>
</EngineUiPanel>


<style lang="scss">
.buttons {
    display: flex;
    gap: 15px;
}
.toolbar {
    display: flex;
    justify-content: space-between;
    margin: 0 3px;
    padding-left: 3px;
    padding-right: 10px;
    margin-bottom: 10px;
    background: #eceff1;
}
.menus {
    display: flex;
    gap: 5px;
}
.tabs {
    display: flex;
    overflow: hidden;
    cursor: pointer;
    padding: 3px;
    .item {
        text-wrap: nowrap;
        margin: 2px;
        padding: 3px 10px;
        border-radius: 2px;
        transition: background 200ms;
        &.selected {
            background: white;
        }
    }
}
.container {
    display: flex;
    height: 100%;
    flex-direction: column;
    transition-delay: 0s;
    transition: opacity .5s;
    &.cost-model-loading {
        opacity: 0.6;
        transition-delay: 300ms;
    }
}
.unstyled-button {
    display: flex;
    gap: 5px;
    align-items: center;
    transition: opacity .5s;
    //cursor: pointer;
    span {
        font-weight: bolder;
    }
    &.disabled {
        opacity: 0.8;
    }
}
:global {
    .cost-model-table {
        flex: 1;
        font-family: "Source Sans";
        --ag-font-size: 14px;
        --ag-cell-horizontal-padding: 7px;
        --ag-header-height: 16px;

        .ag-grid-header-align-left .ag-header-cell-label {
            display: flex;
            justify-content: flex-start;
        }
        .ag-grid-header-align-right .ag-header-cell-label {
            display: flex;
            justify-content: flex-end;
        }
        .ag-row {
            &.ag-grid-row-with-top-border {
                border-top: var(--ag-borders-critical) var(--ag-border-color);
            }
        }
        .ag-cell {
            z-index: 1;
            &.ag-grid-dropdown-cell.ag-cell-not-inline-editing {
                display: flex;
                align-items: center;
                gap: 1px;
                &.ag-grid-cell-editable-highlight {
                    &::before {
                        content: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.5268 6.52673L8 11.0535L3.47321 6.52673L4.52679 5.47314L8 8.94635L11.4732 5.47314L12.5268 6.52673Z" fill="currentColor" /></svg>');
                        width: 16px;
                        height: 16px;
                        position: relative;
                        top: -1px;
                        z-index: -1;
                    }
                }
                &:not(.ag-grid-dropdown-cell-right-aligned) {
                    justify-content: space-between;
                }
                &.ag-grid-dropdown-cell-right-aligned {
                    justify-content: space-between;
                    flex-direction: row-reverse;
                }
            }
            &::after {
                content: '';
                border-radius: 1px;
                background: transparent;
                position: absolute;
                z-index: -2;
                inset: 2px;
                top: auto;
                height: 21px;
            }
            &.ag-grid-cell-editable-highlight::after {
                background: #103A52;
                opacity: 0.08;
            }
            &.ag-grid-cell-overriden-highlight::after {
                background: #C6E6AD;
            }
            &.ag-cell-inline-editing input {
                text-align: right;
            }
        }
        .ag-grid-cell-ignored {
            opacity: 0.5;
        }
        .ag-header-cell {
            display: flex;
            align-items: flex-start;
            text-align: right;
        }
        .ag-grid-cell-bold {
            font-weight: 650;
        }
        .ag-row {
            border: none;
        }
        .ag-root-wrapper {
            border: none;
        }
        .ag-header {
            background: transparent
        }

        .ag-rich-select {
            .ag-picker-field-display {
                flex: none;
            }
            .ag-rich-select-value {
                justify-content: space-between;
            }
        }

        .ag-header {
            .ag-header-cell:before {
                opacity: 0;
                transition: opacity 200ms;
            }
            .ag-pinned-left-header {
                border-right-color: rgba(0,0,0,0);
            }
            &:hover {
                .ag-header-cell:before {
                    opacity: 1;
                }
                .ag-pinned-left-header {
                    border-right-color: var(--ag-border-color);
                }
            }
        }
        .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-focus) {
            border-right: none;
        }

    }
    .CM-toolbar {
        display: flex;
        justify-content: space-between;
        margin: 0 3px;
        padding-left: 3px;
        padding-right: 10px;
        margin-bottom: 10px;
    }
    .CM-tabs {
        display: flex;
        overflow: hidden;
        cursor: pointer;
        padding: 3px;
        .item {
            text-wrap: nowrap;
            margin: 2px;
            padding: 3px 10px;
            border-radius: 2px;
            transition: border-color 200ms;
            border-bottom: 2px solid transparent;
            &.selected {
                border-color: black;
            }
        }
    }
}


</style>

