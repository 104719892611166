import type { AnyTrackerProps, GaugePack, SceneInstance} from 'bim-ts';
import { BimProperty, DC_CNSTS, FixedTiltTypeIdent, NumberProperty, TrackerTypeIdent } from 'bim-ts';
import type { Connection } from './connection';
import type {
    MakeOptional,
    MakeRequired} from 'engine-utils-ts';
import {
    LegacyLogger
} from 'engine-utils-ts';
import { Vector2 } from 'math-ts';
import type {
    PatternStepDesc, SceneInstanceDesc,
} from '../utils';
import { getPropWithThrow } from '../../props-gather';
import { calculateLosses } from '../utils';
import type { ConnsPerTypePerGauge, LossDropStats } from './utils';
import { DefaultMap } from 'engine-utils-ts';
import { sumReduce } from '../../utils';
import type { EquipmentType} from '../create/types';
import { equipmentType } from '../create/types';

export enum NodeId {}

export interface NodeDataModel {
    id: NodeId;
    /** Possible positions of the node */
    hints: Vector2[];
    parent?: Node;
    step: PatternStepDesc;
    si?: SceneInstanceDesc;
    desc: string;
    toChildren: Connection[];
    toParent?: Connection;
    lossDrop: LossDropStats;
}

type NodeDataOptionalKeys =
    'lossDrop' | 'id' | 'desc' |
    'toChildren' | 'hints';

export type NodeInput = MakeOptional<NodeDataModel, NodeDataOptionalKeys>;
type NodeInputDefaults = Pick<NodeDataModel, NodeDataOptionalKeys>;


export type NodeWithSi = MakeRequired<Node, 'si'>;

export interface Node extends NodeDataModel {}
export class Node {

    _actualPosition?: Vector2;
    get isActualPositionCalculated() {
        return typeof this._actualPosition !== 'undefined';
    }
    get actualPosition(): Vector2 {
        if (!this._actualPosition) {
            if (this.hints.length === 1)
                return this.hints[0];
            throw new Error('Node does not have actual position');
        }
        return this._actualPosition;
    }
    set actualPosition(val: typeof this._actualPosition) {
        this._actualPosition = val;
    }
    private _maxVoltageDrop?: number;
    get maxVoltageDrop() {
        if (typeof this._maxVoltageDrop === 'undefined')
            throw new Error('max voltage drop is not set');
        return this._maxVoltageDrop;
    }
    set maxVoltageDrop(val: number) {
        this._maxVoltageDrop = val;
    }
    assertsSi(): asserts this is NodeWithSi {
        if (!this.hasSi()) throw new Error(
            'No scene instance found for node with id ' + this.id,
        );
    }
    hasSi(): this is NodeWithSi {
        return !!this.si;
    }
    private _toParent?: Connection;
    set toParent(val: Connection | undefined) {
        this._toParent = val;
        if (val) {
            this.parent = val.from;
            this.initSiRelationships();
            this.initMaxVoltageDrop();
        }
    }
    get toParent(): Connection | undefined {
        return this._toParent;
    }
    constructor(params: NodeInput) {
        //const defaultDescription = [
        //    params.step.patternStepType,
        //    ...(!params.si ? [] : [
        //        params.si.i.name,
        //        params.si.i.type_identifier,
        //        params.si.id,
        //    ]),
        //]
        //    .filter((x): x is string => typeof x === 'string')
        //    .join('; ');
        const defaults: NodeInputDefaults = {
            toChildren: [], hints: [], id: NaN,
            desc: params.step.patternStepType,
            lossDrop: { drop: 0, losses: 0 },
        };
        this._toParent = params.toParent;
        Object.assign(this, defaults);
        Object.assign(this, params);
        this.initSiRelationships();
        this.initMaxVoltageDrop();
    }
    copy() {
        return new Node({
            ...this,
            hints: [...this.hints],
            toChildren: [...this.toChildren],
        });
    }

    getChildrenNodes() {
        const children = this.toChildren.map(x => x.to);
        return children;
    }

    getAnyChildNode(): Node | null {
        const children = this.getChildrenNodes();
        if (!children.length) return null;
        return children[0];
    }

    isMatchingEquipment(types: EquipmentType[]): boolean {
		if (!this.si) {
			return false;
		}
		const ty = equipmentType(this.si.i);
		if (ty === null) {
			return false;
		}
        return types.includes(ty);
    }


    private _firstParentWithSi?: NodeWithSi;
    get firstParentWithSi() {
        if (!this._firstParentWithSi)
            throw new Error('no child with scene instance reference found');
        return this._firstParentWithSi;
    }
    private _anyFirstChildWithSi?: NodeWithSi;
    set anyFirstChildWithSi(val: NodeWithSi) {
        if (this._anyFirstChildWithSi)
            return;
        this._anyFirstChildWithSi = val;
        if (this.parent)
            this.parent.anyFirstChildWithSi = val;
    }
    get anyFirstChildWithSi() {
        if (!this._anyFirstChildWithSi)
            throw new Error('no child with scene instance reference found');
        return this._anyFirstChildWithSi;
    }
    private initSiRelationships() {
        this._firstParentWithSi = undefined;
        this._anyFirstChildWithSi = undefined;
        if (this.hasSi()) {
            this._firstParentWithSi = this;
            this.anyFirstChildWithSi = this;
            return;
        }
        if (!this.parent)
            throw new Error('no parent with scene instance reference found');
        this._firstParentWithSi = this.parent.firstParentWithSi;
    }

    private initMaxVoltageDrop() {
        if (this.hasSi()) {
            const maxVoltageDrop = this.si.i.properties
                .get('circuit | lv_wiring | max_voltage_drop')?.asNumber();
            if (typeof maxVoltageDrop !== 'undefined') {
                this.maxVoltageDrop = maxVoltageDrop / 100;
                return;
            }
        }
        if (!this.parent) {
            throw new Error(
                `Node ${this.id} does not have parent and max voltage drop set`,
            );
        }
        this.maxVoltageDrop = this.parent.maxVoltageDrop;
    }

    getRequestsByMergeGroups(): Map<Connection, Connection[]> {
        const groupedRoutes = new DefaultMap<Connection, Connection[]>(() => []);
        for (const req of this.toChildren) {
            if (!req.mergingToReq)
                throw new Error('Request is not sutable to calculate losses');
            const arr = groupedRoutes.getOrCreate(req.mergingToReq);
            if (req.mergingToReq === req) continue;
            arr.push(req);
        }
        return new Map(groupedRoutes.entries());
    }

    getConnMapByTypeAndGauge(): ConnsPerTypePerGauge {
        const map: ConnsPerTypePerGauge =
            new DefaultMap(() => new DefaultMap<number, Connection[]>(() => []));
        for (const conn of this.toChildren) {
            const perCondType = map.getOrCreate(conn.conductorType);
            const perGauge = perCondType.getOrCreate(conn.gauge);
            perGauge.push(conn);
        }
        return map;
    }

    get averageHint(): Vector2 | null {
        if (this.hints.length <= 0)
            return null;
        const avHint = new Vector2();
        this.hints.forEach(x => avHint.add(x));
        return avHint.divideScalar(this.hints.length);
    }

    get averageChildrenHint(): Vector2 | null {
        if (this.toChildren.length <= 0) return null;
        const avHint = new Vector2();
        let count = 0;
        for (const conn of this.toChildren) {
            const childAvHint = conn.to.averageHint;
            if (childAvHint === null) continue;
            avHint.add(childAvHint);
            count += 1;
        }
        if (count === 0) return null;
        return avHint.divideScalar(count);
    }


    ///////////////////////////////
    // dc-losses related methods //
    ///////////////////////////////

    calculateLosses(gaugePack: GaugePack) {
        if (!this.step.conductorToChildren) // no children
            return;
        const isMergingNode = DC_CNSTS.merginCableTypes
            .includes(this.step.conductorToChildren.type);
        if (isMergingNode)
            this.calculateLossesAsMergingNode(gaugePack);
        else
            this.calculateLossesAsNonMergingNode(gaugePack);
    }

    private calculateLossesAsMergingNode(gaugePack: GaugePack) {
        this.lossDrop = { losses: 0, drop: 0 };
        const mergeGroups = this.getRequestsByMergeGroups();
        for (const [main, others] of mergeGroups) {
            // from longest to shortest
            const sortedByLength = [main, ...others]
                .sort((a, b) => b.route.length - a.route.length);
            const groupStats: LossDropStats = { drop: 0, losses: 0 };
            let groupCurrent = 0;
            let groupCurrentOperationg = 0;
            let groupPower = 0;
            for (let i = 0; i < sortedByLength.length; i++) {
                const conn = sortedByLength[i];
                const toNode = conn.to;

                let nodeCurrent = toNode.current();
                let nodeCurrentOperationg = toNode.currentOperating();
                let nodePower = toNode.power;

                MultiVertTrunkbusFix1: {
                    if (
                        this.step.patternConfig.patternId !== 'CI_MultVertTrunkbus' ||
                        this.step.patternStepType !== 'cb_si_circuit_exit' ||
                        !this.toChildren.length ||
                        !toNode.toChildren.length
                    ) {
                        break MultiVertTrunkbusFix1;
                    }
                    nodeCurrent = toNode.toChildren[0].maxCurrent
                    nodeCurrentOperationg = toNode.toChildren[0].operatingCurrent
                    nodePower = toNode.toChildren[0].power;
                }

                groupCurrent += nodeCurrent
                groupCurrentOperationg += nodeCurrentOperationg
                groupPower += nodePower;

                const [gaugeId, gauge] = gaugePack.getGaugeBasedOnCurrentAndMaterial(
                    groupCurrentOperationg, conn.gauge, conn.temperatureParams,
                );
                conn.gauge = gaugeId;
                conn.label = gauge.getFullName();
                const nextReq: Connection | undefined = sortedByLength[i + 1];
                const flattenRoutes = conn.route.getPairingRoutes(true);
                let segmentTotalLength = flattenRoutes
                    .reduce((acc, cur) => acc + cur.length, 0);
                if (nextReq) {
                    const nextFlattenRoutes = nextReq.route
                        .getPairingRoutes(true);
                    const negativeLength = nextFlattenRoutes
                        .reduce((acc, cur) => acc + cur.length, 0);
                    segmentTotalLength -= negativeLength;
                }
                const resistivity = gauge.approxDcResistivityByTemperature(
                    NumberProperty.new({
                        unit: 'C',
                        value: toNode.step.patternConfig.temperature,
                    })
                );
                const segmentLosses = calculateLosses(
                    groupCurrentOperationg, resistivity.as('Om/kft'), segmentTotalLength,
                );
                groupStats.losses += segmentLosses;
            }
            sortedByLength.forEach(conn => {
                conn.maxCurrent = groupCurrent;
                conn.operatingCurrent = groupCurrentOperationg;
                conn.power = groupPower;
            })

            groupStats.drop = groupStats.losses / groupPower;
            main.lossDrop = groupStats;
            this.lossDrop.losses += main.lossDrop.losses;
        }
        this.lossDrop.drop = this.lossDrop.losses / this.power;
        const shouldRepeat = !this.checkIfLossesFound(gaugePack);
        if (shouldRepeat)
            this.calculateLossesAsMergingNode(gaugePack);
    }

    private calculateLossesAsNonMergingNode(gaugePack: GaugePack) {
        this.lossDrop = {
            losses: 0,
            drop: 0,
        };
        let shouldRepeat = true;
        for (const conn of this.toChildren) {
            const toNode = conn.to;
            const reqMaxCur = toNode.current();
            const reqOperCur = toNode.currentOperating();
            conn.maxCurrent = reqMaxCur;
            conn.operatingCurrent = reqOperCur;
            const reqPower = toNode.power;
            conn.power = reqPower;
            let resistivity: number | null = null;
            if (conn.conductorType === DC_CNSTS.ConductorType.BusBars) {
                resistivity = 0.000001;
                shouldRepeat = false;
                conn.label = 'other'
            } else {
                const [gaugeId, gauge] = gaugePack.getGaugeBasedOnCurrentAndMaterial(
                    reqOperCur, conn.gauge, conn.temperatureParams,
                );
                conn.gauge = gaugeId;
                resistivity = gauge.approxDcResistivityByTemperature(
                    NumberProperty.new({
                        unit: 'C',
                        value: toNode.step.patternConfig.temperature,
                    })
                ).as('Om/kft');
                conn.label = gauge.getFullName();
            }
            const routes = conn.route.getPairingRoutes(true);
            const length = sumReduce(routes, x => x.length);
            const losses = calculateLosses(
                reqOperCur, resistivity, length,
            );
            conn.lossDrop = {
                losses, drop: losses / reqPower,
            };
            this.lossDrop.losses += conn.lossDrop.losses;
        }
        this.lossDrop.drop = this.lossDrop.losses / this.power;
        if (!shouldRepeat) return;
        shouldRepeat = !this.checkIfLossesFound(gaugePack);
        if (!shouldRepeat) return;
        this.calculateLossesAsNonMergingNode(gaugePack);
    }

    /**
     * @returns
     * If iteration is needed.
     * if true, then no extra iteration needed. Otherwise need new
     * iteration
     */
    private checkIfLossesFound(gaugePack: GaugePack): boolean {
        const gaugesSeen: Set<number> = new Set();
        if (!this.toChildren[0]) {
            LegacyLogger.deferredError(`Node ${this.id} does not have cables connected`, this.toChildren[0]);
            //throw new Error(`Station hierarchy does not match the electrical pattern`);
            return true;
        }
        let maxGaugeId = this.toChildren[0].gauge;
        let maxGauge = gaugePack.gauges.get(maxGaugeId);
        if (!maxGauge) throw new Error('gauge not found');
        for (const req of this.toChildren) {
            if (gaugesSeen.has(req.gauge)) continue;
            gaugesSeen.add(req.gauge);
            const gauge = gaugePack.gauges.get(req.gauge);
            if (!gauge) throw new Error('gauge not found');
            const A = gauge.approxAmpacityByTemperature(
                NumberProperty.new({ value: req.temperatureParams.temperature, unit: 'C' }),
                req.temperatureParams.isBuried,
            );
            const R = gauge.approxDcResistivityByTemperature(
                NumberProperty.new({ value: req.temperatureParams.temperature, unit: 'C' }),
            );
            const AMaxGauge = maxGauge.approxAmpacityByTemperature(
                NumberProperty.new({ value: req.temperatureParams.temperature, unit: 'C' }),
                req.temperatureParams.isBuried,
            )
            const RMaxGauge = maxGauge.approxDcResistivityByTemperature(
                NumberProperty.new({ value: req.temperatureParams.temperature, unit: 'C' }),
            );
            if (!A || !AMaxGauge) throw new Error('amp not calculated');

            if (
                A.value >= AMaxGauge.as(A.unit) &&
                R.value <= RMaxGauge.as(R.unit)
            ) {
                maxGaugeId = req.gauge;
                maxGauge = gauge;
            }
        }
        // all gauges should be the same. Make equal to biggest one.
        if (Array.from(gaugesSeen.values()).length !== 1) {
            for (const req of this.toChildren)
                req.gauge = maxGaugeId;
            return false;
        }
        if (this.lossDrop.drop > this.maxVoltageDrop) {
            const result = gaugePack.getNextGauge(maxGaugeId)
            if (result === null) {
                LegacyLogger.deferredError([
                    'Max gauge overhead on scene instance',
                    this.firstParentWithSi.si.id,
                    `(node id ${this.firstParentWithSi.id})`,
                ].filter((x): x is string => !!x).join(' '), result);
                return true;
            }
            const [nextGauge] = result;
            for (const req of this.toChildren)
                req.gauge = nextGauge;
            return false;
        }
        return true;
    }

    private _isAc?: boolean;
    // calculated once and then cached to this._isAc
    isAc(): boolean {
        if (typeof this._isAc !== 'undefined')
            return this._isAc;
        const firstSiChild = this.anyFirstChildWithSi;
        const acPowerProp = firstSiChild.si.i.properties
            .get('circuit | aggregated_capacity | ac_power');
        this._isAc = !!acPowerProp && acPowerProp.as('W') > 0;
        return this._isAc;
    }

    private _power?: number;
    // calculated once and then cached to this._power
    get power(): number {
        if (typeof this._power !== 'undefined')
            return this._power;
        const firstSiChild = this.anyFirstChildWithSi;
        const isAc = this.isAc();
        // for end of group return the result of the child
        if (
            this.step.patternStepType === 'end_of_group' &&
            this.toChildren.length === 1
        ) {
            this._power = this.toChildren[0].to.power;
            return this._power;
        }
        if (this.step.patternStepType === 'end_of_multiharness') {
            this._power = this.toChildren.length * getTrackerStringPower(firstSiChild.si.i);
            return this._power;
        }
        if (this.step.patternStepType === 'string_exit') {
            this._power = getTrackerStringPower(firstSiChild.si.i);
            return this._power;
        }
        this._power = getAggregatedCapacity(firstSiChild.si.i, isAc);
        return this._power;
    }

    private _current?: number;
    // calculated once and put into this._current
    current(): number {
        if (typeof this._current !== 'undefined')
            return this._current;
        const firstSiChild = this.anyFirstChildWithSi;
        // for end of group return the result of the child
        if (
            this.step.patternStepType === 'end_of_group' &&
            this.toChildren.length === 1
        ) {
            this._current = this.toChildren[0].to.current();
            return this._current;
        }
        if (this.step.patternStepType === 'end_of_multiharness') {
            this._current = this.toChildren.length * getTrackerShortCircuitCurrent(firstSiChild.si.i);
            return this._current;
        }
        if (firstSiChild.step.patternStepType === 'string_exit') {
            this._current = getTrackerShortCircuitCurrent(firstSiChild.si.i);
            return this._current;
        }
        this._current = getMaxCircuitCurrent(firstSiChild.si.i);
        return this._current;
    }

    private _currentOperating?: number;
    // calculated once and put into this._current
    currentOperating(): number {
        if (typeof this._currentOperating !== 'undefined')
            return this._currentOperating;
        const firstSiChild = this.anyFirstChildWithSi;
        // for end of group return the result of the child
        if (
            this.step.patternStepType === 'end_of_group' &&
            this.toChildren.length === 1
        ) {
            this._currentOperating = this.toChildren[0].to.current();
            return this._currentOperating;
        }
        if (this.step.patternStepType === 'end_of_multiharness') {
            this._currentOperating = this.toChildren.length * getTrackerOperatingCurrent(firstSiChild.si.i);
            return this._currentOperating;
        }
        if (firstSiChild.step.patternStepType === 'string_exit') {
            this._currentOperating = getTrackerOperatingCurrent(firstSiChild.si.i);
            return this._currentOperating;
        }
        this._currentOperating = getAggregatedOperatingCurrent(firstSiChild.si.i);
        return this._currentOperating;
    }

    //////////////////////////////////////
    // end of dc-losses related methods //
    //////////////////////////////////////

    /**
     * @returns weather or not iterations where stoped by hooks.
     *
     */
    static traverseBranch(params: {
        node: Node,
        /**
         * @return
         * should iteration stop?
         */
        beforeEach?: (node: Node) => boolean,
        /**
         * @return
         * should iteration stop?
         */
        afterEach?: (node: Node) => boolean,
    }): boolean {
        let shouldStop = false;
        if (params.beforeEach)
            shouldStop = params.beforeEach(params.node);
        if (shouldStop) return true;
        const children = params.node.getChildrenNodes();
        for (const child of children) {
            shouldStop = Node.traverseBranch({
                beforeEach: params.beforeEach,
                afterEach: params.afterEach,
                node: child,
            });
            if (shouldStop) return true;
        }
        if (params.afterEach)
            shouldStop = params.afterEach(params.node);
        return shouldStop;
    }

}

function getTrackerShortCircuitCurrent(si: SceneInstance) {
    if (si.type_identifier === TrackerTypeIdent || si.type_identifier === FixedTiltTypeIdent) {
        return getPropWithThrow(
            si,
            'module | short_circuit_current',
        ).as('A');
    } else if (si.type_identifier === 'any-tracker') {
        const props = si.props as AnyTrackerProps;
        return props.module.short_circuit_current.as('A');
    } else {
        throw new Error('unsupported type');
    }
}

function getMaxCircuitCurrent(si: SceneInstance) {
    const path = ['circuit', 'aggregated_capacity', 'max_current'];
    const prop = si.props.getAtPath(path);
    if (prop instanceof NumberProperty) {
        return prop.as('A');
    }
    return getPropWithThrow(si, BimProperty.MergedPath(path)).as('A');
}

function getTrackerStringPower(si: SceneInstance) {
    if (si.type_identifier === TrackerTypeIdent) {
        return getPropWithThrow(
            si,
            'tracker-frame | string | power'
        ).as('W');
    } else if (si.type_identifier === 'any-tracker') {
        const props = si.props as AnyTrackerProps;
        return props.tracker_frame.string.power?.as('W') ?? 0;
    } else if (si.type_identifier === FixedTiltTypeIdent) {
        return getPropWithThrow(si, 'string | power').as('W');
    } else {
        throw new Error('unsupported type');
    }
}

export function getAggregatedCapacity(si: SceneInstance, isAc: boolean) {
    const path = ['circuit', 'aggregated_capacity', isAc ? 'ac_power' : 'dc_power'];
    const prop = si.props.getAtPath(path);
    if (prop instanceof NumberProperty) {
        return prop.as('W');
    }
    return getPropWithThrow(si, BimProperty.MergedPath(path)).as('W');
}

function getTrackerOperatingCurrent(si: SceneInstance) {
    if (si.type_identifier === TrackerTypeIdent || si.type_identifier === FixedTiltTypeIdent) {
        return getPropWithThrow(si, 'module | current').as('A');
    } else if (si.type_identifier === 'any-tracker') {
        const props = si.props as AnyTrackerProps;
        return props.module.current.as('A');
    } else {
        throw new Error('unsupported type');
    }
}

function getAggregatedOperatingCurrent(si: SceneInstance) {
    const path = ['circuit', 'aggregated_capacity', 'operating_current'];
    const prop = si.props.getAtPath(path);
    if (prop instanceof NumberProperty) {
        return prop.as('A');
    }
    return getPropWithThrow(si, BimProperty.MergedPath(path)).as('A');
}
