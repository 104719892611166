<script lang="ts">
    import { onDestroy } from "svelte";
    import SelectSceneItem from "./SelectItem.svelte";
    import {
        getPluralDisplayName,
        type DisplayItem,
        SceneObjectsStore,
        SelectObjectsStore,
    } from "../SelectObjectsUtils";
    import type { IdBimScene } from "bim-ts";
    import { Switch } from "../../../libui/checkbox";
    import type { SceneInstancesSelectorValue } from "ui-bindings";
    import Divider from "../../custom-components/Divider.svelte";
    import VirtualList from "svelte-tiny-virtual-list";
    import { VersionedStore } from "src/VersionedStore";
    import SelectItem from "../../../libui/icon/svg/selectItem.svelte";
    import SimpleTooltip from "../../../libui/tooltip/SimpleTooltip.svelte";

    export let selected: SceneInstancesSelectorValue[] = [];
    export let store: SelectObjectsStore;

    const switchActive = new VersionedStore(store.isToggleActive);
    const allItems = new VersionedStore<DisplayItem[]>(store.viewList);

    onDestroy(() => {
        store.onDestroy();
    });

    function isReadOnly(id: IdBimScene): boolean | undefined {
        return selected.find((i) => i.value === id)?.readonly
            ? true
            : undefined;
    }

    $: allItemsValue =
        selected.length === $allItems.length
            ? true
            : selected.length === 0
              ? false
              : ("multiple" as const);
</script>

<div class="root {store.types.join('-')}-list">
    <Divider upDown={true} />
    <div class="scene-items-list">
        {#if $allItems.length > 1}
            <span class="select-all-items">
                <SelectSceneItem
                    name={`All ${$allItems.length} ${getPluralDisplayName(store.types)}`}
                    value={allItemsValue}
                    onClickCheckbox={(newVal) => {
                        let changedItems;
                        if (newVal) {
                            changedItems = $allItems.map((i) => ({
                                value: i.id,
                                readonly: i.isReadonly,
                            }));
                        } else {
                            changedItems = selected.filter((i) =>
                                isReadOnly(i.value),
                            );
                        }
                        store.updateSelected(changedItems);
                    }}
                >
                    {#if store instanceof SceneObjectsStore}
                        <span class="tag">
                            <span
                                class="select-button text-main-medium"
                                on:click={() => {
                                    if (store instanceof SceneObjectsStore) {
                                        store.focusSceneInstances(
                                            selected.map((s) => s.value),
                                        );
                                    }
                                }}
                            >
                                <SelectItem />
                            </span>
                        </span>
                    {/if}
                </SelectSceneItem>
            </span>
        {/if}
        <VirtualList height={240} itemCount={$allItems.length} itemSize={24}>
            <div slot="item" let:index let:style {style}>
                {@const item = $allItems[index]}
                <SelectSceneItem
                    name={item.displayName}
                    value={item.value}
                    onClickCheckbox={(newVal) => {
                        let changedItems;
                        if (newVal) {
                            changedItems = selected.slice();
                            changedItems.push({
                                value: item.id,
                                readonly: item.isReadonly,
                            });
                        } else {
                            changedItems = selected.filter(
                                (i) => i.value != item.id,
                            );
                        }
                        store.updateSelected(changedItems);
                    }}
                    readonly={item.isReadonly}
                >
                    {#if store instanceof SceneObjectsStore}
                        <span class="tag text-main-light">
                            {#if item.tag}
                                <span>{@html item.tag}</span>
                            {/if}
                            <span
                                class="select-button text-main-medium"
                                on:click={() => {
                                    if (store instanceof SceneObjectsStore) {
                                        store.focusSceneInstances([item.id]);
                                    }
                                }}
                            >
                                <SelectItem />
                            </span>
                        </span>
                    {:else}
                        <SimpleTooltip
                            id={item.id.toString()}
                            text={item.tag}
                            show={!!item.tag && item.tag.length > 84}
                        >
                            <span class:text-main-light={!item.tag}>
                                {item.tag || "No Description"}
                            </span>
                        </SimpleTooltip>
                    {/if}
                </SelectSceneItem>
            </div>
        </VirtualList>
    </div>
    <Divider upDown={false} />
    <div class="actions-menu">
        <span class="isolate-switch">
            <Switch
                value={$switchActive}
                onChange={(val) => store.onToggle(val)}
            />
            <span class="mdc-typography--body1">
                {store.toggleName}
            </span>
        </span>
    </div>
</div>

<style lang="scss">
    .root {
        min-width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
    .select-all-items {
        padding: 4px 8px 4px 0;
    }
    .scene-items-list {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        width: 100%;
    }
    .actions-menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .isolate-switch {
        display: flex;
        align-items: center;
        gap: 4px;
        height: 32px;
    }
    .scene-items-list::-webkit-scrollbar {
        display: none;
    }
    .scene-items-list :global(.virtual-list-wrapper) {
        overflow-x: hidden;
        overflow-y: scroll;
        padding-right: 8px;
        box-sizing: content-box;
    }
    .tag {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
    }
    .select-button {
        cursor: pointer;
        height: fit-content;
        padding-top: 3px;
    }
</style>
