import type { Bim, IrregularHeightmapGeometry, RegularHeightmapGeometry, IdBimScene, TerrainTileId } from "bim-ts";
import { TerrainGeoVersionSelector, TerrainHeightMapRepresentation } from "bim-ts";
import { LazyVersioned, Yield } from "engine-utils-ts";
import { Aabb2, KrMath, Matrix4 } from "math-ts";
import type { ExportedFileDescription, FileExporter, FileExporterContext } from "ui-bindings";
import { CommonExportSettings, getExportProjectName, selectExportResolution } from "../CommonExportSettings";
import { createGeoTiff } from "./DownloadGeoTiff";
import { ProjectInfo, VerdataSyncerType } from "src";


export class TiffFileExporter implements FileExporter<CommonExportSettings> {

    constructor(
        readonly bim: Bim,
        readonly projectInfo: LazyVersioned<ProjectInfo | undefined>,
        readonly projectVerdataSyncer: VerdataSyncerType,
    ) {
    }

    initialSettings() {
        return {defaultValue: new CommonExportSettings()};
    }

    *startExport(settings: CommonExportSettings, context: FileExporterContext): Generator<Yield, ExportedFileDescription[]> {
        const bimInstancesIds = settings.export_only_selected ?
            this.bim.instances.getSelected() :
            Array.from(this.bim.instances.allIds());
        const {maxSegmentSize, totalArea, representations} = unpackGeometries(this.bim, bimInstancesIds);
        const options = getAvailableOptions(maxSegmentSize, totalArea);

        Yield.NextFrame;

        const resolutionParam = yield * selectExportResolution(context, options, "pixel_size", maxSegmentSize)

        const geoTiffBinary = yield* createGeoTiff(
            resolutionParam,
            this.bim.instances.getSceneOrigin(),
            representations,
        );

        const name = getExportProjectName(this.projectVerdataSyncer, this.projectInfo.poll());

        return [{extension: 'tif', file: geoTiffBinary, name}];
    }
}

export function unpackGeometries(bim: Bim, bimInstancesIds: IdBimScene[]) {
    let maxSegmentSize = 0;
    let globalBbox = Aabb2.empty();
    const representations: {
        tilesIdsBbox: Aabb2,
        tilesSize: number,
        geoTiles: Map<TerrainTileId, RegularHeightmapGeometry> | Map<TerrainTileId, IrregularHeightmapGeometry>,
        worldMatrix: Matrix4,
    }[] = [];

    for (const id of bimInstancesIds) {

        const instance = bim.instances.peekById(id)!;
        if (instance.representation != null && instance.representation instanceof TerrainHeightMapRepresentation) {

            const tilesIdsBbox = Aabb2.empty();
            const regularTiles = new Map<TerrainTileId, RegularHeightmapGeometry>();
            const irregularTiles = new Map<TerrainTileId, IrregularHeightmapGeometry>();

            for (const [tileId, tile] of instance.representation.tiles) {

                const regularGeometry = bim.regularHeightmapGeometries.peekById(
                    tile.selectGeoId(TerrainGeoVersionSelector.Latest));
                if (regularGeometry !== undefined) {
                    if (regularGeometry.segmentSizeInMeters > maxSegmentSize) {
                        maxSegmentSize = regularGeometry.segmentSizeInMeters;
                    }

                    tilesIdsBbox.expandByPoint(tileId);
                    regularTiles.set(tileId, regularGeometry);
                }

                const irregularGeometry = bim.irregularHeightmapGeometries.peekById(
                    tile.selectGeoId(TerrainGeoVersionSelector.Latest));
                if (irregularGeometry !== undefined) {
                    tilesIdsBbox.expandByPoint(tileId);
                    irregularTiles.set(tileId, irregularGeometry);
                }
            }

            globalBbox.union(tilesIdsBbox.clone().scale(instance.representation.tileSize));

            if (irregularTiles.size === 0) {
                representations.push({
                    tilesIdsBbox: tilesIdsBbox,
                    tilesSize: instance.representation.tileSize,
                    geoTiles: regularTiles,
                    worldMatrix: instance.worldMatrix,
                });
            } else if (regularTiles.size === 0) {
                representations.push({
                    tilesIdsBbox: tilesIdsBbox,
                    tilesSize: instance.representation.tileSize,
                    geoTiles: irregularTiles,
                    worldMatrix: instance.worldMatrix,
                });
            } else {
                console.error("instance has both regular and non-regular tiles");
            }
        }
    }

    const totalArea = globalBbox.width() * globalBbox.height();

    if (maxSegmentSize === 0) {

        maxSegmentSize = KrMath.clamp(KrMath.floorPowerOfTwo(1e-6 * totalArea), 0.5, 2);
    }

    return {maxSegmentSize, totalArea, representations};
}

function getAvailableOptions(maxSegmentSize: number, totalArea: number): Map<number, string> {
    const options = new Map<number, string>();

    for (let i = 1; i <= 4; i *= 2) {
        const pixelSize = maxSegmentSize * i;
        let details = "1px = " + pixelSize.toString() + "m (~" +
            (totalArea / (262144 * pixelSize * pixelSize)).toFixed(1) + "MB)";
        options.set(pixelSize, details);
    }

    return options;
}
