<script lang="ts">
    import type { Button } from "./types";
    import { ButtonType } from "./types";
    import IconComponent from "../icon/Icon.svelte";

    export let data: Button;
    export let compact: boolean = false;
</script>

<button
    on:click={(e) => data.onClick && data.onClick(e)}
    disabled={data.disabled === true}
    title={data.hint}
    class="mdc-button"
    class:mdc-button--small={compact}
    class:mdc-button--icon-leading={data.icon !== undefined}
    class:mdc-button--text={data.type === ButtonType.Text ||
        data.type === ButtonType.Secondary}
    class:mdc-button--outlined={data.type === ButtonType.Outlined}
    class:mdc-button--raised={data.type === ButtonType.Contained}
    class:mdc-button--unelevated={data.type === ButtonType.Unelevated}
    class:mdc-button--secondary={data.type === ButtonType.Secondary}
>
    <div class="mdc-elevation-overlay" />
    {#if data.type !== ButtonType.Text && data.type !== ButtonType.Secondary}
        <span class="mdc-button__ripple" />
    {/if}
    <span class="mdc-button__focus-ring" />
    {#if data.icon !== undefined && !data.iconAfterLabel}
        <IconComponent name={data.icon} />
    {/if}
    <span class="mdc-button__label">{data.label}</span>
    {#if data.icon !== undefined && data.iconAfterLabel}
        <IconComponent name={data.icon} />
    {/if}
</button>

<style lang="scss">
    .mdc-button .mdc-button__focus-ring + :global(svg) {
        margin-right: 4px;
    }
    .mdc-button .mdc-button__label + :global(svg) {
        margin-left: 4px;
    }
    .mdc-button .mdc-button__label {
        text-wrap: nowrap;
        text-overflow: ellipsis;
    }
    .mdc-button.mdc-button--icon-leading {
        padding: 0 8px;
        &:not(.mdc-button--small) {
            padding: 0 16px;
        }
    }
</style>
