<script lang="ts">
    import GuideGroup from "./Group.svelte";

    let openedGroup = "";
</script>

<div class="description">
    Cut-fill and piles reveal length are the subject of multiple tradeoffs.
    Please, check items bellow to learn how PVFarm handles such cases:
</div>
<GuideGroup name="Piles length with cut-fill disabled" bind:openedGroup>
    <div class="icon-container">
        <img
            src="static/images/cut-fill-disabled.png"
            alt="Piles length with cut-fill disabled"
        />
    </div>
    <div class="mdc-typography--body1">
        <div class="text-good">
            <div class="mark bg-good" />
            Target slope along solar array axis
        </div>
        <div class="text-accent">
            <div class="mark bg-accent" />
            Existing terrain with cut-fill updates disabled
        </div>
        <div class="text-danger">
            <div class="mark bg-danger" />
            Pile reveal length beyond economically feasible values
        </div>
    </div>
    <p>
        PVFarm always matches target slope along solar array axis. The only way
        to achieve this with with cut-fill disabled is to increase pile reveal
        length. As a side effect you may encounter piles with an extremely high
        length. Possible actions:
    </p>
    <ul>
        <li>Enable cut-fill</li>
        <li>
            Use property analysis window to select solar arrays with extreme
            piles length and delete them or draw exclusion boundaries around
        </li>
        <li>Draw exclusion boundaries based on visual terrain analysis</li>
        <li>
            Consider solar array with greater max slope or bay-to-bay angles
        </li>
        <li>Accept resulting pile reveal values</li>
    </ul>
</GuideGroup>
<GuideGroup
    name="Piles reveal and calculation errors with cut-fill enabled"
    bind:openedGroup
>
    <div class="icon-container">
        <img
            src="static/images/cut-fill-enabled.png"
            alt="Piles reveal and calculation errors with cut-fill enabled"
        />
    </div>
    <div class="mdc-typography--body1">
        <div class="text-good">
            <div class="mark bg-good" />
            Min-max piles reveal variation window
        </div>
        <div class="text-accent">
            <div class="mark bg-accent" />
            Additional fill volume will be needed without the pile reveal window
        </div>
        <div class="text-danger">
            <div class="mark bg-danger" />
            Additional cut volume will be needed without the pile reveal window
        </div>
    </div>
    <p>
        Adjusting the min-max piles reveal target allows you to test balance
        options between the target piles length and savings in cut-fill volume
        and area of preserved topsoil. Greater min-max pile reveal window
        reduces cut-fill amounts and preserves more topsoil.
    </p>
    <p>
        Some piles could have their target min-max length exceeded due to the
        limitations in calculation process. Please, <a
            class="text-accent"
            href="mailto:info@sunbim.io"
            target="”_blank”">share a feedback with us</a
        > if such errors are not acceptable for the design phase of your project.
    </p>
    <p class="mdc-typography--body1 text-main-medium">
        * A sum of all calculations errors is usually relatively small compared
        to other sources of the pile length variation: topography data
        inaccuracy, terrain grading errors margin, piles installation errors
        margin.
    </p>
</GuideGroup>
<GuideGroup
    name="Cut-fill target slopes vs. slopes along solar array axis"
    bind:openedGroup
>
    <div class="icon-container">
        <img
            src="static/images/slopes.png"
            alt="Cut-fill target slopes vs. slopes along solar array axis"
        />
    </div>
    <div class="mdc-typography--body1">
        <div class="text-good">
            <div class="mark bg-good" />
            Target slope along solar array axis
        </div>
        <div class="text-accent">
            <div class="mark bg-accent" />
            Cut-fill max slopes targets
        </div>
        <div class="text-danger">
            <div class="mark bg-danger" />
            Actual terrain slopes after the cut-fill and additional cut-fill volumes
        </div>
    </div>
    <p>
        The only economically feasible way to install solar arrays with the
        target slope is to have terrain graded to the same slope (slightly
        affected by min-max pile reveal window). As a result terrain slopes
        after the cut-fill could be less than cut-fill max slopes targets under
        the solar arrays only.
    </p>
    <ul>
        <li>
            Consider solar array with a greater max slope or a flexible
            bay-to-bay angles
        </li>
        <li>Increase Max pile reveal target (inefficient)</li>
        <li>Accept the cut-fill volumes estimate</li>
    </ul>
</GuideGroup>
<GuideGroup name="12ft/4m cut-fill grid size" bind:openedGroup>
    <div class="icon-container">
        <img src="static/images/cut-fill-grid.png" alt="Cut-fill grid size" />
    </div>
    <div class="mdc-typography--body1">
        <div class="text-good">
            <div class="mark bg-good" />
            High-res DEM data uploaded to PVFarm
        </div>
        <div class="text-accent">
            <div class="mark bg-accent" />
            Cut-fill estimations
        </div>
    </div>
    <p>
        Estimations with a grid different from 12ft/4m are likely to result in
        extremely optimistic (more dense grid) or pessimistic (less dense grid)
        estimates. 12ft/4m digital grid provides precision more or less
        achievable by the grading equipment.
    </p>

    <p>
        You still benefit from uploading high-res DEM data: PVFarm uses high
        resolution terrain data to calculate cut-fill volume estimations.
    </p>
</GuideGroup>
<GuideGroup name="Length of the piles in the 3d viewport" bind:openedGroup>
    <div class="icon-container">
        <img
            src="static/images/piles-viewport.png"
            alt="Length of the piles in the 3d viewport"
        />
    </div>
    <div class="mdc-typography--body1">
        <div class="text-good">
            <div class="mark bg-good" />
            Visible length of the piles in the 3d viewport
        </div>
        <div class="text-accent">
            <div class="mark bg-accent" />
            Actual length of the piles available via reports
        </div>
    </div>
    <p>
        Visible length of the piles in the 3d viewport are equal to the longest
        pile in the each array. Actual length of the piles can be analysed using
        the reports.
    </p>
</GuideGroup>

<style>
    .description {
        padding: 12px 0 8px;
    }
    .icon-container {
        margin: 0 -8px;
        min-height: 140px;
    }
    a {
        font-weight: 600;
        text-decoration: none;
    }
    .mark {
        width: 12px;
        height: 4px;
        border-radius: 2px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;
    }
</style>
