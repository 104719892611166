import { Failure, Success, Yield } from "engine-utils-ts";
import { ProjectInfo, VerdataSyncerType } from "src";
import { FileExporterContext, PropertyFitChecker, PUI_ConfigBasedBuilderParams, PUI_ConfigPropertyTransformer, PUI_PropertyNodeSelector, PUI_PropertyNodeSelectorArgs } from "ui-bindings";

export const contoursSimplifyingEps = 2e-3;

export class CommonExportSettings {
    export_only_selected: boolean = true;
}

export function tryGetProjectIdFromWindowLocation(): string | null {
    const path = window.location.pathname;
    const projectIdString = window.location.pathname.slice(1);
    if (Number.isInteger(parseInt(projectIdString))) {
        return projectIdString;
    }
    return null;
}

export function getExportProjectName(projectVerdataSyncer: VerdataSyncerType, projectInfo?:ProjectInfo,): string | undefined {
    let projectNameParts:string[] = [];
    const projectId = tryGetProjectIdFromWindowLocation();

    if(projectId !== null){
        projectNameParts.push(projectId);
    }

    if(projectInfo!==undefined){
        const projectName = projectInfo.name;
        projectNameParts.push(projectName);
    }

    const version = projectVerdataSyncer.getCurrentVersionId();
    projectNameParts.push(`ver.${version}`);

    return projectNameParts.join("_");
}


export function* selectExportResolution(
    context: FileExporterContext, 
    options:Map<number, string>, 
    defaultFieldName: string, 
    defaultValue: number ): Generator<Yield, number> {

    function getValueFromDetails(details: string, options: Map<number, string>) {
        for (let [key, value] of options.entries()) {
            if (value === details) {
                return new Success(key);
            }
        }
        return new Failure({msg: "unexpected export parameter"});
    }

    const defaultValueWithField = { [defaultFieldName]: defaultValue };

    const propertyFitChecker: PropertyFitChecker = (
        propertyName: string | number,
        propertyValue: number,
        propertyPath: (string | number)[]
    ) => {
        return options.has(propertyValue);
    };
    const propertyTransformer = new PUI_ConfigPropertyTransformer<
        number,
        string,
        PUI_PropertyNodeSelector,
        PUI_PropertyNodeSelectorArgs
    >(
        (num) => options.get(num)!,
        (propValue) => getValueFromDetails(propValue, options),
        () => [
            PUI_PropertyNodeSelector,
            {
                options: [...options.values()],
            },
        ]
    );
    const params = yield* context.requestSettings({
        ident: "export-settings",
        defaultValue: defaultValueWithField,
        uiBuilderParams: {
            configBuilderParams: PUI_ConfigBasedBuilderParams.new([
                [propertyFitChecker, propertyTransformer],
            ]),
        },
    });

    return params[defaultFieldName];
}

