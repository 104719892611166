<script lang="ts">
    import { getContext, onDestroy } from "svelte";
    import type { Bim } from "bim-ts";
    import { LazyBasic, Observer } from "engine-utils-ts";
    import {
        UiBindings,
        PUI_SceneInstancesSelectorPropertyNode,
    } from "ui-bindings";

    import SelectedItemsList from "./common/SelectedItems.svelte";
    import SelectSceneItemActions from "./common/SelectItemsActions.svelte";
    import type { TextButtonSettings } from "../custom-components/SelectArea/SelectedArea";
    import {
        getPluralDisplayName,
        showType,
        SceneObjectsStore,
    } from "./SelectObjectsUtils";
    import { KreoEngine } from "engine-ts";
    import SelectItem from "../../libui/icon/svg/selectItem.svelte";

    export let property: PUI_SceneInstancesSelectorPropertyNode;

    const bim: Bim = getContext("bim");
    const engine = getContext<KreoEngine>("engine");
    const uiBindings: UiBindings = getContext("uiBindings");

    const itemsStore = new SceneObjectsStore({
        bim,
        engine,
        uiBindings,
        types: property.types,
        maxSelect: property.maxSelect,
        filter: property.filterItems,
        selected: property.value,
        updateSelected: (selected) => {
            property.value = selected;
        },
    });

    $: {
        itemsStore.selected.replaceWith(property.value);
    }

    $: displayName = getPluralDisplayName(property.types);

    let subscription: Observer | null = null;
    const isSelectingMode = new LazyBasic("isSelecting", false);
    function startSelection() {
        if (!isSelectingMode.poll()) {
            subscribeToKeyboardEvents();
            isSelectingMode.replaceWith(true);
            //unselect all
            bim.instances.toggleSelected(false, bim.instances.getSelected());
            // subscribe to select event
            subscription = itemsStore.subscribeToSelectEvent();
        }
    }

    function stopSelection() {
        isSelectingMode.replaceWith(false);
        // unsubscribe from select event
        subscription?.dispose();
        subscription = null;
        unsubscribeFromKeyboardEvents();
    }

    function subscribeToKeyboardEvents() {
        document.addEventListener("keydown", handleKeyDown, true);
    }

    function unsubscribeFromKeyboardEvents() {
        document.removeEventListener("keydown", handleKeyDown, true);
    }

    function handleKeyDown(event: Event) {
        if (!(event instanceof KeyboardEvent)) {
            return;
        }
        if (event.key === "Escape") {
            stopSelection();
        }
    }

    onDestroy(() => {
        stopSelection();
    });

    let additionalActions: TextButtonSettings[] = [];
    $: if (displayName) {
        additionalActions = [
            {
                label: `Select ${displayName} from scene`,
                iconBefore: "Select",
                action: () => {
                    startSelection();
                },
                isEnabled: isSelectingMode,
            },
        ];

        if (property.createInstance) {
            additionalActions.push({
                label: `Draw new ${showType(property.types[0])}`,
                iconBefore: "AddPlus",
                action: () => {
                    if (property.createInstance) {
                        property.createInstance(uiBindings);
                    }
                },
            });
        }
    }
</script>

<div class="body-container">
    <SelectedItemsList
        name={property.name}
        store={itemsStore}
        defaultMessage={`No ${displayName} selected`}
        hasError={property.hasItemsError}
        hasErrorInGroups={property.hasErrorInGroups}
        customSelectedItemsMessage={property.customSelectedItemsMessage}
        let:item
        let:isError
    >
        <span class="tag text-main-light" class:text-danger={isError}>
            {#if item.tag}
                <span>
                    {@html item.tag}
                </span>
            {/if}
            <span
                class="select-button"
                on:click={() => {
                    itemsStore.focusSceneInstances([item.id]);
                }}
            >
                <SelectItem />
            </span>
        </span>
        <svelte:fragment slot="selected" let:ids>
            <span
                class="select-button"
                on:click={() => {
                    itemsStore.focusSceneInstances(ids);
                }}
            >
                <SelectItem />
            </span>
        </svelte:fragment>
    </SelectedItemsList>
</div>
<SelectSceneItemActions store={itemsStore} {additionalActions} />

<style lang="scss">
    .tag {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
    }
    .select-button {
        cursor: pointer;
        height: fit-content;
        padding-top: 3px;
        color: var(--color-main-medium);
    }
</style>
