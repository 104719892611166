<script lang="ts">
    //@ts-ignore
    import NumberSpinner from "svelte-number-spinner";
    import flash from "../../flash";
    import { Vector2 } from "math-ts";
    import { ObjectUtils, LazyBasic, LazyDerived, type LazyVersioned, StringUtils } from "engine-utils-ts";
    import { getContext } from "svelte";
    import type { Bim, IdBimScene } from "bim-ts";
    import { ContextMenuConfig, PUI_Builder, PUI_Lazy, type UiBindings } from "ui-bindings";
    import {ChevronRight, Multiple} from "../../../libui/icon/svg";
    import DCRatioWidget from "./DCRatioWidget.svelte"
    import { type InvertersGroup, ILRProperty } from "layout-service";
    import { List, ListGroup, ListInput, ListItem, TitledList } from "../../../libui/list";
    import { ChipsInput } from "../../../libui/chips";
    import type { KreoEngine } from "engine-ts";
    import { NumberSpinnerUtils } from "src/pui/NumberSpinnerUtils";
    import { IconButton } from "../../../libui/button";

    export let name: string;
    export let value: [number, number];
    export let minMax: [number, number];
    export let step: number;
    export let inverters: InvertersGroup[] | undefined;
    export let openBlocks: ((pos:Vector2) => void) | undefined;
    export let readonly: boolean = false;
    export let notActive: boolean | undefined;

    const bim = getContext<Bim>('bim');
    const engine = getContext<KreoEngine>("engine");
    const uiBindings = getContext<UiBindings>('uiBindings');

    const lazyInverters = new LazyBasic('lazy-inverters', inverters?.slice() ?? []);
    $:{
        lazyInverters.replaceWith(inverters ?? []);
    }

    $:property = new ILRProperty({
        name,
        value,
        minMax,
        step,
        onChange:(newValue) => {
            value = newValue;
        },
        action: inverters ? (pos)=> { 
            const config = createILRContextViewUiForBlocks(bim, lazyInverters, pos);
            uiBindings.addContextMenuView(config);
        } : undefined
    });

    let div: HTMLElement;
    let preValue = value;
    $:if (!ObjectUtils.areObjectsEqual(preValue, value)) {
        preValue = value;
        flash(div);
    }

    const speed = 0.1;

    export function createILRContextViewUiForBlocks(
    bim: Bim,
    lazyInverters: LazyVersioned<InvertersGroup[]>,
    pos: Vector2
    ): ContextMenuConfig {


    const puiRootLazy = LazyDerived.new1(
        "ilr-context-lazy",
        null,
        [lazyInverters],
        ([invertersByBlock])=>{
            const groups = new ListGroup([]);
            for (const block of invertersByBlock) {
                const items: ListItem[] = [];
                const sortedByDcRatio = block.inverters.slice()
                    .sort((a, b) => a.dcRatio - b.dcRatio || a.model.localeCompare(b.model));
                for (const inverter of sortedByDcRatio) {
                    const onClick = () => {
                        const children: IdBimScene[] = [];
                        bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(inverter.id, (id) => {
                            children.push(id);
                            return true;
                        });
                        bim.instances.setSelected([inverter.id]);
                        engine.focusCamera(children);
                    };
                    const inst = bim.instances.peekById(inverter.id)!;
                    const item = new ListItem(
                        StringUtils.capitalizeFirstLatterInWord(`${inst.type_identifier} ${inverter.id}`),
                        undefined,
                        undefined,
                        new IconButton("SelectItem", onClick)
                    );
                    item.onClick = onClick;
                    
                    item.chips = new ChipsInput([`${inverter.dcRatio}`]);
                    items.push(item);
                }
                const list = new List(items);
                const group = new TitledList(block.label, list);
                groups.lists.push(group);
            }

            const builder = new PUI_Builder({});
            builder.addCustomProp<
                ListInput,
                undefined
            >({
                name: "list-item",
                type_ident: "list",
                value: new ListInput(groups),
                context: undefined,
                onChange: () => {},
            });

           return builder.finish()
        }
    ).withoutEqCheck();

    const config = new ContextMenuConfig({
        identity: `ilr-context-for-blocks-${lazyInverters.poll().map(i=>i.label).join(', ')}`,
        viewSource: new PUI_Lazy(puiRootLazy),
        positionPx: pos,
        header: "ILR by Inverter",
    });
    return config;
}

const decimals = 2;
</script>

<div 
    class="ui-config-property property-row"
    class:not-active={notActive}
    >
    <div bind:this={div} class="property-name">
        <div class="property-name-label mdc-typography--body1 text-main-medium">
            {property.name}
        </div>
    </div>
    <div class="property-value">
        <div class="edit-field-container">
            {#if readonly}
                <div class="config-ui-input-field mdc-typography--body1 ilr-input">
                    { NumberSpinnerUtils.addUnit(property.minValue, false, decimals) }
                </div>
            {:else}
                <NumberSpinner
                    bind:value={property.minValue}
                    type="number"
                    class="
                    config-ui-input-field
                    mdc-typography--body1 ilr-input
                    "
                    step={property.step}
                    {speed}
                    min={property.minMax[0]}
                    max={property.minMax[1]}
                    decimals={decimals}
                />
            {/if}
            <DCRatioWidget
                minMax={[property.minValue, property.maxValue]}
                inverterGroups={inverters ?? []}
                onClick={(pos) => {
                    property.action(new Vector2(pos.x, pos.y));
                }}
            />
            {#if readonly}
                <div class="config-ui-input-field mdc-typography--body1 ilr-input ilr-max">
                    { NumberSpinnerUtils.addUnit(property.maxValue, false, decimals) }
                </div>
            {:else}
                <NumberSpinner
                    bind:value={property.maxValue}
                    type="number"
                    class="
                    config-ui-input-field
                    mdc-typography--body1 ilr-input ilr-max
                    "
                    step={property.step}
                    {speed}
                    min={property.minMax[0]}
                    max={property.minMax[1]}
                    decimals={decimals}
                />
            {/if}

            {#if inverters}
                <span
                    class="expend-more multiple"
                    on:click={(e) => {
                        property.action(new Vector2(e.x, e.y));
                    }}
                >
                    <Multiple />
                </span>
            {/if}
            {#if openBlocks}
                <span
                    class="expend-more text-main-light"
                    on:click={(e) => {
                        if (openBlocks) {
                            openBlocks(new Vector2(e.x, e.y));
                        }
                    }}
                >
                    <ChevronRight />
                </span>
            {/if}
        </div>
    </div>
</div>

<style lang="scss">
    .expend-more {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .multiple {
        padding: 4px;
    }
</style>
