import { TerrainHeightMapRepresentation, type Bim, type IdBimScene, type SceneInstance } from 'bim-ts';
import { IterUtils } from 'engine-utils-ts';

export function terrainHasChanges(instance?: SceneInstance) {
    if (
        !!instance &&
        instance.representation instanceof TerrainHeightMapRepresentation
    ) {
        for (const [_tileId, tile] of instance.representation.tiles) {
            if (tile.updatedGeo != 0) {
                return true;
            }
        }
    }
    return false;
}

export function sumByProperty(
    bim: Bim,
    instances: [IdBimScene, string][],
    path: string = "computed_result | length",
    propertyUnit: string = "m",
) {
    const sum = IterUtils.sum(instances, (item) => {
        const inst = bim.instances.perId.get(item[0]);
        if (inst) {
            return inst.properties.get(path)?.asNumber() ?? 0;
        }
        return 0;
    });
    const { value, unit } = bim.unitsMapper.mapToConfigured({
        value: sum,
        unit: propertyUnit,
    })!;
    return `${Math.round(value)} ${unit}`;
}

export const LayoutGroupLabels = {
    "combiner-box": "Combiner boxes",
    "sectionalizing-cabinet": "Sectionalized cabinets",
    substation: "Substations",
    inverter: "Inverters",
    transformer: "Transformers",
};