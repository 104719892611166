<script lang="ts">
    import "ag-grid-enterprise/styles/ag-theme-balham.min.css";

    export let hide: boolean = false;
</script>

<div class="ag-piles" style:display={hide ? "none" : "block"}>
    <slot></slot>
</div>

<style lang="scss">
    .ag-piles :global(.ag-theme-balham) {
        --ag-font-size: 14px;
        --ag-font-family: "Source Sans";
        --ag-foreground-color: var(--color-main-dark);
        --ag-header-background-color: transparent;
        --ag-header-foreground-color: var(--color-main-medium);
        --ag-data-color: var(--color-main-dark);

        --ag-cell-horizontal-padding: 2px;
        --ag-range-selection-border-color: transparent;

        --ag-row-border-width: 0px;
        --ag-borders: 0;
        :global(.ag-cell) {
            -webkit-font-smoothing: antialiased;
            line-height: 30px;
        }
        :global(.divider) {
            --ag-row-border-width: 1px;
            --ag-row-border-color: var(--color-divider);
        }
        :global(.ag-header-row) {
            border-bottom: 1px solid var(--color-divider);
        }
        :global(.header-cell-visible) {
            width: auto !important;
        }
        :global(.ag-header-cell) {
            &:before {
                display: none;
            }
        }
        :global(.ag-cell-icon) {
            display: flex;
            align-items: center;
        }
        :global(.ag-cell.editable),
        :global(.ag-cell.readonly) {
            --ag-cell-horizontal-padding: 8px;
        }
        :global(.ag-cell.editable:after) {
            content: "";
            border-radius: 1px;
            background-color: var(--color-alt);
            position: absolute;
            z-index: -2;
            inset: 2px;
        }
        :global(.toggle-cell div) {
            display: flex;
            align-items: center;
            height: 100%;
            justify-content: end;
        }
    }
    .ag-piles :global(.hide-header .ag-header) {
        display: none;
    }
</style>
