import type { BasicPropertyValue, SceneInstance } from 'bim-ts';
import { type Bim, type IdBimScene, type UnitsMapper, PropertyBase, BimProperty, NumberProperty } from 'bim-ts';
import { ObservableObject, type LazyVersioned, LazyDerived, VersionedInvalidator, IterUtils, DefaultMapObjectKey } from 'engine-utils-ts';
import { PUI_ActionsNode, PUI_GroupNode, PUI_PropertyNodeMultiSelector, PUI_PropertyNodeSelector } from 'ui-bindings';


export interface PropertyFilter {
    fits(property: BimProperty | PropertyBase): boolean;
}
export class PropertyValueRangeFilter implements PropertyFilter {
    constructor(
        readonly asUnit: string,
        readonly minValue: number,
        readonly maxValue: number,
    ) {
    }

    fits(property: BimProperty | PropertyBase): boolean {
        let v: number|undefined = undefined;
        if (this.asUnit) {
            if (property instanceof BimProperty || property instanceof NumberProperty) {
                v = property.as(this.asUnit);
            } else {
                return false;
            }
        } else {
            v = (property as any).value;
        }
        if (v !== undefined && v >= this.minValue && v <= this.maxValue) {
            return true;
        }
        return false;
    }
}
export class PropertyExactValuesFilter implements PropertyFilter {
    constructor(
        readonly asUnit: string,
        readonly values: Set<string>,
    ) {
    }
    fits(property: BimProperty | PropertyBase): boolean {
        let v: number|string|undefined = undefined;
        if (this.asUnit) {
            if (property instanceof BimProperty || property instanceof NumberProperty) {
                v = property.as(this.asUnit);
            } else {
                return false;
            }
        } else {
            v = (property as any).value;
        }
        if (v === undefined) {
            return false;
        }
        v = v.toString();
        return this.values.has(v);
    }
}

export interface SceneInstanceFilter {
    fits(instance: SceneInstance): boolean;
}

export class SceneInstanceByTypeFilter implements SceneInstanceFilter {
    constructor(
        readonly allowedTypes: string[],
    ) {

    }
    fits(instance: SceneInstance): boolean {
        if (this.allowedTypes.length === 0) {
            return true;
        }
        return this.allowedTypes.includes(instance.type_identifier);
    }
}

export class SceneInstanceByPropertyFilter implements SceneInstanceFilter {
    
    readonly propertyPathSplit: string[];

    constructor(
        readonly propertyPath: string,
        readonly propertyFilters: PropertyFilter,
    ) {
        this.propertyPathSplit = propertyPath.split(' | ');
    }
    
    fits(instance: SceneInstance): boolean {
        const p = instance.properties.get(this.propertyPath);   
        if (p) {
            return this.propertyFilters.fits(p);
        }
        const pNew = instance.props.getAtPath(this.propertyPathSplit);
        if (pNew instanceof PropertyBase) {
            return this.propertyFilters.fits(pNew);
        }
        return false;
    }
    
}

export interface InstancesQuery {
    readonly filters: {filter: SceneInstanceFilter, inverter: boolean}[];
}

export interface InstancesQueryResult {
    readonly resultIds: IdBimScene[];
    readonly queryCountsPerFilter: number[];
}

export interface FilterQueryResult {
}


export interface SelectByPropsValueParams {
    object_types: string[],
    property_path: string | null;
    property_values_as_string: string[];
}

export function createSelectByPropParamsUi(
    bim: Bim,
    unitsMapper: UnitsMapper,
): [
    ObservableObject<SelectByPropsValueParams>,
    LazyVersioned<PUI_GroupNode>,
] {
    const paramsObj = new ObservableObject<SelectByPropsValueParams>({
        identifier: 'props-chart',
        initialState: {
            object_types: [],
            property_path: null,
            property_values_as_string: [],
        }
    });
    paramsObj.observeObject({
        settings: {doNotNotifyCurrentState: true, immediateMode: true},
        onPatch: ({currentValueRef}) => {
            try {
                localStorage.setItem('select-by-props-value-params', JSON.stringify(currentValueRef));
            } catch (e) {
                console.error(e);
            }
        },
    });

    try {
        const settings = localStorage.getItem('select-by-props-value-params');
        if (settings) {
            const asObj = JSON.parse(settings) as SelectByPropsValueParams;
            paramsObj.applyPatch({
                patch: {...asObj, property_values_as_string: []}
            })

        }
    } catch (e) {
        console.error(e);
    }

    const validObjsTypes = bim.instances.getLazyKnownTypes();

    const propsValuesGatherInvalidator = new VersionedInvalidator();
    class AvailablePropertyValue {

        readonly valueAsString: string;

        constructor(
            readonly valueUnit: BasicPropertyValue,
            readonly ids: IdBimScene[] = [],
        ) {
            this.valueAsString = valueUnit.value.toString();
            if (valueUnit.unit) {
                this.valueAsString += ` ${valueUnit.unit}`;
            }
        }

        toString() {
            let valueStr = this.valueUnit;
            if (typeof valueStr === 'number') {
                valueStr = valueStr;
            }
            return `${this.valueAsString} [${this.ids.length}]`;
        }
    }

    const propertySelectorParams = LazyDerived.new1(
        '', null,
        [paramsObj],
        ([params]) => {
            return {
                object_type: params.object_types,
                property_path: params.property_path,
            };
        }
    )

    const lazyKnownProps = bim.instances.basicPropsView.asLazyVersioned();

    const availableValuesGatherer = LazyDerived.new2(
        'available-values-gatherer',
        [propsValuesGatherInvalidator],
        [propertySelectorParams, lazyKnownProps],
        ([params, knownProps]) => {
            const propertyDescr = knownProps.find((kp) => kp.label == params.property_path);
            if (!propertyDescr) {
                return [];
            }
            const perPropertyValue = new DefaultMapObjectKey<BasicPropertyValue, AvailablePropertyValue>({
                unique_hash: (k) => `${k.value}:${k.unit}`,
                valuesFactory: (kp) => new AvailablePropertyValue(kp),
            });

            for (const [id, instance] of bim.instances.perId) {
                if (!propertyDescr.isFoundInTypeIdent(instance.type_identifier)) {
                    continue;
                }
                let property = bim.instances.basicPropsView.peekKnownPropertyValueByPath(id, propertyDescr.path);
                if (!property) {
                    property = {value: "N/A", unit: ""};
                } else {
                    if (typeof property.value === 'number') {
                        const converted = unitsMapper.mapToConfigured({value: property.value, unit: property.unit});
                        property = {value: converted.value, unit: converted.unit};
                    }
                }
                perPropertyValue.getOrCreate(Object.freeze(property)).ids.push(id);
            }

            const allValues = Array.from(perPropertyValue.values());
            allValues.sort((a, b) => {
                if (typeof a.valueUnit.value === 'number' && typeof b.valueUnit.value === 'number') {
                    return a.valueUnit.value - b.valueUnit.value;
                }
                return a.valueUnit.toString().localeCompare(b.valueUnit.toString());
            });

            return allValues;
        }
    );

    const selectByPropsParamsUi = LazyDerived.new4(
        'select-by-props-params-ui',
        [],
        [paramsObj, validObjsTypes, lazyKnownProps, availableValuesGatherer],
        ([params, validObjsTypes, knownProps, availableValues]) => {

            const node = new PUI_GroupNode({
                name: 'params',
                sortChildren: false,
            });
            node.addMaybeChild(new PUI_PropertyNodeMultiSelector({
                name: 'object type',
                options: validObjsTypes.map(ty => ({value: ty})),
                value: params.object_types.filter(t => validObjsTypes.includes(t)).map(ty => ({value: ty})),
                onChange: (newValue) => {
                    const newValues = newValue.map(t => t.value) as string[];

                    paramsObj.applyPatch({
                        patch: {
                            object_types: newValues,
                        },
                    });
                },
            }));

            const knownPropertyDescription = knownProps.find(d => d.label == params.property_path);
            const allowedPropsForSelectedTypes = params.object_types.length > 0 ?
                knownProps.filter(t => t.isFoundInTypeIdends(params.object_types)) : knownProps;

            node.addMaybeChild(new PUI_PropertyNodeSelector({
                name: 'property',
                value: knownPropertyDescription ? knownPropertyDescription.label : null,
                options: allowedPropsForSelectedTypes.map(t => t.label),
                onChange: (newValue) => {
                    paramsObj.applyPatch({
                        patch: {
                            property_path: newValue,
                        },
                    });
                },
            }));

            const filteredValues = IterUtils.filterMap(
                params.property_values_as_string,
                (v) => {
                    const found = availableValues.find(av => av.valueAsString == v);
                    if (!found) {
                        return undefined;
                    }
                    return {value: v, label: found.toString()};
                }
            );
            node.addMaybeChild(new PUI_PropertyNodeMultiSelector({
                name: 'by exact values',
                value: filteredValues,
                options: availableValues.map(v => ({value: v.valueAsString, label: v.toString()})),
                onChange: (newValue) => {
                    paramsObj.applyPatch({
                        patch: {
                            property_values_as_string: newValue.map(v => v.value as string),
                        },
                    });
                },
            }));

            if (filteredValues.length > 0) {
                node.addMaybeChild(new PUI_ActionsNode({
                    name: 'actions',
                    context: undefined,
                    actions: [
                        {
                            label: 'add to selection',
                            action: () => {
                                for (const v of filteredValues) {
                                    const ids = availableValues.find(av => av.valueAsString == v.value)?.ids;
                                    if (ids) {
                                        bim.instances.toggleSelected(true, ids);
                                    } else {
                                        console.error(`No ids for value ${v.value}`);
                                    }
                                }
                            }
                        },
                        {
                            label: 'select',
                            action: () => {
                                const resultIds: IdBimScene[] = [];
                                for (const v of filteredValues) {
                                    const ids = availableValues.find(av => av.valueAsString == v.value)?.ids;
                                    if (ids) {
                                        IterUtils.extendArray(resultIds, ids);
                                    } else {
                                        console.error(`No ids for value ${v.value}`);
                                    }
                                }
                                bim.instances.setSelected(resultIds);
                            }
                        },
                    ]
                }))
            }
            return node;
        }
    );

    return [
        paramsObj,
        selectByPropsParamsUi,
    ]
}