<script lang="ts">
    import { getContext, createEventDispatcher } from "svelte";
    import type { Readable } from "svelte/store";
    import type { UnitsMapper } from "bim-ts";
    import type { Layout } from "files-converters";
    import SelectorPropertyBase from "../../SelectorPropertyBase.svelte";

    export let options: Layout.CatalogItem[];
    export let value: Layout.CatalogItem | undefined;

    const unitsMapper = getContext<Readable<UnitsMapper>>("unitsMapper");
    const dispatch = createEventDispatcher();

    $: dropdownOptions = options.map((option) => {
        const label = option.getName($unitsMapper);
        return {
            label,
            value: label,
        };
    });
    $: currentValue = value ? [value.getName($unitsMapper)] : [];
</script>

<SelectorPropertyBase
    placeholder="Do not import"
    value={currentValue}
    options={dropdownOptions}
    on:change={(e) => {
        const newValue = e.detail.value;
        const index = dropdownOptions.findIndex((o) => o.label === newValue);
        dispatch("change", options[index]);
    }}
/>

<style>
</style>
