<script lang="ts">
    import type { ContextMenuSettings } from "./ContextMenuSettings";
    import ActionsProperty from "../pui/ActionsProperty.svelte";
    import { PUI_ActionsNode } from "ui-bindings";
    import { ButtonComponent, IconButton } from '../libui/button';
    import { clickOutside } from './ClickOutside';

    export let settings: ContextMenuSettings;
    let menuEl: HTMLDivElement;

    function close() {
      if(settings.dispose){
        settings.dispose();
      }
    }

    function onActionClick() { 
      if (settings.menuAction?.actionFn) {
        settings.menuAction.actionFn();
      }
      close();
    }
    
    </script>
  {#if settings.isMenuOpened}
    <div
        style="
            --mouse-x: {settings.offsetPx.x}px;
            --mouse-y: {settings.offsetPx.y}px;
            --z-index: {settings.zIndex ?? 999}
        "
        class="mdc-menu mdc-menu-surface mdc-menu-surface--open"
        on:click={() => {
            settings.closeOnInteraction && close();
        }}
        use:clickOutside={() => {
          if(settings?.dispose && !settings.menuAction){
            settings.dispose();
          }
        }}
        tabindex = "0"
        bind:this={menuEl}
    >
    {#if settings.closeIcon}
        <div class="header-holder">
          <div class="mdc-typography--headline5 header">
              {settings.header ?? ""}
          </div>
          <div class="close-menu-action-container">
              <ButtonComponent desc={new IconButton("Close", close)} />
          </div>
      </div>
  {/if}
  <div
      class="body-container custom-scrollbar"
      style="
        --max-height: {settings.maxHeightPx ? settings.maxHeightPx + 'px' : '50vh'};
        --width: {settings.widthPx ? settings.widthPx + 'px' : 'auto'};
        --min-width: {settings.minWidthPx ? settings.minWidthPx + 'px': 'auto' }
        "
    >
      <slot name="body"></slot>
      {#if settings.menuAction}
      <div class="action-container">
        <ActionsProperty
          property = {new PUI_ActionsNode({
              name: "close action",
              actions: [{
                label: settings.menuAction.name,
                isEnabled: settings.menuAction.isEnabled,
                action: onActionClick,
                style:{
                  type: "primary",
                  compact: true,
                }
              }],
              context: undefined,
          })}
        />
      </div>
      {/if}
    </div>
    </div>
  {/if}

<style lang="scss">
    .close-menu-action-container :global(.custom-icon-container) {
        padding: 4px;
    }
    .body-container {
        flex: 1;
        max-height: var(--max-height);
        width: var(--width);
        min-width: var(--min-width);
        overflow: auto;
        & :global(.mdc-tooltip) {
          width: 320px;
        }
    }
    .mdc-menu {
        //display: flex;
        flex-direction: column;
        transition: none;
        --mouse-x: 0;
        --mouse-y: 0;
        left: 0;
        top: 0;
        transform:
            translateX(min(var(--mouse-x), calc(100vw - 100%)))
            translateY(min(var(--mouse-y), calc(100vh - 100%)));
        
        --z-index: 999;
        z-index: var(--z-index);
        overflow: inherit;
    }

    .header-holder {
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        height: 40px;
        position: relative;
        z-index: 1;
        padding-right: 16px;
        padding-left: 12px;
    }
    .action-container {
        padding: 0px 16px;
        margin: 8px 0 24px;
    }
    .mdc-menu :global(.container .group:first-child:last-child) {
        margin: 0;
    }
</style>
