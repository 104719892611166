import type { ScopedLogger } from "engine-utils-ts";
import { ErrorUtils, IterUtils, StringUtils, convertThrow, doUnitDimensionsMatch } from "engine-utils-ts";
import type { EquipmentArea, MetricsGroup, ProjectMetricsType, PropertyGroup, UnitsMapper } from "..";
import { NumberProperty, PropertyBase, StringProperty } from "..";
import type { AggregateMethodType } from "./ProjectMetricsTemplate";
import { MetricTemplateGroup, MetricTemplateValue, ProjectMetricsDefaultTemplate } from "./ProjectMetricsTemplate";

export type MetricsTableValueType = string | number | null | undefined;

export class MetricTableValues {
    name: string;
    value: MetricsTableValueType[];
    unit?: string;
    decimals?: number;
    constructor(name: string, value: MetricsTableValueType[], unit?: string, decimals?: number) {
        this.name = name;
        this.value = value;
        this.unit = unit;
        this.decimals = decimals;
    }

    get displayUnit() {
        return this.decimals === 0 && this.value.some(v => typeof v === "number") ? "each" : this.unit;
    }

    get displayDecimals() {
        return this.decimals === 0 ? 0 : this.decimals === undefined ? undefined : 2;
    }
}

export interface MetricsTable {
    headers: string[];
    rows: MetricsRows;
}

export class MetricsRows {
    readonly name: string;
    readonly rows: Map<string, MetricTableValues | MetricsRows>;
    readonly aggregateMethod: AggregateMethodType;
    unit?: string;

    
    private _total?: MetricTableValues;

    constructor(args: { name: string, rows: Map<string, MetricTableValues | MetricsRows>, unit?: string, aggregateMethod?: AggregateMethodType}) {
        this.name = args.name;
        this.rows = args.rows;
        this.unit = args.unit;
        this.aggregateMethod = args.aggregateMethod ?? 'none';
    }

    tryCalculateTotal() { 
        if(this._total || this.aggregateMethod === 'none'){
            return this._total;
        }

        let total: (number | null)[][] = [];
        let totalUnit = this.unit;
        let decimals: number | undefined = undefined;
        function canRowBeAdded(row: MetricTableValues){
            const doUnitsMatch = row.unit == totalUnit || (row.unit && totalUnit && doUnitDimensionsMatch(row.unit, totalUnit));
            if(total.length > 0 && !doUnitsMatch){
                total = [];
                return false;
            }
            totalUnit = totalUnit ?? row.unit;
            decimals = row.decimals;
            return true;
        }
        function addToTotal(row: MetricTableValues){
            for (let i = 0; i < row.value.length; i++) {
                const value = row.value[i];
                const totalValue = total[i];
                if(!totalValue){
                    total[i] = [];
                }
                if(typeof value === "number" && isFinite(value)){
                    total[i].push(value);
                } else if(totalValue == null){
                    total[i].push(null);
                }
            }
        }
        for (const row of this.rows.values()) {
            if(row instanceof MetricTableValues){
                if(!canRowBeAdded(row)){
                    break;
                }
                addToTotal(row);
            } else if(row instanceof MetricsRows){ 
                const subTotal = row.tryCalculateTotal();
                if(!subTotal || !canRowBeAdded(subTotal)){
                    total = [];
                    break;
                }
                addToTotal(subTotal);
            }
        }

        if(total.length > 0){
            const result: (number | null)[] = [];
            switch (this.aggregateMethod) {
                case 'avg':
                    for (let i = 0; i < total.length; i++) {
                        const values = total[i];
                        const sum = IterUtils.sum(values, v => v ?? 0);
                        result.push(sum / values.length);
                    }
                    break;  
                case 'sum':
                    for (let i = 0; i < total.length; i++) {
                        const values = total[i];
                        const sum = IterUtils.sum(values, v=> v ?? 0);
                        result.push(sum);
                    }
                    break;
                case 'min': {
                    for (let i = 0; i < total.length; i++) {
                        const values = total[i];
                        const sum = IterUtils.min(IterUtils.filterMap(values, v => {
                            if(typeof v === "number"){
                                return v;
                            }
                            return undefined;
                        }));
                        result.push(sum ?? null);
                    }
                    break
                }  
                case 'max': {
                    for (let i = 0; i < total.length; i++) {
                        const values = total[i];
                        const sum = IterUtils.max(IterUtils.filterMap(values, v => {
                            if(typeof v === "number"){
                                return v;
                            }
                            return undefined;
                        }));
                        result.push(sum ?? null);
                    }
                    break
                }                     
                default:
                    break;
            }
            this._total = new MetricTableValues(this.name, result, totalUnit, decimals);
        }

        return this._total;
    }
}

export function mergeMetricsToTable(
    metrics: MetricsGroup[], 
    areas: EquipmentArea[], 
    unitsMapper: UnitsMapper,
    logger: ScopedLogger,
    template: MetricTemplateGroup = ProjectMetricsDefaultTemplate
): MetricsTable {

    const perId = new Map(metrics.map((area) => [area.id, area]));
    const rowGroups: MetricsRows = new MetricsRows({name: "root", rows: new Map()});
    const headers: string[] = [];
    const metricsPerArea: (Partial<PropertyGroup> | null)[] = [];
    for (const context of areas) {
        const group = perId.get(context.id);
        metricsPerArea.push(group?.metrics ?? null);

        headers.push(context.name);
    }

    traverseByPropertyGroup(unitsMapper, template, metricsPerArea, rowGroups);
    
    return {
        headers,
        rows: rowGroups,
    };
}

function formatName(value: {key: string, name?: string}){
    return value.name ?? StringUtils.capitalizeFirstLatterInWord(value.key);
}

function traverseByPropertyGroup(
    unitsMapper: UnitsMapper,
    template: MetricTemplateGroup,
    source: (Partial<ProjectMetricsType> | null)[] = [],
    rows: MetricsRows,
   // path: (string | number)[] = [],
) {
    for (const child of template.children) {
        // const currentPath = path.slice();
        // currentPath.push(child.name);
        function getOrCreateGroup(templateGroup: MetricTemplateGroup): MetricsRows { 
            const group = rows.rows.get(templateGroup.key);
            if(!group){
                const newGroup = new MetricsRows({
                    name: templateGroup.name, 
                    rows: new Map(), 
                    unit: templateGroup.unit, 
                    aggregateMethod: templateGroup.aggregateMethod
                });
                rows.rows.set(templateGroup.key, newGroup);
                return newGroup;
            } else if(group instanceof MetricsRows){
                return group;
            } else {
                ErrorUtils.logThrow('unexpected type', group);
            }
        }
        function addProperty(group: MetricsRows, metric: { name: string, key: string, unit?: string}, prop: PropertyBase | null | undefined, idx: number){
            const key = `${metric.key}:${metric.name}`;
            const tableValue = group.rows.get(key);
            if(prop == null && tableValue == null){
                //skip
            } else if(prop instanceof NumberProperty || prop instanceof StringProperty || prop == null){
                if(!tableValue){
                    let unit: string | undefined;
                    let value = prop?.value ?? null;
                    let decimals = 0;
                    if(prop instanceof NumberProperty){
                        const targetUnit = metric.unit ?? prop.unit;
                        const valueUnit = unitsMapper.mapToConfigured({value: prop.value, unit: targetUnit});
                        unit = valueUnit.unit;
                        value = valueUnit.value;
                        decimals = prop.decimals;
                    }

                    const newArray = IterUtils.newArray<MetricsTableValueType>(idx + 1, () => null);
                    newArray[idx] = value;

                    group.rows.set(key, new MetricTableValues(
                        formatName(metric), 
                        newArray, 
                        unit, 
                        decimals
                    ));
                } else if(tableValue instanceof MetricTableValues){
                    const value = tableValue.value[idx];
                    if(!value){
                        for (let i = 0; i < idx; i++) {
                            tableValue.value[i] ??= null;
                        }
                        let value: MetricsTableValueType = prop?.value;
                        if(prop instanceof NumberProperty && !isFinite(prop.value)){
                            value = undefined;
                        } else if(prop instanceof NumberProperty && prop.unit){
                            if(!tableValue.unit){
                                throw new Error('unexpected unit');
                            }
                            value = convertThrow(prop.value, prop.unit, tableValue.unit);
                        }

                        tableValue.value[idx] = value;
                    } else {
                        console.error('property already exists', tableValue, value);
                    }
                } else {
                    ErrorUtils.logThrow('unexpected type', prop);
                }
            } else {
                console.error('unexpected type, skipped', prop);
            }
        }
        if (child instanceof MetricTemplateValue) {
            for (let i = 0; i < source.length; i++) {
                const src = source[i];
                const prop = src?.[child.key];

                if(prop instanceof PropertyBase || prop == null){ 
                    addProperty(rows, child, prop, i);
                } else {
                    const group = getOrCreateGroup(new MetricTemplateGroup({ name: child.name, aggregateMethod: child.aggregateMethod }));
                    for (const key in prop) {
                        const child = prop[key];
                        if(child instanceof PropertyBase || child == null){
                            addProperty(group, { name: key, key: key }, child, i);
                        } else {
                            console.error('unexpected type', child);
                        }
                    }
                }
            }
        } else if(child instanceof MetricTemplateGroup) {
            const newGroup = getOrCreateGroup(child);
            traverseByPropertyGroup(unitsMapper, child, source, newGroup);
        } else {
            console.error('unexpected type', child);
        }
    }
}