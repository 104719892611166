<script lang="ts">
    import { UnitsMapper } from "bim-ts";
    import { PUI_PropertyNodeNumber } from "ui-bindings";
    import flash from "../pui/flash";
    import NumberPropertyBase from "../pui/custom-components/NumberPropertyWithOption/NumberPropertyBase.svelte";
    import { getContext } from "svelte";
    import { VersionedStore } from "src/VersionedStore";
    import DoubleInputProperty from "./DoubleInputProperty.svelte";

    export let sourceProperty: PUI_PropertyNodeNumber;
    export let showValue: boolean = true;
    export let decimals: number | undefined = 3;

    const unitsMapper = getContext<VersionedStore<UnitsMapper>>("unitsMapper");

    let div: HTMLElement;

    let prevValue = sourceProperty?.value;
    $: if (prevValue != sourceProperty?.value) {
        prevValue = sourceProperty.value;
        if (div) {
            flash(div);
        }
    }
</script>

<DoubleInputProperty
    name={sourceProperty.name}
    showValue2={showValue}
    changedValue={sourceProperty.value}
>
    <div class="select" slot="value1">
        <slot name="body" />
    </div>
    <div
        class="input-value text-main-dark"
        class:read-only={sourceProperty.readonly}
        class:edit-field-container={!sourceProperty.readonly}
        slot="value2"
    >
        <NumberPropertyBase
            bind:sourceProperty
            unitsMapper={$unitsMapper}
            {decimals}
        />
        <slot name="tip" />
    </div>
</DoubleInputProperty>

<style lang="scss">
    .select {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
    }

    .input-value {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
</style>
