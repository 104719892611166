<script lang="ts">
import { setContext } from "svelte";
import { VersionedStore } from "../../VersionedStore";
import { Icon } from "../icon";
import {
  List,
  ListComponent,
  ListInput,
  ListItem,
} from "../list";
import { ContextMenu } from "./ContextMenu";
import { Action, ContextMenuStructure } from "./types";
import ContextMenuComponent from "../../reusable/ContextMenu.svelte";
import { Vector2 } from "math-ts";
import { LazyDerived } from "engine-utils-ts";

let contextMenuImpl = setContext(
    'context-menu',
    new ContextMenu()
);

type DataType = {
    source: ListInput,
    menuStructure: ContextMenuStructure,
    contextMenu: ContextMenu,
}

const data = new VersionedStore(LazyDerived.new1<DataType, ContextMenu>(
    'data',
    [],
    [contextMenuImpl],
    ([contextMenu]) => {
        let menuStructure: ContextMenuStructure;
        if (contextMenu.menuStructure instanceof ContextMenuStructure) {
            menuStructure = contextMenu.menuStructure;
        } else {
            menuStructure = contextMenu.menuStructure.poll();
        }
        const source = new ListInput(new List(
            menuStructure.items.map(x => {
                const item = new ListItem('')
                if (x instanceof Action) {
                    item.text = x.name;
                    item.after = x.hint;
                    item.before = x.icon && new Icon(x.icon)
                    item.onClick = () => {
                        x.action();
                        contextMenu.disposeMenu();
                    };
                    item.disabled = !!x.disabled;
                } else {
                    item.after = x.checkboxInput;
                    item.text = x.name;
                    if(x.onClick){
                        item.onClick = x.onClick;
                    }
                }
                return item;
            })
        ));
        return {
            source,
            menuStructure,
            contextMenu,
        }
    }
))

</script>

<ContextMenuComponent
    settings ={{
        isMenuOpened: $data.contextMenu.isMenuOpened,
        offsetPx: new Vector2($data.contextMenu.xOffsetPx, $data.contextMenu.yOffsetPx),
        dispose: () => $data.contextMenu.disposeMenu(),
        closeOnInteraction: $data.menuStructure.closeOnInteraction,
    }}
>
    <div slot="body">
        <ListComponent input={$data.source}/>
    </div>
</ContextMenuComponent>

<slot></slot>

<style lang="scss">

</style>
